<template>
    <div class="creator-tag-nameplate-wrapper">
        <div class="creator-tag-nameplate d-flex" @click="openDrawer">
            <b-img-lazy
                :src="superview.channel.logo"
                class="avatar avatar-xs my-auto mr-1"
            />

            <div class="nameplate d-flex">
                <div class="name my-auto px-2">
                    {{ superview.channel.display_name }}
                </div>
                <verified-badge
                    v-if="superview.channel.is_partner"
                    :platform="'twitch'"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import VerifiedBadge from '@/components/Client/VerifiedBadge'

    export default {
        name: 'creator-tag-nameplate',

        components: {
            VerifiedBadge,
        },

        props: {
            superview: {
                type: Object,
            },
        },

        methods: {
            openDrawer() {
                this.$store.commit('drawer:set', {
                    enabled: true,
                    creator: this.superview,
                })
            },
        },
    }
</script>

<style lang="scss">
.creator-tag-nameplate-wrapper {
    position: relative;

    .creator-tag-nameplate {
        border: 1px solid rgba(white, 0.1);
        border-radius: 50px;
        overflow: hidden;
        height: 24px;
        cursor: pointer;
        transition: 0.25s all;

        &:hover {
            background: rgba(white, 0.15);
            color: white;
        }

        .nameplate {
            margin-left: 24px;
        }

        .avatar {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }

        .name {
            font-size: 0.9rem;
        }
    }
}
</style>
