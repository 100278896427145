<template>
    <div class="view view-campaign-summary">
        <!-- <div class="row row-panels mb-3 row-statuses">
            <div class="col" v-for="status in statuses">
                <div class="panel p-3" @click="filters.status = status" :class="{ 'active': filters.status === status }">
                    <h4 class="h5 m-0">{{ status | capitalize }}</h4>
                    <small class="m-0">{{ summariesByStatus && summariesByStatus[status] ? summariesByStatus[status].length : 0 }} Creators</small>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-lg-2">
                <b-button
                    variant="outline-primary"
                    v-for="type in deliverableAssociationTypes"
                    @click="state.deliverableAssociationType = type"
                    :class="{
                        'active': state.deliverableAssociationType === type
                    }"
                >
                    {{ type | capitalize }}
                </b-button>
            </div>

            <div class="col-lg-2">
                <b-button
                    variant="outline-primary"
                    v-for="platformId in platformIds"
                    @click="onPlatformClick(platformId)"
                    :class="{
                        'active': state.platformId === platformId
                    }"
                >
                    {{ platformId | capitalize }}
                </b-button>
            </div>

            <div class="col-lg-8 d-flex">
                <b-button variant="primary" class="ml-auto" @click="exportCreators">Download CSV</b-button>
            </div>

            <div class="col-lg-12">
                <campaign-summary-table
                    :platformId="state.platformId"
                    :filters="filters"
                    :summaries="summaries"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { json2csvAsync } from 'json-2-csv'
import CampaignSummaryTable from '@/components/Tables/CampaignSummaryTable'

export default {
    name: 'view-campaign-report',

    metaInfo() {
        return {
            title: 'Campaign Report'
        }
    },

    components: {
        CampaignSummaryTable,
    },

    props: {
        campaign: {
            type: Object,
        },

        supercampaign: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            filters: {
                text: '',
                status: 'delivered',
            },

            deliverableAssociationTypes: ['computed', 'manual'],
            platformIds: ['twitch', 'youtube'],

            state: {
                deliverableAssociationType: 'computed',
                platformId: 'twitch',
            },

            statuses: ['delivered', 'budgeted', 'unbudgeted'],

            summariesByStatus: null,
            summaries: [],
        }
    },

    created() {
        this.getCampaignSummaries()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        selectedSummaries() {
            if (!this.summariesByStatus) return []
            return this.summariesByStatus[this.filters.status]
        },
    },

    watch: {
        state: {
            deep: true,
            handler(newValue, oldValue) {
                this.getCampaignSummaries()
            },
        }
    },

    methods: {
        getCampaignSummaries() {
            this.axios.get(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}/summary`, {
                params: {
                    type: this.state.deliverableAssociationType,
                    platform_id: this.state.platformId,
                },
            }).then(({ data }) => {
                this.summariesByStatus = data.payload.summariesByStatus
                this.summaries = data.payload.creators
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching summary for campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Summary',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        async exportCreators() {
            const link = document.createElement('a')

            const toHumanDuration = (value) => {
                const hours = Math.floor(value / (60 * 60))
                const minutes = Math.floor((value % (60 * 60)) / 60)

                let result = []
                if (hours > 0) result.push(`${hours}h`)
                if (minutes > 0) result.push(`${minutes}m`)
                return result.join(' ')
            }

            const csvResults = await json2csvAsync(
                _.map(this.summaries, (creator) => {
                    return {
                        'Logo': `=IMAGE("${_.get(creator, 'superview.channel.logo')}")`,
                        'Creator Name': _.get(creator, 'superview.channel.name'),
                        'Connect Page': `https://streamforge.com/twitch/${_.get(creator, 'superview.channel.name')}`,
                        'Stream AVG Viewers': numeral(_.get(creator, 'summary.avg_viewers')).format('0,0'),
                        '90 day ACV': numeral(_.get(creator, 'superview.stream.day_90.aggregation.viewers.avg')).format('0,0'),
                        'ACV Performance': numeral(_.get(creator, 'summary.acv_performance')).format('+0,0.0%'),
                        'CPC': numeral(_.get(creator, 'summary.cpc')).format('$0.00'),
                        'Clicks': numeral(_.get(creator, 'summary.unique_clicks')).format('0,0'),
                        'Time Streamed': toHumanDuration(_.get(creator, 'summary.sum_duration_seconds')),
                        'Time Hired For': toHumanDuration(_.get(creator, 'summary.target_duration_seconds')),
                        'Overdelivery': numeral(_.get(creator, 'summary.over_streamed')).format('+0,0.0%'),
                    }
                })
            )

            link.setAttribute("href", 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvResults))
            link.setAttribute("download", 'campaign-report.csv')
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },

        onPlatformClick(platformId) {
            this.state.platformId = platformId

            if (platformId === 'youtube') {
                this.state.deliverableAssociationType = 'manual'
            }
        },
    }
}
</script>

<style lang="scss">
.view-campaign-summary {
    .row-statuses {
        .panel {
            cursor: pointer;
            transition: 0.25s all;

            &.active {
                background: rgba(white, 0.1);
            }

            &:hover {
                background: rgba(white, 0.1);
            }
        }
    }
}
</style>
