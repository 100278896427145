import Vue from 'vue'
import _ from 'lodash'

export default {
    state: {
        campaigns: [],
        payloads: {

        },
        activeId: 0,
        timeFilter: 30
    },

    mutations: {
        'campaigns:update'(state, campaigns) {
            Vue.set(state, 'campaigns', campaigns)
        },

        'campaigns:payload:update'(state, { campaign_id, payload }) {
            Vue.set(state.payloads, campaign_id, payload)
        },

        'campaigns:clear'(state) {
            _.forEach({
                campaigns: null,
                analytics: {},
                streams: {},
                activeId: 0,
                timeFilter: 30
            }, (value, key) => {
                Vue.set(state, key, value)
            })
        },

        'campaigns:select'(state, id) {
            Vue.set(state, 'activeId', id)
        },

        'campaigns:setTimeFilter'(state, value) {
            Vue.set(state, 'timeFilter', value)
        }
    },

    getters: {
        activeCampaign(state) {
            if (!state.campaigns || !state.campaigns.length) {
                return false
            }

            if (state.campaigns[state.activeId]) {
                return state.campaigns[state.activeId]
            }

            if (state.campaigns[0]) {
                Vue.set(state, 'activeId', 0)
                return state.campaigns[0]
            }

            return false
        },

        campaigns(state) {
            return state.campaigns
        },

        clientAnalytics(state) {
            return state.analytics
        },

        clientStreams(state) {
            return state.streams
        },

        campaignActiveId(state) {
            return state.activeId
        },

        timeFilter(state) {
            return state.timeFilter
        }
    }
}
