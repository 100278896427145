<template>
    <div class="game-key-group-modal-wrapper">
        <b-modal id="game-key-group-modal" @ok="onSubmit" @hide="onHide">
            <template #modal-title>
                <h1 class="h4 m-0">Add Game Key Pool</h1>
            </template>

            <template #modal-footer="{ ok }">
                <b-button :variant="type === 'delete' ? 'danger' : 'success'" @click="ok()">
                    {{ type === 'delete' ? 'Remove Key Pool' : 'Add Key Pool' }}
                </b-button>
            </template>

            <form @submit.stop.prevent="onSubmit">
                <div class="row" v-if="type !== 'delete'">
                    <div class="col-lg-12">
                        <p class="m-0">Key pools are used to group together sets of keys, making it possible to differentiate between versions of a game, or DLC packs.</p>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <b-form-group label="Type">
                            <b-form-select
                                v-model="values.type"
                                :options="options.type"
                                required
                            />
                        </b-form-group>

                        <b-form-group label="Title">
                            <b-form-input
                                v-model="values.title"
                                autocomplete="off"
                            />
                        </b-form-group>

                        <b-form-group label="Description">
                            <b-form-textarea
                                v-model="values.description"
                                rows="3"
                                max-rows="5"
                            />
                        </b-form-group>

                        <b-form-group label="Game Platform">
                            <b-form-select
                                v-model="values.console_id"
                                :options="consoles"
                                value-field="id"
                                text-field="title"
                                required
                            />
                        </b-form-group>

                        <b-form-group label="Marketplace">
                            <b-form-select
                                v-model="values.marketplace_id"
                                :options="marketplaces"
                                value-field="id"
                                text-field="title"
                                required
                            />
                        </b-form-group>

                        <b-form-group label="Region Lock">
                            <b-form-select
                                v-model="values.region_id"
                                :options="regions"
                                value-field="id"
                                text-field="title"
                                required
                            />
                        </b-form-group>
                    </div>
                </div>

                <div class="row" v-if="type === 'delete'">
                    <div class="col-lg-12">
                        <p>Are you sure you want to remove this key pool and all it's keys from our system?</p>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'game-key-group-modal',

    props: {
        type: {
            type: String,
            default: 'create',
        },
        consoles: {
            type: Array,
            default: () => [],
        },
        marketplaces: {
            type: Array,
            default: () => [],
        },
        regions: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            options: {
                type: [{
                    text: 'Game',
                    value: 'game',
                }, {
                    text: 'DLC',
                    value: 'dlc',
                }],
            },

            values: {
                title: '',
                description: '',
                type: null,
                console_id: null,
                marketplace_id: null,
                region_id: null,
            },
        }
    },

    created() {
    },

    methods: {
        onSubmit() {
            this.$emit('submit', this.type, this.values)
        },

        onHide(event) {
            this.$emit('close', event)
        },
    }
}
</script>

<style lang="scss">
.game-key-group-modal-wrapper {
}
</style>
