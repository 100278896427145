<template>
    <div class="topbar py-4 mb-sm">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 d-flex">
                    <div class="brand my-auto">
                        <router-link :to="{ name: 'supercampaigns' }">
                            <img
                                src="/img/brand/streamforge-logo-text.png"
                                class="w-75"
                            />
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="menu d-flex h-100">
                        <div class="menu-item my-auto pr-4">
                            <router-link :to="{ name: 'supercampaigns' }">
                                Campaigns
                            </router-link
                            >
                        </div>
                        <div class="menu-item my-auto px-4">
                            <router-link :to="{ name: 'discover' }">
                                Discover
                            </router-link>
                        </div>
                        <div class="menu-item my-auto px-4">
                            <router-link :to="{ name: 'lists' }">
                                Lists
                            </router-link>
                        </div>
                        <div class="menu-item my-auto px-4">
                            <router-link :to="{ name: 'games' }">
                                Games
                            </router-link>
                        </div>
                        <div class="menu-item my-auto px-4" v-if="isStaff">
                            <router-link :to="{ name: 'users' }">
                                Admin
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 user-top-info d-flex">
                    <!-- User Information -->
                    <base-dropdown tag="div" class="user-wrapper" menu-classes="dropdown-menu-right mt-2">
                        <template slot="title">
                            <div
                                class="media align-items-center mb-0 d-flex"
                            >
                                <div class="media-body mr-3 d-none d-sm-flex">
                                    <span class="name mb-0 text-sm text-muted font-weight-bold" v-if="self.operator">{{ self.operator.first_name }} {{ self.operator.last_name }}</span>
                                </div>

                                <span class="avatar avatar-md rounded-circle">
                                    <img v-if="self.operator" :src="self.operator.avatar_url">
                                </span>

                                <font-awesome-icon :icon="['fas', 'chevron-down']" size="lg" class="user-info-icon ml-3" />
                            </div>
                        </template>

                        <!-- <router-link :to="{ name: 'settings' }" class="dropdown-item">
                            <div class="icon-wrapper">
                                <font-awesome-icon :icon="['fas', 'cog']" class="my-auto" />
                            </div>
                            <div class="dropdown-item-label">
                                Settings
                            </div>
                        </router-link> -->

                        <router-link :to="{ name: 'logout' }" class="dropdown-item">
                            <div class="icon-wrapper">
                                <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="my-auto" />
                            </div>
                            <div class="dropdown-item-label">
                                Logout
                            </div>
                        </router-link>
                    </base-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import _ from "lodash"

import BaseDropdown from '@/components/Base/BaseDropdown'

export default {
    components: {
        BaseDropdown,
    },

    data() {
        return {

        }
    },

    computed: {
        ...mapGetters([
            'self'
        ]),

        isStaff() {
            return this.self.operator && ['superadmin', 'staff'].includes(this.self.operator.role)
        },
    },

    created() {},

    methods: {

    },
}
</script>
<style lang="scss">
.topbar {
    position: relative;

    .user-top-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-left: auto;

        .currency-number {
            white-space: nowrap;
            position: relative;

            .max {
                opacity: 0;
                animation: slide-right 1s forwards;
            }
        }

        .media-body {
            display: flex;
            flex-direction: column;

            .name {
                font-size: 18px;
            }

            .type {
                font-size: 12px;
                text-align: right;
                white-space: nowrap;
            }
        }

        .dropdown {
            -webkit-touch-callout: none; /* iOS Safari */
              -webkit-user-select: none; /* Safari */
               -khtml-user-select: none; /* Konqueror HTML */
                 -moz-user-select: none; /* Firefox */
                  -ms-user-select: none; /* Internet Explorer/Edge */
                      user-select: none; /* Non-prefixed version, currently
                                            supported by Chrome and Opera */

            .dropdown-toggle {
                display: flex;
                width: 100%;
                transition: color 0.25s;
                color: #f6f6f8;

                &:hover {
                    color: #fff;
                }
            }

            .dropdown-menu {
                background: #1e2029;
                animation: slide-up 0.25s forwards;
                box-shadow: none;

                // If we don't have the arrow to dropdown source, use this
                border-radius: 5px;
                overflow: hidden;
                flex-direction: column;

                /* For when it is active */
                &.show {
                    display: flex !important;
                }

                .dropdown-menu-inner {
                    min-width: 500px;
                }

                .dropdown-items-wrapper {
                    max-height: 100%;
                    overflow-y: scroll;
                    padding: 12px;
                }

                .dropdown-item {
                    .dropdown-item-label {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                    }

                    &:hover {
                        color: #fff;
                        background: hsla(0, 0%, 100%, 0.05);
                        transition: 0.25s all;
                    }
                }
            }
        }

        .notifications-wrapper {
            display: flex;
            height: 100%;
            width: 100%;

            .mark-all-read {
                opacity: 0.6;
                cursor: pointer;
                transition: 0.25s all;

                &:hover {
                    opacity: 1;
                }
            }

            .notification-dropdown .dropdown-toggle::after {
                border-left: 0px;
                border-right: 0px;
            }

            .notification-dropdown {
                .read {
                    opacity: 0.35;
                }

                .notification-icon {
                    position: relative;
                    margin: auto 0;
                    font-size: 1.25em;

                    .new-count {
                        position: absolute;
                        top: -10px;
                        right: -10px;
                        height: 15px;
                        width: 15px;
                        border-radius: 50%;
                        background: #fcd878;
                        color: #000;
                        text-align: center;
                        font-size: 8px;
                        line-height: 15px;
                        font-weight: 700;
                        font-family: monospace;
                    }
                }

                .dropdown-header {
                    font-size: 16px;
                    padding: 6px 12px;
                    text-transform: none;
                }

                .dropdown-menu {
                    max-height: 50vh;
                    width: 300px;
                    padding-bottom: 0;
                    box-shadow: 0 0 10px rgba(18, 20, 29, 0.25);

                    &:before {
                        right: 34px;
                    }

                    .dropdown-item {
                        white-space: normal;
                        cursor: pointer;
                        position: relative;
                        color: rgba(255, 255, 255, 0.8);
                        font-size: 16px;
                        padding: 10px 12px;
                        border-radius: 6px;

                        & > svg {
                            margin-right: 0.5em;
                        }

                        &:active {
                            background: transparent;
                        }

                        span {
                            font-size: 14px;
                            font-style: italic;
                            font-weight: 400;
                            color: hsla(0, 0%, 100%, 0.5);
                        }

                        &:hover {
                            background-color: hsla(0, 0%, 100%, 0.05);
                        }
                    }
                }
            }
        }

        .user-wrapper {
            cursor: pointer;

            .user-info-icon {
                display: block !important;
                transition: 0.25s all;
            }

            &.show {
                .user-info-icon {
                    color: #fcd878;
                    transform: rotate(180deg);
                }
            }

            .avatar {
                position: relative;
                background-color: #08080c;

                .circle {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 38px;
                    height: 38px;
                    fill: none;
                    stroke-width: 10;
                    // stroke-linecap: round;
                    pointer-events: none;
                    margin: auto;
                    overflow: visible;
                    z-index: -1;
                }

                .rank {
                    position: absolute;
                    bottom: -12px;
                    // right: -20px;
                    right: 0px;
                    width: 100%;

                    img {
                        width: 22px;
                        height: 22px;
                        margin: 0 auto;
                        display: block;
                        opacity: 0;
                        transition: opacity 1s ease;

                        &[lazy="loaded"] {
                            opacity: 1;
                        }
                    }
                }
            }

            .dropdown-menu {
                padding: 12px;

                .dropdown-item {
                    position: relative;
                    color: hsla(0, 0%, 100%, 0.5);
                    display: flex;

                    padding: 6px 12px;
                    border-radius: 6px;
                    transition: color 200ms ease, background-color 200ms ease;

                    .icon-wrapper {
                        text-align: center;
                        margin-right: 0.75em;
                        transition: 1s all;
                        display: flex;

                        svg {
                            font-size: 1.15em;
                        }
                    }

                    & > svg {
                        margin: 0;
                    }

                    &:hover {
                        color: #fff;

                        .icon-wrapper {
                            color: #fcd878;
                        }
                    }

                    &:active, &.active {
                        background: inherit !important;

                        .icon-wrapper {
                            color: #fcd878 !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
