<template>
    <div class="app-wrapper">
        <notifications group="global" />

        <div v-if="isFullscreen" id="#app" class="app-fullscreen">
            <router-view :key="$route.path"/>
        </div>

        <div v-else id="#app" class="app">
            <topbar />
            <superdrawer />
            <drawer />

            <div class="container">
                <main>
                    <transition
                        name="fade"
                        origin="center"
                        mode="out-in"
                        :duration="250"
                        v-on:before-enter="transitionBeforeEnter"
                        v-on:enter="transitionEnter"
                        v-on:before-leave="transitionBeforeLeave"
                        v-on:leave="transitionLeave"
                        v-bind:css="false"
                    >
                        <router-view :key="$route.path"/>
                    </transition>
                </main>

                <router-view name="footer"></router-view>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
    import NotificationManager from '@/components/Managers/NotificationManager'
    import Topbar from './layout/Topbar'
    import Drawer from './layout/Drawer'
    import Superdrawer from './layout/Superdrawer'

    export default {
        components: {
            NotificationManager,
            Topbar,
            Drawer,
            Superdrawer,
        },

        metaInfo: {
            titleTemplate: (titleChunk) => {
                return titleChunk ? `${titleChunk} - Streamforge` : 'Streamforge';
            }
        },

        created() {
            this.bootstrapAxios()

            if (this.isAuthenticated) {
                this.getSelf()
            }
        },

        computed: {
            ...mapGetters([
                'tokens',
                'isAuthenticated',
                'self'
            ]),

            isFullscreen() {
                return this.$route.meta.layout && this.$route.meta.layout === 'fullscreen'
            },

            isFullwidth() {
                return this.$route.meta.layout && this.$route.meta.layout === 'fullwidth'
            },
        },

        watch: {
            isAuthenticated(value) {
                if (value) {
                    // Setup Axios
                    this.bootstrapAxios()
                    this.getSelf()
                }
            },

            tokens(current, previous) {
                if (current.core !== previous.core) {
                    // Setup Axios
                    this.bootstrapAxios()
                    this.getSelf()
                }
            }
        },

        methods: {
            getSelf() {
                this.axios.get(`/me`).then(({ data }) => {
                    this.$store.commit('self:operator', {
                        operator: data.payload.operator,
                    })
                }).catch((error) => {
                    let message = `Failed to find your account info.`
                    const apiMessage = _.get(error, 'response.data.payload.message')
                    if (apiMessage) message = apiMessage

                    this.$notify({
                        group: 'global',
                        type: 'alert',
                        title: 'Error - Account data not found',
                        text: message,
                        duration: 10000,
                        speed: 1000
                    })
                })
            },

            bootstrapAxios() {
                this.axios.defaults.headers.Authorization = `Bearer ${this.tokens.core}`

                this.axios.interceptors.response.use(
                    (response) => {

                        console.log(`[${response.status}] - ${this.$route.fullpath}`)

                        // If request using a bearer token fails, it has expired, and log them out
                        if (response.status === 401) {

                            // Save the URL the user was trying to access so we can redirect to it after
                            // So long as it is not an admin route
                            if (this.$route.fullpath && !this.$route.fullpath.includes('/admin')) {
                                this.$store.commit('authentication:redirect', this.$route.fullPath)
                            } else {
                                this.$store.commit('authentication:redirect', null)
                            }

                            // Logout
                            this.$store.commit('authentication:logout')

                            // Clear user info
                            this.$store.commit('self:clear')

                            this.$router.push({ name: 'login' })
                        }

                        return response
                    },

                    (error) => {
                        if (error.response && error.response.status === 403) {

                            if (error.response.data.message === 'You have been banned.') {
                                this.$router.push({ name: 'banned' })
                            }
                        }

                        return Promise.reject(error)
                    }
                )
            },

            transitionBeforeEnter(el) {
                el.style.opacity = 0
            },
            transitionEnter(el, done) {
                // console.error('transitionEnter | Document Hidden?', document.hidden)

                if (document.hidden) {
                    el.style.opacity = 1
                    done()
                } else {
                    Velocity(el, { opacity: 1 }, { duration: 250 }, { complete: done })
                }
            },
            transitionBeforeLeave(el) {
                el.style.opacity = 1
            },
            transitionLeave(el, done) {
                // console.error('transitionLeave | Document Hidden?', document.hidden)

                if (document.hidden) {
                    done()
                } else {
                    Velocity(el, { opacity: 1 }, { duration: 250, complete: done })
                }

                Velocity(el, { opacity: 0 }, { duration: 250, complete: done })
            }
        }
    }
</script>
<style lang="scss">
.app-fullscreen {
    height: 100vh;
}

.vue-notification-group {
    top: 25px !important;
    right: 25px !important;
}

.vue-notification {
    padding: 10px;
    font-size: 12px;
    border-radius: 5px;

    color: #ffffff;
    background: #44A4FC;
    border-left: 5px solid #187FE7;

    &.warn {
        background: #ffb648;
        border-left-color: #f48a06;
        color: #000;
    }

    &.error {
        background: #E54D42;
        border-left-color: #B82E24;
    }

    &.success {
        background: #68CD86;
        border-left-color: #42A85F;
    }
}
</style>
