<template>
<div>
    <b-table
        id="campaign-summary-table"
        ref="table"
        class="table-panels"
        :busy.sync="isBusy"
        :items="summaries"
        :fields="fields"
        sort-by="ended_at"
        :sort-desc="true"
        fixed
        borderless
        sort-icon-right
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
    >
        <template #cell(superview)="data">
            <div class="creator-wrapper my-auto d-flex" v-if="data.item.superview && data.item.superview">
                <b-img-lazy
                    :src="data.item.participant.platform_user_logo"
                    class="avatar my-auto"
                />

                <div
                    class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                >
                    <span class="h6 m-0">
                        {{ data.item.participant.platform_user_name }}

                        <verified-badge
                            v-if="data.item.superview.channel.partner"
                            :platform="'twitch'"
                        />
                    </span>

                    <small
                        :style="{
                            color: getColors.ranks[data.item.superview.rank.name]
                        }"
                    >
                        {{ data.item.superview.rank.name | capitalize }}
                    </small>
                </div>
            </div>
        </template>

        <template #cell(summary.acv_performance)="data">
            <div class="acv-performance d-flex flex-column">
                <b-progress
                    class="my-auto w-100"
                    :variant="data.value.variant"
                    :max="1"
                    height="1rem"
                    :class="{
                        'negative': data.value.is_negative,
                        'positive': !data.value.is_negative,
                        'high': data.value.ratio > 0.75,
                    }"
                    v-if="data.value.ratio"
                >
                    <b-progress-bar :value="data.value.ratio">
                        <span
                            class="progress-text"
                            :class="{
                                'text-danger': data.value.is_negative,
                                'text-success': !data.value.is_negative,
                            }">
                            {{ data.value.prefix }}{{ data.value.ratio | numeral('0,0%') }}
                        </span>
                    </b-progress-bar>
                </b-progress>

                <b-progress
                    class="my-auto w-100"
                    height="1rem"
                    v-else
                >
                <span class="w-100 text-center">
                    No Data
                </span>
                </b-progress>
            </div>
        </template>

        <template #cell(summary.over_streamed)="data">
            <div class="acv-performance d-flex flex-column">
                <b-progress
                    class="my-auto w-100"
                    :variant="data.value.variant"
                    :max="1"
                    height="1rem"
                    :class="{
                        'negative': data.value.is_negative,
                        'positive': !data.value.is_negative,
                        'high': data.value.ratio > 0.75,
                    }"
                    v-if="data.value.ratio"
                >
                    <b-progress-bar :value="data.value.ratio">
                        <span
                            class="progress-text"
                            :class="{
                                'text-danger': data.value.is_negative,
                                'text-success': !data.value.is_negative,
                            }">
                            {{ data.value.prefix }}{{ data.value.ratio | numeral('0,0%') }}
                        </span>
                    </b-progress-bar>
                </b-progress>

                <b-progress
                    class="my-auto w-100"
                    height="1rem"
                    v-else
                >
                <span class="w-100 text-center">
                    No Data
                </span>
                </b-progress>
            </div>
        </template>
    </b-table>

    <div class="d-flex">
        <b-pagination
            class="mx-auto"
            :total-rows="summaries.length"
            :per-page="pagination.perPage"
            v-model="pagination.currentPage"
         />
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import numeral from 'numeral'
import VerifiedBadge from '@/components/Client/VerifiedBadge'

export default {
    components: {
        VerifiedBadge,
    },

    props: {
        summaries: {
            type: Array,
        },

        filters: {
            type: Object,
        },

        platformId: {
            type: String,
            default: 'twitch',
        },
    },

    data() {
        return {
            isBusy: false,

            pagination: {
                perPage: 25,
                currentPage: 1,
                totalRows: 0,
            },
        }
    },

    computed: {
        ...mapGetters([
            'getColors'
        ]),

        fields() {
            if (this.platformId === 'twitch') {
                return [{
                    key: 'superview',
                    label: 'Creator',
                    sortable: true,
                    formatter: (value, key, item) => {
                        if (value) {
                            return numeral(value).format('0,0')
                        } else {
                            return '---'
                        }
                    },
                }, {
                    key: 'summary.cost',
                    label: 'Cost',
                    sortable: true,
                    formatter: (value, key, item) => {
                        if (value) {
                            return '$' + numeral(value).format('0,0')
                        } else {
                            return '---'
                        }
                    },
                },
                // {
                //     key: 'summary.sum_impressions',
                //     label: 'Impressions',
                //     sortable: true,
                //     formatter: (value, key, item) => {
                //         if (value) {
                //             return numeral(value).format('0,0')
                //         } else {
                //             return '---'
                //         }
                //     },
                // },
                {
                    key: 'summary.avg_viewers',
                    label: 'Stream ACV',
                    sortable: true,
                    formatter: (value, key, item) => {
                        if (value) {
                            return numeral(value).format('0,0')
                        } else {
                            return '---'
                        }
                    },
                }, {
                    key: 'summary.acv_performance',
                    label: 'ACV Performance',
                    sortable: true,
                    sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        if (value && item.summary) {
                            const ratio = item.summary.acv_performance
                            const isNegative = ratio > 0 ? false : true
                            const prefix = ratio > 0 ? '+' : '-'
                            const variant = ratio > 0 ? 'success' : 'danger'

                            return {
                                ratio: Math.abs(ratio),
                                is_negative: isNegative,
                                prefix: prefix,
                                variant: variant,
                            }
                        } else {
                            return {
                                ratio: 0,
                                is_negative: true,
                                prefix: '',
                                variant: 'primary',
                            }
                        }
                    },
                }, {
                    key: 'summary.unique_clicks',
                    label: 'Clicks',
                    sortable: true,
                    formatter: (value, key, item) => {
                        if (value) {
                            return numeral(value).format('0,0')
                        } else {
                            return '---'
                        }
                    },
                }, {
                    key: 'summary.cpc',
                    label: 'CPC',
                    sortable: true,
                    sortNullLast: true,
                    formatter: (value, key, item) => {
                        if (value) {
                            let result = `$${numeral(value).format('0,0.00')}`
                            if (item.rank) result += ` (${numeral(item.rank.cpc + 1).format('0o')})`
                            return result
                        } else {
                            return '---'
                        }
                    },
                },
                // {
                //     key: 'summary.ctr',
                //     label: 'CTR',
                //     sortable: true,
                //     formatter: (value, key, item) => {
                //         if (value) {
                //             let result = `${numeral(value).format('0,0%')}`
                //             if (item.rank) result += ` (${numeral(item.rank.ctr + 1).format('0o')})`
                //             return result
                //         } else {
                //             return '---'
                //         }
                //     },
                // }, {
                //     key: 'summary.cpm',
                //     label: 'CPM',
                //     sortable: true,
                //     sortNullLast: true,
                //     formatter: (value, key, item) => {
                //         if (value) {
                //             let result = `$${numeral(value).format('0,0.00')}`
                //             if (item.rank) result += ` (${numeral(item.rank.cpm + 1).format('0o')})`
                //             return result
                //         }  else {
                //             return '---'
                //         }
                //     },
                // },
                {
                    key: 'summary.sum_duration_seconds',
                    label: 'Duration',
                    sortable: true,
                    sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        const hours = Math.floor(value / (60 * 60))
                        const minutes = Math.floor((value % (60 * 60)) / 60)

                        let result = []
                        if (hours > 0) result.push(`${hours}h`)
                        if (minutes > 0) result.push(`${minutes}m`)
                        return result.join(' ')
                    }
                },
                {
                    key: 'summary.target_duration_seconds',
                    label: 'Hired For',
                    sortable: true,
                    sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        const hours = Math.floor(value / (60 * 60))
                        const minutes = Math.floor((value % (60 * 60)) / 60)

                        let result = []
                        if (hours > 0) result.push(`${hours}h`)
                        if (minutes > 0) result.push(`${minutes}m`)
                        return result.join(' ')
                    }
                },
                {
                    key: 'summary.over_streamed',
                    label: 'Overdelivery',
                    sortable: true,
                    sortDirection: 'desc',
                    formatter: (value, key, item) => {
                        if (value && item.summary) {
                            const ratio = item.summary.over_streamed
                            const isNegative = ratio > 0 ? false : true
                            const prefix = ratio > 0 ? '+' : '-'
                            const variant = ratio > 0 ? 'success' : 'danger'

                            return {
                                ratio: Math.abs(ratio),
                                is_negative: isNegative,
                                prefix: prefix,
                                variant: variant,
                            }
                        } else {
                            return {
                                ratio: 0,
                                is_negative: true,
                                prefix: '',
                                variant: 'primary',
                            }
                        }
                    },
                }]
            }




            if (this.platformId === 'youtube') {
                return [
                    {
                        key: 'superview',
                        label: 'Creator',
                        sortable: true,
                        formatter: (value, key, item) => {
                            if (value) {
                                return numeral(value).format('0,0')
                            } else {
                                return '---'
                            }
                        },
                    }, {
                        key: 'summary.cost',
                        label: 'Cost',
                        sortable: true,
                        formatter: (value, key, item) => {
                            if (value) {
                                return '$' + numeral(value).format('0,0')
                            } else {
                                return '---'
                            }
                        },
                    },
                    {
                        key: 'summary.sum_views',
                        label: 'Views',
                        sortable: true,
                        formatter: (value, key, item) => {
                            if (value) {
                                return numeral(value).format('0,0')
                            } else {
                                return '---'
                            }
                        },
                    }, {
                        key: 'summary.acv_performance',
                        label: 'Views Performance',
                        sortable: true,
                        sortDirection: 'desc',
                        formatter: (value, key, item) => {
                            if (value && item.summary) {
                                const ratio = item.summary.acv_performance
                                const isNegative = ratio > 0 ? false : true
                                const prefix = ratio > 0 ? '+' : '-'
                                const variant = ratio > 0 ? 'success' : 'danger'

                                return {
                                    ratio: Math.abs(ratio),
                                    is_negative: isNegative,
                                    prefix: prefix,
                                    variant: variant,
                                }
                            } else {
                                return {
                                    ratio: 0,
                                    is_negative: true,
                                    prefix: '',
                                    variant: 'primary',
                                }
                            }
                        },
                    }, {
                        key: 'summary.unique_clicks',
                        label: 'Clicks',
                        sortable: true,
                        formatter: (value, key, item) => {
                            if (value) {
                                return numeral(value).format('0,0')
                            } else {
                                return '---'
                            }
                        },
                    }, {
                        key: 'summary.cpc',
                        label: 'CPC',
                        sortable: true,
                        sortNullLast: true,
                        formatter: (value, key, item) => {
                            if (value) {
                                let result = `$${numeral(value).format('0,0.00')}`
                                if (item.rank) result += ` (${numeral(item.rank.cpc + 1).format('0o')})`
                                return result
                            } else {
                                return '---'
                            }
                        },
                    },
                    {
                        key: 'summary.ctr',
                        label: 'CTR',
                        sortable: true,
                        formatter: (value, key, item) => {
                            if (value) {
                                let result = `${numeral(value).format('0,0%')}`
                                if (item.rank) result += ` (${numeral(item.rank.ctr + 1).format('0o')})`
                                return result
                            } else {
                                return '---'
                            }
                        },
                    }, {
                        key: 'summary.cpm',
                        label: 'CPM',
                        sortable: true,
                        sortNullLast: true,
                        formatter: (value, key, item) => {
                            if (value) {
                                let result = `$${numeral(value).format('0,0.00')}`
                                if (item.rank) result += ` (${numeral(item.rank.cpm + 1).format('0o')})`
                                return result
                            }  else {
                                return '---'
                            }
                        },
                    },
                ]
            }

        },
    },

    methods: {
    }
}
</script>

<style lang="scss">
#campaign-summary-table {
    border-collapse:separate;
    border-spacing: 0 0.5rem;
    transition: opacity 0.25s;

    &[aria-busy="true"] {
        opacity: 0.5;
    }

    thead {
        th {
            font-weight: 400;
            line-height: 1.3;
            color: #f6f6f8;
            font-size: 1rem;

            &:first-child {
                width: 20%;
            }
        }
    }

    tbody {
        tr {
            background: rgba(white, 0.05);
            border-radius: 5px;
            margin-bottom: 5px;

            &:hover {
                background-color: rgba(white, 0.075);
            }

            td {
                vertical-align: middle;
            }

            td:first-child {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }

            td:last-child {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }

    .progress {
        background-color: rgba(white, 0.05);
        box-shadow: none;
        border-radius: 5px;

        &.negative {
            justify-content: flex-end;

            .progress-bar {
                .progress-text {
                    right: 100%;
                    padding-right: 0.5rem;
                }
            }
        }

        &.positive {
            .progress-bar {
                .progress-text {
                    left: 100%;
                    padding-left: 0.5rem;
                }
            }
        }

        &.high {
            &.negative {
                .progress-bar {
                    .progress-text {
                        left: 0;
                        right: initial;
                        padding-left: 0.5rem;
                        color: #000 !important;
                    }
                }
            }

            &.positive {
                .progress-bar {
                    .progress-text {
                        right: 0;
                        left: initial;
                        padding-right: 0.5rem;
                        color: #000 !important;
                    }
                }
            }
        }

        .progress-bar {
            position: relative;
        }

        .progress-text {
            font-weight: 600;
            position: absolute;
            color: #bec2cc;
        }
    }

    .avatar {
        width: 48px;
    }
}
</style>
