<template>
    <div class="list-modal-wrapper">
        <b-modal id="list-modal" @ok="onSubmit" @hide="onHide">
            <template #modal-title>
                <h1 class="h4 m-0">{{ type | capitalize }} List</h1>
            </template>

            <template #modal-footer="{ ok }">
                <b-button :variant="type === 'delete' ? 'danger' : 'success'" @click="ok()">
                    {{ type | capitalize }} List
                </b-button>
            </template>

            <form @submit.stop.prevent="onSubmit">
                <div class="row" v-if="type !== 'delete'">
                    <div class="col-lg-12">
                        <b-form-group label="Name">
                            <b-form-input v-model="values.name" @keydown.enter.prevent="null" type="text" />
                        </b-form-group>
                    </div>
                </div>

                <div class="row" v-if="type === 'delete'">
                    <div class="col-lg-12">
                        <p>Are you sure you want to delete this list?</p>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'list-modal',

    props: {
        type: {
            type: String,
            default: 'create',
        },
        list: {
            type: Object,
        },
    },

    watch: {
        list(newValue) {

            // If an existing list has been attached to the modal
            if (newValue) {
                this.values.name = this.list.name

            // If modal has been reset to create new list state
            } else {
                this.resetState()
            }
        },
    },

    data() {
        return {
            options: {
            },

            defaults: {
                name: '',
            },

            values: null,
        }
    },

    created() {
        this.resetState()
        // this.onGameSearch()
    },

    methods: {
        resetState() {
            this.values = _.cloneDeep(this.defaults)
        },

        onKeyupEnter() {
            this.onSubmit()
            this.onHide()
        },

        onSubmit() {
            let data

            if (this.type === 'create') {
                data = {
                    list: {
                        name: this.values.name,
                    },
                }
            }

            if (this.type === 'update') {
                data = {
                    list_id: this.list.id,
                    list: {
                        name: this.values.name,
                    },
                }
            }

            if (this.type === 'delete') {
                data = {
                    list_id: this.list.id,
                }
            }

            this.$emit('submit', this.type, data)
            this.resetState()
        },

        onHide(event) {
            this.$emit('close', event)
            this.resetState()
        },
    }
}
</script>

<style lang="scss">
.list-modal-wrapper {
}
</style>
