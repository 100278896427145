<template>
    <div class="view view-campaign-streams">
        <div class="row">

            <div class="col-lg-12">

                <chapters-table
                    :campaign-id="$route.params.campaign_id"
                    :chapters="chapters"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import ChaptersTable from '@/components/Tables/CampaignChaptersTable'

export default {
    name: 'view-campaign-streams',

    metaInfo() {
        return {
            title: 'Campaign Streams'
        }
    },

    components: {
        ChaptersTable,
    },

    props: {
        campaign: {
            type: Object,
        },
    },

    data() {
        return {
            filters: {
                text: ''
            },

            chapters: [],
        }
    },

    created() {
        // this.getCampaignChapters()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),
    },

    methods: {
        getCampaignChapters() {
            this.axios.get(`/campaigns/${this.$route.params.campaign_id}/chapters`).then(({ data }) => {
                this.chapters = data.payload.chapters
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching streams for campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Streams',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },
    }
}
</script>

<style lang="scss">
.view-campaign-streams {
    .row-creators {
        overflow-y: auto;
        max-height: 400px;
    }

    .creator {
        border-radius: 5px;
        cursor: pointer;
        transition: 0.25s all;

        &:hover {
            background: rgba(white, 0.05);
        }

        &.active {
            background: rgba(white, 0.075)
        }
    }

    .clickthroughs-table {
        &.row-panels .panel {
            transition: 0.25s all;
            cursor: pointer;

            &:hover {
                background: rgba(255, 255, 255, 0.075);
            }
        }
    }
}
</style>
