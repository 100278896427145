<template>
    <div class="view view-targeting mb-lg">


        <div class="row mt-4">
            <div class="col-lg-2 sidebar">
                <div class="row">
                    <div class="col-lg-12 d-flex">
                        <b-button v-b-modal.list-modal variant="primary" block>Create Segment</b-button>
                    </div>

                    <div class="col-lg-12 mt-sm">
                        <b-form-input
                            placeholder="Search for a profile"
                            autocomplete="off"
                            v-model="filter.text"
                        />
                    </div>

                    <div class="col-lg-12 mt-2">
                        <div class="row row-lists row-panels">
                            <div
                                class="col-lg-12 mb-1"
                                v-for="segment in filteredSegments"
                                @click="onListSelect(list)"
                                v-if="filteredSegments.length"
                            >
                                <div class="list-item py-2 px-3" :class="{
                                    'active': selectedListId === list.id
                                }">
                                    <p class="h6 m-0">{{ list.name }}</p>
                                    <small>{{ list.creators.length }} creators</small>

                                    <div class="actions mt-2" v-if="selectedListId === list.id">
                                        <b-button size="sm" variant="link" @click="onListActionClick(list, 'update')">Edit</b-button>
                                        <b-button size="sm" variant="link" class="text-danger" @click="onListActionClick(list, 'delete')">Delete</b-button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12" v-if="!filteredSegments.length">
                                <div class="list-item py-2 px-3">
                                    <p class="m-0">No targeting profiles found</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-10">
                <div class="row">
                    <div class="col-lg-12 mb-md">
                        <pre-filter-options
                            :isLocked="false"
                            :platform_id="'twitch'"
                            :defaults="filters.pre.default"
                            :override="filters.pre.override"
                            gameClass="col-lg-3"
                            @filters-change="onPreFiltersChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import PreFilterOptions from '@/components/Options/PreFilterOptions'

export default {
    name: 'view-targeting',

    metaInfo() {
        return {
            title: 'Targeting Profiles',
        }
    },

    components: {
        PreFilterOptions,
    },

    data() {
        return {
            segments: [],

            filter: {
                text: '',
            },

            filters: {
                pre: {
                    current: null,
                    override: null,
                    default: {
                        type: 'streamer',
                        languages: [{ value: 'en', text: 'English' }],
                        games: [],
                    },
                },
                post: {
                    current: null,
                    options: null,
                    defaults: null,
                },
            },

            selectedSegmentId: null,
        }
    },

    created() {
        this.axios.get(`/segments`)
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        filteredSegments() {
            return []
        },

        selectedSegment() {
            if (!this.selectedSegmentId) return null

            return _.find(this.segments, item => item.id === this.selectedSegmentId)
        },
    },

    methods: {
    },
}
</script>

<style lang="scss">
.view-targeting {
}
</style>
