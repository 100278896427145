<template>
    <div
        class="superdrawer-frame"
        :style="{
            'height': `${windowHeight}px`,
        }"
        v-if="superdrawer.enabled && superdrawer.creator"
    >
        <div class="close d-flex mr-2 mt-2" @click="onCloseDrawer">
            <font-awesome-icon
                class="m-auto"
                :icon="['fas', 'times']"
                size="lg"
            />
        </div>

        <div class="superdrawer-wrapper">
            <div class="backdrop" v-if="superdrawer.creator.channel.profile_banner"
                :style="{
                    'background-image': `url(${superdrawer.creator.channel.profile_banner})`
                }"
            />

            <div class="superdrawer">
                <div class="superdrawer-content d-flex flex-column">
                    <div class="d-flex p-3 header">
                        <div class="name-rank-avatar-wrapper my-auto d-flex">
                            <img
                                v-if="superdrawer.creator.platform_id === 'twitch'"
                                :src="superdrawer.creator.channel.logo"
                                class="avatar avatar-xl my-auto"
                            />

                            <img
                                v-if="superdrawer.creator.platform_id === 'youtube'"
                                :src="superdrawer.creator.channel.snippet.thumbnails.medium.url"
                                class="avatar avatar-xl my-auto"
                            />

                            <div class="name-rank-wrapper d-flex flex-column my-auto ml-3">
                                <h2 class="h4 m-0" v-if="superdrawer.creator.platform_id === 'twitch'">
                                    {{ superdrawer.creator.channel.display_name }}

                                    <verified-badge
                                        v-if="superdrawer.creator.channel.partner"
                                        :platform="'twitch'"
                                    />
                                </h2>

                                <h2 class="h4 m-0" v-if="superdrawer.creator.platform_id === 'youtube'">
                                    {{ superdrawer.creator.channel.snippet.title }}
                                </h2>

                                <p
                                    v-if="superdrawer.creator.rank"
                                    class="m-0"
                                    :style="{ color: getColors.ranks[superdrawer.creator.rank.name] }"
                                >{{ superdrawer.creator.rank.name | capitalize }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex px-3">
                        <div class="pr-1 w-50">
                            <router-link tag="button" class="btn btn-outline-primary w-100"
                                :to="{
                                    name: 'profiles-twitch',
                                    params: {
                                        channel_name: superdrawer.creator.channel.name
                                    }
                                }"
                            >View Profile</router-link>
                        </div>

                        <div class="pl-1 w-50" v-if="superdrawer.creator.platform_id === 'twitch'">
                            <a class="h4 m-0" :href="`https://twitch.tv/${superdrawer.creator.channel.name}`" target="blank">
                                <button class="btn btn-outline-info w-100">Twitch</button>
                            </a>
                        </div>

                        <div class="pl-1 w-50" v-if="superdrawer.creator.platform_id === 'youtube'">
                            <a class="h4 m-0" :href="`https://youtube.com/channel/${superdrawer.creator.channel.id}`" target="blank">
                                <button class="btn btn-outline-danger w-100">YouTube</button>
                            </a>
                        </div>
                    </div>

                    <div class="py-2">
                        <p class="m-0 text-center">Updated {{ superdrawer.creator._updated_at | moment('from', 'now') }}</p>
                    </div>

                    <div class="px-3 tabs">
                        <b-tabs pills>
                            <b-tab title="Metrics" class="" v-if="superdrawer.creator.platform_id === 'twitch'">
                                <div class="row mt-4">
                                    <div class="col-lg-12">
                                        <h3 class="h5">Averages</h3>
                                    </div>

                                    <div class="col-lg-6">
                                        <h5 class="h6">Viewers</h5>
                                        <p>{{ superdrawer.creator.stream.day_90.aggregation.viewers.avg | numeral('0,0') }}</p>
                                    </div>
                                    <!-- <div class="col-lg-6">
                                        <h5 class="h6">Chatters</h5>
                                        <p>{{ superdrawer.creator.stream.day_90.aggregation.chatters.avg | numeral('0,0') }}</p>
                                    </div> -->
                                    <!-- <div class="col-lg-6">
                                        <h5 class="h6">Engagement</h5>
                                        <p>{{ superdrawer.creator.stream.day_90.aggregation.chatters.avg / superdrawer.creator.stream.day_90.aggregation.viewers.avg | numeral('0%') }}</p>
                                    </div> -->

                                    <div class="col-lg-12 mt-3">
                                        <h3 class="h5">Totals</h3>
                                    </div>

                                    <div class="col-lg-6">
                                        <h5 class="h6">Followers</h5>
                                        <p>{{ superdrawer.creator.channel.followers | numeral('0,0') }}</p>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5 class="h6">Views</h5>
                                        <p>{{ superdrawer.creator.channel.views | numeral('0,0') }}</p>
                                    </div>

                                    <div class="col-lg-12 mt-3">
                                        <h3 class="h5">Peak</h3>
                                    </div>

                                    <div class="col-lg-6">
                                        <h5 class="h6">Viewers</h5>
                                        <p>{{ superdrawer.creator.stream.day_90.aggregation.viewers.peak.value | numeral('0,0') }}</p>
                                    </div>
                                    <!-- <div class="col-lg-6">
                                        <h5 class="h6">Chatters</h5>
                                        <p>{{ superdrawer.creator.stream.day_90.aggregation.chatters.peak.value | numeral('0,0') }}</p>
                                    </div> -->
                                </div>
                            </b-tab>

                            <b-tab title="Metrics" class="" v-if="superdrawer.creator.platform_id === 'youtube'">
                                <div class="row mt-4">
                                    <div class="col-lg-12">
                                        <h3 class="h5">Averages</h3>
                                    </div>

                                    <div class="col-lg-6">
                                        <h5 class="h6">Views per Video</h5>
                                        <p>{{ superdrawer.creator.video.day_90.aggregation.views.avg | numeral('0,0') }}</p>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5 class="h6">Comments</h5>
                                        <p>{{ superdrawer.creator.video.day_90.aggregation.comments.avg | numeral('0,0') }}</p>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5 class="h6">Likes</h5>
                                        <p>{{ superdrawer.creator.video.day_90.aggregation.likes.avg | numeral('0,0') }}</p>
                                    </div>

                                    <div class="col-lg-12 mt-3">
                                        <h3 class="h5">Totals</h3>
                                    </div>

                                    <div class="col-lg-6">
                                        <h5 class="h6">Followers</h5>
                                        <p>{{ superdrawer.creator.channel.statistics.subscriberCount | numeral('0a') }}</p>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5 class="h6">Views</h5>
                                        <p>{{ superdrawer.creator.channel.statistics.viewCount | numeral('0a') }}</p>
                                    </div>

                                    <div class="col-lg-12 mt-3">
                                        <h3 class="h5">Peak</h3>
                                    </div>

                                    <div class="col-lg-6">
                                        <h5 class="h6">Viewers</h5>
                                        <p>{{ superdrawer.creator.video.day_90.aggregation.views.peak.value | numeral('0,0') }}</p>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5 class="h6">Comments</h5>
                                        <p>{{ superdrawer.creator.video.day_90.aggregation.comments.peak.value | numeral('0,0') }}</p>
                                    </div>
                                </div>
                            </b-tab>

                            <b-tab title="Games">
                                <div
                                    class="row games-wrapper mt-4"
                                    :style="{
                                        'max-height': `${tabContentHeight}px`
                                    }"
                                >
                                    <div class="col-lg-12 mb-3" v-for="game in games">
                                        <div
                                            class="p-3 game h-100 d-flex"
                                            :style="{
                                                'background-image': `url(https://static-cdn.jtvnw.net/ttv-boxart/${encodeURI(game.name)}-285x380.jpg)`
                                            }"
                                        >
                                            <div class="row my-auto w-100">

                                                <div class="col-lg-9">
                                                    <div class="row">
                                                        <div class="col-lg-12 mb-1 d-flex">
                                                            <span class="h5 my-0 game-name" v-tooltip="{
                                                                content: `
                                                                    <div class='font-weight-bold text-center'>${game.name}</div>
                                                                    <div class='text-center'>Streamed ${game.count} times</div>`,
                                                                html: true,
                                                            }">
                                                                <span class="text-primary">({{ game.count }})</span>
                                                                {{ game.name }}
                                                            </span>

                                                        </div>

                                                        <div class="col-lg-12" v-if="superdrawer.creator.platform_id === 'twitch'">
                                                            {{ game.duration_streamed_human }}
                                                        </div>

                                                        <div class="col-lg-12" v-if="superdrawer.creator.platform_id === 'youtube'">
                                                            Made {{ game.count }} videos
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3 d-flex">
                                                    <span class="h3 m-auto">{{ game.affinity | numeral('0%') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                            <b-tab title="About" class="">
                                <div class="row mt-4">
                                    <div class="col-lg-12 mb-3">
                                        <h5 class="h6">Platform ID ({{ superdrawer.creator.platform_id | capitalize }})</h5>
                                        <p>{{ superdrawer.creator._id }}</p>

                                        <h5 class="h6">Tags</h5>

                                        <b-badge
                                            variant="primary"
                                            v-if="superdrawer.creator.platform_id === 'twitch'"
                                        >
                                            <span>{{ getLanguageFromCode(superdrawer.creator.channel.language || superdrawer.creator.channel.broadcaster_language) }}</span>
                                        </b-badge>

                                        <b-badge
                                        class="ml-2"
                                            variant="primary"
                                            v-if="superdrawer.creator.channel.mature"
                                        >
                                            <span>Mature</span>
                                        </b-badge>
                                    </div>

                                    <div class="col-lg-12" v-if="superdrawer.creator.platform_id === 'twitch'">
                                        <h5 class="h6">Description</h5>
                                        <p>{{ superdrawer.creator.channel.description }}</p>
                                    </div>

                                    <div class="col-lg-12" v-if="superdrawer.creator.platform_id === 'youtube'">
                                        <h5 class="h6">Description</h5>
                                        <p>{{ superdrawer.creator.channel.snippet.description }}</p>
                                    </div>

                                    <div class="col-lg-12" v-if="superdrawer.creator.platform_id === 'twitch'">
                                        <h5 class="h6">Last Stream Title</h5>
                                        <p>{{ superdrawer.creator.channel.status }}</p>
                                    </div>

                                    <div class="col-lg-12" v-if="superdrawer.creator.platform_id === 'twitch'">
                                        <h5 class="h6">Last Streamed Game</h5>
                                        <p>{{ superdrawer.creator.channel.game }}</p>
                                    </div>

                                    <!-- TODO: Calculate this on the ETL System -->
                                    <!-- <div class="col-lg-6">
                                        <h5 class="h6">Stream Quality</h5>
                                        <p>1080p</p>
                                    </div> -->
                                </div>
                            </b-tab>

                            <b-tab title="Profiling" class="px-1" v-if="superdrawer.creator.metadata">
                                <div class="row mt-4">
                                    <div class="col-lg-12 mb-3">
                                        <h5 class="h6">Emails</h5>

                                        <p v-for="email in superdrawer.creator.metadata.emails">
                                            {{ email }}
                                        </p>
                                    </div>

                                    <div class="col-lg-12 mb-3" v-if="superdrawer.creator.metadata.location">
                                        <h5 class="h6">Location</h5>
                                        <p v-for="(location, key) in superdrawer.creator.metadata.location">
                                            {{ key }}: {{ location }}
                                        </p>
                                    </div>

                                    <div class="col-lg-12">
                                        <h5 class="h6">Links</h5>
                                        <p v-for="(link, hostname) in superdrawer.creator.metadata.links">
                                            {{ link.href }}
                                        </p>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import VerifiedBadge from '@/components/Client/VerifiedBadge'

export default {
    name: 'superdrawer',

    components: {
        VerifiedBadge,
    },

    data() {
        return {
            windowHeight: window.innerHeight,
            tabContentHeight: (window.innerHeight * 0.8) - 149,

            participant: null,
            superview: null,
        }
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.windowHeight = window.innerHeight
            this.tabContentHeight = (window.innerHeight * 0.8) - 149
        })
    },

    watch: {
        superdrawer: {
            deep: true,
            handler(value) {
                if (value.participant) {
                    this.getParticipant(value.participant.id)
                }
            },
        },
    },

    computed: {
        ...mapGetters([
            'getColors',
            'superdrawer',
            'getLanguageFromCode',
        ]),

        games() {
            let games = []

            if (this.superdrawer.creator.platform_id === 'twitch') {
                games = this.superdrawer.creator.stream.day_90.accumulation.games.list
                if (!games || !games.length) return []

                const totalSecondsStreamed = _.sumBy(games, 'duration_seconds')

                games = _.map(games, (game) => {
                    game.affinity = game.duration_seconds / totalSecondsStreamed
                    game.duration_streamed_human = this.toHumanTime(game.duration_seconds)
                    return game
                })
            }

            if (this.superdrawer.creator.platform_id === 'youtube') {
                games = this.superdrawer.creator.video.day_90.accumulation.games.list
                if (!games || !games.length) return []

                // const totalVideosCount = this.superdrawer.creator.video.day_90.accumulation.videos.count
                const totalVideosCount = _.sumBy(this.superdrawer.creator.video.day_90.accumulation.games.list, 'count')

                games = _.map(games, (game) => {
                    game.affinity = game.count / totalVideosCount
                    game.video_count = game.count
                    return game
                })
            }

            return _.orderBy(games, ['affinity'], ['desc'])
        },
    },

    methods: {
        onCloseDrawer() {
            this.$store.commit('superdrawer:set', {
                enabled: false,
                creator: null,
            })
        },

        getParticipant(id) {
            this.axios.get(`/participants/${id}`, {
                params: {
                    relations: ['tasks', 'link'],
                },
            }).then(({ data }) => {
                this.participant = data.payload.participant
                this.superview = data.payload.superview

            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message
                    ? error.message
                    : `There was an error fetching your lists.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Fetch Lists',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        /**
         * Translates seconds into human readable format of seconds, minutes, hours, days, and years
         *
         * @param  {number} seconds The number of seconds to be processed
         * @return {string}         The phrase describing the the amount of time
         */
        toHumanTime(seconds) {
             if (seconds < 3600) {
                 return `Less than an hour`
             }

            const levels = [
                [Math.floor(seconds / 31536000), 'years'],
                [Math.floor((seconds % 31536000) / 86400), 'days'],
                [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
                // [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
                // [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
            ]

            let returntext = ''

            for (let i = 0, max = levels.length; i < max; i++) {
                if (levels[i][0] === 0) continue;
                returntext += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length-1): levels[i][1])
            }

            return returntext.trim()
        }
    }
}
</script>

<style lang="scss">
.superdrawer-frame {
    position: fixed;
    top: 0;
    right: 0;
    width: 50vw;
    min-width: 350px;
    // height: 100%;
    background: #2C2E3B;
    z-index: 1000;

    .superdrawer-wrapper, .superdrawer, .superdrawer-content {
        height: 100%;
    }

    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 20vh;
        background-position: center;
        background-size: cover;
        opacity: 0.5;

        &:before {
            content: '';
            width: 100%;
            height: 20vh;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(to top, #2C2E3B 0%, rgba(30, 32, 41, 0.8) 75%, rgba(30, 32, 41, 0.4) 100%);
        }
    }

    .close {
        cursor: pointer;
        position: absolute;
        right: 100%;
        background: rgba(white, 0.05);
        border-radius: 50%;
        height: 50px;
        width: 50px;
    }

    .header {
        height: 20%;
        position: relative;
    }

    .tabs {
        height: 80%;
    }

    .games-wrapper {
        overflow-y: scroll;

        .game {
            background-position: center;
            background-size: cover;
            border-radius: 5px;
            position: relative;
            overflow: hidden;

            &:before {
                content: "";
                background: linear-gradient(
                    90deg,
                    #1e2029 0,
                    rgba(30, 32, 41, 0.9) 50%,
                    rgba(30, 32, 41, 0.6)
                );
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

            .game-name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
