import Vue from 'vue'

export default {
    state: {
        twitch: [{
            value: "en",
            text: "English"
        }, {
            value: "zh",
            text: "Chinese"
        }, {
            value: "ja",
            text: "Japanese"
        }, {
            value: "ko",
            text: "Korean"
        }, {
            value: "es",
            text: "Spanish"
        }, {
            value: "fr",
            text: "French"
        }, {
            value: "de",
            text: "German"
        }, {
            value: "it",
            text: "Italian"
        }, {
            value: "pt",
            text: "Portuguese"
        }, {
            value: "sv",
            text: "Swedish"
        }, {
            value: "no",
            text: "Norwegian"
        }, {
            value: "da",
            text: "Danish"
        }, {
            value: "nl",
            text: "Dutch"
        }, {
            value: "fi",
            text: "Finnish"
        }, {
            value: "pl",
            text: "Polish"
        }, {
            value: "el",
            text: "Greek"
        }, {
            value: "ru",
            text: "Russian"
        }, {
            value: "tr",
            text: "Turkish"
        }, {
            value: "cs",
            text: "Czech"
        }, {
            value: "sk",
            text: "Slovak"
        }, {
            value: "hu",
            text: "Hungarian"
        }, {
            value: "ar",
            text: "Arabic"
        }, {
            value: "bg",
            text: "Bulgarian"
        }, {
            value: "th",
            text: "Thai"
        }, {
            value: "vi",
            text: "Vietnamese"
        }, {
            value: "asl",
            text: "American Sign Language"
        }, {
            value: "other",
            text: "Other"
        }]
    },

    getters: {
        getLanguageFromCode: (state) => (localeCode) => {
            const match = _.find(state.twitch, (language) => {
                return language.value === localeCode
            })

            if (match) return match.text
            return `Language not found`
        },

        twitchLanguages: (state) => {
            return state.twitch
        },

        languages: (state) => {
            return state
        },
    }
}
