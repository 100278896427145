<template>
    <div class="view view-twitch-profile">

        <div class="row mb-4">
            <div class="col-lg-12 mb-2">
                <h4 class="h2 m-0">{{ $route.params.channel_name }}</h4>
            </div>

            <div class="col-lg-12 mb-2" v-if="superview">
                <creator-table
                    :creators="[{ superview }]"
                    type="discover"
                />
            </div>

            <div class="col-lg-12" v-if="tableChapters.length">
                <h1>Chapters</h1>
                <chapters-table :items="tableChapters" />
            </div>

            <div class="col-lg-12" v-if="tableStreams.length">
                <h1>Streams</h1>
                <chapters-table :items="tableStreams" />
            </div>

            <div class="col-lg-12 mt-5" v-if="superview">
                <pre v-html="printJSON(superview)" />
            </div>

            <div class="col-lg-12 mt-5" v-if="chapters">
                <pre v-html="printJSON(chapters)" />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import CreatorTable from '@/components/Tables/CreatorTable'
import ChaptersTable from '@/components/Tables/TwitchProfileChaptersTable'

export default {
    name: 'view-twitch-profile',

    metaInfo() {
        return {
            title: 'Twitch Profile',
        }
    },

    components: {
        CreatorTable,
        ChaptersTable,
    },

    data() {
        return {
            superview: null,
            chapters: [],
        }
    },

    created() {
        this.getTwitchProfile()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        tableChapters() {
            if (!this.chapters && !this.superview) return []

            return _.map(this.chapters, (chapter) => {
                return {
                    superview: this.superview,
                    chapter: chapter,
                }
            })
        },

        tableStreams() {
            if (!this.streams && !this.superview) return []

            return _.map(this.streams, (chapter) => {
                return {
                    superview: this.superview,
                    chapter: chapter,
                }
            })
        },
    },

    methods: {
        getTwitchProfile() {
            const channelName = this.$route.params.channel_name.toLowerCase()

            this.axios.get(`/discover/twitch/${channelName}`).then(({ data }) => {
                this.superview = data.payload.superview
                this.chapters = data.payload.chapters
                this.streams = data.payload.streams
            })
        },

        printJSON(json) {
            if (typeof json != 'string') {
                 json = JSON.stringify(json, undefined, 2);
            }
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                var cls = 'number';
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = 'key';
                    } else {
                        cls = 'string';
                    }
                } else if (/true|false/.test(match)) {
                    cls = 'boolean';
                } else if (/null/.test(match)) {
                    cls = 'null';
                }
                return '<span class="' + cls + '">' + match + '</span>';
            });
        },
    }
}
</script>

<style lang="scss">
.view-twitch-profile {
    pre {
        outline: 1px solid rgba(white, 0.075);
        padding: 15px;
        margin: 5px;
    }

    .string { color: green; }
    .number { color: darkorange; }
    .boolean { color: blue; }
    .null { color: magenta; }
    .key { color: red; }
}
</style>
