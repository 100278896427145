<template>
    <div class="task-modal-wrapper">
        <b-modal id="task-modal" @ok="onSubmit" @hide="onHide">
            <template #modal-title>
                <h1 class="h4 m-0" v-if="task">Update Task</h1>
                <h1 class="h4 m-0" v-if="!task">Create New Task</h1>
            </template>

            <template #modal-footer="{ ok }">
                <b-button variant="success" @click="ok()">
                    {{ task ? 'Update Task' : 'Create Task' }}
                </b-button>
            </template>

            <b-alert variant="light" show v-if="task">
                You are editing a "{{ getFromDictionary(`task.title.${task.type}`)}}" task for {{ creator.superview.channel.display_name }}.
            </b-alert>

            <b-alert variant="light" show v-if="!task && creator">
                You are creating a new task for {{ creator.superview.channel.display_name }}.
            </b-alert>

            <form ref="form" @submit.stop.prevent="onSubmit" class="form-task-create">
                <div class="row">
                    <div class="col-lg-12" v-if="!this.task">
                        <b-form-group label="Task Type">
                            <b-form-select v-model="values.type">
                                <b-form-select-option v-for="typeOption in options.type" :value="typeOption.value">
                                    {{ getFromDictionary(`task.title.${typeOption.value}`) }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <!-- Youtube Video -->
                    <div class="col-lg-12" v-if="values.type === 'youtube-video'">
                        <div class="row">
                            <!-- <div class="col-lg-12 mb-4">
                                <b-form-checkbox
                                    v-model="values.is_approval_required"
                                    value="true"
                                    unchecked-value="false"
                                >Approval Required</b-form-checkbox>
                            </div> -->

                            <div class="col-lg-6">
                                <b-form-group label="Payout amount">
                                    <b-input-group prepend="$" append=".00">
                                        <b-form-input v-model="values.payout_amount" type="number" />
                                    </b-input-group>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <!-- Twitch Stream -->
                    <div class="col-lg-12" v-if="values.type === 'twitch-stream'">
                        <div class="row">
                            <div class="col-lg-4">
                                <b-form-group label="Hours">
                                    <b-form-input v-model="values.payload.duration_hours" type="number" />
                                </b-form-group>
                            </div>

                            <div class="col-lg-6">
                                <b-form-group label="Payout amount">
                                    <b-input-group prepend="$" append=".00">
                                        <b-form-input v-model="values.payout_amount" type="number" />
                                    </b-input-group>
                                </b-form-group>
                            </div>

                        </div>
                    </div>

                    <!-- Twitch Stream -->
                    <div class="col-lg-12" v-if="values.type === 'custom-text'">
                        <div class="row">
                            <div class="col-lg-12">
                                <b-form-group label="Title">
                                    <b-form-input v-model="values.payload.title" type="text" />
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <!-- Retrieve Key -->
                    <div class="col-lg-12" v-if="values.type === 'retrieve-key'">
                        <div class="row">
                            <div class="col-lg-12" v-if="campaign && campaign.game && campaign.game.key_groups">
                                <b-form-group label="Key Pool">
                                    <b-form-select
                                        v-model="values.payload.group_id"
                                        :options="campaign.game.key_groups"
                                        text-field="title"
                                        value-field="id"
                                    />
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <b-form-group label="Notes for creator">
                            <b-form-textarea
                                v-model="values.notes"
                                placeholder="Enter something..."
                                rows="3"
                                max-rows="6"
                            />
                        </b-form-group>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import DropdownTextFilterable from '@/components/Dropdowns/DropdownTextFilterable'

export default {
    name: 'task-modal',

    components: {
        DropdownTextFilterable,
    },

    props: {
        campaign: {
            type: Object,
        },
        task: {
            type: Object,
        },
        creator: {
            type: Object,
        },
    },

    watch: {
        task(newValue) {

            // If an existing task has been attached to the modal
            if (newValue) {
                // Set game from campaign
                this.values.type = this.task.type
                this.values.payout_amount = this.task.payout_amount
                this.values.notes = this.task.notes

                this.values.payload.title = this.task.payload.title
                this.values.payload.duration_hours = this.task.payload.duration_seconds / (60 * 60)
                this.values.payload.group_id = this.task.payload.group_id

            // If modal has been reset to create new task state
            } else {
                this.resetState()
            }
        },
    },

    data() {
        return {
            options: {
                type: [{
                    value: 'custom-text',
                    text: 'Custom',
                }, {
                    value: 'twitch-stream',
                    text: 'Twitch Stream',
                }, {
                    value: 'youtube-video',
                    text: 'YouTube Video',
                }, {
                    value: 'twitch-panel',
                    text: 'Twitch Panel',
                }, {
                    value: 'retrieve-key',
                    text: 'Retrieve Key',
                }],
            },

            defaults: {
                type: 'twitch-panel',
                is_approval_required: false,
                payout_amount: 0,
                notes: null,

                payload: {
                    // Twitch Stream
                    duration_hours: 0,
                    title: null,
                    group_id: null,
                },
            },

            values: null,
        }
    },

    created() {
        this.resetState()
    },

    computed: {
        ...mapGetters([
            'getFromDictionary',
        ]),

        // clickthroughLinkURL() {
        //     const hasCampaignClickthroughLink = !!this.campaign && !!this.campaign.payload.clickthrough_link
        //     const hasParticipantClickthroughPath = !!this.creator && !!this.creator.participant.link && !!this.creator.participant.link.path
        //
        //     if (hasCampaignClickthroughLink && hasParticipantClickthroughPath) {
        //         return `${this.campaign.payload.clickthrough_link}${this.creator.participant.link.path}`
        //     }
        //
        //     return `No clickthrough set for campaign`
        // },
        //
        // panelImageURL() {
        //     const hasCampaignPanelImageUrl = !!this.campaign && !!this.campaign.payload && !!this.campaign.payload.panel_image_url
        //
        //     if (hasCampaignPanelImageUrl) {
        //         return this.campaign.payload.panel_image_url
        //     }
        //
        //     return `No panel image url set for campaign`
        // }
    },

    methods: {
        resetState() {
            this.values = _.cloneDeep(this.defaults)
        },

        onSubmit() {
            let data
            const state = this.task ? 'update' : 'create'

            if (this.values.type === 'twitch-stream') {

                // We are creating a new task
                if (state === 'create') {
                    data = {
                        participants: [
                            this.creator.participant.id,
                        ],
                        task: {
                            type: this.values.type,
                            payout_amount: Number(this.values.payout_amount),
                            notes: this.values.notes,
                            payload: {
                                duration_seconds: this.values.payload.duration_hours * (60 * 60),
                            },
                        },
                    }
                }

                // We are updating an existing task
                if (state === 'update') {
                    data = {
                        task_id: this.task.id,
                        task: {
                            payout_amount: Number(this.values.payout_amount),
                            notes: this.values.notes,
                            payload: {
                                duration_seconds: this.values.payload.duration_hours * (60 * 60),
                            },
                        },
                    }
                }
            }

            if (this.values.type === 'youtube-video') {

                // We are creating a new task
                if (state === 'create') {
                    data = {
                        participants: [
                            this.creator.participant.id,
                        ],
                        task: {
                            type: this.values.type,
                            payout_amount: Number(this.values.payout_amount),
                            notes: this.values.notes,
                            payload: {},
                        },
                    }
                }

                // We are updating an existing task
                if (state === 'update') {
                    data = {
                        task_id: this.task.id,
                        task: {
                            payout_amount: Number(this.values.payout_amount),
                            notes: this.values.notes,
                            payload: {},
                        },
                    }
                }
            }

            if (this.values.type === 'twitch-panel') {

                // We are creating a new task
                if (state === 'create') {
                    data = {
                        participants: [
                            this.creator.participant.id,
                        ],
                        task: {
                            type: this.values.type,
                            notes: this.values.notes,
                        },
                    }
                }

                // We are updating an existing task
                if (state === 'update') {
                    data = {
                        task_id: this.task.id,
                        task: {
                            notes: this.values.notes,
                        },
                    }
                }
            }

            if (this.values.type === 'retrieve-key') {

                // We are creating a new task
                if (state === 'create') {
                    data = {
                        participants: [
                            this.creator.participant.id,
                        ],
                        task: {
                            type: this.values.type,
                            notes: this.values.notes,
                            payload: {
                                group_id: this.values.payload.group_id,
                            },
                        },
                    }
                }

                // We are updating an existing task
                if (state === 'update') {
                    data = {
                        task_id: this.task.id,
                        task: {
                            notes: this.values.notes,
                            payload: {
                                group_id: this.values.payload.group_id,
                            },
                        },
                    }
                }
            }

            if (this.values.type === 'custom-text') {

                // We are creating a new task
                if (state === 'create') {
                    data = {
                        participants: [
                            this.creator.participant.id,
                        ],
                        task: {
                            type: this.values.type,
                            notes: this.values.notes,
                            payload: {
                                title: this.values.payload.title,
                            },
                        },
                    }
                }

                // We are updating an existing task
                if (state === 'update') {
                    data = {
                        task_id: this.task.id,
                        task: {
                            notes: this.values.notes,
                            payload: {
                                title: this.values.payload.title,
                            },
                        },
                    }
                }
            }

            this.resetState()
            this.$emit('submit', state, data)
        },

        onHide(event) {
            this.resetState()
            this.$emit('close', event)
        },
    }
}
</script>

<style lang="scss">
.task-modal-wrapper {
}
</style>
