<template>
    <div class="view view-campaign-tasks">
        <task-modal
            :campaign="campaign"
            :creator="modal.creator"
            :task="modal.task"
            @submit="onModalSubmit"
            @close="onModalClose"
        />

        <b-modal
            id="task-delete-modal"
            @ok="onTaskDeleteConfirm"
            @close="this.deletedTaskId = null"
            @cancel="this.deletedTaskId = null"
        >
            <template #modal-footer="{ ok, cancel }">
                <b-button variant="outline-primary" @click="cancel()">
                    Cancel
                </b-button>
                <b-button variant="danger" @click="ok()">
                    Delete
                </b-button>
            </template>
            <p>Are you sure you want to delete this task?</p>
        </b-modal>

        <div class="row">
            <div class="col-lg-3">
                <div class="row">
                    <div
                        class="col-lg-12 creator"
                        @click="onCreatorClick(null)"
                        :class="{
                            'active': !selectedParticipantId
                        }"
                    >
                        <div class="panel py-3 d-flex h-100">
                            <div class="avatar my-auto">
                                <font-awesome-icon :icon="['fas', 'tasks']" />
                            </div>

                            <div class="name-rank-wrapper d-flex flex-column my-auto ml-3">
                                <span class="h6 m-0">All tasks</span>
                            </div>

                            <div class="d-flex ml-auto">
                                <span class="h4 my-auto">{{ tasks.length }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <b-form-input
                            v-model="filters.text"
                            placeholder="Filter Creators"
                            autocomplete="off"
                        />
                    </div>
                </div>

                <div class="row row-creators mt-3">
                    <div
                        class="col-lg-12 creator mb-1"
                        v-for="{ superview, participant, tasks } in filteredCreators"
                        @click="onCreatorClick(participant.id)"
                        :class="{
                            'active': participant.id === selectedParticipantId
                        }"
                    >
                        <div class="panel py-2 d-flex h-100">
                            <img
                                :src="participant.platform_user_logo"
                                class="avatar my-auto"
                            />

                            <div
                                class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                            >
                                <span class="h6 m-0">
                                    {{ participant.platform_user_name }}

                                    <verified-badge
                                        v-if="superview.channel.partner"
                                        :platform="'twitch'"
                                    />
                                </span>

                                <small
                                    :style="{
                                        color: getColors.ranks[superview.rank.name]
                                    }"
                                >
                                    {{ superview.rank.name | capitalize }}
                                </small>
                            </div>

                            <div class="d-flex ml-auto">
                                <span class="h4 my-auto">{{ tasks.length }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12" v-if="!filteredCreators.length">
                        <div class="panel p-3">
                            <p class="m-0 text-center">No creators found</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <div class="row mb-4">
                    <div class="col-lg-12 mb-4" v-if="campaign && !selectedCreator">
                        <div class="row row-panels">
                            <div class="col-lg-4">
                                <div class="panel p-3">
                                    <h4 class="h5 pb-2 border-bottom border-light">Campaign Budget</h4>
                                    <p class="m-0 text-right">
                                        <span class="font-weight-bold text-success align-bottom">${{ usedBudget.total | numeral('0,0') }}</span>
                                        <span> / </span>
                                        <span class="align-bottom">${{ campaign.budget_amount | numeral('0,0') }}</span>
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-5">
                                <div class="panel p-3">
                                    <h4 class="h5 pb-2 border-bottom border-light">Budget by Task Type</h4>

                                    <div class="row">
                                        <div class="col-lg-12" v-for="(budget, key) in usedBudget.types">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    {{ types[key] }}
                                                </div>
                                                <div class="col-lg-3 text-right">
                                                    {{ budget / usedBudget.total | numeral('0%') }}
                                                </div>
                                                <div class="col-lg-3 text-right">
                                                    ${{ budget | numeral('0,0') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3">
                                <div class="panel p-3">
                                    <h4 class="h5 pb-2 border-bottom border-light">Task Completion</h4>
                                    <p class="m-0 text-right">
                                        <span class="font-weight-bold align-bottom">{{ status.completed | numeral('0,0') }}</span>
                                        <span> / </span>
                                        <span class="align-bottom">{{ status.total | numeral('0,0') }}</span>
                                        <span class="ml-1">({{ status.completed / status.total | numeral('0%') }})</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-9 d-flex">
                        <h2 class="h3 my-auto" v-if="!selectedCreator">All Tasks</h2>
                        <h2 class="h3 my-auto" v-if="selectedCreator">{{ selectedCreator.participant.platform_user_name }}'s Tasks</h2>
                    </div>

                    <div class="col-lg-3 d-flex" v-if="selectedParticipantId">
                        <button class="btn btn-primary btn-sm btn-inner--icon ml-auto" @click.prevent="onTaskNewClick">
                            <font-awesome-icon :icon="['fas', 'plus']" />
                            <span>New Task</span>
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="row no-gutters px-3">
                            <div class="col-lg-3 d-flex" v-if="!selectedCreator">
                                <h3 class="h6 my-auto">Creator</h3>
                            </div>
                            <div class="col-lg-3 d-flex">
                                <h3 class="h6 my-auto">Task Type</h3>
                            </div>
                            <div class="col d-flex">
                                <h3 class="h6 my-auto">Status</h3>
                            </div>
                            <div class="col d-flex">
                                <h3 class="h6 my-auto">Payout</h3>
                            </div>
                            <div class="col d-flex">
                                <h3 class="h6 my-auto">Last Updated</h3>
                            </div>
                            <div class="col d-flex">
                                <h3 class="h6 my-auto">Actions</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tasks-table row row-panels mt-3" v-if="tasksInView">
                    <div class="col-lg-12 mb-2" v-for="task in tasksInView">
                        <div class="panel p-3" @click="onTaskClick(task.id)">
                            <div class="row no-gutters">
                                <div class="col-lg-3 d-flex" v-if="!selectedCreator">
                                    <div class="creator-wrapper my-auto d-flex">
                                        <img :src="task.creator.participant.platform_user_logo" class="avatar my-auto" />

                                        <div
                                            class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                                        >
                                            <span class="h6 m-0">
                                                {{ task.creator.participant.platform_user_name }}

                                                <verified-badge
                                                    v-if="task.creator.superview.channel.partner"
                                                    :platform="'twitch'"
                                                />
                                            </span>

                                            <small
                                                :style="{
                                                    color: getColors.ranks[task.creator.superview.rank.name]
                                                }"
                                            >
                                                {{ task.creator.superview.rank.name | capitalize }}
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-3 d-flex">
                                    <div class="type-wrapper my-auto">
                                        <h4 class="h5 m-0">{{ types[task.type] }}</h4>
                                        <small>Due: {{ task.due_at | moment('calendar') }}</small>
                                    </div>
                                </div>

                                <div class="col d-flex">
                                    <div class="status-wrapper my-auto">
                                        <b-badge pill variant="primary">{{ task.status ? task.status : 'Pending' }}</b-badge>
                                    </div>
                                </div>

                                <div class="col d-flex">
                                    <div class="payout-wrapper my-auto" v-if="task.payout_amount">
                                        ${{ task.payout_amount | numeral('0,0.00') }}
                                    </div>
                                    <div class="payout-wrapper my-auto" v-else>
                                        ---
                                    </div>
                                </div>

                                <div class="col d-flex">
                                    <div class="updated-at-wrapper my-auto">
                                        {{ task.updated_at | moment('calendar') }}
                                    </div>
                                </div>

                                <div class="col d-flex">
                                    <div class="action-view-wrapper my-auto">
                                        <button class="btn btn-outline-primary btn-sm" @click="onTaskViewClick(task)">Edit</button>
                                        <button class="btn btn-outline-danger btn-sm" @click="onTaskDeleteClick(task)">Delete</button>
                                    </div>
                                </div>

                                <div class="col-lg-12 mt-4" v-if="expandedTaskId === task.id">
                                    <div class="row no-gutters">
                                        <div class="col-lg-2" v-if="task.type === 'twitch-stream'">
                                            <h5 class="h6">Hours</h5>
                                            <p class="m-0">{{ task.payload.duration_seconds / (60 * 60) }}</p>
                                        </div>

                                        <div class="col-lg-2" v-if="task.type === 'twitch-stream'">
                                            <h5 class="h6">Budget Allocation</h5>
                                            <p class="m-0">{{ task.payout_amount / usedBudget.total | numeral('0%') }}</p>
                                        </div>

                                        <div class="col-lg-2" v-if="task.type === 'retrieve-key'">
                                            <h5 class="h6">Key Status</h5>
                                            <p class="m-0">
                                                <font-awesome-icon v-if="task.payload.key_id" class="mr-1 text-success" :icon="['fas', 'check']" />
                                                <font-awesome-icon v-else class="mr-1 text-danger" :icon="['fas', 'times']" />
                                                {{ task.payload.key_id ? `Claimed` : 'Unclaimed' }}
                                            </p>
                                        </div>

                                        <div class="col-lg-2" v-if="task.type === 'retrieve-key'">
                                            <h5 class="h6">Key Group</h5>
                                            <p class="m-0">{{ task.payload.group_id ? getKeyGroup(task.payload.group_id).title : 'None' }}</p>
                                        </div>



                                        <div class="col-lg-12 mt-3" v-if="task.notes">
                                            <h5 class="h6">Notes for creator</h5>
                                            <p class="m-0 pr-2">{{ task.notes || 'No notes' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12" v-if="!tasksInView.length">
                        <div class="panel p-3">
                            <p class="m-0 text-center">No Tasks Assigned</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import VerifiedBadge from '@/components/Client/VerifiedBadge'
import TaskModal from '@/components/Modals/TaskModal'

export default {
    name: 'view-campaign-tasks',

    metaInfo() {
        return {
            title: 'Campaign Tasks'
        }
    },

    components: {
        VerifiedBadge,
        TaskModal,
    },

    props: {
        campaign: {
            type: Object,
        },
    },

    data() {
        return {
            filters: {
                text: ''
            },

            modal: {
                task: null,
                creator: null,
            },

            types: {
                'retrieve-key': 'Retrieve Key',
                'twitch-stream': 'Twitch Stream',
                'youtube-video': 'Youtube Video',
                'twitch-panel': 'Twitch Panel',
                'custom-text': 'Custom (Text)',
            },

            tasks: [],
            creators: [],
            creatorsWithTasks: [],

            selectedParticipantId: null,
            expandedTaskId: null,
            deletedTaskId: null,
        }
    },

    created() {
        this.getCampaignTasks()
        this.getCampaignParticipants()
    },

    // We are attaching tasks to creators only when both data sets have been loaded in
    watch: {
        tasks() {
            if (!this.creators.length) return
            this.attachTasksToCreators()
        },

        creators() {
            this.attachTasksToCreators()
        },
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        tasksInView() {
            if (!this.selectedParticipantId) return this.tasksWithCreators

            const creator = _.find(this.creatorsWithTasks, (creator) => {
                return creator.participant.id === this.selectedParticipantId
            })

            if (creator) return creator.tasks
            return []
        },

        filteredCreators() {
            if (!this.filters.text) return this.creatorsWithTasks

            return _.filter(this.creatorsWithTasks, (item) => {
                return item.participant.platform_user_name.includes(this.filters.text)
            })
        },

        selectedCreator() {
            if (!this.selectedParticipantId) return null

            return _.find(this.creators, (creator) => {
                return creator.participant.id === this.selectedParticipantId
            })
        },

        tasksWithCreators() {
            return _.reduce(this.creatorsWithTasks, (results, creator) => {
                _.forEach(creator.tasks, (task) => {
                    results.push(
                        _.assign(task, {
                            creator: _.omit(creator, ['tasks'])
                        })
                    )
                })

                return results
            }, [])
        },

        usedBudget() {
            return _.reduce(this.tasks, (result, task) => {
                if (task.payout_amount) {
                    result.total += task.payout_amount
                    result.types[task.type] += task.payout_amount
                }

                return result
            }, {
                total: 0,
                types: {
                    'twitch-stream': 0,
                    'youtube-video': 0,
                }
            })
        },

        status() {
            return _.reduce(this.tasks, (result, task) => {
                result.total++

                if (task.completed_at) {
                    result.completed++
                }

                return result
            }, {
                total: 0,
                completed: 0,
            })
        },
    },

    methods: {
        getCampaignTasks() {
            const campaignId = this.$route.params.campaign_id

            this.axios.get(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${campaignId}/tasks`).then(({ data }) => {
                this.tasks = data.payload.tasks
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching tasks for campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Tasks',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        getCampaignParticipants() {
            const campaignId = this.$route.params.campaign_id

            this.axios.get(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${campaignId}/participants`, {
                params: {
                    relations: ['campaign_link'],
                },
            }).then(({ data }) => {
                this.creators = data.payload.creators
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching participants for campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Participants',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onCreatorClick(participantId) {
            this.selectedParticipantId = participantId
        },

        onTaskClick(taskId) {
            if (this.expandedTaskId === taskId) {
                this.expandedTaskId = null
            } else {
                this.expandedTaskId = taskId
            }
        },

        onTaskDeleteClick(task) {
            this.deletedTaskId = task.id
            this.$bvModal.show('task-delete-modal')
        },

        onTaskDeleteConfirm() {
            this.axios.delete(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.campaign.id}/tasks/${this.deletedTaskId}`).then(({ data }) => {
                const task = data.payload.task

                // Find the task object to be deleted
                const deletedTask = _.find(this.tasks, item => item.id === this.deletedTaskId)

                // Remove it from tasks
                this.tasks = _.filter(this.tasks, (task) => {
                    return task.id !== this.deletedTaskId
                })

                // Find it's entry in creatorWithTasks
                const creatorWithTasks = _.find(this.creatorsWithTasks, item => item.participant.id === deletedTask.participant_id)

                // Remove it from creatorWithTasks
                creatorWithTasks.tasks = _.filter(creatorWithTasks.tasks, item => item.id !== this.deletedTaskId)

                // Reset the task that is marked for deletion, as it has been deleted at this point
                this.deletedTaskId = null

                this.$notify({
                    group: 'global',
                    type: 'success',
                    title: 'Task Deleted',
                    text: 'The task was successfully deleted.',
                    duration: 10000,
                    speed: 1000
                })

            }).catch((error) => {
                const message = error.message
                    ? error.message
                    : `There was an error deleting the task.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Delete Task',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        getKeyGroup(groupId) {
            if (!this.campaign.game.key_groups) return null
            return _.find(this.campaign.game.key_groups, item => item.id === groupId)
        },

        onModalSubmit(state, data) {
            console.log(state, data)

            if (state === 'update') {
                this.updateTask(data)
            }

            if (state === 'create') {
                this.createTask(data)
            }
        },

        onModalClose() {
            this.modal.task = null
            this.modal.creator = null
        },

        updateTask(data) {
            this.axios.put(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.campaign.id}/tasks/${data.task_id}`, {
                task: data.task,
            }).then(({ data }) => {
                const task = data.payload.task

                const existingTask = _.find(this.tasks, item => item.id === task.id)
                _.assign(existingTask, task)

                const creator = _.find(this.creatorsWithTasks, item => item.participant.id === task.participant_id)

                if (creator) {
                    const existingTaskOnCreator = _.find(creator.tasks, item => item.id === task.id)
                    _.assign(existingTaskOnCreator, task)
                }

            }).catch((error) => {
                const message = error.message
                    ? error.message
                    : `There was an error updating the task.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Update Task',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        createTask(data) {
            this.axios.post(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.campaign.id}/tasks`, data).then(({ data }) => {
                this.tasks.push(...data.payload.tasks)

            }).catch((error) => {
                const message = error.message
                    ? error.message
                    : `There was an error creating a new task.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Create Task',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        // We are threading together creators and their tasks to make things easier
        attachTasksToCreators() {
            const tasksByParticipantId = _.groupBy(this.tasks, 'participant_id')

            const creatorsWithTasks = _.reduce(this.creators, (result, creator) => {
                const tasks = tasksByParticipantId[creator.participant.id]

                const creatorEntry = _.assign(creator, {
                    tasks: [],
                })

                if (tasks) {
                    creatorEntry.tasks = tasks
                }

                result[creator.participant.id] = creatorEntry

                return result
            }, {})

            this.creatorsWithTasks = _.orderBy(creatorsWithTasks, ['tasks.length'], ['desc'])
        },

        onTaskNewClick() {
            this.modal.task = null
            this.modal.creator = this.selectedCreator
            this.$bvModal.show('task-modal')
        },

        onTaskViewClick(task) {
            this.modal.task = task
            this.modal.creator = task.creator

            this.$bvModal.show('task-modal')
        },
    }
}
</script>

<style lang="scss">
.view-campaign-tasks {
    .row-creators {
        overflow-y: auto;
        max-height: 400px;
    }

    .creator {
        border-radius: 5px;
        cursor: pointer;
        transition: 0.25s all;

        &:hover {
            background: rgba(white, 0.05);
        }

        &.active {
            background: rgba(white, 0.075)
        }
    }

    .tasks-table {
        &.row-panels .panel {
            transition: 0.25s all;
            cursor: pointer;

            &:hover {
                background: rgba(255, 255, 255, 0.075);
            }
        }
    }
}
</style>
