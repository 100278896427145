<template>
<div>
    <b-table
        id="applications-table"
        class="table-panels"
        :busy.sync="isBusy"
        :items="selectedSlot.applications"
        :fields="fields"
        sort-by="created_at"
        :sort-desc="true"
        fixed
        borderless
        sort-icon-right
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        show-empty
        @row-clicked="openDrawer"
    >
        <template #cell(superview.channel.name)="data">
            <div class="creator-wrapper my-auto d-flex" v-if="superviewsById[data.item.platform_user_id]">
                <b-avatar
                    class="my-auto"
                    size="md"
                    :src="superviewsById[data.item.platform_user_id].channel.logo"
                />

                <div class="name-rank-wrapper d-flex flex-column my-auto ml-3">
                    <span class="h6 m-0">
                        {{ superviewsById[data.item.platform_user_id].channel.display_name }}

                        <verified-badge
                            v-if="superviewsById[data.item.platform_user_id].channel.is_partner"
                            :platform="'twitch'"
                        />
                    </span>

                    <small
                        :style="{
                            color: getColors.ranks[superviewsById[data.item.platform_user_id].rank.name]
                        }"
                    >
                        {{ superviewsById[data.item.platform_user_id].rank.name | capitalize }}
                    </small>
                </div>
            </div>

            <div v-else class="creator-wrapper my-auto d-flex">
                <b-avatar
                    class="my-auto"
                    size="md"
                />

                <div class="name-rank-wrapper d-flex flex-column my-auto ml-3">
                    <span class="h6 m-0">#{{ data.item.platform_user_id }}</span>
                    <small>Undiscovered Channel</small>
                </div>
            </div>
        </template>

        <template #cell(actions)="data">
            <div class="actions-wrapper my-auto d-flex" v-if="isActionsEnabled">
                <b-button
                    variant="outline-primary"
                    @click="data.toggleDetails"
                >Expand</b-button>
    
                <b-button
                    variant="outline-primary"
                    @click="onSelectForSlotClick(data)"
                    v-if="!chosenSlotCreator || chosenSlotCreator.participant.user_id !== data.item.user_id"
                >Select</b-button>
            </div>
        </template>

        <template #row-details="data">
            <div class="row row-panels-outline">
                <div 
                    v-for="application in applicationsByUserId[data.item.user_id]" 
                    class="col-lg-4 border"
                    :class="{
                        'border-primary': application.slot.id === data.item.slot_id,
                        'border-light': application.slot.id !== data.item.slot_id,
                        'opacity-50': application.slot.participant_id,
                    }"
                >
                    <div class="row">
                        <div class="col-lg-12">
                            {{ application.slot.start_at | moment('ddd MMM Do YYYY') }}
                        </div>
                        <div class="col-lg-5">
                            <small class="font-weight-bold">Start</small>
                            <p class="m-0">{{ application.slot.start_at | moment('hh:mm A') }}</p>
                        </div>
                        <div class="col-lg-5">
                            <small class="font-weight-bold">End</small>
                            <p class="m-0">{{ application.slot.end_at | moment('hh:mm A') }}</p>
                        </div>
                        <div class="col-lg-2 d-flex">
                            <span class="h4 m-auto">{{ application.slot.application_count | numeral('0,0') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            
        </template>

        <template #empty="scope">
            <p class="m-0 text-center">No applications for this slot</p>
        </template>
    </b-table>

    <div class="d-flex">
        <b-pagination
            class="mx-auto"
            :total-rows="totalRows"
            :per-page="pagination.perPage"
            v-model="pagination.currentPage"
         />
    </div>
</div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import moment from 'moment'
import numeral from 'numeral'
import VerifiedBadge from '@/components/Client/VerifiedBadge'

export default {
    components: {
        VerifiedBadge,
    },

    props: {
        campaign_id: {
            type: String,
        },

        selectedSlot: {
            type: Object,
        },

        superviewsById: {
            type: Object,
        },

        chosenSlotCreator: {
            type: Object,
        },

        isActionsEnabled: {
            type: Boolean,
        },

        applicationsByUserId: {
            type: Object,
        },
    },

    data() {
        return {
            isBusy: false,

            pagination: {
                perPage: 10,
                currentPage: 1,
            },

            fields: [{
                key: 'superview.channel.name',
                label: 'Creator',
                sortable: true,
            }, {
                key: 'superview.stream.day_90.aggregation.viewers.avg',
                label: 'ACV (90 day)',
                sortable: true,
                formatter: (value, key, item) => {
                    return numeral(value).format('0,0')
                },
            }, {
                key: 'superview.channel.language',
                label: 'Language',
                sortable: true,
                formatter: (value, key, item) => {
                    return value ? value.toUpperCase() : 'Unknown'
                },
            }, {
                key: 'created_at',
                label: 'Submitted At',
                sortable: true,
                sortDirection: 'desc',
                formatter: (value, key, item) => {
                    return moment(value).calendar()
                },
            }, {
                key: 'actions',
                label: 'Actions',
                sortable: false,
            }],
        }
    },

    computed: {
        ...mapGetters([
            'getColors'
        ]),

        totalRows() {
            return _.size(this.selectedSlot.applications)
        },
    },

    methods: {
        openDrawer(item, index, event) {
            this.$store.commit('drawer:set', {
                enabled: true,
                creator: this.superviewsById[item.platform_user_id],
            })
        },

        onSelectForSlotClick(data) {
            this.$emit('select-application', this.selectedSlot, data.item)
        },
    }
}
</script>

<style lang="scss" scoped>
.table-panels {
    border-collapse:separate;
    border-spacing: 0 0.5rem;
    transition: opacity 0.25s;

    &[aria-busy="true"] {
        opacity: 0.5;
    }

    thead {
        th {
            font-weight: 400;
            line-height: 1.3;
            color: #f6f6f8;
            font-size: 1rem;
        }
    }

    tbody {
        tr {
            background: rgba(white, 0.05);
            border-radius: 5px;
            margin-bottom: 5px;

            &:hover {
                background-color: rgba(white, 0.075);
            }

            td {
                vertical-align: middle;
            }

            td:first-child {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }

            td:last-child {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }

    .opacity-50 {
        opacity: 50%;
    }
}
</style>
