import Vue from 'vue'

/**
 * A token with null, is a OAuth that is yet to be linked.
 * A token with false, is a OAuth token that has become invalid and required relink.
 */
export default {
    state: {
        tokens: {
            core: null
        },
        is_authenticated: false,
        redirect: false,
    },

    mutations: {
        'authentication:login'(state, token) {
            Vue.set(state.tokens, 'core', token)
            Vue.set(state, 'is_authenticated', true)
        },
        'authentication:token'(state, tokens) {
            Object.keys(tokens).forEach((token) => {
                if (token) Vue.set(state.tokens, token, tokens[token])
            })
        },
        'authentication:logout'(state) {
            Vue.set(state, 'is_authenticated', false)

            Vue.set(state, 'tokens', {
                core: null,
            })
        },
        'authentication:clear'(state, token) {
            Vue.set(state.tokens, token, null)
        },
        'authentication:invalidate'(state, token) {
            Vue.set(state.tokens, token, false)
        },
        'authentication:redirect'(state, path) {
            Vue.set(state, 'redirect', path)
        },
        'authentication:clear:redirect'(state) {
            Vue.set(state, 'redirect', false)
        },
    },

    getters: {
        tokens(state) {
            return state.tokens
        },
        getToken: (state) => (type) => {
            console.log(state.tokens[type])
            return state.tokens[type]
        },
        isAuthenticated(state) {
            return state.is_authenticated
        },
        redirect(state) {
            return state.redirect
        },
    }
}
