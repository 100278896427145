<template>
    <div class="view view-campaign">
        <campaign-modal
            modal-id="modal-campaign-local"
            :type="modal.type"
            :campaign="modal.campaign"
            :supercampaign="supercampaign"
            :games="games"
            @submit="onModalSubmit"
            @close="onModalClose"
        />

        <!-- <div class="row mb-sm" v-if="campaign && campaign.siblings && campaign.siblings.length">
            <div class="col-lg-12">
                <router-link
                    v-for="campaign in campaign.siblings"
                    :to="{
                        name: 'campaign',
                        params: {
                            campaign_id: campaign.id
                            }
                    }"
                    tag="button"
                    class="btn btn-sm btn-primary ml-auto"
                >
                    <font-awesome-icon :icon="typeToIcon(campaign.type)" class="mr-2" />{{ campaign.type }}
                </router-link>

                <b-button variant="success" size="sm" @click="onCampaignActionClick(null, 'create')">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                </b-button>
            </div>
        </div> -->

        <div class="row mb-4 row-campaign py-sm" v-if="campaign">
            <div class="col d-flex">
                <div class="campaign-options-inner my-auto">
                    <!-- <router-link
                        :to="{ name: 'campaign-overview', params: { campaign_id: $route.params.campaign_id } }"
                        tag="button"
                        class="btn btn-outline-primary btn-sm"
                        active-class="btn-primary"
                    >
                        Overview
                    </router-link> -->

                    <router-link
                        :to="{ name: 'campaign-participants', params: { campaign_id: $route.params.campaign_id } }"
                        tag="button"
                        class="btn btn-outline-primary btn-sm"
                        active-class="btn-primary"
                    >
                        Creators
                    </router-link>

                    <router-link
                        :to="{ name: 'campaign-tasks', params: { campaign_id: $route.params.campaign_id } }"
                        tag="button"
                        class="btn btn-outline-primary btn-sm"
                        active-class="btn-primary"
                    >
                        Tasks
                    </router-link>

                    <router-link
                        :to="{ name: 'campaign-attribution', params: { campaign_id: $route.params.campaign_id } }"
                        tag="button"
                        class="btn btn-outline-primary btn-sm"
                        active-class="btn-primary"
                        v-if="campaign.type === 'game-sponsorship' || campaign.type === 'key-distribution'"
                    >
                        Conversion
                    </router-link>

                    <!-- <router-link
                        :to="{ name: 'campaign-streams', params: { campaign_id: $route.params.campaign_id } }"
                        tag="button"
                        class="btn btn-outline-primary btn-sm"
                        active-class="btn-primary"
                        v-if="campaign.type === 'game-sponsorship' || campaign.type === 'key-distribution'"
                    >
                        Streams
                    </router-link> -->

                    <router-link
                        :to="{ name: 'campaign-slots', params: { campaign_id: $route.params.campaign_id } }"
                        tag="button"
                        class="btn btn-outline-primary btn-sm"
                        active-class="btn-primary"
                        v-if="campaign.type === 'steam-broadcast'"
                    >
                        Slots
                    </router-link>

                    <router-link
                        :to="{ name: 'campaign-applications', params: { campaign_id: $route.params.campaign_id } }"
                        tag="button"
                        class="btn btn-outline-primary btn-sm"
                        active-class="btn-primary"
                        v-if="campaign.type === 'steam-broadcast'"
                    >
                        Applications
                    </router-link>

                    <router-link
                        :to="{ name: 'campaign-summary', params: { campaign_id: $route.params.campaign_id } }"
                        tag="button"
                        class="btn btn-outline-primary btn-sm"
                        active-class="btn-primary"
                        v-if="campaign.type !== 'steam-broadcast'"
                    >
                        Reporting
                    </router-link>


                    <router-link
                        :to="{ name: 'campaign-deliverables', params: { campaign_id: $route.params.campaign_id } }"
                        tag="button"
                        class="btn btn-outline-primary btn-sm"
                        active-class="btn-primary"
                        v-if="campaign.type === 'game-sponsorship'"
                    >
                        Deliverables
                    </router-link>

                    <!-- <router-link
                        :to="{ name: 'campaign-settings', params: { campaign_id: $route.params.campaign_id } }"
                        tag="button"
                        class="btn btn-outline-primary btn-sm"
                        active-class="btn-primary"
                    >
                        Settings
                    </router-link> -->
                </div>
            </div>

            <div class="col-lg-4 text-right d-flex justify-content-end">
                <b-button
                    v-if="campaign.is_enabled"
                    variant="success"
                    @click="toggleCampaignStatus"
                    v-tooltip="'This campaign can currently be seen by users on the creator dashboard.'"
                >
                    <span>Visible to creators</span>
                </b-button>
                <b-button
                    v-else
                    variant="warning"
                    @click="toggleCampaignStatus"
                    v-tooltip="'This campaign is invisible to users on the creator dashboard.'"
                >
                    <span>Hidden from creators</span>
                </b-button>

                <b-dropdown text="Actions" variant="primary">
                    <b-dropdown-item @click="onCampaignActionClick(campaign, 'update')">Edit</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="onCampaignActionClick(campaign, 'delete')" variant="danger">Delete</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <router-view
            v-if="supercampaign && campaign"
            :supercampaign="supercampaign"
            :campaign="campaign"
        />
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import CampaignModal from '@/components/Modals/CampaignModal'

export default {
    name: 'view-campaign',

    metaInfo() {
        return {
            title: 'Campaign'
        }
    },

    components: {
        CampaignModal,
    },

    props: {
        supercampaign: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            campaign: null,
            is_enabled: false,

            games: [],

            modal: {
                type: 'create',
                campaign: null,
            },
        }
    },

    created() {
        this.getCampaign()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ])
    },

    methods: {
        getCampaign() {
            this.axios.get(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}`, {
                params: {
                    relations: ['game', 'key_groups'],
                },
            }).then(({ data }) => {
                this.campaign = data.payload.campaign
                this.is_enabled = data.payload.campaign.is_enabled
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },


        onCampaignActionClick(campaign, type = 'create') {
            // This way we save the state of the modal if it's clicked out of
            if (campaign) {
                this.modal.campaign = campaign
            }
            this.modal.type = type
            this.$bvModal.show('modal-campaign-local')
        },

        onModalClose() {
            this.modal.campaign = null
            this.modal.type = 'create'
        },

        onModalSubmit(type, { campaign_id, campaign }) {
            if (type === 'create') {
                this.axios.post(`/supercampaigns/${this.supercampaign.id}/campaigns`, {
                    campaign,
                }).then(({ data }) => {
                    const campaign = data.payload.campaign

                    this.$notify({
                        group: "global",
                        type: "success",
                        text: `Successfully created new campaign: ${campaign.title}`,
                        duration: 5000,
                        speed: 1000,
                    })

                    this.$router.push({
                        name: 'campaign',
                        params: {
                            campaign_id: campaign.id,
                        },
                    })
                }).catch((error) => {
                    const message = error.message ? error.message : `There was an error trying to create your campaign.`

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Create Campaign',
                        text: message,
                        duration: 10000,
                        speed: 1000
                    })
                })

            } else if (type === 'update') {
                this.axios.put(`/supercampaigns/${this.supercampaign.id}/campaigns/${campaign_id}`, {
                    campaign,
                }).then(({ data }) => {
                    // this.$set('campaign', _.assign(this.campaign, data.payload.campaign))
                    this.campaign = _.assign(this.campaign, data.payload.campaign)


                    this.$notify({
                        group: "global",
                        type: "success",
                        text: `Successfully updated campaign: ${campaign.title}`,
                        duration: 5000,
                        speed: 1000,
                    })
                }).catch((error) => {
                    const message = error.message ? error.message : `There was an error trying to edit your campaign.`

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Edit Campaign',
                        text: message,
                        duration: 10000,
                        speed: 1000
                    })
                })

            } else if (type === 'delete') {
                this.axios.delete(`/supercampaigns/${this.supercampaign.id}/campaigns/${campaign_id}`).then(({ data }) => {
                    this.modal.campaign = null

                    this.$router.push({
                        name: 'supercampaign',
                        params: {
                            supercampaign_id: this.$route.params.supercampaign_id,
                        },
                    })
                }).catch(({ message }) => {
                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Delete Campaign',
                        text: message,
                        duration: 10000,
                        speed: 1000
                    })
                })
            }
        },

        toggleCampaignStatus() {
            this.axios.put(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.campaign.id}`, {
                campaign: {
                    id: this.campaign.id,
                    is_enabled: !this.campaign.is_enabled,
                },
            }).then(({ data }) => {
                this.campaign.is_enabled = !this.campaign.is_enabled
            }).catch((error) => {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Status',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        printJSON(json) {
            if (typeof json != 'string') {
                 json = JSON.stringify(json, undefined, 2);
            }
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                var cls = 'number';
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = 'key';
                    } else {
                        cls = 'string';
                    }
                } else if (/true|false/.test(match)) {
                    cls = 'boolean';
                } else if (/null/.test(match)) {
                    cls = 'null';
                }
                return '<span class="' + cls + '">' + match + '</span>';
            });
        },

        typeToIcon(type) {
            switch (type) {
                case 'key-distribution':
                    return ['fas', 'key']
                case 'game-sponsorship':
                    return ['fas', 'gamepad']
                case 'steam-broadcast':
                    return ['fab', 'steam']
                default:
                    return 'info'
            }
        },
    }
}
</script>

<style lang="scss">
.view-campaign {

    .row-campaign {
        border-top: 1px solid rgba(white, 0.05);
        border-bottom: 1px solid rgba(white, 0.05);
    }

    pre {
        outline: 1px solid rgba(white, 0.075);
        padding: 15px;
        margin: 5px;
    }

    .string { color: green; }
    .number { color: darkorange; }
    .boolean { color: blue; }
    .null { color: magenta; }
    .key { color: red; }
}
</style>
