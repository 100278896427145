<template>
    <div class="bookmarks-table-wrapper">
        <div class="bookmarks-table row row-panels overflow-auto">
            <!-- table header -->
            <div class="col-lg-12 mb-2 header">
                <div class="px-3 w-100 mb-2">
                    <div class="row no-gutters panel-overlay table-header">
                        <div class="col-lg-3 d-flex">
                            <h3 class="h6 my-auto">Creator</h3>
                        </div>
                        <div class="col d-flex">
                            <h3 class="h6 my-auto">ACV</h3>
                        </div>
                        <div class="col-lg d-flex">
                            <h3 class="h6 my-auto">Peak CCV</h3>
                        </div>
                        <div class="col d-flex">
                            <h3 class="h6 my-auto">Engagement</h3>
                        </div>
                        <div class="col d-flex">
                            <h3 class="h6 my-auto">Duration</h3>
                        </div>
                        <div class="col-lg-2 d-flex">
                            <h3 class="h6 my-auto">Started At</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 p-0">
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <div class="col-lg-12 mb-2" v-for="{ bookmark, superview, channel } in itemsOnPage">
                            <div class="panel p-3 w-100" @click="openCreatorDrawer(superview, $event)">
                                <div class="row no-gutters panel-overlay">
                                    <div class="col-lg-3 d-flex">
                                        <div class="creator-wrapper my-auto d-flex" v-if="superview && superview.channel">
                                            <img
                                                :src="superview.channel.logo"
                                                class="avatar my-auto"
                                            />

                                            <div
                                                class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                                            >
                                                <span class="h6 m-0">
                                                    {{ superview.channel.display_name }}

                                                    <verified-badge
                                                        v-if="superview.channel.partner"
                                                        :platform="'twitch'"
                                                    />
                                                </span>

                                                <small
                                                    :style="{
                                                        color: getColors.ranks[superview.rank.name]
                                                    }"
                                                >
                                                    {{ superview.rank.name | capitalize }}
                                                </small>
                                            </div>
                                        </div>

                                        <div class="creator-wrapper my-auto d-flex" v-else-if="bookmark.channel">
                                            <img
                                                :src="bookmark.channel.logo"
                                                class="avatar my-auto"
                                            />

                                            <div
                                                class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                                            >
                                                <span class="h6 m-0">
                                                    {{ bookmark.channel.display_name }}

                                                    <verified-badge
                                                        v-if="bookmark.channel.partner"
                                                        :platform="'twitch'"
                                                    />
                                                </span>

                                                <small
                                                    :style="{
                                                        color: getColors.ranks['member']
                                                    }"
                                                >
                                                    Unranked
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col d-flex">
                                        <div class="my-auto">
                                            <font-awesome-icon
                                                :icon="['fas', 'eye']"
                                                class="mr-1 font-muted"
                                            />
                                            <span class="ml-1" v-if="bookmark.viewers">
                                                {{ bookmark.viewers | numeral('0,0') }}
                                            </span>
                                            <span class="ml-1" v-else>
                                                ---
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col d-flex">
                                        <div class="my-auto">
                                            <font-awesome-icon
                                                :icon="['fas', 'mountain']"
                                                class="mr-1 font-muted"
                                            />
                                            <span class="ml-1" v-if="bookmark.series && bookmark.series.length">
                                                {{ getPeakViewers(bookmark.series) | numeral('0,0') }}
                                            </span>

                                            <span class="ml-1" v-else>
                                                ---
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col d-flex">
                                        <div class="my-auto">
                                            <font-awesome-icon
                                                :icon="['fas', 'comments']"
                                                class="mr-1 font-muted"
                                            />
                                            <span class="ml-1" v-if="bookmark.series_chat && bookmark.series_chat.length">
                                                {{ getEngagement(bookmark.viewers, bookmark.series_chat) | numeral('0.0%') }}
                                            </span>

                                            <span class="ml-1" v-else>
                                                ---
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col d-flex">
                                        <div class="my-auto">
                                            <span>{{ getDuration(bookmark.created_at) }}</span>
                                        </div>
                                    </div>
                                    <div class="col d-flex">
                                        <div class="my-auto">
                                            <span>{{ bookmark.created_at | moment('calendar') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="col-lg-12 mb-2"
                v-if="items && items.length === 0"
            >
                <div class="panel p-3 text-center">
                    No streams found
                </div>
            </div>

            <!-- pagination controls -->
            <div class="col-lg-12 d-flex mt-2">
                <b-pagination
                    v-model="pagination.currentPage"
                    :total-rows="items.length"
                    :per-page="pagination.perPage"
                    class="mx-auto"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'BookmarksTable',

    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        ...mapGetters([
            'getColors'
        ]),

        itemsOnPage() {
            const { currentPage, perPage } = this.pagination
            const startIndex = (currentPage - 1) * perPage

            return this.items.slice(startIndex, currentPage * perPage)
        },
    },

    data() {
        return {
            pagination: {
                perPage: 10,
                currentPage: 1,
            },
        }
    },

    methods: {
        toHumanDuration(durationSeconds) {
            const hours = Math.floor(durationSeconds / (60 * 60))
            const minutes = Math.round((durationSeconds % (60 * 60)) / 60)

            let result = []
            if (hours > 0) result.push(`${hours}h`)
            if (minutes > 0) result.push(`${minutes}m`)
            return result.join(' ')
        },

        openCreatorDrawer(superview, event) {
            const ignoreList = ['BUTTON', 'LABEL', 'INPUT']

            if (ignoreList.includes(event.target.tagName)) return
            if (!superview) return

            this.$store.commit('drawer:set', {
                enabled: true,
                creator: superview,
            })
        },

        getEngagement(viewers, series) {
            const lastItem = _.last(series)

            if (lastItem) return lastItem.chatters_count / viewers
            return 0
        },

        getPeakViewers(series) {
            const max = _.maxBy(series, 'viewers')
            return max ? max.viewers : 0

        },

        getDuration(startedAt) {
            const seconds = moment.utc().diff(moment.utc(startedAt), 'seconds')
            return this.toHumanDuration(seconds)
        },
    },
}
</script>

<style lang="scss" scoped>
.bookmarks-table {
    .table-header {
        min-height: 30px;
    }

    .pagination-controls {
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
            padding: 0;
        }
    }

    &.row-panels .panel {
        transition: 0.25s all;

        &:hover {
            background: rgba(255, 255, 255, 0.075);
        }
    }
}
</style>
