var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view view-supercampaign"},[_c('campaign-modal',{attrs:{"modal-id":"modal-supercampaign-campaign","type":_vm.modal.type,"campaign":_vm.modal.campaign,"supercampaign":_vm.supercampaign},on:{"submit":_vm.onModalSubmit,"close":_vm.onModalClose}}),(_vm.supercampaign)?_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-lg-8"},[_c('h4',{staticClass:"h2 m-0"},[_vm._v(_vm._s(_vm.supercampaign.title))]),_c('p',{staticClass:"small m-0"},[_vm._v("Created "+_vm._s(_vm._f("moment")(_vm.supercampaign.created_at,'from', 'now', true))+" ago")])]),_c('div',{staticClass:"col-lg-4 d-flex justify-content-end"},[_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"mb-1 text-right"},[_vm._v("Campaign Status")]),_c('b-dropdown',{attrs:{"text":_vm.getFromDictionary(("campaign.status." + (_vm.supercampaign.status)), 'label'),"variant":"primary"}},_vm._l((_vm.options.statuses),function(item,key){return _c('b-dropdown-group',{attrs:{"header":item.name}},_vm._l((item.steps),function(step){return _c('b-dropdown-item-button',{attrs:{"active":_vm.supercampaign.status === ((item.name) + "-" + step)},on:{"click":function($event){$event.preventDefault();return _vm.onSupercampaignStatusClick(item.name, step)}}},[_vm._v(" "+_vm._s(_vm.getFromDictionary(("campaign.status." + (item.name) + "-" + step), 'label'))+" ")])}),1)}),1)],1)])]):_vm._e(),_c('div',{staticClass:"row mb-sm"},[_c('div',{staticClass:"col-lg-12 d-flex"},[(_vm.supercampaign && _vm.supercampaign.campaigns && _vm.supercampaign.campaigns.length)?_c('div',{staticClass:"mr-2"},_vm._l((_vm.supercampaign.campaigns),function(campaign){return _c('router-link',{staticClass:"btn btn-sm btn-outline-primary ml-auto",class:{
                        'active': _vm.$route.params.campaign_id === campaign.id
                    },attrs:{"to":{
                        name: 'campaign',
                        params: {
                            supercampaign_id: _vm.supercampaign.id,
                            campaign_id: campaign.id,
                        },
                    },"tag":"button"}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":_vm.typeToIcon(campaign.type)}}),_vm._v(_vm._s(_vm.typeToTitle(campaign.type))+" ")],1)}),1):_vm._e(),_c('b-button',{attrs:{"variant":"outline-success","size":"sm"},on:{"click":function($event){return _vm.onCampaignActionClick(null, 'create')}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'plus']}}),_vm._v(" New ")],1)],1)]),(_vm.supercampaign && (!_vm.supercampaign.campaigns || !_vm.supercampaign.campaigns.length))?_c('div',{staticClass:"row row-panels"},[_vm._m(0)]):_vm._e(),(_vm.supercampaign)?_c('router-view',{attrs:{"supercampaign":_vm.supercampaign,"campaign":_vm.campaign}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"panel p-3"},[_c('p',{staticClass:"m-0"},[_vm._v("Please click the plus sign above to create a component for this campaign.")])])])}]

export { render, staticRenderFns }