// import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
// import '@fortawesome/fontawesome-free/scss/brands.scss';
// import '@fortawesome/fontawesome-free/scss/solid.scss';
// import '@fortawesome/fontawesome-free/scss/regular.scss';

import {
    library
} from '@fortawesome/fontawesome-svg-core'

import {
    faEye,
    faAngleLeft,
    faAngleRight,
    faTh,
    faBars,
    faTimes,
    faFeatherAlt,
    faUser,
    faCircleNotch,
    faBell,
    faChevronDown,
    faCaretUp,
    faCaretRight,
    faCaretDown,
    faCaretLeft,
    faExclamation,
    faChartLine,
    faMicrophoneAlt,
    faDoorOpen,
    faExternalLinkAlt,
    faShieldAlt,
    faSignOutAlt,
    faUserCog,
    faSearch,
    faThumbsUp,
    faThumbsDown,
    faCog,
    faTools,
    faBolt,
    faScroll,
    faAngleDoubleUp,
    faStreetView,
    faStopwatch,
    faInfoCircle,
    faComments,
    faGamepad,
    faRedo,
    faArrowLeft,
    faArrowDown,
    faEllipsisH,
    faGlobe,
    faUndo,
    faSave,
    faEraser,
    faUserCircle,
    faLock,
    faCalendarAlt,
    faCrown,
    faUserPlus,
    faCheck,
    faSitemap,
    faTrophy,
    faFire,
    faUserSecret,
    faQuestion,
    faDungeon,
    faMountain,
    faUserClock,
    faHome,
    faExternalLinkSquareAlt,
    faCertificate,
    faFilm,
    faCircle,
    faPlay,
    faExpand,
    faDesktop,
    faGlobeEurope,
    faGlobeAmericas,
    faGlobeAfrica,
    faGlobeAsia,
    faCopy,
    faTags,
    faKey,
    faUsers,
    faStore,
    faTasks,
    faChartBar,
    faPlug,
    faThLarge,
    faCompass,
    faTrashAlt,
    faEnvelopeOpen,
    faEnvelopeOpenText,
    faGraduationCap,
    faLink,
    faArrowRight,
    faChevronUp,
    faStar,
    faHandshake,
    faTicketAlt,
    faGifts,
    faExclamationCircle,
    faExclamationTriangle,
    faHeart,
    faHeartBroken,
    faPlus,
    faDollarSign,
    faHandHoldingUsd,
    faUserFriends,
    faEllipsisV,
    faTrash,
    faStop,
    faSortAmountDownAlt,
    faSortAmountUpAlt,
    faBroadcastTower,
    faEdit,
    faSignInAlt,
} from '@fortawesome/free-solid-svg-icons'

import {
    faDiscord,
    faTwitter,
    faInstagram,
    faYoutube,
    faTwitch,
    faPlaystation,
    faXbox,
    faSteam,
} from '@fortawesome/free-brands-svg-icons'

import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
// require('../assets/vendor/font-awesome/css/font-awesome.min.css')

library.add(
    faDiscord,
    faTwitter,
    faInstagram,
    faYoutube,
    faTwitch,
    faEye,
    faAngleLeft,
    faAngleRight,
    faTh,
    faBars,
    faTimes,
    faFeatherAlt,
    faUser,
    faCircleNotch,
    faExclamation,
    faBell,
    faChevronDown,
    faCaretUp,
    faCaretRight,
    faCaretDown,
    faCaretLeft,
    faChartLine,
    faMicrophoneAlt,
    faDoorOpen,
    faExternalLinkAlt,
    faShieldAlt,
    faSignOutAlt,
    faUserCog,
    faSearch,
    faThumbsUp,
    faThumbsDown,
    faCog,
    faTools,
    faBolt,
    faScroll,
    faAngleDoubleUp,
    faStreetView,
    faStopwatch,
    faInfoCircle,
    faComments,
    faGamepad,
    faRedo,
    faArrowLeft,
    faArrowDown,
    faEllipsisH,
    faGlobe,
    faUndo,
    faSave,
    faEraser,
    faUserCircle,
    faLock,
    faCalendarAlt,
    faCrown,
    faUserPlus,
    faCheck,
    faSitemap,
    faTrophy,
    faFire,
    faUserSecret,
    faQuestion,
    faDungeon,
    faMountain,
    faUserClock,
    faCircle,
    faHome,
    faExternalLinkSquareAlt,
    faCertificate,
    faFilm,
    faPlay,
    faExpand,
    faPlaystation,
    faXbox,
    faDesktop,
    faSteam,
    faGlobeEurope,
    faGlobeAmericas,
    faGlobeAfrica,
    faGlobeAsia,
    faCopy,
    faTags,
    faKey,
    faUsers,
    faStore,
    faTasks,
    faChartBar,
    faPlug,
    faThLarge,
    faCompass,
    faTrashAlt,
    faEnvelopeOpen,
    faEnvelopeOpenText,
    faGraduationCap,
    faLink,
    faArrowRight,
    faChevronUp,
    faStar,
    faHandshake,
    faTicketAlt,
    faGifts,
    faExclamationCircle,
    faExclamationTriangle,
    faHeart,
    faHeartBroken,
    faPlus,
    faDollarSign,
    faHandHoldingUsd,
    faUserFriends,
    faEllipsisV,
    faTrash,
    faStop,
    faSortAmountDownAlt,
    faSortAmountUpAlt,
    faBroadcastTower,
    faEdit,
    faSignInAlt,
)

export default {
    install(Vue) {
        Vue.component('font-awesome-icon', FontAwesomeIcon)
    }
}
