<template>
<div>
    <div class="row row-panels mt-3">

        <div class="col-lg-6">
            <h4 class="display-4 mb-3">Create Organization</h4>

            <div class="panel p-3" v-if="values.organization">
                <div class="form-row">
                    <div class="form-group col-lg-8">
                        <label class="form-label" for="firstName">Name</label>
                        <input class="form-control" type="text" v-model="values.organization.name">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label" for="email">Type</label>
                    <b-form-select v-model="values.organization.type" v-if="organization_types">
                        <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                        <b-form-select-option :value="type.key" v-for="type in organization_types">{{ type.title }}</b-form-select-option>
                    </b-form-select>
                </div>

                <button class="btn btn-primary mt-2" @click="createOrganization">Create</button>
            </div>
        </div>

        <div class="col-lg-6">
            <h4 class="display-4 mb-3">Create User</h4>

            <div class="panel p-3" v-if="values.operator">
                <div class="form-row">
                    <div class="form-group col-lg-6">
                        <label class="form-label">First Name</label>
                        <input class="form-control" type="text" v-model="values.operator.first_name">
                    </div>

                    <div class="form-group col-lg-6">
                        <label class="form-label">Last Name</label>
                        <input class="form-control" type="text" v-model="values.operator.last_name">
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-label">Email</label>
                    <input class="form-control" type="text" v-model="values.operator.email">
                </div>

                <div class="form-group">
                    <label class="form-label" for="email">Role</label>
                    <b-form-select v-model="values.operator.role" v-if="operator_roles">=
                        <b-form-select-option
                            v-for="type in operator_roles"
                            :value="type.key"
                        >
                            {{ type.title }}
                        </b-form-select-option>
                    </b-form-select>
                </div>

                <div class="form-group">
                    <label class="form-label">Organization</label>
                    <b-form-select v-model="values.operator.organization_id" v-if="organizations">
                        <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>
                        <b-form-select-option :value="organization.id" v-for="organization in organizations">{{ organization.name }}</b-form-select-option>
                    </b-form-select>
                </div>

                <button class="btn btn-primary mt-2" @click="createUser">Send Registration Link</button>
            </div>
        </div>

    </div>
</div>
</template>

<script>
export default {
    name: 'users',

    metaInfo() {
        return {
            title: 'Administration'
        }
    },

    data() {
        return {
            defaults: {
                organization: {
                    name: '',
                    type: 'publisher',
                },

                operator: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    organization_id: null,
                    role: 'user',
                },
            },

            values: {
                organization: null,
                operator: null,
            },

            organizations: null,
            organization_types: [{
                key: 'publisher',
                title: 'Publisher',
            }, {
                key: 'developer',
                title: 'Developer',
            }, {
                key: 'brand',
                title: 'Brand',
            }],

            operator_roles: [{
                key: 'user',
                title: 'User',
            }, {
                key: 'staff',
                title: 'Staff',
            }, {
                key: 'superadmin',
                title: 'Superadmin',
            }],
        }
    },

    created() {
        this.values.organization = _.clone(this.defaults.organization)
        this.values.operator = _.clone(this.defaults.operator)

        this.getOrganizations()
    },

    methods: {
        getOrganizations() {
            this.axios.get(`/organizations/all`).then(({
                data
            }) => {
                this.organizations = data.payload.organizations
            })
        },

        createUser() {
            this.axios.post(`/operators`, {
                operator: this.values.operator,
            }).then(({ data }) => {
                _.assign(this.values.operator, this.defaults.operator)

                this.$notify({
                    group: 'global',
                    type: 'success',
                    title: 'Success - User Created',
                    text: `An invitation to register an account has been sent to the provided email address.`,
                    duration: 10000,
                    speed: 1000
                })
            }).catch((error) => {
                let message = `Failed to create new user.`
                const apiMessage = _.get(error.response.data.payload.message)
                if (apiMessage) message = apiMessage

                this.$notify({
                    group: 'global',
                    type: 'alert',
                    title: 'Error - Create User',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        createOrganization() {
            this.axios.post(`/organizations`, {
                organization: this.values.organization,
            }).then(({ data }) => {
                this.organizations.push(data.payload.organization)
                _.assign(this.values.organization, this.defaults.organization)

                this.$notify({
                    group: 'global',
                    type: 'success',
                    title: 'Success - Organization Created',
                    text: `The new organization was successfully created.`,
                    duration: 10000,
                    speed: 1000
                })
            }).catch((error) => {
                let message = `Failed to create new organization.`
                const apiMessage = _.get(error.response.data.payload.message)
                if (apiMessage) message = apiMessage

                this.$notify({
                    group: 'global',
                    type: 'alert',
                    title: 'Error - Create Organization',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
