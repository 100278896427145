<template>
<div>
    <b-table
        id="chapters-table"
        ref="table"
        class="table-panels"
        :busy.sync="isBusy"
        :items="clickthroughProvider"
        :fields="fields"
        sort-by="ended_at"
        :sort-desc="true"
        fixed
        borderless
        sort-icon-right
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
    >
        <template #cell(superview)="data">
            <div class="creator-wrapper my-auto d-flex" v-if="data.item.superview && data.item.superview.channel">
                <b-img-lazy
                    :src="data.item.superview.channel.logo"
                    class="avatar my-auto"
                />

                <div
                    class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                >
                    <span class="h6 m-0">
                        {{ data.item.superview.channel.display_name }}

                        <verified-badge
                            v-if="data.item.superview.channel.partner"
                            :platform="'twitch'"
                        />
                    </span>

                    <small
                        :style="{
                            color: getColors.ranks[data.item.superview.rank.name]
                        }"
                    >
                        {{ data.item.superview.rank.name | capitalize }}
                    </small>
                </div>
            </div>
        </template>

        <template #cell(superview.stream.day_90.aggregation.viewers.avg)="data">
            <div class="acv-performance d-flex flex-column" v-if="data.value.ratio">
                <b-progress class="my-auto w-100" :variant="data.value.variant" :max="1" height="1rem" :class="{
                    'negative': data.value.is_negative,
                    'positive': !data.value.is_negative,
                    'high': data.value.ratio > 0.75,
                }">
                    <b-progress-bar :value="data.value.ratio">
                        <span
                            class="progress-text"
                            :class="{
                                'text-danger': data.value.is_negative,
                                'text-success': !data.value.is_negative,
                            }">
                            {{ data.value.prefix }}{{ data.value.ratio | numeral('0,0%') }}
                        </span>
                    </b-progress-bar>
                </b-progress>
            </div>
        </template>
    </b-table>

    <div class="d-flex">
        <b-pagination
            class="mx-auto"
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            v-model="pagination.currentPage"
         />
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import numeral from 'numeral'
import VerifiedBadge from '@/components/Client/VerifiedBadge'

export default {
    components: {
        VerifiedBadge,
    },

    props: {
        campaignId: {
            type: String,
        },

        dateRangeStart: {
            type: String,
        },

        dateRangeEnd: {
            type: String,
        },

        filters: {
            type: Object,
        },
    },

    watch: {
        dateRangeStart(newValue) {
            this.$root.$emit('bv::refresh::table', 'chapters-table')
        },

        filters: {
            deep: true,

            // We have to move our method to a handler field
            handler(newValue) {
                const debouncedRefresh = _.debounce(this.$refs.table.refresh, 1000)()
            }
        },
    },

    data() {
        return {
            isBusy: false,

            pagination: {
                perPage: 10,
                currentPage: 1,
                totalRows: 0,
            },

            fields: [{
                key: 'superview',
                label: 'Creator',
                sortable: true,
                formatter: (value, key, item) => {
                    if (value) {
                        return numeral(value).format('0,0')
                    } else {
                        return '---'
                    }
                },
            }, {
                key: 'chapter.statistics.avg_viewers',
                label: 'ACV',
                sortable: true,
                formatter: (value, key, item) => {
                    if (value) {
                        return numeral(value).format('0,0')
                    } else {
                        return '---'
                    }
                },
            }, {
                key: 'superview.stream.day_90.aggregation.viewers.avg',
                label: 'ACV Performance',
                sortable: false,
                formatter: (value, key, item) => {
                    if (value) {
                        const ratio = (item.chapter.statistics.avg_viewers / value) - 1
                        const isNegative = ratio > 0 ? false : true
                        const prefix = ratio > 0 ? '+' : '-'
                        const variant = ratio > 0 ? 'success' : 'danger'

                        return {
                            ratio: Math.abs(ratio),
                            is_negative: isNegative,
                            prefix: prefix,
                            variant: variant,
                        }
                    } else {
                        return null
                    }
                },
            }, {
                key: 'chapter.statistics.peak_viewers.value',
                label: 'Peak Viewers',
                sortable: true,
                formatter: (value, key, item) => {
                    if (value) {
                        return numeral(value).format('0,0')
                    } else {
                        return '---'
                    }
                },
            }, {
                key: 'chapter.statistics.views_gain',
                label: 'Impressions',
                sortable: true,
                formatter: (value, key, item) => {
                    if (value) {
                        return numeral(value).format('0,0')
                    } else {
                        return '---'
                    }
                },
            }, {
                key: 'chapter.duration_seconds',
                label: 'Duration',
                sortable: true,
                sortDirection: 'desc',
                formatter: (value, key, item) => {
                    const hours = Math.floor(value / (60 * 60))
                    const minutes = Math.round((value % (60 * 60)) / 60)

                    let result = []
                    if (hours > 0) result.push(`${hours}h`)
                    if (minutes > 0) result.push(`${minutes}m`)
                    return result.join(' ')
                }
            }, {
                key: 'chapter.ended_at',
                label: 'Time',
                sortable: true,
                sortDirection: 'desc',
                formatter: (value, key, item) => {
                    return moment(value).calendar()
                },
            }],
        }
    },

    computed: {
        ...mapGetters([
            'getColors'
        ]),
    },

    methods: {
        clickthroughProvider(ctx) {
            this.isBusy = true

            const params = {
                offset: (ctx.currentPage - 1) * ctx.perPage,
                limit: ctx.perPage,
                order_field: ctx.sortBy,
                order_direction: ctx.sortDesc ? 'desc' : 'asc',
                date_range_start: moment.utc(this.dateRangeStart).format(),
                date_range_end: moment.utc(this.dateRangeEnd).format(),
            }

            // Attach filters to params
            _.assign(params, this.filters)

            return this.axios.get(`/campaigns/${this.campaignId}/chapters`, {
                params,
            }).then(({ data }) => {
                this.isBusy = false
                this.pagination.totalRows = data.payload.count
                return data.payload.chapters
            }).catch((error) => {
                console.error(`Error in table provider`, error)
                this.isBusy = false
                return []
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.table-panels {
    border-collapse:separate;
    border-spacing: 0 0.5rem;
    transition: opacity 0.25s;

    &[aria-busy="true"] {
        opacity: 0.5;
    }

    thead {
        th {
            font-weight: 400;
            line-height: 1.3;
            color: #f6f6f8;
            font-size: 1rem;
        }
    }

    tbody {
        tr {
            background: rgba(white, 0.05);
            border-radius: 5px;
            margin-bottom: 5px;

            &:hover {
                background-color: rgba(white, 0.075);
            }

            td {
                vertical-align: middle;
            }

            td:first-child {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }

            td:last-child {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }

    .progress {
        background-color: rgba(white, 0.05);
        box-shadow: none;
        border-radius: 5px;

        &.negative {
            justify-content: flex-end;

            .progress-bar {
                .progress-text {
                    right: 100%;
                    padding-right: 0.5rem;
                }
            }
        }

        &.positive {
            .progress-bar {
                .progress-text {
                    left: 100%;
                    padding-left: 0.5rem;
                }
            }
        }

        &.high {
            &.negative {
                .progress-bar {
                    .progress-text {
                        left: 0;
                        right: initial;
                        padding-left: 0.5rem;
                        color: #000 !important;
                    }
                }
            }

            &.positive {
                .progress-bar {
                    .progress-text {
                        right: 0;
                        left: initial;
                        padding-right: 0.5rem;
                        color: #000 !important;
                    }
                }
            }
        }

        .progress-bar {
            position: relative;
        }

        .progress-text {
            font-weight: 600;
            position: absolute;
            color: #bec2cc;
        }
    }
}
</style>
