import Vue from 'vue'

export default {
    state: {
        discover: {
            searches: {},
            default: {
                filters: {
                    games: [],
                    languages: [],
                    minRank: 0,
                },
                results: [],
                created_at: null,
                version: 0,
            },
        },
    },

    mutations: {
        'discover:save'(state, search) {
            Vue.set(state.discover.searches, search.name, search)
        },
        'discover:delete'(state, search) {
            Vue.set(state.discover.searches, search.name, null)
            delete state.discover.searches[search.name]
        },
    },

    getters: {
        savedDiscoverSearches: (state) => {
            return state.discover.searches
        },
    }
}
