<template>
<div class="page page-logout"></div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    created() {
        this.$store.commit('authentication:logout')
        this.$store.commit('self:clear')
        this.$router.push({ name: 'login' })
    },
}
</script>
<style lang="scss">
</style>
