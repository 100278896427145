<template>
    <div class="view view-supercampaign">
        <campaign-modal
            modal-id="modal-supercampaign-campaign"
            :type="modal.type"
            :campaign="modal.campaign"
            :supercampaign="supercampaign"
            @submit="onModalSubmit"
            @close="onModalClose"
        />

        <div class="row mb-4" v-if="supercampaign">
            <div class="col-lg-8">
                <h4 class="h2 m-0">{{ supercampaign.title }}</h4>
                <p class="small m-0">Created {{ supercampaign.created_at | moment('from', 'now', true) }} ago</p>
            </div>

            <div class="col-lg-4 d-flex justify-content-end">
                <div class="d-flex flex-column">
                    <p class="mb-1 text-right">Campaign Status</p>

                    <b-dropdown :text="getFromDictionary(`campaign.status.${supercampaign.status}`, 'label')" variant="primary">
                        <b-dropdown-group :header="item.name" v-for="(item, key) in options.statuses">
                            <b-dropdown-item-button
                                v-for="step in item.steps"
                                :active="supercampaign.status === `${item.name}-${step}`"
                                @click.prevent="onSupercampaignStatusClick(item.name, step)"
                            >
                                {{ getFromDictionary(`campaign.status.${item.name}-${step}`, 'label') }}
                            </b-dropdown-item-button>
                        </b-dropdown-group>
                    </b-dropdown>
                </div>
            </div>
        </div>

        <div class="row mb-sm">
            <div class="col-lg-12 d-flex">
                <div class="mr-2" v-if="supercampaign && supercampaign.campaigns && supercampaign.campaigns.length">
                    <router-link

                        v-for="campaign in supercampaign.campaigns"
                        :to="{
                            name: 'campaign',
                            params: {
                                supercampaign_id: supercampaign.id,
                                campaign_id: campaign.id,
                            },
                        }"
                        tag="button"
                        class="btn btn-sm btn-outline-primary ml-auto"
                        :class="{
                            'active': $route.params.campaign_id === campaign.id
                        }"
                    >
                        <font-awesome-icon :icon="typeToIcon(campaign.type)" class="mr-2" />{{ typeToTitle(campaign.type) }}
                    </router-link>
                </div>

                <b-button variant="outline-success" size="sm" @click="onCampaignActionClick(null, 'create')">
                    <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" /> New
                </b-button>
            </div>
        </div>

        <div class="row row-panels" v-if="supercampaign && (!supercampaign.campaigns || !supercampaign.campaigns.length)">
            <div class="col-lg-3">
                <div class="panel p-3">
                    <p class="m-0">Please click the plus sign above to create a component for this campaign.</p>
                </div>
            </div>
        </div>

        <router-view
            v-if="supercampaign"
            :supercampaign="supercampaign"
            :campaign="campaign"
        />
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import CampaignModal from '@/components/Modals/CampaignModal'

export default {
    name: 'view-supercampaign',

    metaInfo() {
        return {
            title: 'Campaign'
        }
    },

    components: {
        CampaignModal,
    },

    data() {
        return {
            supercampaign: null,
            campaign: null,
            is_enabled: false,

            modal: {
                type: 'create',
                campaign: null,
            },

            options: {
                statuses: [{
                    name: 'kickoff',
                    steps: [
                        'unscheduled',
                        'scheduled',
                        'completed',
                    ],
                }, {
                    name: 'discovery',
                    steps: [
                        'targeting',
                        'creators',
                    ],
                }, {
                    name: 'execution',
                    steps: [
                        'collection',
                    ]
                }, {
                    name: 'reporting',
                    steps: [
                        'aggregation',
                        'delivery',
                    ],
                }],
            }
        }
    },

    created() {
        if (!this.$route.params.supercampaign_id) {
            this.$router.push({
                name: 'campaigns'
            })
        }

        this.getCampaign()
    },

    computed: {
        ...mapGetters([
            'getColors',
            'getFromDictionary',
        ])
    },

    methods: {
        getCampaign() {
            this.axios.get(`/supercampaigns/${this.$route.params.supercampaign_id}`, {
                params: {
                    relations: ['game', 'campaigns', 'key_groups'],
                },
            }).then(({ data }) => {
                this.supercampaign = data.payload.supercampaign

                // Make sure that we never end up on a blank page, redirect to first campaign
                if (!this.$route.params.campaign_id) {
                    if (this.supercampaign.campaigns && this.supercampaign.campaigns.length > 0) {
                        const fistCampaign = _.first(this.supercampaign.campaigns)

                        this.$router.push({
                            name: 'campaign',
                            params: {
                                supercampaign_id: this.$route.params.supercampaign_id,
                                campaign_id: fistCampaign.id,
                            },
                        })
                    }
                }
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },


        onCampaignActionClick(campaign, type = 'create') {
            // This way we save the state of the modal if it's clicked out of
            if (campaign) this.modal.campaign = campaign
            this.modal.type = type
            this.$bvModal.show('modal-supercampaign-campaign')
        },

        onSupercampaignStatusClick(status, step) {
            const newStatus = `${status}-${step}`
            if (this.supercampaign.status === newStatus) return

            this.axios.put(`/supercampaigns/${this.$route.params.supercampaign_id}`, {
                supercampaign: {
                    status: newStatus,
                },
            }).then(({ data }) => {
                _.assign(this.supercampaign, data.payload.supercampaign)

                this.$notify({
                    group: "global",
                    type: "success",
                    text: `Successfully updated campaign status`,
                    duration: 5000,
                    speed: 1000,
                })
            })
        },

        onModalClose() {
            this.modal.campaign = null
            this.modal.type = 'create'
        },

        onModalSubmit(type, { campaign_id, campaign }) {
            if (type === 'create') {
                this.axios.post(`/campaigns`, {
                    campaign: _.assign(campaign, {
                        parent_id: this.supercampaign.id,
                        organization_game_id: this.supercampaign.organization_game_id,
                    }),
                }).then(({ data }) => {
                    const campaign = data.payload.campaign
                    this.supercampaign.campaigns.push(campaign)

                    this.$notify({
                        group: 'global',
                        type: 'success',
                        text: `Successfully created new campaign: ${campaign.title}`,
                        duration: 5000,
                        speed: 1000,
                    })

                    this.$router.push({
                        name: 'campaign',
                        params: {
                            supercampaign_id: this.$route.params.supercampaign_id,
                            campaign_id: campaign.id,
                        },
                    })
                }).catch((error) => {
                    const message = error.message ? error.message : `There was an error trying to create your campaign.`

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Delete Campaign',
                        text: message,
                        duration: 10000,
                        speed: 1000
                    })
                })

            }

            // else if (type === 'update') {
            //     this.axios.put(`/campaigns/${campaign_id}`, {
            //         campaign,
            //     }).then(({ data }) => {
            //         const oldCampaign = _.find(this.campaigns, item => item.id === campaign_id)
            //         _.assign(oldCampaign, data.payload.campaign)
            //
            //         this.$notify({
            //             group: "global",
            //             type: "success",
            //             text: `Successfully updated campaign: ${campaign.title}`,
            //             duration: 5000,
            //             speed: 1000,
            //         })
            //     })
            //
            // } else if (type === 'delete') {
            //     this.axios.delete(`/campaigns/${campaign_id}`).then(({ data }) => {
            //         this.modal.campaign = null
            //         this.campaigns = _.filter(this.campaigns, item => item.id !== campaign_id)
            //     }).catch(({ message }) => {
            //         this.$notify({
            //             group: 'global',
            //             type: 'error',
            //             title: 'Error - Delete Campaign',
            //             text: message,
            //             duration: 10000,
            //             speed: 1000
            //         })
            //     })
            // }
        },

        typeToIcon(type) {
            switch (type) {
                case 'key-distribution':
                    return ['fas', 'key']
                case 'game-sponsorship':
                    return ['fas', 'gamepad']
                case 'steam-broadcast':
                    return ['fab', 'steam']
                default:
                    return 'info'
            }
        },

        typeToTitle(type) {
            return type.replace('-', ' ')
        },
    }
}
</script>

<style lang="scss">
.view-supercampaign {

}
</style>
