<template>
    <div class="supercampaign-modal-wrapper">
        <b-modal :id="modalId" @ok="onSubmit" @hide="onHide" scrollable size="lg" no-close-on-backdrop>
            <template #modal-title>
                <h1 class="h4 m-0">{{ type | capitalize }} Campaign</h1>
            </template>

            <template #modal-footer="{ ok }">
                <b-button :variant="type === 'delete' ? 'danger' : 'success'" @click="ok()">
                    {{ type | capitalize }} Campaign
                </b-button>
            </template>

            <div class="row" v-if="type === 'delete'">
                <div class="col-lg-12">
                    <p>Are you sure you want to delete this campaign?</p>
                </div>
            </div>

            <form ref="form" @submit.stop.prevent="onSubmit" class="form-supercampaign-create" v-if="type !== 'delete'">
                <div class="row">

                    <!-- Field present in every campaign type -->
                    <div class="col-lg-8">
                        <b-form-group
                            label="Campaign Title"
                            description="This title will be visible to creators."
                            :invalid-feedback="validation.title.reason"
                        >
                            <b-form-input
                                v-model="values.title"
                                placeholder="Enter campaign title"
                                required
                                :state="validation.title.status"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-lg-4" v-if="type === 'create'">
                        <b-form-group
                            label="Abbreviation"
                            description="Cannot be changed afterwards. Used for clickthrough links."
                            :invalid-feedback="validation.abbreviation.reason"
                        >
                            <b-form-input
                                v-model="values.abbreviation"
                                placeholder="4 letter identifier"
                                required
                                :state="validation.abbreviation.status"
                                @keyup="debouncedValidate(validateAbbrevation, values.abbreviation)"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-lg-12">
                        <b-form-group label="Campaign Description" description="This description will be visible to creators.">
                            <b-form-textarea
                                v-model="values.description"
                                placeholder="Game description that is shown to creators."
                                rows="3"
                                max-rows="6"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-lg-3 mt-3">
                        <b-form-group label="Budget">
                            <b-input-group prepend="$" append=".00">
                                <b-form-input type="number" v-model="values.budget_amount" />
                            </b-input-group>
                        </b-form-group>
                    </div>

                    <!-- <div class="col-lg-4 mt-3" v-if="type === 'create'"> -->
                    <div class="col-lg-4 mt-3">
                        <b-form-group label="Game" description="Do NOT change the game associated with an ongoing campaign. This could have unintended side-effects.">
                            <b-form-select v-model="values.organization_game_id" v-if="games">
                                <b-form-select-option :value="null">Select a game</b-form-select-option>
                                <b-form-select-option :value="game.id" v-for="game in games">{{ game.igdb.name }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-lg-4 mt-3">
                        <b-form-group label="Stage" description="What stage in the game's lifecycle is this?">
                            <b-form-select v-model="values.stage">
                                <b-form-select-option :value="null">Select a stage</b-form-select-option>
                                <b-form-select-option :value="stage" v-for="stage in options.stage">{{ stage }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <div class="d-flex">
                            <h3 class="h5 m-0">Targeting Profile</h3>

                            <div class="filters-games ml-2 d-flex position-relative">
                                <dropdown-text-filterable
                                    class="my-auto"
                                    itemTextProperty="name"
                                    :itemList="this.options.games"
                                    :itemClick="onGameResultClick"
                                    :search="onGameSearch"
                                    searchPlaceholder="Enter game name"
                                    alignment="left"
                                >
                                    <font-awesome-icon :icon="['fas', 'plus']" class="check" />
                                </dropdown-text-filterable>
                            </div>
                        </div>

                        <div class="row row-panels filters-games mt-2">
                            <div
                                class="mb-2 d-flex col-lg-4"
                                v-for="game in values.payload.targeting.games"
                                :key="game._id"
                            >
                                <div class="panel game-item px-3 py-1 w-100">
                                    <div
                                        class="game-backdrop"
                                        :style="{
                                            'background-image': `url(${game.cover_url})`
                                        }"
                                    />

                                    <div class="foreground d-flex">
                                        <div class="title">
                                            {{ game.name }}
                                        </div>
                                        <div
                                            class="remove d-flex"
                                            @click="removeTargetingGame(game)"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'times']"
                                                class="ml-auto my-auto"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12" v-if="!values.payload.targeting || !values.payload.targeting.games.length">
                                <p class="m-0 text-center small my-1">No games added to targeting</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import GameDropdown from '@/components/Dropdowns/GameDropdown'
import DropdownTextFilterable from '@/components/Dropdowns/DropdownTextFilterable.vue'

export default {
    name: 'supercampaign-modal',

    components: {
        GameDropdown,
        DropdownTextFilterable,
    },

    props: {
        modalId: {
            type: String,
        },
        type: {
            type: String,
        },
        supercampaign: {
            type: Object,
        },
        games: {
            type: Array,
        },
    },

    watch: {
        supercampaign(newValue) {

            // If an existing supercampaign has been attached to the modal
            if (newValue) {
                this.values.title = this.supercampaign.title
                this.values.abbreviation = this.supercampaign.abbreviation
                this.values.description = this.supercampaign.description
                this.values.stage = this.supercampaign.stage

                this.values.budget_amount = Number(this.supercampaign.budget_amount)
                this.values.organization_game_id = this.supercampaign.organization_game_id

                // Merge supercampaign settings with defaults to ensure all fields are preserved
                if (this.supercampaign.payload) {
                    this.values.payload = _.assign(_.cloneDeep(this.defaults.payload), this.supercampaign.payload)
                }

            // If modal has been reset to create new supercampaign state
            } else {
                this.resetState()
            }
        },
    },

    data() {
        return {
            options: {
                stage: [
                    'Closed Alpha',
                    'Closed Beta',
                    'Open Alpha',
                    'Open Beta',
                    'Pre-Release',
                    'Release',
                    'Minor Patch',
                    'Major Patch',
                    'DLC',
                    'Platform Release',
                    'Early Access',
                    'Post Release',
                ],

                games: [],
            },

            defaults: {
                title: '',
                abbreviation: '',
                description: '',
                stage: null,

                budget: 0,
                game: null,
                organization_game_id: null,

                payload: {
                    showcase: {
                        game_name: null,
                        screenshots: [],
                        videos: [],
                    },
                    targeting: {
                        games: [],
                    },
                },
            },

            validation_override: {},

            values: null,
        }
    },

    computed: {
        ...mapGetters([
            'ranks',
        ]),

        organizationGame() {
            if (!this.values.organization_game_id) return null
            return _.find(this.games, item => item.id === this.values.organization_game_id)
        },

        validation() {
            const fields = [
                'title',
                'abbreviation',
                'description',
                'organization_game_id',
            ]

            const state = {}

            _.forEach(fields, (field) => {
                state[field] = { status: null, reason: null }
            })

            if (this.values.title) {
                if (this.values.title.length < 5) {
                    state.title.status = false
                    state.title.reason = `Please enter more than 5 characters.`
                }
            }

            if (this.values.abbreviation) {
                if (this.values.abbreviation.length !== 4) {
                    state.abbreviation.status = false
                    state.abbreviation.reason = `Must be exactly 4 characters.`
                }

                if (!this.values.abbreviation.match(/^[a-zA-Z]+$/)) {
                    state.abbreviation.status = false
                    state.abbreviation.reason = `Cannot contain non-alphabet characters.`
                }
            }

            // Always overwrite with validation overrides
            return _.assign(state, this.validation_override)
        },
    },

    created() {
        this.resetState()
    },

    methods: {
        debouncedValidate: _.debounce((action, text) => {
            action(text)
        }, 500),

        validateAbbrevation(text) {

            // Don't bother if it's not all 4 letters
            if (this.values.abbreviation.length !== 4) return

            this.axios.get(`/campaigns/abbreviation/${text}`).then(({ data }) => {
                if (data.payload.supercampaign) {
                    this.$set(this.validation_override, 'abbreviation', {
                        status: false,
                        reason: `That abbreviation is already being used.`
                    })
                } else {
                    if (this.validation_override['abbreviation']) {
                        this.$set(this.validation_override, 'abbreviation', null)
                        delete this.validation_override['abbreviation']
                    }
                }
            }).catch((error) => {
                console.error(error)
            })
        },

        resetState() {
            this.values = _.cloneDeep(this.defaults)
        },

        onSubmit() {
            let data

            if (this.type === 'delete') {
                data = {
                    supercampaign_id: this.supercampaign.id,
                }
            }

            if (this.type === 'create') {
                data = {
                    supercampaign: {
                        title: this.values.title,
                        abbreviation: this.values.abbreviation,
                        description: this.values.description,
                        organization_game_id: this.values.organization_game_id,
                        stage: this.values.stage,
                        budget_amount: Number(this.values.budget_amount),
                        payload: this.values.payload,
                    },
                }
            }

            if (this.type === 'update') {
                data = {
                    supercampaign_id: this.supercampaign.id,
                    supercampaign: {
                        title: this.values.title,
                        description: this.values.description,
                        organization_game_id: this.values.organization_game_id,
                        stage: this.values.stage,
                        budget_amount: Number(this.values.budget_amount),
                        payload: this.values.payload,
                    },
                }
            }

            this.$emit('submit', this.type, data)
            this.resetState()
        },

        onHide(event) {
            this.$emit('close', event)
            this.resetState()
        },

        onGameSearch(text) {
            this.axios.post(`/games/igdb/search`, {
                text
            }).then(({ data }) => {
                this.options.games = data.payload.games
            }).catch(error => {
                if (error.is_cancelled) return
                console.error(error.message)
            })
        },

        removeTargetingGame(game) {
            this.values.payload.targeting.games = _.filter(
                this.values.payload.targeting.games,
                item => {
                    // console.log(item._id, game._id, typeof item._id, typeof game._id, item._id === game._id)
                    return String(item._id) !== String(game._id)
                }
            )
        },

        onGameResultClick(game) {
            const isGameDuplicate = _.find(this.values.payload.targeting.games, item => item._id === game._id)

            if (!isGameDuplicate) {
                this.values.payload.targeting.games.push(game)

            } else {
                this.$notify({
                    group: "global",
                    type: "error",
                    title: "Error - Add Game To Filters",
                    text: `This game has already been added to the targeting.`,
                    duration: 10000,
                    speed: 1000
                })
            }
        },

        onGameClick(game) {
            this.values.game = game
        },
    }
}
</script>

<style lang="scss">
#modal-supercampaign {
    .filters-games {
        .game-item {
            border-radius: 5px;
            position: relative;
            overflow: hidden;

            .game-backdrop {
                background-position: center;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: center;

                &:before {
                    content: "";
                    background: linear-gradient(
                        90deg,
                        #1e2029 0,
                        rgba(30, 32, 41, 0.9) 50%,
                        rgba(30, 32, 41, 0.5)
                    );
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .foreground {
                position: relative;

                .title {
                    width: calc(100% - 20px);
                }

                .remove {
                    width: 20px;
                    cursor: pointer;
                    transition: color 0.25s;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>
