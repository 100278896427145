import VueMoment from 'vue-moment'
import VueNumeral from 'vue-numeral-filter'
import Vue2Filters from 'vue2-filters'

import _ from 'lodash'

const GlobalFilters = {
    install(Vue) {
        // Vue.filter('capitalize', function(value) {
        //     if (!value) return ''
        //     value = value.toString()
        //     return value.charAt(0).toUpperCase() + value.slice(1)
        // })
        //
        // Vue.filter('orderBy', function(array, fields, order) {
        //     if (!array) return []
        //     return _.orderBy(array, fields, order)
        // })

        Vue.use(VueMoment)
        Vue.use(VueNumeral)
        Vue.use(Vue2Filters)
    }
}

export default GlobalFilters
