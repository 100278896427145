<template>
<div>
    <div class="discover-table-wrapper">
        <div class="select-all-wrapper">
            <div class="select-all-inner d-flex h-100">
                <!-- <b-form-checkbox
                    class="custom-control-inline m-auto"
                    v-model="actions.isAllSelected"
                    @change="onSelectAll"
                /> -->
            </div>
        </div>

        <div class="actions-wrapper" v-if="actions.selected.length">
            <div class="actions-inner">
                <div class="campaign-select-wrapper position-relative">
                    <dropdown-detailed
                        itemTextProperty="title"
                        :itemList="campaigns"
                        :itemClick="onCampaignSelect"
                        searchPlaceholder='Enter campaign name'
                        secondaryItemTextProperty="type"
                        game="payload"
                    >
                        <button class="btn btn-sm btn-outline-primary">Add selected to Campaign ({{ actions.selected.length }})</button>
                    </dropdown-detailed>
                </div>

                <!-- <div class="campaign-select-wrapper position-relative ml-2">
                    <dropdown-detailed
                        itemTextProperty="title"
                        :itemList="campaigns"
                        :itemClick="onCampaignSelectAll"
                        searchPlaceholder='Enter campaign name'
                        secondaryItemTextProperty="type"
                        game="payload.game.name"
                    >
                        <button class="btn btn-sm btn-outline-primary">Add all to Campaign ({{ creators.length }})</button>
                    </dropdown-detailed>
                </div> -->

                <div class="campaign-select-wrapper position-relative ml-2">
                    <dropdown-text-filterable
                        itemTextProperty="name"
                        :itemList="lists"
                        :itemClick="onListSelect"
                        searchPlaceholder='Enter list name'
                    >
                        <button class="btn btn-sm btn-outline-primary">Add to List ({{ actions.selected.length }})</button>
                    </dropdown-text-filterable>
                </div>

                <!-- <div class="campaign-select-wrapper position-relative ml-2">
                    <dropdown-text-filterable
                        itemTextProperty="name"
                        :itemList="lists"
                        :itemClick="onListSelectAll"
                        searchPlaceholder='Enter list name'
                    >
                        <button class="btn btn-sm btn-outline-primary">Add all to List ({{ creators.length }})</button>
                    </dropdown-text-filterable>
                </div> -->
            </div>
        </div>

        <b-form-checkbox-group v-model="actions.selected">
            <b-table
                id="discover-table"
                class="table-panels"
                :busy.sync="isBusy"
                :items="creators"
                :fields="fields"
                :sort-by.sync="sort.field"
                :sort-desc.sync="sort.isDescending"
                borderless
                sort-icon-right
                :per-page="pagination.perPage"
                :current-page.sync="pagination.currentPage"
                :fixed="true"
                @row-clicked="onRowClick"
                ref="bootstrapTable"
                empty-text="No creators to show"
                show-empty
            >
                <template #head(superview._id)="data"></template>

                <template #cell(superview._id)="data">
                    <div class="d-flex">
                        <b-form-checkbox
                            class="m-auto d-flex"
                            :value="data.value"
                        />
                    </div>
                </template>

                <template #cell(superview.channel.name)="data">
                    <div class="creator-wrapper my-auto d-flex">
                        <b-img-lazy
                            :src="data.item.superview.channel.logo"
                            class="avatar my-auto"
                        />

                        <div
                            class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                        >
                            <span class="h6 m-0">
                                {{ data.item.superview.channel.name }}

                                <verified-badge
                                    v-if="data.item.superview.channel.is_partner"
                                    :platform="'twitch'"
                                />
                            </span>

                            <small
                                v-if="data.item.superview.rank"
                                :style="{
                                    color: getColors.ranks[data.item.superview.rank.name]
                                }"
                            >
                                {{ data.item.superview.rank.name | capitalize }}
                            </small>
                        </div>
                    </div>
                </template>

                <template #cell(superview.channel.snippet.title)="data">
                    <div class="creator-wrapper my-auto d-flex">
                        <b-img-lazy
                            :src="data.item.superview.channel.snippet.thumbnails.medium.url.replace('s240', 's88')"
                            class="avatar my-auto"
                        />

                        <div
                            class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                        >
                            <span class="h6 m-0">
                                {{ data.item.superview.channel.snippet.title }}
                            </span>

                            <small
                                v-if="data.item.superview.rank"
                                :style="{
                                    color: getColors.ranks[data.item.superview.rank.name]
                                }"
                            >
                                {{ data.item.superview.rank.name | capitalize }}
                            </small>

                            <small
                                v-else
                            >
                                Not Tracked
                            </small>
                        </div>
                    </div>
                </template>

                <template #cell(affinity.streamer.percent)="data">
                    <div class="affinity-wrapper my-auto">
                        <div class="affinity-streamer">
                            <font-awesome-icon
                                :icon="['fas', 'microphone-alt']"
                                class="mr-1 font-muted"
                                v-tooltip="`Streamer Affinity`"
                            />
                            <span class="ml-1">{{ data.item.affinity.streamer.percent | numeral("0%") }}</span>
                        </div>
                    </div>
                </template>

                <template #cell(affinity.audience.percent)="data">
                    <div class="affinity-wrapper my-auto">
                        <div class="affinity-audience" v-if="data.item.affinity.audience">
                            <font-awesome-icon
                                :icon="['fas', 'users']"
                                class="mr-1 font-muted"
                                v-tooltip="`Audience Affinity`"
                            />
                            <span class="ml-1">{{ data.item.affinity.audience.percent | numeral("0%") }}</span>
                        </div>
                    </div>
                </template>

                <template #cell(superview.stream.day_90.aggregation.viewers.avg)="data">
                    <div class="acv-wrapper my-auto">
                        <font-awesome-icon
                            :icon="['fas', 'eye']"
                            class="mr-1 font-muted"
                        />
                        <span class="ml-1" v-if="data.value">
                            {{ data.value | numeral("0,0") }}
                        </span>
                        <span class="ml-1" v-else>
                            ---
                        </span>
                    </div>
                </template>

                <template #cell(superview.channel.followers)="data">
                    <div class="followers-wrapper my-auto">
                        <font-awesome-icon
                            :icon="['fas', 'user-friends']"
                            class="mr-1 font-muted"
                        />
                        <span class="ml-1">
                            {{ data.value | numeral("0a") }}
                        </span>
                    </div>
                </template>

                <template #cell(superview.video.day_90.aggregation.views.avg)="data">
                    <div class="acv-wrapper my-auto">
                        <font-awesome-icon
                            :icon="['fas', 'eye']"
                            class="mr-1 font-muted"
                        />
                        <span class="ml-1" v-if="data.value">
                            {{ data.value | numeral("0,0") }}
                        </span>
                        <span class="ml-1" v-else>
                            ---
                        </span>
                    </div>
                </template>

                <template #cell(superview.channel.statistics.subscriberCount)="data">
                    <div class="acv-wrapper my-auto">
                        <font-awesome-icon
                            :icon="['fas', 'user-friends']"
                            class="mr-1 font-muted"
                        />
                        <span class="ml-1" v-if="data.value">
                            {{ data.value | numeral("0a") }}
                        </span>
                        <span class="ml-1" v-else>
                            ---
                        </span>
                    </div>
                </template>

                <template #cell(superview.channel.snippet.country)="data">
                    <div class="acv-wrapper my-auto">
                        <font-awesome-icon
                            :icon="['fas', 'globe-americas']"
                            class="mr-1 font-muted"
                        />
                        <span class="ml-1" v-if="data.value">
                            {{ data.value }}
                        </span>
                        <span class="ml-1" v-else>
                            ---
                        </span>
                    </div>
                </template>
            </b-table>
        </b-form-checkbox-group>
    </div>

    <div class="d-flex">
        <b-pagination
            class="mx-auto"
            :total-rows="creators.length"
            :per-page="pagination.perPage"
            v-model="pagination.currentPage"
         />
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import numeral from 'numeral'
import VerifiedBadge from '@/components/Client/VerifiedBadge'
import DropdownTextFilterable from '@/components/Dropdowns/DropdownTextFilterable'
import DropdownDetailed from '@/components/Dropdowns/DetailedDropdown'

export default {
    name: 'DiscoverTable',

    components: {
        VerifiedBadge,
        DropdownTextFilterable,
        DropdownDetailed
    },

    props: {
        creators: {
            type: Array,
            default: () => [],
        },
        lists: {
            type: Array,
            default: () => [],
        },
        campaigns: {
            type: Array,
            default: () => [],
        },
        platform_id: {
            type: String,
            default: 'twitch',
        },
        type: {
            default: 'by-name',
        },
    },

    watch: {
        creators() {
            this.actions.selected = []
            this.actions.isAllSelected = false
        },
    },

    data() {
        return {
            isBusy: false,

            actions: {
                selected: [],
                isAllSelected: false,
            },

            sort: {
                field: 'affinity.combined.percent',
                isDescending: true,
            },

            pagination: {
                perPage: 10,
                currentPage: 1,
                totalRows: 100,
            },
        }
    },

    computed: {
        ...mapGetters([
            'getColors',
            'getFromDictionary',
        ]),

        fields() {
            const fields = []

            if (this.platform_id === 'twitch') {
                fields.push(...[{
                    key: 'superview._id',
                    label: '',
                    sortable: false,
                }, {
                    key: 'superview.channel.name',
                    label: 'Creator',
                    sortable: true,
                }])

                if (this.type === 'by-game') {
                    fields.push(...[{
                        key: 'affinity.streamer.percent',
                        label: 'Streamer Affinity',
                        sortable: true,
                        formatter: (value, key, item) => {
                            return numeral(value).format('0,0%')
                        },
                    }, {
                        key: 'affinity.audience.percent',
                        label: 'Audience Affinity',
                        sortable: true,
                        formatter: (value, key, item) => {
                            return numeral(value).format('0,0%')
                        },
                    }])
                }

                fields.push(...[{
                    key: 'superview.stream.day_90.aggregation.viewers.avg',
                    label: 'AVG Viewers',
                    sortable: true,
                    formatter: (value, key, item) => {
                        return numeral(value).format('0,0')
                    },
                }, {
                    key: 'superview.channel.followers',
                    label: 'Followers',
                    sortable: true,
                    formatter: (value, key, item) => {
                        return numeral(value).format('0.0a')
                    },
                }, {
                    key: 'superview.channel.language',
                    label: 'Locale',
                    sortable: true,
                    formatter: (value, key, item) => {
                        return value ? value.split('-')[0].toUpperCase() : '?'
                    },
                }])
            }

            if (this.platform_id === 'youtube') {
                fields.push(...[{
                    key: 'superview._id',
                    label: '',
                    sortable: false,
                }, {
                    key: 'superview.channel.snippet.title',
                    label: 'Creator',
                    sortable: true,
                }])

                if (this.type === 'by-game') {
                    fields.push(...[{
                        key: 'affinity.streamer.percent',
                        label: 'Streamer Affinity',
                        sortable: true,
                        formatter: (value, key, item) => {
                            return numeral(value).format('0,0%')
                        },
                    }])
                }

                fields.push(...[{
                    key: 'superview.video.day_90.aggregation.views.avg',
                    label: 'AVG Views',
                    sortable: true,
                }, {
                    key: 'superview.channel.statistics.subscriberCount',
                    label: 'Subscribers',
                    sortable: true,
                }, {
                    key: 'superview.channel.snippet.country',
                    label: 'Country',
                    sortable: true,
                }])
            }

            return fields
        },
    },

    methods: {
        onSelectAll(isChecked) {
            if (isChecked) {
                const paginatedItems = this.$refs['bootstrapTable'].paginatedItems
                this.actions.selected = _.map(paginatedItems, 'superview._id')
            } else {
                this.actions.selected = []
            }
        },

        onRowClick(item, index, event) {
            const ignoreList = ['BUTTON', 'LABEL', 'INPUT']
            if (ignoreList.includes(event.target.tagName)) return

            // this.$store.commit('drawer:set', {
            //     enabled: true,
            //     creator: item.superview,
            // })

            this.$emit('row-clicked', { item, index, event })
        },

        onCampaignSelect(campaign, isAll) {
            let creators = []

            if (isAll) {
                creators = _.map(this.creators, (creator) => {
                    return {
                        platform_user_id: creator.superview._id,
                        platform_id: creator.superview.platform_id,
                    }
                })

            } else {
                creators = _.reduce(this.actions.selected, (results, platformUserId) => {
                    const creator = _.find(this.creators, (creator) => {
                        return creator.superview._id === platformUserId
                    })

                    if (creator) {
                        results.push({
                            platform_user_id: creator.superview._id,
                            platform_id: creator.superview.platform_id,
                        })
                    }

                    return results
                }, [])
            }

            this.actions.selected = []
            this.actions.isAllSelected = false
            this.$emit('campaign-select', campaign, creators)
        },

        onListSelectAll(list) {
            this.onListSelect(list, true)
        },

        onListSelect(list, isAll) {
            let creators = []

            if (isAll) {
                creators = _.map(this.creators, (creator) => {
                    return {
                        platform_user_id: creator.superview._id,
                        platform_id: 'twitch',
                    }
                })

            } else {
                creators = _.reduce(this.actions.selected, (results, platformUserId) => {
                    const creator = _.find(this.creators, (creator) => {
                        return creator.superview._id === platformUserId
                    })

                    if (creator) {
                        results.push({
                            platform_user_id: creator.superview._id,
                            platform_id: 'twitch',
                        })
                    }

                    return results
                }, [])
            }

            this.actions.selected = []
            this.actions.isAllSelected = false
            this.$emit('list-select', list, creators)
        },
    }
}
</script>

<style lang="scss">
.discover-table-wrapper {
    position: relative;

    .select-all-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 68px;
        width: 10%;
    }

    .actions-wrapper {
        position: absolute;
        top: 0;
        left: 10%;
        height: 68px;

        background: #12141d;
        display: flex;
        width: 90%;
        z-index: 10;

        .actions-inner {
            margin: auto 0;
            display: flex;
        }
    }

    thead {
        th {
            &:nth-child(1) {
                width: 10%;
            }

            &:nth-child(2) {
                width: 30%;
            }
        }
    }
}

.table-panels {
    border-collapse:separate;
    border-spacing: 0 0.5rem;
    transition: opacity 0.25s;

    &[aria-busy="true"] {
        opacity: 0.5;
    }

    thead {
        th {
            font-weight: 400;
            line-height: 1.3;
            color: #f6f6f8;
            font-size: 1rem;
            transition: 0.25s all;
            vertical-align: middle !important;

            & > div {
                margin: auto 0;
            }

            &:hover {
                background: rgba(white, 0.025);
                border-radius: 5px;
            }

            &:active {
                background: rgba(white, 0.05);
                border-radius: 5px;
            }
        }
    }

    tbody {
        tr {
            background: rgba(white, 0.05);
            border-radius: 5px;
            margin-bottom: 5px;

            &:hover {
                background-color: rgba(white, 0.075);
            }

            td {
                vertical-align: middle;
            }

            td:first-child {
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
            }

            td:last-child {
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }

    .affinity-streamer, .affinity-audience {
        svg {
            min-width: 20px;
        }
    }
}
</style>
