<template>
    <div class="view view-campaign-overview">

    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
    name: 'view-campaign-overview',

    metaInfo() {
        return {
            title: 'Campaign Overview'
        }
    },

    components: {
    },

    props: {
        campaign: {
            type: Object,
        },
    },

    data() {
        return {

        }
    },

    created() {
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),
    },

    methods: {

    }
}
</script>

<style lang="scss">
.view-campaign-overview {

}
</style>
