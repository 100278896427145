<template>
    <div class="view view-game">

        <div class="row mb-4" v-if="game">
            <div class="col-lg-12 mb-2">
                <h4 class="h2 m-0">{{ game.igdb.name }}</h4>
                <p class="small">Created {{ game.created_at | moment('from', 'now', true) }} ago</p>
            </div>

            <div class="col-lg-12">
                <!-- <router-link
                    :to="{ name: 'game-summary', params: { game_id: $route.params.game_id } }"
                    tag="button"
                    class="btn btn-outline-primary btn-sm"
                    active-class="btn-primary"
                >
                    Summary
                </router-link> -->

                <router-link
                    :to="{ name: 'game-keys', params: { game_id: $route.params.game_id } }"
                    tag="button"
                    class="btn btn-outline-primary btn-sm"
                    active-class="btn-primary"
                >
                    Keys
                </router-link>

                <router-link
                    :to="{ name: 'game-twitch-chapters', params: { game_id: $route.params.game_id } }"
                    tag="button"
                    class="btn btn-outline-primary btn-sm"
                    active-class="btn-primary"
                >
                    Past Streams
                </router-link>

                <router-link
                    :to="{ name: 'game-twitch-bookmarks', params: { game_id: $route.params.game_id } }"
                    tag="button"
                    class="btn btn-outline-primary btn-sm"
                    active-class="btn-primary"
                >
                    Live Streams
                </router-link>
            </div>

            <div class="col-lg-12 mt-5" v-if="$route.name === 'game'">
                <pre v-html="printJSON(game)" />
            </div>
        </div>

        <router-view :game="game" />
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
    name: 'view-game',

    metaInfo() {
        return {
            title: 'Game'
        }
    },

    components: {
    },

    data() {
        return {
            game: null,
        }
    },

    created() {
        this.getGame()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ])
    },

    methods: {
        getGame() {
            this.axios.get(`/games/${this.$route.params.game_id}`).then(({ data }) => {
                this.game = data.payload.game
            })
        },

        printJSON(json) {
            if (typeof json != 'string') {
                 json = JSON.stringify(json, undefined, 2);
            }
            json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
            return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                var cls = 'number';
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = 'key';
                    } else {
                        cls = 'string';
                    }
                } else if (/true|false/.test(match)) {
                    cls = 'boolean';
                } else if (/null/.test(match)) {
                    cls = 'null';
                }
                return '<span class="' + cls + '">' + match + '</span>';
            });
        },
    }
}
</script>

<style lang="scss">
.view-campaign {
    pre {
        outline: 1px solid rgba(white, 0.075);
        padding: 15px;
        margin: 5px;
    }

    .string { color: green; }
    .number { color: darkorange; }
    .boolean { color: blue; }
    .null { color: magenta; }
    .key { color: red; }
}
</style>
