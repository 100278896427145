import Vue from 'vue'
import moment from 'moment'

export default {
    state: {
        notifications: [],
        updated_at: moment().format()
    },

    mutations: {
        'notifications:set'(state, { notifications }) {
            Vue.set(state, 'updated_at', moment().format())
            Vue.set(state, 'notifications', notifications)
        },

        'notifications:add'(state, { notification }) {
            Vue.set(state, 'updated_at', moment().format())
            state.notifications.push(notification)
        },

        'notifications:removeById'(state, { id }) {
            Vue.set(state, 'updated_at', moment().format())
            state.notifications = state.notifications.filter(item => item.id !== id)
        },

        'notifications:update'(state, { id, properties }) {
            Vue.set(state, 'updated_at', moment().format())
            const existing = state.notifications.find(item => item.id === id)

            if (!existing) {
                console.log(`[Notification Store]: Update called on non-existant item.`, id)
                return
            }

            Object.keys(properties).forEach((key) => {
                Vue.set(existing, key, properties[key])
            })
        },
    },

    getters: {
        notifications(state) {
            return state.notifications
        }
    }
}
