<template>
    <div class="view view-game-summary">
        <div class="row row-panels">
            <div class="col-lg-3">
                <div class="row" v-if="seriesAggregation">
                    <div class="col-lg-12">
                        <div class="panel p-3 text-center">
                            <h1 class="h1 mb-1">
                                {{ seriesAggregation.avg.today_order | numeral('0,0') }}
                                <sup><small>{{ seriesAggregation.change.order > 0 ? '+' : '' }}{{ seriesAggregation.change.order | numeral('0,0')}}</small></sup>
                            </h1>
                            <p class="m-0">Avg Directory Position</p>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <div class="panel p-3 text-center">
                            <h1 class="h1 mb-1">
                                {{ seriesAggregation.avg.today_viewers_count | numeral('0,0') }}
                                <sup><small>{{ seriesAggregation.change.viewers_count > 0 ? '+' : '' }}{{ seriesAggregation.change.viewers_count | numeral('0,0')}}</small></sup>
                            </h1>
                            <p class="m-0">Avg Viewers</p>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <div class="panel p-3 text-center">
                            <h1 class="h1 mb-1">
                                {{ seriesAggregation.avg.today_channels_count | numeral('0,0') }}
                                <sup><small>{{ seriesAggregation.change.channels_count > 0 ? '+' : '' }}{{ seriesAggregation.change.channels_count | numeral('0,0')}}</small></sup>
                            </h1>
                            <p class="m-0">Avg Streamers</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <div class="panel p-3 h-100">
                    <game-series-chart
                        class="game-series-chart"
                        :series="twitch.series"
                        :fields="twitch.fields"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import GameSeriesChart from '@/components/Charts/GameSeriesChart'

export default {
    name: 'view-game-summary',

    metaInfo() {
        return {
            title: 'Live Streams'
        }
    },

    components: {
        GameSeriesChart,
    },

    data() {
        return {
            twitch: {
                fields: ['today', 'lastWeek'],
                series: [],
            },
        }
    },

    created() {
        this.getGameSummary()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        seriesAggregation() {
            if (!this.twitch.series || !this.twitch.series.length) return false

            const aggregation = {
                avg: {
                    today_order: _.meanBy(this.twitch.series, 'today_order'),
                    today_viewers_count: _.meanBy(this.twitch.series, 'today_viewers_count'),
                    today_channels_count: _.meanBy(this.twitch.series, 'today_channels_count'),
                    lastWeek_order: _.meanBy(this.twitch.series, 'lastWeek_order'),
                    lastWeek_viewers_count: _.meanBy(this.twitch.series, 'lastWeek_viewers_count'),
                    lastWeek_channels_count: _.meanBy(this.twitch.series, 'lastWeek_channels_count'),
                },
            }

            aggregation.change = {
                order: aggregation.avg.today_order - aggregation.avg.lastWeek_order,
                viewers_count: aggregation.avg.today_viewers_count - aggregation.avg.lastWeek_viewers_count,
                channels_count: aggregation.avg.today_channels_count - aggregation.avg.lastWeek_channels_count,
            }

            return aggregation
        }
    },

    methods: {
        getGameSummary() {
            Promise.all([
                this.axios.get(`/games/${this.$route.params.game_id}/twitch/series`, {
                    params: {
                        date_range_start: moment.utc().subtract(24, 'hours').format(),
                        date_range_end: moment.utc().format(),
                    }
                }).then(({ data }) => {
                    return _.map(data.payload.series, (item) => {
                        item.series = 'today'
                        return item
                    })
                }),

                this.axios.get(`/games/${this.$route.params.game_id}/twitch/series`, {
                    params: {
                        date_range_start: moment.utc().subtract(48, 'hours').format(),
                        date_range_end: moment.utc().subtract(24, 'hours').format(),
                    }
                }).then(({ data }) => {
                    return _.map(data.payload.series, (item) => {
                        item.series = 'yesterday'
                        return item
                    })
                }),

                this.axios.get(`/games/${this.$route.params.game_id}/twitch/series`, {
                    params: {
                        date_range_start: moment.utc().subtract(7, 'days').subtract(24, 'hours').format(),
                        date_range_end: moment.utc().subtract(7, 'days').format(),
                    }
                }).then(({ data }) => {
                    return _.map(data.payload.series, (item) => {
                        item.series = 'lastWeek'
                        return item
                    })
                }),
            ]).then((rangesSeries) => {
                const entriesWithOffset = _.reduce(rangesSeries, (results, series, index) => {
                    _.forEach(series, (entry) => {
                        entry.minutes_offset = moment.utc(entry.time).diff(moment.utc(entry.time).startOf('day'), 'minutes')

                        if (entry.minutes_offset % 30 === 0) {
                            const offsetGroup = results[entry.minutes_offset]

                            if (offsetGroup) {
                                offsetGroup[entry.series] = entry
                            } else {
                                results[entry.minutes_offset] = {
                                    [entry.series]: entry
                                }
                            }
                        }
                    })

                    return results
                }, {})

                this.twitch.series = _.map(entriesWithOffset, item => this.flattenObjectWithUnderscore(item))
            })
        },

        flattenObjectWithUnderscore(ob) {
            let toReturn = {}

            for (var i in ob) {
                if (!ob.hasOwnProperty(i)) continue

                if ((typeof ob[i]) == 'object' && ob[i] !== null) {
                    var flatObject = this.flattenObjectWithUnderscore(ob[i])
                    for (var x in flatObject) {
                        if (!flatObject.hasOwnProperty(x)) continue

                        toReturn[i + '_' + x] = flatObject[x]
                    }
                } else {
                    toReturn[i] = ob[i]
                }
            }

            return toReturn
        }
    }
}
</script>

<style lang="scss">
.view-game-summary {
    .game-series-chart {
        min-height: 300px;
    }
}
</style>
