<template>
    <div class="view view-supercampaigns">
        <supercampaign-modal
            modal-id="modal-supercampaign"
            :type="modal.type"
            :supercampaign="modal.supercampaign"
            :games="games"
            @submit="onModalSubmit"
            @close="onModalClose"
        />

        <div class="row" v-if="supercampaigns">
            <div class="col-lg-12">
                <div class="row mb-4">
                    <div class="col-lg-6 my-auto d-flex">
                        <h4 class="display-3">Campaigns</h4>
                    </div>

                    <div class="col-lg-6 d-flex">
                        <b-button variant="primary" class="ml-auto" @click="onCampaignActionClick(null, 'create')">New Campaign</b-button>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="row side-menu no-gutters">
                    <div
                        class="col-lg-12 side-menu-option"
                        v-for="({ date, campaigns }, monthIndex) in supercampaignsByMonth"
                        :class="{ 'active': state.selected.monthIndex === monthIndex }"
                        @click="state.selected.monthIndex = monthIndex"
                    >
                        <div class="panel p-3">
                            <div class="d-flex justify-content-between">
                                <span>{{ date }}</span>
                                <b-badge :variant="campaigns.length ? 'primary' : 'light'" pill class="d-flex"><span class="my-auto">{{ campaigns.length }}</span></b-badge>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9" v-if="supercampaignsByMonth && supercampaignsByMonth[state.selected.monthIndex] && supercampaignsByMonth[state.selected.monthIndex].campaigns && supercampaignsByMonth[state.selected.monthIndex].campaigns.length">
                <div class="row row-panels">
                    <div class="col-lg-4 mb-3" v-for="supercampaign in supercampaignsByMonth[state.selected.monthIndex].campaigns">
                        <supercampaign-card :supercampaign="supercampaign" @on-ellipsis-click="onEllipsisClick" />
                    </div>
                </div>
            </div>

            <div class="col-lg-9 mb-3" v-else>
                <div class="row row-panels">
                    <div class="col-lg-6">
                        <div class="panel p-3">
                            <p class="m-0">No campaigns during that period</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import SupercampaignModal from '@/components/Modals/SuperCampaignModal'
import SupercampaignCard from '@/components/Cards/SupercampaignCard'

import DropdownTextFilterable from '@/components/Dropdowns/DropdownTextFilterable.vue'

export default {
    name: 'view-supercampaigns',

    metaInfo() {
        return {
            title: 'Campaigns'
        }
    },

    components: {
        SupercampaignModal,
        DropdownTextFilterable,
        SupercampaignCard,
    },

    data() {
        return {
            supercampaigns: [],
            games: [],
            text: '',

            modal: {
                type: 'create',
                supercampaign: null,
            },
            state: {
                isShown: true,
                selected: {
                    monthIndex: 0,
                },
            },
        }
    },

    created() {
        this.getSupercampaigns()
        this.getGames()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        // TODO: Do all of this in one reduce function
        supercampaignsByMonth() {
            const monthOptions = []

            let dateIterator = moment.utc().startOf('month')

            for (let i = 0; i < 6; i++) {
                monthOptions.push(dateIterator.clone().format('YYYY-MM'))
                dateIterator.subtract(1, 'months')
            }

            const menuOptions = _.map(monthOptions, (monthKey) => {
                const campaigns = _(this.supercampaigns).filter((item) => {
                    const firstDate = this.getFirstStartDate(item)
                    const monthStartDate = moment.utc(monthKey, 'YYYY-MM')
                    const monthEndDate = moment.utc(monthKey, 'YYYY-MM').add(1, 'month')
                    const isWithinMonth = moment.utc(firstDate).isBetween(monthStartDate, monthEndDate)
                    return isWithinMonth
                }).value()

                return {
                    key: monthKey,
                    date: moment.utc(monthKey, 'YYYY-MM').format('MMMM, YYYY'),
                    campaigns,
                    size: _.size(campaigns),
                }
            })

            const futureCampaigns = _.filter(this.supercampaigns, (item) => {
                const firstDate = this.getFirstStartDate(item)
                return moment.utc(firstDate).isAfter(moment.utc())
            })

            menuOptions.unshift({
                key: 'upcoming',
                date: 'Upcoming',
                campaigns: futureCampaigns,
                size: _.size(futureCampaigns),
            })

            const archivedCampaigns = _.filter(this.supercampaigns, (item) => {
                const firstDate = this.getFirstStartDate(item)
                return moment.utc(firstDate).isBefore(moment.utc().subtract(6, 'months').startOf('month'))
            })

            menuOptions.push({
                key: 'archived',
                date: 'Archived',
                campaigns: archivedCampaigns,
                size: _.size(archivedCampaigns),
            })

            return menuOptions
        },
    },

    methods: {
        getFirstStartDate(supercampaign) {
            if (supercampaign.campaigns && supercampaign.campaigns.length) {
                return _.first(supercampaign.campaigns).started_at
            }

            return supercampaign.created_at
        },

        getSupercampaigns() {
            this.axios.get(`/supercampaigns`, {
                params: {
                    relations: ['games', 'campaigns']
                }
            }).then(({ data }) => {
                this.supercampaigns = data.payload.supercampaigns
            })
        },

        getGames() {
            this.axios.get(`/organizations/00000000-0000-0000-0000-000000000000/games`, {
                params: {
                    relations: ['key_groups'],
                },
            }).then(({ data }) => {
                this.games = data.payload.games
            })
        },

        onCampaignActionClick(supercampaign, type = 'create') {
            // This way we save the state of the modal if it's clicked out of
            if (supercampaign) this.modal.supercampaign = supercampaign
            this.modal.type = type
            this.$bvModal.show('modal-supercampaign')
        },

        onModalClose() {
            this.modal.supercampaign = null
            this.modal.type = 'create'
        },

        onEllipsisClick({ supercampaign, type }) {
            // This way we save the state of the modal if it's clicked out of
            if (supercampaign) this.modal.supercampaign = supercampaign

            this.modal.type = type
            this.$bvModal.show('modal-supercampaign')
        },

        onModalSubmit(type, { supercampaign_id, supercampaign }) {
            if (type === 'create') {
                this.axios.post(`/supercampaigns`, {
                    supercampaign: supercampaign,
                }).then(({ data }) => {
                    const supercampaign = data.payload.supercampaign

                    this.$notify({
                        group: "global",
                        type: "success",
                        text: `Successfully created new campaign: ${supercampaign.title}`,
                        duration: 5000,
                        speed: 1000,
                    })

                    this.$router.push({
                        name: 'supercampaign',
                        params: {
                            supercampaign_id: supercampaign.id,
                        },
                    })
                }).catch((error) => {
                    const message = error.message ? error.message : `There was an error trying to create your campaign.`

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Delete Campaign',
                        text: message,
                        duration: 10000,
                        speed: 1000
                    })
                })

            } else if (type === 'update') {
                this.axios.put(`/supercampaigns/${supercampaign_id}`, {
                    supercampaign: supercampaign,
                }).then(({ data }) => {
                    const supercampaignIndex = _.findIndex(this.supercampaigns, item => item.id === supercampaign_id)
                    this.$set(this.supercampaigns, supercampaignIndex, _.assign(supercampaign, data.payload.supercampaign))

                    this.$notify({
                        group: "global",
                        type: "success",
                        text: `Successfully updated campaign: ${supercampaign.title}`,
                        duration: 5000,
                        speed: 1000,
                    })
                })

            } else if (type === 'delete') {
                this.axios.delete(`/supercampaigns/${supercampaign_id}`).then(({ data }) => {
                    this.modal.supercampaign = null
                    this.supercampaigns = _.filter(this.supercampaigns, item => item.id !== supercampaign_id)
                }).catch(({ message }) => {
                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Delete Campaign',
                        text: message,
                        duration: 10000,
                        speed: 1000
                    })
                })
            }
        },
    }
}
</script>

<style lang="scss">
.view-supercampaigns {
    .title-underlined {
        border-bottom: 1px solid rgba(white, 0.1);
    }

    .side-menu-option {
        cursor: pointer;
        transition: 0.25s all;
        border-top: 1px solid rgba(white, 0.05);

        &:first-child {
            border-top: 1px solid transparent;
        }

        &:first-child {
            border-top: 1px solid transparent;
        }

        &:hover {
            background: rgba(white, 0.05);
            border-top: 1px solid transparent;
            border-radius: 5px;

            & + .side-menu-option {
                border-top: 1px solid transparent;
            }
        }

        &.active {
            background: rgba(white, 0.075);
            border-radius: 5px;
            border-top: 1px solid transparent;

            & + .side-menu-option {
                border-top: 1px solid transparent;
            }
        }
    }
}
</style>
