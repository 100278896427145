<template>
    <div class="row post-filters-wrapper mt-3">
        <div class="col-lg-12" v-if="defaults.avg_viewers">
            <h4 class="h6">Avg Viewers</h4>

            <vue-range-slider
                class="w-100"
                v-model="filters.avg_viewers"
                :step="1"
                :min="Math.floor(defaults.avg_viewers.min)"
                :max="Math.min(Math.ceil(defaults.avg_viewers.max), 1000)"
                :enable-cross="false"
                :clickable="false"
                :formatter="tooltipFormatter('avg_viewers', '0,0')"
                tooltip-dir="bottom"
                :lazy="true"
                @slide-end="triggerChange"
            />
        </div>
            <div class="col-lg-12" v-if="defaults.avg_views">
                <h4 class="h6">Avg Views</h4>

                <vue-range-slider
                    class="w-100"
                    v-model="filters.avg_views"
                    :step="1"
                    :min="Math.floor(defaults.avg_views.min)"
                    :max="Math.min(Math.ceil(defaults.avg_views.max), 1000000)"
                    :enable-cross="false"
                    :clickable="false"
                    :formatter="tooltipFormatter('avg_views', '0,0')"
                    tooltip-dir="bottom"
                    :lazy="true"
                    @slide-end="triggerChange"
                />
            </div>

        <div class="col-lg-12 mt-5" v-if="defaults.affinity && defaults.affinity.streamer">
            <h4 class="h6">Streamer Affinity</h4>

            <vue-range-slider
                class="w-100"
                v-model="filters.affinity.streamer"
                :min="defaults.affinity.streamer.min"
                :max="defaults.affinity.streamer.max"
                :step="0.01"
                :clickable="false"
                :enable-cross="false"
                :formatter="tooltipFormatter('affinity.streamer', '0%')"
                tooltip-dir="bottom"
                :lazy="true"
                @slide-end="triggerChange"
            />
        </div>

        <div class="col-lg-12 mt-5" v-if="filters.affinity && filters.affinity.audience">
            <h4 class="h6">Audience Affinity</h4>

            <vue-range-slider
                class="w-100"
                v-model="filters.affinity.audience"
                :min="defaults.affinity.audience.min"
                :max="defaults.affinity.audience.max"
                :step="0.01"
                :clickable="false"
                :enable-cross="false"
                :formatter="tooltipFormatter('affinity.audience', '0%')"
                tooltip-dir="bottom"
                :lazy="true"
                @slide-end="triggerChange"
            />
        </div>

        <div class="col-lg-12 mt-5" v-if="filters.games && filters.games.count">
            <h4 class="h6"># of Games Streamed</h4>

            <vue-range-slider
                class="w-100"
                v-model="filters.games.count"
                :min="Math.max(defaults.games.count.min, 1)"
                :max="defaults.games.count.max"
                :step="1"
                :clickable="false"
                :enable-cross="false"
                :formatter="tooltipFormatter('games.count', '0')"
                tooltip-dir="bottom"
                :lazy="true"
                @slide-end="triggerChange"
            />
        </div>


        <!-- Language Filters -->
        <div class="col-lg-12 mt-md" v-if="defaults.language">
            <div class="filters-languages-wrapper d-flex position-relative">
                <h4 class="h6 m-0">Languages</h4>

                <div class="filters-languages ml-auto">
                    <b-dropdown
                        variant="link"
                        class="dropdown-light ml-auto mb-0 p-0 dropdown-toggle-icon-only"
                        no-caret
                        right
                    >
                        <template #button-content>
                            <slot>
                                <font-awesome-icon :icon="['fas', 'plus']" />
                            </slot>
                        </template>

                        <div class="results-wrapper">
                            <b-dropdown-item
                                v-for="item in unselectedLanguages"
                                @click="addFilterLanguage(item)"
                            >
                                {{ item.name }} ({{ item.count | numeral('0,0') }})
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                </div>
            </div>

            <div class="row filters-languages mt-2">
                <div class="col-lg-12" v-if="!isAllLocalesSelected">
                    <b-badge
                        variant="primary"
                        v-for="language in filters.language"
                    >
                        <span>{{ language.name }}</span>
                        <div
                            class="remove d-inline ml-1"
                            @click="removeFilterLanguage(language)"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'times']"
                                class="ml-auto my-auto"
                            />
                        </div>
                    </b-badge>
                </div>

                <div class="col-lg-12" v-else>
                    <p class="text-center small my-1">Showing all languages</p>
                </div>
            </div>

            <div class="row filters-email mt-4">
                <div class="col-lg-12" v-if="defaults.metadata && defaults.metadata.email">
                    <h4 class="h6">Requirements</h4>

                    <b-form-checkbox
                        v-model="defaults.metadata.email.value"
                        :value="true"
                        :unchecked-value="false"
                    >
                        Has email
                    </b-form-checkbox>
                </div>
            </div>
        </div>

        <!-- Country Filters -->
        <div class="col-lg-12 mt-5" v-if="defaults.country">
            <div class="filters-country-wrapper d-flex position-relative">
                <h3 class="h5 m-0">Countries</h3>

                <div class="filters-country ml-auto">
                    <b-dropdown
                        variant="link"
                        class="dropdown-light ml-auto mb-0 p-0 dropdown-toggle-icon-only"
                        no-caret
                        right
                    >
                        <template #button-content>
                            <slot>
                                <font-awesome-icon :icon="['fas', 'plus']" />
                            </slot>
                        </template>

                        <div class="results-wrapper">
                            <b-dropdown-item
                                v-for="item in unselectedCountries"
                                @click="addFilterCountry(item)"
                            >
                                {{ item.name }} ({{ item.count | numeral('0,0') }})
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                </div>
            </div>

            <div class="row filters-country mt-2">
                <div class="col-lg-12" v-if="!isAllCountriesSelected">
                    <b-badge
                        variant="primary"
                        v-for="country in filters.country"
                    >
                        <span>{{ country.name }}</span>
                        <div
                            class="remove d-inline ml-1"
                            @click="removeFilterCountry(country)"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'times']"
                                class="ml-auto my-auto"
                            />
                        </div>
                    </b-badge>
                </div>

                <div class="col-lg-12" v-else>
                    <p class="text-center small my-1">Showing all countries</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import numeral from 'numeral'
import { mapGetters } from 'vuex'

import VueRangeSlider from 'vue-range-component'
import 'vue-range-component/dist/vue-range-slider.css'

export default {
    components: {
        VueRangeSlider,
    },

    name: 'PostFilterOptions',

    props: {
        defaults: {
            type: Object,
        },
    },

    watch: {
        defaults: {
            deep: true,
            handler(value) {
                this.filters = _.mapValues(this.defaults, (item) => {
                    if (!item.hasOwnProperty('value')) {
                        if (item instanceof Object) return _.mapValues(item, 'value')
                    }

                    return item.value
                })
            },
        },
        filters: {
            deep: true,
            handler(value) {
                console.log(`filters changed`, value)
                this.$emit('filters-change', value)
            },
        },
    },

    data() {
        return {
            filters: {},

            optionsByPlatform: {
                twitch: [
                    'avg_viewers',
                    'affinity.streamer',
                    'affinity.audience',
                    'language',
                    'metadata.email',
                ],
                youtube: [
                    'avg_views',
                    'affinity.streamer',
                    'language',
                ],
            },
        }
    },

    beforeMount() {
        // If defaults are provided, overwrite starting values
        if (this.defaults) {
            this.filters = _.assign(this.filters, _.mapValues(this.defaults, (item) => {
                if (!item.hasOwnProperty('value')) {
                    if (item instanceof Object) return _.mapValues(item, 'value')
                }

                return item.value
            }))
        }
    },

    computed: {
        isAllLocalesSelected() {
            const options = _.get(this, 'defaults.language.options')
            const filters = _.get(this, 'filters.language')

            if ((options && filters && options.length === filters.length) || filters.length === 0) return true
            return false
        },

        unselectedLanguages() {
            return _.filter(this.defaults.language.options, (item) => {
                return !_.find(this.filters.language, needle => needle.code === item.code)
            })
        },

        isAllCountriesSelected() {
            const options = _.get(this, 'defaults.country.options')
            const filters = _.get(this, 'filters.country')

            if ((options && filters && options.length === filters.length) || filters.length === 0) return true
            return false
        },

        unselectedCountries() {
            return _.filter(this.defaults.country.options, (item) => {
                return !_.find(this.filters.country, needle => needle.code === item.code)
            })
        },
    },

    methods: {
        isOptionEnabled(fieldName) {
            return !!this.defaults[fieldName]
        },

        tooltipFormatter(field, format) {
            return (input) => {
                let postfix = ''
                if (field === 'avg_viewers' && input >= 1000) postfix = '+'
                if (field === 'avg_views' && input >= 1000000) postfix = '+'
                return numeral(input).format(format) + postfix
            }
        },

        addFilterLanguage(language) {
            this.filters.language.push(language)
        },

        removeFilterLanguage(language) {
            this.filters.language = _.filter(this.filters.language, item => item.code !== language.code)
        },

        addFilterCountry(country) {
            this.filters.country.push(country)
        },

        removeFilterCountry(country) {
            this.filters.country = _.filter(this.filters.country, item => item.code !== country.code)
        },

        triggerChange() {
            console.log(`triggerChange`, this.filters)
            this.$emit('filters-change', this.filters)
        },
    },
}
</script>

<style lang="scss">
.post-filters-wrapper {
    h3 {
        font-size: 1.25em;
    }

    .filters-languages {
        .remove {
            cursor: pointer;
        }
    }
}
</style>
