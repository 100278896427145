// import '../assets/vendor/nucleo/css/nucleo.css'
import '../assets/scss/argon.scss'
// import '../../node_modules/axios-progress-bar/dist/nprogress.css'
import globalComponents from './globalComponents'
import globalDirectives from './globalDirectives'
import globalFilters from './globalFilters'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { loadProgressBar } from 'axios-progress-bar'
import store from "../store"

export default {
    install(Vue) {
        Vue.use(globalComponents)
        Vue.use(globalDirectives)
        Vue.use(globalFilters)

        const axiosInstance = axios.create({
            baseURL: process.env.VUE_APP_ROOT_API,
            headers: {
                'Content-Type': 'application/json'
            },
            validateStatus: (status) => {
                return (status >= 200 && status < 300) || status === 401
            },
        })

        axiosInstance.interceptors.request.use((config) => {
            //  Generate cancel token source
            let source = axios.CancelToken.source()

            // Set cancel token on this request
            config.cancelToken = source.token

            // Add to vuex to make cancellation available from anywhere
            store.commit('ADD_CANCEL_TOKEN', source)

            return config
        }, (error) => {
            return Promise.reject(error)
        })

        axiosInstance.interceptors.response.use((response) => {
            return response
        }, (error) => {
            if (axios.isCancel(error)) {
                error.is_cancelled = true
                return Promise.reject(error)
            } else {
                return Promise.reject(error)
            }
        })

        loadProgressBar(null, axiosInstance)
        Vue.use(VueAxios, axiosInstance)
    }
}
