<template>
    <div class="view view-campaign-attribution">
        <div class="row">
            <div class="col-lg-3">
                <div class="row">
                    <div
                        class="col-lg-12 creator"
                        @click="onCreatorClick(null)"
                        :class="{
                            'active': !selectedParticipantId
                        }"
                    >
                        <div class="panel py-3 d-flex h-100">
                            <div class="avatar my-auto">
                                <font-awesome-icon :icon="['fas', 'tasks']" />
                            </div>

                            <div class="name-rank-wrapper d-flex flex-column my-auto ml-3">
                                <span class="h6 m-0">All Clicks</span>
                            </div>

                            <div class="d-flex ml-auto">
                                <span class="h4 my-auto">{{ statistics.total.unique_clicks | numeral('0,0') }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <b-form-input
                            v-model="filters.text"
                            placeholder="Filter Creators"
                            autocomplete="off"
                        />
                    </div>
                </div>

                <div class="row row-creators mt-3">
                    <div
                        class="col-lg-12 creator mb-1"
                        v-for="{ superview, participant, link } in filteredCreators"
                        @click="onCreatorClick(participant.id)"
                        :class="{
                            'active': participant.id === selectedParticipantId
                        }"
                    >
                        <div class="panel py-2 d-flex h-100">
                            <img
                                :src="participant.platform_user_logo"
                                class="avatar my-auto"
                            />

                            <div
                                class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                            >
                                <span class="h6 m-0">
                                    {{ participant.platform_user_name }}

                                    <verified-badge
                                        v-if="superview.channel.partner"
                                        :platform="'twitch'"
                                    />
                                </span>

                                <small
                                    :style="{
                                        color: getColors.ranks[superview.rank.name]
                                    }"
                                >
                                    {{ superview.rank.name | capitalize }}
                                </small>
                            </div>

                            <div class="d-flex ml-auto">
                                <span class="h4 my-auto" v-if="link">{{ link.unique_clicks | numeral('0,0') }}</span>
                                <span class="h4 my-auto" v-else>No Link</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12" v-if="!filteredCreators.length">
                        <div class="panel p-3">
                            <p class="m-0 text-center">No creators found</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <div class="row row-panels mb-4">

                    <div class="col-lg-3">
                        <div class="panel p-3">
                            <h4 class="h5 pb-2 border-bottom border-light">Unique Link Clicks</h4>
                            <p class="m-0 text-right">
                                <span class="font-weight-bold align-bottom">{{ statistics.creator ? statistics.creator.unique_clicks : statistics.total.unique_clicks | numeral('0,0') }}</span>
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div class="panel p-3">
                            <h4 class="h5 pb-2 border-bottom border-light">Link Clicks</h4>
                            <p class="m-0 text-right">
                                <span class="font-weight-bold align-bottom">{{ statistics.creator ? statistics.creator.hits : statistics.total.hits | numeral('0,0') }}</span>
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-6" v-if="selectedCreatorLink">
                        <div class="panel p-3">
                            <h4 class="h5 pb-2 border-bottom border-light">Attribution Link</h4>
                            <input v-if="selectedCreatorLink.path" type="text" class="form-control w-100" :value="`https://streaml.ink/${selectedCreatorLink.path}`">
                            <p class="m-0" v-else>Invalid attribution link</p>
                        </div>
                    </div>
                </div>

                <clicks-table
                    :campaign_id="$route.params.campaign_id"
                    :supercampaign_id="$route.params.supercampaign_id"
                    :link="selectedCreatorLink"
                    :total-rows="statistics.creator ? statistics.creator.unique_clicks : statistics.total.unique_clicks"
                    :creators-by-participant-id="creatorsByParticipantId"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import VerifiedBadge from '@/components/Client/VerifiedBadge'
import ClicksTable from '@/components/Tables/ClicksTable'

export default {
    name: 'view-campaign-attribution',

    metaInfo() {
        return {
            title: 'Campaign Attribution'
        }
    },

    components: {
        VerifiedBadge,
        ClicksTable,
    },

    props: {
        campaign: {
            type: Object,
        },
    },

    data() {
        return {
            filters: {
                text: ''
            },

            types: {
                'twitch-stream': 'Twitch Stream',
                'youtube-video': 'Youtube Video',
            },

            clickthroughs: [],
            clickthroughsByParticipantId: {},

            linksByParticipantId: {},

            creators: [],
            creatorsByParticipantId: {},

            selectedParticipantId: null,
        }
    },

    created() {
        this.getCampaignLinks()
        this.getCampaignParticipants()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        filteredCreators() {
            if (!this.filters.text) return this.creatorsWithLinks

            return _.filter(this.creatorsWithLinks, (creator) => {
                return item.superview.channel.name.includes(this.filters.text)
            })
        },

        selectedCreator() {
            if (!this.selectedParticipantId) return null

            return _.find(this.creators, (creator) => {
                return creator.participant.id === this.selectedParticipantId
            })
        },

        selectedCreatorLink() {
            if (!this.selectedParticipantId) return null
            return this.linksByParticipantId[this.selectedParticipantId]
        },

        statistics() {
            let statistics = {
                total: {
                    hits: 0,
                    unique_clicks: 0,
                },
            }

            if (!_.size(this.linksByParticipantId)) {
                return statistics
            }

            statistics = {
                total: {
                    hits: _.sumBy(_.values(this.linksByParticipantId), 'hits'),
                    unique_clicks: _.sumBy(_.values(this.linksByParticipantId), 'unique_clicks'),
                },
            }

            if (this.selectedParticipantId) {
                const link = this.linksByParticipantId[this.selectedParticipantId]

                if (link) {
                    statistics.creator = {
                        hits: link.hits,
                        unique_clicks: link.unique_clicks,
                    }
                }
            }

            return statistics
        },

        creatorsWithLinks() {
            const results = _.map(this.creators, (creator) => {
                return {
                    participant: creator.participant,
                    superview: creator.superview,
                    link: this.linksByParticipantId[creator.participant.id],
                }
            })

            return _.orderBy(results, ['link.unique_clicks'], ['desc'])
        },
    },

    methods: {
        getCampaignLinks() {
            const campaignId = this.$route.params.campaign_id

            this.axios.get(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${campaignId}/links`).then(({ data }) => {
                this.linksByParticipantId = _.keyBy(data.payload.links, 'participant_id')
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching participant links for campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Participant Links',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        getCampaignParticipants() {
            const campaignId = this.$route.params.campaign_id

            this.axios.get(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${campaignId}/participants`).then(({ data }) => {
                this.creators = data.payload.creators
                this.creatorsByParticipantId = _.keyBy(data.payload.creators, 'participant.id')
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching participants for campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Participants',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onCreatorClick(participantId) {
            this.selectedParticipantId = participantId
        },
    }
}
</script>

<style lang="scss">
.view-campaign-attribution {
    .row-creators {
        overflow-y: auto;
        max-height: 400px;
    }

    .creator {
        border-radius: 5px;
        cursor: pointer;
        transition: 0.25s all;

        &:hover {
            background: rgba(white, 0.05);
        }

        &.active {
            background: rgba(white, 0.075)
        }
    }

    .clickthroughs-table {
        &.row-panels .panel {
            transition: 0.25s all;
            cursor: pointer;

            &:hover {
                background: rgba(255, 255, 255, 0.075);
            }
        }
    }
}
</style>
