<template>
<header class="header-user pt-2" :class="{ 'expanded': isExpanded }" v-if="$route.name !== 'browser-view'">
    <div class="mobile-brand">
        <img src="/img/brand/streamforge-logo-text.png" height="40px">
    </div>

    <div class="mobile-expand" @click="expand">
        <font-awesome-icon :icon="['fas', 'bars']" class="mobile-expand-icon" v-if="!isExpanded" />
        <font-awesome-icon :icon="['fas', 'times']" class="mobile-expand-icon" v-if="isExpanded" />
    </div>

    <div class="navigation-wrapper">
        <div class="navigation-scrollbox">
            <div class="navigation-sub-wrapper">
                <h3 class="navigation-title mb-2">Dashboard</h3>
                <div class="navigation-link" @click="expand">
                    <router-link :to="{ name: 'home' }">
                        <font-awesome-icon :icon="['fas', 'home']" class="mr-2" /><span>Home</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'user-sidebar',

    components: {
    },

    data() {
        return {
            isExpanded: false
        }
    },

    computed: {
        ...mapGetters([
            'self',
            'getColors'
        ])
    },

    methods: {
        expand() {
            this.isExpanded = !this.isExpanded
            this.$intercom.update({
                "hide_default_launcher": this.isExpanded
            })
            this.isExpanded && this.$intercom.hide()
        },
        openSupport() {
            this.$intercom.update({
                "hide_default_launcher": true
            })
            this.$intercom.show()
        }
    }
}
</script>

<style lang="scss">
header.header-user {
    .mobile-expand {
        display: none;
    }

    .mobile-brand {
        display: none;
    }

    .navigation-sub-wrapper {
        .navigation-title {
            color: #bec2cc;
            font-size: 14px;
            line-height: 22px;
            font-weight: 600;
        }

        .navigation-link {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            padding: 5px 0;
            cursor: pointer;

            .support-link {
                width: fit-content;
            }

            &:hover {
                svg {
                    color: #fcd878;
                }

                a {
                    opacity: 1;
                }
            }

            a {
                color: #fff;
                opacity: 0.75;

                -webkit-transition: opacity 200ms ease;
                transition: opacity 200ms ease;
                font-weight: 600;

                &.active {
                    color: #fff;
                    font-weight: 600;
                    opacity: 1;

                    svg {
                        color: #fcd878;
                    }
                }
            }

            svg {
                transition: 0.25s all;
                width: 1em;
            }

            .new {
                font-size: 0.8em;
                color: #fcd878;
                font-weight: 500 !important;
                line-height: 0em;
            }
        }
    }
}
</style>
