<template>
    <div class="view view-lists mb-lg">
        <list-modal
            :type="modal.type"
            :list="modal.list"
            @submit="onModalSubmit"
            @close="onModalClose"
        />

        <div class="row mt-4">
            <div class="col-lg-2 sidebar">
                <div class="row">
                    <div class="col-lg-12 d-flex">
                        <b-button v-b-modal.list-modal variant="primary" block>Create List</b-button>
                    </div>

                    <div class="col-lg-12 mt-sm">
                        <b-form-input
                            placeholder="Search for a list"
                            autocomplete="off"
                            v-model="filter.text"
                        />
                    </div>

                    <div class="col-lg-12 mt-2">
                        <div class="row row-lists row-panels">
                            <div
                                class="col-lg-12 mb-1"
                                v-for="list in filteredLists"
                                @click="onListSelect(list)"
                                v-if="filteredLists.length"
                            >
                                <div class="list-item py-2 px-3" :class="{
                                    'active': selectedListId === list.id
                                }">
                                    <p class="h6 m-0">{{ list.name }}</p>
                                    <small>{{ list.creators.length }} creators</small>

                                    <div class="actions mt-2" v-if="selectedListId === list.id">
                                        <b-button size="sm" variant="link" @click="onListActionClick(list, 'update')">Edit</b-button>
                                        <b-button size="sm" variant="link" class="text-danger" @click="onListActionClick(list, 'delete')">Delete</b-button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12" v-if="!filteredLists.length">
                                <div class="list-item py-2 px-3">
                                    <p class="m-0">No lists found</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-10">
                <div class="row">
                    <div class="col-lg-12 mb-md">
                        <pre-filter-options
                            :isLocked="false"
                            :platform_id="'twitch'"
                            :defaults="filters.pre.default"
                            :override="filters.pre.override"
                            gameClass="col-lg-3"
                            @filters-change="onPreFiltersChange"
                        />
                    </div>

                    <div class="col-lg-12 results-wrapper">
                        <creator-table
                            :creators="creators"
                            :campaigns="campaigns"
                            type="list"
                            @remove-creators="onRemoveCreators"
                            @campaign-select="onCampaignSelect"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import CreatorTable from '@/components/Tables/CreatorTable'
import ListModal from '@/components/Modals/ListModal'
import PreFilterOptions from '@/components/Options/PreFilterOptions'

export default {
    name: 'view-lists',

    metaInfo() {
        return {
            title: 'Lists',
        }
    },

    components: {
        CreatorTable,
        ListModal,
        PreFilterOptions,
    },

    data() {
        return {
            lists: [],
            creators: [],

            filter: {
                text: '',
            },

            filters: {
                pre: {
                    current: null,
                    override: null,
                    default: {
                        maxRankIndex: 4,
                        type: 'streamer',
                        languages: [{ value: 'en', text: 'English' }],
                        games: [],
                    },
                },
                post: {
                    current: null,
                    options: null,
                    defaults: null,
                },
            },

            selectedListId: null,

            campaigns: [],

            modal: {
                type: 'create',
                list: null,
            },
        }
    },

    created() {
        this.getLists().then(() => {
            const firstList = _.first(this.lists)
            this.onListSelect(firstList)
        })

        this.getCampaigns()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        filteredLists() {
            if (!this.filter.text) return this.lists

            return _.filter(this.lists, (item) => {
                return item.name.toLowerCase().includes(this.filter.text.toLowerCase())
            })
        },
    },

    methods: {
        getLists() {
            return this.axios.get('/lists').then(({ data }) => {
                this.lists = data.payload.lists
            }).catch((error) => {
                if (error.is_cancelled) return
                console.log(error.message)
            })
        },

        getListCreators(list) {
            return this.axios.get(`/lists/${list.id}/creators`).then(({ data }) => {
                this.creators = data.payload.creators
            }).catch((error) => {
                if (error.is_cancelled) return
                console.log(error.message)
            })
        },

        getCampaigns() {
            return this.axios.get(`/campaigns`).then(({ data }) => {
                this.campaigns = data.payload.campaigns
            }).catch((error) => {
                console.log(error.message)
            })
        },

        onListSelect(list) {
            // Escape if already selected
            if (this.selectedListId === list.id) return

            this.selectedListId = list.id
            this.getListCreators(list)

            // const selectedList = _.find(this.lists, item => item.id === this.selectedListId)
            // if (selectedList && selectedList.targeting)
        },

        onListActionClick(list, type) {
            this.modal.list = list
            this.modal.type = type
            this.$bvModal.show('list-modal')
        },

        onModalSubmit(type, { list_id, list }) {
            if (type === 'create') {
                this.axios.post('/lists', {
                    list,
                }).then(({ data }) => {
                    this.lists.push(data.payload.list)
                    this.selectedListId = data.payload.list.id
                    this.creators = []
                }).catch(({ message }) => {
                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Create List',
                        text: message,
                        duration: 10000,
                        speed: 1000
                    })
                })

            } else if (type === 'update') {
                this.axios.put(`/lists/${list_id}`, {
                    list,
                }).then(({ data }) => {
                    const oldList = _.find(this.lists, item => item.id === list_id)
                    _.assign(oldList, data.payload.list)
                }).catch(({ message }) => {
                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Update List',
                        text: message,
                        duration: 10000,
                        speed: 1000
                    })
                })
            } else if (type === 'delete') {
                this.axios.delete(`/lists/${list_id}`).then(({ data }) => {
                    if (this.selectedListId === list_id) {
                        this.selectedListId = null
                    }

                    this.lists = _.filter(this.lists, item => item.id !== list_id)
                }).catch(({ message }) => {
                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Delete List',
                        text: message,
                        duration: 10000,
                        speed: 1000
                    })
                })
            }
        },

        onModalClose() {
            this.modal.type = 'create'
            this.modal.list = null
        },

        onCampaignSelect(campaign, creators) {
            const body = {
                creators: creators,
            }

            this.axios.post(`/campaigns/${campaign.id}/participants`, body).then(({ data }) => {
                this.$notify({
                    group: "global",
                    type: "success",
                    text: `Successfully added creators to ${campaign.title}`,
                    duration: 5000,
                    speed: 1000
                })
            }).catch((error) => {
                console.log(error.message)

                const message = error.message ? error.message : `Error adding creator to campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Add Creator to Campaign',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onRemoveCreators(creators) {
            const body = {
                creators: creators,
            }

            const removedCreatorsLookup = _.keyBy(creators, 'platform_user_id')

            this.axios.delete(`/lists/${this.selectedListId}/creators`, {
                data: body,
            }).then(({ data }) => {
                this.creators = _.filter(this.creators, (creator) => {
                    return !removedCreatorsLookup[creator.list_creator.platform_user_id]
                })

                this.$notify({
                    group: "global",
                    type: "success",
                    text: `Successfully removed ${data.payload.count} creators from list.`,
                    duration: 5000,
                    speed: 1000
                })
            }).catch((error) => {
                console.log(error.message)
            })
        },
    }
}
</script>

<style lang="scss">
.view-lists {
    .row-lists {
        overflow-y: auto;

        .list-item {
            border-radius: 5px;
            cursor: pointer;
            transition: 0.25s all;

            &:hover {
                background: rgba(white, 0.05);
            }

            &.active {
                background: rgba(white, 0.075)
            }
        }
    }
}
</style>
