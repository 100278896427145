<template>
    <div v-if="!loading" class="page page-client-login m-0">
        <div class="row row-panels my-auto d-flex justify-content-center">
            <div class="col-lg-4 px-5 py-md panel">

                <div v-if="!success">
                    <div v-if="codeValid">
                        <h1 class="display-3 text-center text-uppercase mb-4">Password Reset</h1>

                        <base-alert class="font-weight-bold my-3" type="danger" v-if="errorMessage">
                            <div class="text-center">{{ errorMessage }}</div>
                            <ul v-if="errorList" class="mt-3">
                                <li v-for="error in errorList" :key="error">{{ error }}</li>
                            </ul>
                        </base-alert>

                        <div class="form-group">
                            <label class="form-label" for="password">New Password</label>
                            <input
                                class="form-control mb-2"
                                type="password"
                                name="password"
                                v-model="password"
                                @keyup.enter="submit"
                            >

                            <label class="form-label" for="confirmPassword">Confirm New Password</label>
                            <input
                                class="form-control"
                                type="password"
                                name="confirmPassword"
                                v-model="confirmPassword"
                                @keyup.enter="submit"
                            >
                        </div>

                        <div class="form-group mt-3">
                            <button
                                type="button"
                                class="btn btn-primary w-100"
                                @click="submit"
                                :class="{ 'disabled': !this.password || !this.confirmPassword}"
                            >Submit</button>
                        </div>
                    </div>

                    <div v-else>
                        <div class="text-center">
                            <font-awesome-icon
                                :icon="['fas', 'exclamation-triangle']"
                                class="text-danger"
                                size="5x"
                            />
                            <h3 class="display-4 mt-sm">Password Reset Expired</h3>
                        </div>
                        <div class="mt-3 text-center">Click <a href="/forgot-password">here</a> to send a new reset password email.</div>
                    </div>
                </div>

                <div v-else>
                    <div class="text-center">
                        <font-awesome-icon
                            :icon="['fas', 'check']"
                            class="text-success"
                            size="5x"
                        />
                        <h3 class="display-4 mt-sm">Password Reset Successful</h3>
                    </div>
                    <div class="mt-3 text-center">Click <a href="/login">here</a> to log in with your new password.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseAlert from '@/components/Base/BaseAlert'

export default {
    name: 'ResetPassword',

    metaInfo() {
        return {
            title: 'Reset Password'
        }
    },

    components: {
        BaseAlert
    },

    data() {
        return {
            password: '',
            confirmPassword: '',
            loading: true,
            codeValid: true,
            errorMessage: null,
            errorList: null,
            success: false
        }
    },

    created() {
        // First check that the provided code is valid
        this.checkCodeValid()
    },

    methods: {
        submit() {
            this.axios.post(`/operators/password`, {
                code: this.$route.query.code,
                newPassword: this.password,
                confirmPassword: this.confirmPassword,
                type: 'forgot'
            }).then(({ data }) => {
                this.success = true
            }).catch(({ response }) => {
                if (response.data.message == "This code has expired") {
                    return this.codeValid = false
                }

                this.errorMessage = response.data.message
                this.errorList = response.data.errors;
            })
        },

        checkCodeValid() {
            this.axios.post(`/operators/password/check`, {
                code: this.$route.query.code
            }).then(({ data }) => {
                this.codeValid = true
                this.loading = false
            }).catch(({ response }) => {
                console.log(response.data.message)
                this.codeValid = false
                this.loading = false
            })
        }
    }

}
</script>

<style lang="scss" scoped>

</style>
