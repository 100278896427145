var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{ref:"table",staticClass:"table-panels",attrs:{"id":"campaign-summary-table","busy":_vm.isBusy,"items":_vm.summaries,"fields":_vm.fields,"sort-by":"ended_at","sort-desc":true,"fixed":"","borderless":"","sort-icon-right":"","per-page":_vm.pagination.perPage,"current-page":_vm.pagination.currentPage},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"cell(superview)",fn:function(data){return [(data.item.superview && data.item.superview)?_c('div',{staticClass:"creator-wrapper my-auto d-flex"},[_c('b-img-lazy',{staticClass:"avatar my-auto",attrs:{"src":data.item.participant.platform_user_logo}}),_c('div',{staticClass:"name-rank-wrapper d-flex flex-column my-auto ml-3"},[_c('span',{staticClass:"h6 m-0"},[_vm._v(" "+_vm._s(data.item.participant.platform_user_name)+" "),(data.item.superview.channel.partner)?_c('verified-badge',{attrs:{"platform":'twitch'}}):_vm._e()],1),_c('small',{style:({
                            color: _vm.getColors.ranks[data.item.superview.rank.name]
                        })},[_vm._v(" "+_vm._s(_vm._f("capitalize")(data.item.superview.rank.name))+" ")])])],1):_vm._e()]}},{key:"cell(summary.acv_performance)",fn:function(data){return [_c('div',{staticClass:"acv-performance d-flex flex-column"},[(data.value.ratio)?_c('b-progress',{staticClass:"my-auto w-100",class:{
                        'negative': data.value.is_negative,
                        'positive': !data.value.is_negative,
                        'high': data.value.ratio > 0.75,
                    },attrs:{"variant":data.value.variant,"max":1,"height":"1rem"}},[_c('b-progress-bar',{attrs:{"value":data.value.ratio}},[_c('span',{staticClass:"progress-text",class:{
                                'text-danger': data.value.is_negative,
                                'text-success': !data.value.is_negative,
                            }},[_vm._v(" "+_vm._s(data.value.prefix)+_vm._s(_vm._f("numeral")(data.value.ratio,'0,0%'))+" ")])])],1):_c('b-progress',{staticClass:"my-auto w-100",attrs:{"height":"1rem"}},[_c('span',{staticClass:"w-100 text-center"},[_vm._v(" No Data ")])])],1)]}},{key:"cell(summary.over_streamed)",fn:function(data){return [_c('div',{staticClass:"acv-performance d-flex flex-column"},[(data.value.ratio)?_c('b-progress',{staticClass:"my-auto w-100",class:{
                        'negative': data.value.is_negative,
                        'positive': !data.value.is_negative,
                        'high': data.value.ratio > 0.75,
                    },attrs:{"variant":data.value.variant,"max":1,"height":"1rem"}},[_c('b-progress-bar',{attrs:{"value":data.value.ratio}},[_c('span',{staticClass:"progress-text",class:{
                                'text-danger': data.value.is_negative,
                                'text-success': !data.value.is_negative,
                            }},[_vm._v(" "+_vm._s(data.value.prefix)+_vm._s(_vm._f("numeral")(data.value.ratio,'0,0%'))+" ")])])],1):_c('b-progress',{staticClass:"my-auto w-100",attrs:{"height":"1rem"}},[_c('span',{staticClass:"w-100 text-center"},[_vm._v(" No Data ")])])],1)]}}])}),_c('div',{staticClass:"d-flex"},[_c('b-pagination',{staticClass:"mx-auto",attrs:{"total-rows":_vm.summaries.length,"per-page":_vm.pagination.perPage},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }