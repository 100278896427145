<template>
    <div class="view view-games mb-md">
        <game-modal
            @submit="onModalSubmit"
        />

        <div class="row mb-3">
            <div class="col-lg-2 offset-10 d-flex">
                <b-button block variant="primary" @click="onAddGameClick">Add Game</b-button>
            </div>
        </div>

        <div class="row row-panels row-games">
            <div class="col-lg-4 mb-3 game" v-for="game in games">
                <div class="panel p-3 d-flex pointer-cursor" @click="onGameOpenClick(game)">
                    <div class="boxart">
                        <img :src="game.igdb.cover.url.replace('t_thumb', 't_cover_small')">
                    </div>
                    <div class="d-flex ml-4">
                        <div class="my-auto">
                            <h3 class="h4 m-0">{{ game.name }}</h3>
                            <p class="mb-2">Added {{ game.created_at | moment('calendar') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import GameDropdown from '@/components/Dropdowns/GameDropdown'
import GameModal from '@/components/Modals/GameModal'

export default {
    name: 'view-games',

    metaInfo() {
        return {
            title: 'Games'
        }
    },

    components: {
        GameDropdown,
        GameModal,
    },

    data() {
        return {
            games: [],
        }
    },

    created() {
        this.getGames()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),
    },

    methods: {
        getGames() {
            this.axios.get(`/organizations/00000000-0000-0000-0000-000000000000/games`).then(({ data }) => {
                this.games = data.payload.games
            })
        },

        onAddGameClick() {
            this.$bvModal.show('game-modal')
        },

        onGameOpenClick(game) {
            this.$router.push({
                name: 'game',
                params: {
                    game_id: game.id,
                },
            })
        },

        onModalSubmit(type, values) {
            if (type === 'create') {
                this.axios.post(`/organizations/00000000-0000-0000-0000-000000000000/games`, {
                    game: {
                        igdb_id: values.game.igdb_id,
                    },
                    notes: values.notes,
                }).then(({ data }) => {
                    this.games.push(data.payload.game)
                }).catch((error) => {
                    console.error(error)
                })
            }
        },
    }
}
</script>

<style lang="scss">
.view-games {
    .row-games {
        .game {
            .panel {
                transition: 0.25s all;
                cursor: pointer;

                .boxart img {
                    border-radius: 5px;
                }

                &:hover {
                    background: rgba(white, 0.1);
                }
            }
        }
    }
}
</style>
