export default {
    state: {
        default: '#eeeeee',
        inactive: '#909090',

        ranks: {
            bronze: '#d7995b',
            silver: '#aaaaaa',
            gold: '#d7b740',
            platinum: '#b1d9df',
            ruby: '#ff7e79',
            emerald: '#74f695',
            sapphire: '#4499ff',
            diamond: '#98c2ff'
        },

        socials: {
            twitch: '#6441a5',
            twitter: '#38A1F3',
            instagram: '#bc2a8d',
            youtube: '#ff0000',
            discord: '#7289da'
        },
    },

    getters: {
        getColor: (state) => (type, item) => {
            if (!state[type] || !state[type][item]) return state.default
            return state[type][item]
        },

        getColors: (state) => {
            return state
        },

        colors: state => state,

        ranks: (state) => {
            let index = 1

            return _.map(state.ranks, (color, key) => {
                const result =  {
                    name: key,
                    index: _.size(state.ranks) - index,
                    color: color,
                }

                index++
                return result
            }).reverse()
        },
    }
}
