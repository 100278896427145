<template>
    <div class="row filter-wrapper">
        <!-- Game Filters -->
        <div class="col-lg-12" v-if="isOptionEnabled('games')">
            <div class="filters-games-wrapper d-flex position-relative">
                <h3 class="h5 m-0">Games</h3>

                <div class="filters-games ml-auto">
                    <dropdown-text-filterable
                        itemTextProperty="displayName"
                        :itemList="this.options.games"
                        :itemClick="onGameResultClick"
                        :search="onGameSearch"
                        searchPlaceholder="Enter game name"
                    >
                        <font-awesome-icon :icon="['fas', 'plus']" class="check" />
                    </dropdown-text-filterable>
                </div>
            </div>

            <div class="row row-panels filters-games mt-2">
                <div
                    class="mb-1 d-flex" :class="gameClass"
                    v-for="game in filters.games"
                    :key="game._id"
                >
                    <div class="panel game-item px-3 py-1 w-100">
                        <div
                            class="game-backdrop"
                            :style="{
                                'background-image': `url(${game.avatarURL})`
                            }"
                        />

                        <div class="foreground d-flex">
                            <div class="title">
                                {{ game.displayName }}
                            </div>
                            <div
                                class="remove d-flex"
                                @click="removeFilterGame(game)"
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'times']"
                                    class="ml-auto my-auto"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12" v-if="!filters.games.length">
                    <p class="m-0 text-center small my-1">Not filtering by game.</p>
                </div>
            </div>
        </div>

        <!-- <div class="col-lg-12 mt-4" v-if="isOptionEnabled('ranks')">
            <h3 class="h5">Minimum Rank</h3>

            <b-form-select
                v-model="filters.maxRankIndex"
                :options="options.ranks"
            />
        </div>

        <div class="col-lg-12 mt-4" v-if="isOptionEnabled('types')">
            <h3 class="h5">Search Type</h3>

            <b-form-select
                v-model="filters.type"
                :options="options.types"
            />
        </div> -->

        <!-- <div class="col-lg-12 mt-4">
            <button
                class="btn btn-primary w-100 mt-3"
                @click="onSubmit()"
                :class="{
                    disabled: isLocked
                }"
            >
                Find Creators
            </button>
        </div> -->
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DropdownTextFilterable from '@/components/Dropdowns/DropdownTextFilterable.vue'

export default {
    components: {
        DropdownTextFilterable,
    },

    name: 'PreFilterOptions',

    props: {
        isLocked: {
            type: Boolean,
            defaultValue: false
        },
        defaults: {
            type: Object,
            defaultValue: {
                maxRankIndex: 4,
                type: 'streamer',
                languages: [{ value: "en", text: "English" }]
            }
        },
        override: {
            type: Object,
        },
        platform_id: {
            type: String,
            default: 'twitch',
        },
        gameClass: {
            type: String,
            default: 'col-lg-12',
        },
    },

    watch: {
        override: {
            deep: true,
            handler(value) {
                if (value) {
                    Vue.set(this, 'filters', value)
                }
            },
        },

        filters: {
            deep: true,
            handler(value) {
                this.$emit('filters-change', value)
            },
        }
    },

    data() {
        return {
            options: {
                ranks: [
                    { value: 0, text: "Diamond" },
                    { value: 1, text: "Sapphire" },
                    { value: 2, text: "Emerald" },
                    { value: 3, text: "Ruby" },
                    { value: 4, text: "Platinum" },
                    { value: 5, text: "Gold" },
                    { value: 6, text: "Silver" },
                    { value: 7, text: "Bronze" }
                ],
                games: [],
                types: [{
                    value: 'streamer',
                    text: 'Streamer',
                }, {
                    value: 'audience',
                    text: 'Audience',
                }],
            },

            filters: {
                maxRankIndex: 0,
                games: [],

                languageSearchText: '',
                languages: [],

                type: 'streamer',
            },

            optionsByPlatform: {
                twitch: [
                    'games',
                ],
                youtube: [
                    'games',
                ],
            },
        }
    },

    created() {

        // Prepopulate game search results
        this.onGameSearch()
    },

    beforeMount() {
        // If defaults are provided, overwrite starting values
        if (this.defaults) {
            this.filters = _.assign(this.filters, this.defaults)
        }
    },

    computed: {
        ...mapGetters([
            'twitchLanguages',
        ]),

        languages() {
            return _.filter(this.twitchLanguages, (item) => {

                // Check if selected languages includes language
                const isSelected = _.find(this.filters.languages, (needle) => {
                    return needle.value === item.value
                })

                const isTextMatch = true

                if (this.languageSearchText) {
                    isTextMatch = item.value.includes(this.languageSearchText)
                }

                return !isSelected && isTextMatch
            })
        },
    },

    methods: {
        isOptionEnabled(fieldName) {
            const platformOptions = this.optionsByPlatform[this.platform_id]

            // console.log(fieldName, !this.platform, !platformOptions, !platformOptions.includes(fieldName))

            if (!this.platform_id || !platformOptions) return false
            return platformOptions.includes(fieldName)
        },

        onGameSearch(text) {
            this.axios.post(`/games/twitch/search`, {
                text
            }).then(({ data }) => {
                this.options.games = data.payload.games
            }).catch(error => {
                if (error.is_cancelled) return
                console.error(error.message)
            })
        },

        onLanguageSearch(text) {
            this.filters.languageSearchText = text
        },

        onGameResultClick(game) {
            const isGameDuplicate = _.find(this.filters.games, item => item._id === game._id)

            if (!isGameDuplicate) {
                this.filters.games.push(game)

            } else {
                this.$notify({
                    group: "global",
                    type: "error",
                    title: "Error - Add Game To Filters",
                    text: `This game has already been added to the search filters.`,
                    duration: 10000,
                    speed: 1000
                })
            }
        },

        removeFilterGame(game) {
            console.log('removeFilterGame', game)

            this.filters.games = _.filter(
                this.filters.games,
                item => {
                    // console.log(item._id, game._id, typeof item._id, typeof game._id, item._id === game._id)
                    return String(item._id) !== String(game._id)
                }
            )
        },

        addFilterLanguage(language) {
            const hasLanguage = !!_.find(
                this.filters.languages,
                item => item.value === language.value
            )

            if (!hasLanguage) {
                this.filters.languages.push(language)
            }
        },

        removeFilterLanguage(language) {
            this.filters.languages = _.filter(this.filters.languages, item => item.value !== language.value)
        },

        onSubmit() {
            if (this.isLocked) return
            this.$emit("filters-submit", this.filters)
        },
    },
}
</script>

<style lang="scss">
.filter-wrapper {
    h3 {
        font-size: 1.25em;
    }

    .filters-languages {
        .remove {
            cursor: pointer;
        }
    }

    .filters-games {
        .game-item {
            border-radius: 5px;
            position: relative;
            overflow: hidden;

            .game-backdrop {
                background-position: center;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: center;

                &:before {
                    content: "";
                    background: linear-gradient(
                        90deg,
                        #1e2029 0,
                        rgba(30, 32, 41, 0.9) 50%,
                        rgba(30, 32, 41, 0.5)
                    );
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .foreground {
                position: relative;

                .title {
                    width: calc(100% - 20px);
                }

                .remove {
                    width: 20px;
                    cursor: pointer;
                    transition: color 0.25s;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }

    // .search-wrapper {
    //     position: relative;

    //     .search-results-wrapper {
    //         position: absolute;
    //         top: 100%;
    //         left: 0;
    //         width: 100%;
    //         z-index: 50;

    //         .search-results {
    //             background: #1e2029;
    //             border-radius: 12px;
    //             box-shadow: 5px #000;
    //             overflow: hidden;

    //             &.hidden {
    //                 display: none;
    //             }

    //             .search-results-item {
    //                 overflow: hidden;
    //                 text-overflow: ellipsis;
    //                 cursor: pointer;

    //                 &:hover {
    //                     background: rgba(white, 0.05);
    //                 }

    //                 .index {
    //                     color: rgba(white, 0.3);
    //                 }

    //                 .game-name {
    //                     white-space: nowrap;
    //                     overflow: hidden;
    //                     text-overflow: ellipsis;
    //                 }
    //             }
    //         }
    //     }
    // }
}
</style>
