<template>
    <div class="page page-client-login m-0">
        <div class="row my-auto d-flex justify-content-center">
            <div class="col-lg-4 px-5 py-md bg-secondary">

                <h1 class="display-1 text-center text-uppercase mb-4">Login</h1>
                <p>Please enter the password you were provided below to access this page.</p>

                <base-alert class="font-weight-bold text-center my-3" type="danger" v-if="errorMessage">
                    {{ errorMessage }}
                </base-alert>

                <div class="form-group">
                    <label class="form-label" for="email">Email</label>
                    <input
                        class="form-control mb-2"
                        type="text"
                        name="email"
                        v-model="email"
                        @keyup.enter="login"
                    >

                    <label class="form-label" for="email">Password</label>
                    <input
                        class="form-control"
                        type="password"
                        name="password"
                        v-model="password"
                        @keyup.enter="login"
                    >
                </div>

                <div class="form-group mt-3">
                    <button
                        type="button"
                        class="btn btn-primary w-100"
                        @click="login"
                        :class="{ 'disabled': !this.password }"
                    >Login</button>
                </div>

                <div class="text-center mt-4"><a href="/forgot-password">Forgot Password?</a></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseAlert from '@/components/Base/BaseAlert'

export default {
    name: 'client-login',

    metaInfo() {
        return {
            title: 'Login'
        }
    },

    components: {
        BaseAlert
    },

    data() {
        return {
            email: '',
            password: '',
            errorMessage: null
        }
    },

    created() {
        this.$store.commit('authentication:logout')
        this.$store.commit('campaigns:clear')
    },

    methods: {
        login() {
            if (!this.password) return

            this.axios.post(`/login`, {
                email: this.email,
                password: btoa(this.password),
            }).then(({ status, data }) => {
                if (status !== 200) {
                    this.password = ''
                    this.errorMessage = 'Invalid credentials provided.'
                    return
                }

                this.$store.commit('authentication:login', decodeURIComponent(data.payload.jwt))
                this.$router.push({ name: 'supercampaigns' })
            }).catch((error) => {
                if (error.response) {
                    this.errorMessage = error.response.data.message
                }

                console.error(error)
            })
        }
    }
}
</script>

<style lang="scss">
.page-client-login {
    display: flex;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: all 5000s ease-in-out 0s;
    }

}
</style>
