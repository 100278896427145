<template>
<div class="card-supercampaign h-100">
    <div class="panel p-3 h-100 d-flex flex-column">

        <!-- background image -->
        <div
            v-if="backdropUrl"
            class="backdrop"
            :style="{
                'background-image': `url(${backdropUrl})`
            }"
        />

        <div class="row">
            <div class="meta-wrapper col-lg-12 d-flex justify-content-between">
                <h3 class="h5 my-auto d-flex flex-column">
                    <span v-if="supercampaign.game" :title="supercampaign.game.name">{{ supercampaign.game.name }}</span>
                    <span v-else-if="supercampaign.title" :title="supercampaign.title">{{ supercampaign.title }}</span>
                    <span v-else-if="supercampaign.intake_form" :title="supercampaign.intake_form.game_name">{{ supercampaign.intake_form.game_name }}</span>
                    <small class="text-primary">{{ supercampaign.stage }}</small>
                </h3>

                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
                    <template #button-content>
                        <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
                    </template>
                    <b-dropdown-item @click="onCampaignActionClick(supercampaign, 'update')">Edit</b-dropdown-item>
                    <b-dropdown-item @click="onCampaignActionClick(supercampaign, 'delete')">Delete</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>

        <div class="description-wrapper mt-1 mb-5">
            <b-badge variant="primary" v-for="campaign in supercampaign.campaigns">
                <font-awesome-icon :icon="typeToIcon(campaign.type)" class="mr-1" />
                {{ slugToTitleCase(campaign.type) }}
            </b-badge>
        </div>



        <div class="d-flex mt-auto">
            <div class="d-flex">
                <p class="mt-auto mb-0">{{ getFirstStartDate(supercampaign) | moment('MMM Do, YYYY') }}</p>
            </div>

            <router-link
                :to="{
                    name: 'supercampaign',
                    params: {
                        supercampaign_id: supercampaign.id,
                    },
                }"
                tag="a"
                class="btn btn-primary ml-auto"
            >
                Open
            </router-link>
        </div>
    </div>
</div>
</template>
<script>
import _ from 'lodash'

export default {
    name: 'supercampaign-card',
    props: {
        supercampaign: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getFirstStartDate(supercampaign) {
            if (supercampaign.campaigns && supercampaign.campaigns.length) {
                return _.first(supercampaign.campaigns).started_at
            }

            return supercampaign.created_at
        },
        onCampaignActionClick(supercampaign, type) {
            this.$emit('on-ellipsis-click', {
                supercampaign: supercampaign,
                type,
            })
        },
        typeToVariant(type) {
            switch (type) {
                case 'key-distribution':
                    return 'success'
                case 'game-sponsorship':
                    return 'warning'
                case 'steam-broadcast':
                    return 'info'
                default:
                    return 'info'
            }
        },
        typeToIcon(type) {
            switch (type) {
                case 'key-distribution':
                    return ['fas', 'key']
                case 'game-sponsorship':
                    return ['fas', 'gamepad']
                case 'steam-broadcast':
                    return ['fab', 'steam']
                default:
                    return 'info'
            }
        },
        slugToTitleCase(type) {
            return type.replaceAll('-', ' ').replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
        },
    },
    computed: {
        backdropUrl() {
            const screenshots = _.get(this.supercampaign, 'payload.showcase.screenshots')
            if (!screenshots || !screenshots.length) return false

            const selectedScreenshot = _.sample(screenshots)
            return selectedScreenshot.url
        },
    }
}
</script>
