<template>
    <div class="campaign-modal-wrapper">
        <b-modal :id="modalId" @ok="onSubmit" @hide="onHide" scrollable size="lg" no-close-on-backdrop>
            <template #modal-title>
                <h1 class="h4 m-0">{{ type | capitalize }} Campaign Component</h1>
            </template>

            <template #modal-footer="{ ok }">
                <b-button :variant="type === 'delete' ? 'danger' : 'success'" @click="ok()">
                    {{ type | capitalize }} Campaign
                </b-button>
            </template>

            <div class="row" v-if="type === 'delete'">
                <div class="col-lg-12">
                    <p>Are you sure you want to delete this campaign?</p>
                </div>
            </div>

            <form ref="form" @submit.stop.prevent="onSubmit" class="form-campaign-create" v-if="type !== 'delete'">
                <div class="row">
                    <div class="col-lg-12" v-if="supercampaign">
                        <p>Creating a component that is attached to {{ supercampaign.title }}.</p>
                    </div>

                    <div class="col-lg-12" v-if="type === 'create'">
                        <b-form-group label="Type">
                            <b-form-select v-model="values.type" :options="options.type" />
                        </b-form-group>
                    </div>

                    <!-- Field present in every campaign type -->
                    <div class="col-lg-8">
                        <b-form-group
                            label="Title"
                            description="This title will be visible to creators."
                            :invalid-feedback="validation.title.reason"
                        >
                            <b-form-input
                                v-model="values.title"
                                placeholder="Enter campaign title"
                                required
                                :state="validation.title.status"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-lg-4">
                        <b-form-group
                            label="Abbreviation"
                            description="Cannot be changed afterwards."
                            :invalid-feedback="validation.abbreviation.reason"
                        >
                            <b-form-input
                                :disabled="type === 'create' ? false : true"
                                v-model="values.abbreviation"
                                placeholder="4 letter identifier"
                                required
                                :state="validation.abbreviation.status"
                                @keyup="debouncedValidate(validateAbbrevation, values.abbreviation)"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-lg-12">
                        <b-form-group label="Description" description="This description will be visible to creators.">
                            <b-form-textarea
                                v-model="values.description"
                                placeholder="Game description that is shown to creators."
                                rows="3"
                                max-rows="6"
                            />
                        </b-form-group>
                    </div>

                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-lg-6">
                                <label>Start Date</label>
                                <b-form-input type="date" v-model="values.dates.start_date" />
                            </div>

                            <div class="col-lg-6">
                                <label>Start Time</label>
                                <b-form-input type="time" v-model="values.dates.start_time" />
                            </div>

                            <div class="col-lg-6 mt-3">
                                <label>End Date</label>
                                <b-form-input type="date" v-model="values.dates.end_date" />
                            </div>

                            <div class="col-lg-6 mt-3">
                                <label>End Time</label>
                                <b-form-input type="time" v-model="values.dates.end_time" />
                            </div>
                        </div>
                    </div>

                    <!-- Game Sponsorship -->
                    <div class="col-lg-12 mt-4" v-if="values.type === 'game-sponsorship'">
                        <div class="row">
                            <div class="col-lg-3 mt-3">
                                <b-form-group label="Budget">
                                    <b-input-group prepend="$" append=".00">
                                        <b-form-input type="number" v-model="values.budget_amount" />
                                    </b-input-group>
                                </b-form-group>
                            </div>

                            <!-- <div class="col-lg-7 mt-3" v-if="type === 'create'">
                                <b-form-group label="Game" description="Make sure you have games added to your account.">
                                    <b-form-select v-model="values.organization_game_id" v-if="games">
                                        <b-form-select-option :value="null">Select a game</b-form-select-option>
                                        <b-form-select-option :value="game.id" v-for="game in games">{{ game.igdb.name }}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div> -->

                            <div class="col-lg-6 mt-3">
                                <b-form-group
                                    label="Delivery"
                                >

                                    <b-form-checkbox
                                        v-model="values.attribution.is_enabled"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                        Click Tracking Enabled
                                    </b-form-checkbox>

                                    <!-- <b-form-checkbox
                                        v-model="values.stream_delivery.is_tracked"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                        Tracking enabled
                                    </b-form-checkbox> -->

                                    <b-form-checkbox
                                        v-model="values.stream_delivery.is_window_expected"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                        Creator must provide stream time
                                    </b-form-checkbox>
                                </b-form-group>
                            </div>

                            <div class="col-lg-6 mt-3" v-if="values.attribution.is_enabled">
                                <b-form-group
                                    label="Clickthrough Link Destination"
                                >
                                    <b-form-input
                                        v-model="values.attribution.clickthrough_destination_url"
                                        type="url"
                                        placeholder="Enter link of store item"
                                        required
                                    />
                                </b-form-group>
                            </div>

                            <div class="col-lg-6 mt-3" v-if="values.attribution.is_enabled">
                                <b-form-group
                                    label="Panel Image"
                                >
                                    <b-form-input
                                        v-model="values.attribution.panel_image_url"
                                        type="url"
                                        placeholder="URL of panel image"
                                        required
                                    />
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <!-- Steam Broadcast -->
                    <div class="col-lg-12 mt-4" v-if="values.type === 'steam-broadcast'">
                        <div class="row">

                            <!-- <div class="col-lg-6 mt-3" v-if="type === 'create'">
                                <b-form-group label="Game" description="Make sure you have games added to your account.">
                                    <b-form-select v-model="values.organization_game_id" v-if="games">
                                        <b-form-select-option :value="null">Select a game</b-form-select-option>
                                        <b-form-select-option :value="game.id" v-for="game in games">{{ game.igdb.name }}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div> -->

                            <div class="col-lg-3 mt-3">
                                <b-form-group label="Budget">
                                    <b-input-group prepend="$" append=".00">
                                        <b-form-input type="number" v-model="values.budget_amount" />
                                    </b-input-group>
                                </b-form-group>
                            </div>

                            <div class="col-lg-3 mt-3">
                                <b-form-group label="Hours Per Slot">
                                    <b-form-input v-model="values.slots.duration_hours" type="number" />
                                </b-form-group>
                            </div>

                            <div class="col-lg-3 mt-3">
                                <b-form-group label="Payout per slot">
                                    <b-input-group prepend="$" append=".00">
                                        <b-form-input v-model="values.slots.payout_per_slot" type="number" />
                                    </b-input-group>
                                </b-form-group>
                            </div>

                            <div class="col-lg-3 mt-3">
                                <b-form-group label="Minimum Rank">
                                    <b-form-select v-model="values.min_rank_index">
                                        <b-form-select-option :value="rank.index" v-for="rank in ranks">{{ rank.name | capitalize }} ({{ options.ranksToCCV[rank.name.toLowerCase()] }}+ CCV)</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-lg-3 mt-3">
                                <b-form-group label="Schedule Status" description="Locking the schedule will display a notice to non-selected creators that they have not been picked.">
                                    <b-form-select v-model="values.slots.state">
                                        <b-form-select-option value="draft">Drafting Schedule</b-form-select-option>
                                        <b-form-select-option value="locked">Creators Locked In</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-lg-3 mt-3">
                                <b-form-group label="Applications Status" description="Setting to not accepting will disallow further applications.">
                                    <b-form-select v-model="values.applications.state">
                                        <b-form-select-option value="open">Accepting Applications</b-form-select-option>
                                        <b-form-select-option value="locked">Not Accepting Applications</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-lg-6 mt-3">
                                <b-form-group label="Stream RTMP URL" description="The url creator's should input to OBS.">
                                    <b-form-input v-model="values.ingest_server.rtmp_url" type="text" />
                                </b-form-group>
                            </div>

                            <div class="col-lg-6 mt-3">
                                <b-form-group label="Stream Key" description="The stream key creator's should input to OBS.">
                                    <b-form-input v-model="values.ingest_server.stream_key" type="text" />
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <!-- Key Distribution -->
                    <div class="col-lg-12 mt-4" v-if="values.type === 'key-distribution'">
                        <div class="row">
                            <!-- <div class="col-lg-6 mt-3" v-if="type === 'create'">
                                <b-form-group label="Game" description="Make sure you have games added to your account.">
                                    <b-form-select v-model="values.organization_game_id" v-if="games">
                                        <b-form-select-option :value="null">Select a game</b-form-select-option>
                                        <b-form-select-option :value="game.id" v-for="game in games">{{ game.igdb.name }}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div> -->

                            <div class="col-lg-6 mt-3" v-if="supercampaign && supercampaign.game && supercampaign.game.key_groups">
                                <b-form-group
                                    label="Distribution"
                                    description="What keys should creators have tasks auto generated for?"
                                >
                                    <div class="row">
                                        <div class="col-lg-12" v-for="keyGroup in supercampaign.game.key_groups">
                                            <b-form-checkbox
                                                v-model="values.keys.default_distribution"
                                                :value="keyGroup.id"
                                            >{{ keyGroup.title }}</b-form-checkbox>
                                        </div>

                                        <div class="col-lg-12" v-if="!supercampaign.game.key_groups.length">
                                            <b-alert show variant="danger" class="m-0">No key groups for game!</b-alert>
                                        </div>
                                    </div>
                                </b-form-group>
                            </div>

                            <div class="col-lg-6 mt-3">
                                <b-form-group label="Minimum Rank">
                                    <b-form-select v-model="values.min_rank_index">
                                        <b-form-select-option :value="rank.index" v-for="rank in ranks">{{ rank.name | capitalize }} ({{ options.ranksToCCV[rank.name.toLowerCase()] }}+ CCV)</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </div>

                            <div class="col-lg-6 mt-3">
                                <b-form-group
                                    label="Delivery"
                                >

                                    <b-form-checkbox
                                        v-model="values.attribution.is_enabled"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                        Attribution enabled
                                    </b-form-checkbox>

                                    <!-- <b-form-checkbox
                                        v-model="values.stream_delivery.is_tracked"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                        Tracking enabled
                                    </b-form-checkbox> -->

                                    <b-form-checkbox
                                        v-model="values.stream_delivery.is_window_expected"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                        Creator must provide stream time
                                    </b-form-checkbox>
                                </b-form-group>
                            </div>

                            <div class="col-lg-6 mt-3" v-if="values.attribution.is_enabled">
                                <b-form-group
                                    label="Clickthrough Link Destination"
                                >
                                    <b-form-input
                                        v-model="values.attribution.clickthrough_destination_url"
                                        type="url"
                                        placeholder="Enter link of store item"
                                        required
                                    />
                                </b-form-group>
                            </div>

                            <div class="col-lg-6 mt-3" v-if="values.attribution.is_enabled">
                                <b-form-group
                                    label="Panel Image"
                                >
                                    <b-form-input
                                        v-model="values.attribution.panel_image_url"
                                        type="url"
                                        placeholder="URL of panel image"
                                        required
                                    />
                                </b-form-group>
                            </div>

                            <div class="col-lg-3 mt-3" v-if="values.stream_delivery.is_window_expected">
                                <b-form-group label="Minimum expected hours streamed">
                                    <b-form-input v-model="values.stream_delivery.expected_hours" type="number" />
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import GameDropdown from '@/components/Dropdowns/GameDropdown'

export default {
    name: 'campaign-modal',

    components: {
        GameDropdown,
    },

    props: {
        modalId: {
            type: String,
        },
        type: {
            type: String,
        },
        campaign: {
            type: Object,
        },
        supercampaign: {
            type: Object,
        },
    },

    watch: {
        campaign(newValue) {

            // If an existing campaign has been attached to the modal
            if (newValue) {
                this.values.type = this.campaign.type
                this.values.title = this.campaign.title
                this.values.abbreviation = this.campaign.abbreviation
                this.values.description = this.campaign.description
                this.values.state = this.campaign.state

                this.values.dates.start_date = moment(this.campaign.started_at).format('YYYY-MM-DD')
                this.values.dates.start_time = moment(this.campaign.started_at).format('HH:mm:SS')

                this.values.dates.end_date = moment(this.campaign.ended_at).format('YYYY-MM-DD')
                this.values.dates.end_time = moment(this.campaign.ended_at).format('HH:mm:SS')

                this.values.budget_amount = this.campaign.budget_amount
                this.values.min_rank_index = this.campaign.payload.min_rank_index

                if (this.supercampaign) {
                    this.values.organization_game_id = this.supercampaign.organization_game_id
                    this.values.parent_id = this.supercampaign.id
                }

                if (this.campaign.payload.ingest_server) {
                    this.values.ingest_server.rtmp_url = this.campaign.payload.ingest_server.rtmp_url
                    this.values.ingest_server.stream_key = this.campaign.payload.ingest_server.stream_key
                }

                if (this.campaign.payload.slots) {
                    this.values.slots.duration_hours = Number(this.campaign.payload.slots.duration_hours)
                    this.values.slots.payout_per_slot = Number(this.campaign.payload.slots.payout_per_slot)
                    this.values.slots.state = this.campaign.payload.slots.state
                }

                if (this.campaign.payload.applications) {
                    this.values.applications.state = this.campaign.payload.applications.state
                }

                if (this.campaign.payload.stream_delivery) {
                    this.values.stream_delivery.is_window_expected = Boolean(this.campaign.payload.stream_delivery.is_window_expected)
                    this.values.stream_delivery.expected_hours = Number(this.campaign.payload.stream_delivery.expected_hours)
                    this.values.stream_delivery.is_tracked = Boolean(this.campaign.payload.stream_delivery.is_tracked)
                }

                if (this.campaign.payload.attribution) {
                    this.values.attribution.is_enabled = Boolean(this.campaign.payload.attribution.is_enabled)
                    this.values.attribution.panel_image_url = this.campaign.payload.attribution.panel_image_url
                    this.values.attribution.clickthrough_destination_url = this.campaign.payload.attribution.clickthrough_destination_url
                }

                if (this.campaign.payload.keys) {
                    this.values.keys.default_distribution = this.campaign.payload.keys.default_distribution
                }

            // If modal has been reset to create new campaign state
            } else {
                this.resetState()
            }
        },
    },

    data() {
        return {
            options: {
                type: [{
                    text: 'Game sponsorship',
                    value: 'game-sponsorship',
                    fields: ['budget', 'tasks', 'game'],
                }, {
                    text: 'Steam broadcast',
                    value: 'steam-broadcast',
                }, {
                    text: 'Key distribution',
                    value: 'key-distribution'
                }],
                ranksToCCV: {
                    diamond: 500,
                    sapphire: 250,
                    emerald: 100,
                    ruby: 50,
                    platinum: 25,
                    gold: 10,
                    silver: 3,
                    bronze: 1,
                    member: 0,
                },
            },

            defaults: {
                type: 'key-distribution',

                title: '',
                abbreviation: '',
                description: '',

                dates: {
                    start_date: null,
                    start_time: null,
                    end_date: null,
                    end_time: null,
                },

                budget: 0,
                game: null,
                organization_game_id: null,
                parent_id: null,

                slots: {
                    duration_hours: 4,
                    payout_per_slot: 0,
                    state: 'draft',
                },

                applications: {
                    state: 'open',
                },

                min_rank_index: 5,

                attribution: {
                    is_enabled: false,
                    clickthrough_destination_url: '',
                    panel_image_url: '',
                },

                ingest_server: {
                    rtmp_url: '',
                    stream_key: '',
                },

                stream_delivery: {
                    is_window_expected: false,
                    expected_hours: 1,
                    is_tracked: false,
                },

                keys: {
                    default_distribution: [],
                },
            },

            validation_override: {},

            values: null,
        }
    },

    computed: {
        ...mapGetters([
            'ranks',
        ]),

        validation() {
            const fields = [
                'title',
                'abbreviation',
                'description',
                // 'clickthrough_link',
                // 'panel_image_url',
                // 'organization_game_id',
            ]

            const state = {}

            _.forEach(fields, (field) => {
                state[field] = { status: null, reason: null }
            })

            if (this.values.title) {
                if (this.values.title.length < 5) {
                    state.title.status = false
                    state.title.reason = `Please enter more than 5 characters.`
                }
            }

            if (this.values.abbreviation) {
                if (this.values.abbreviation.length !== 4) {
                    state.abbreviation.status = false
                    state.abbreviation.reason = `Must be exactly 4 characters.`
                }

                if (!this.values.abbreviation.match(/^[a-zA-Z]+$/)) {
                    state.abbreviation.status = false
                    state.abbreviation.reason = `Cannot contain non-alphabet characters.`
                }
            }

            // TODO: Need to adjust validation for fields that are objects, like how panel_image_url is
            // part of the attribution value

            // if (this.values.clickthrough_link) {
            //     if (!this.values.clickthrough_link.includes('http')) {
            //         state.clickthrough_link.status = false
            //         state.clickthrough_link.reason = `Please enter a valid link.`
            //     }
            // }

            // if (this.values.panel_image_url) {
            //     if (!this.values.panel_image_url.includes('http')) {
            //         state.panel_image_url.status = false
            //         state.panel_image_url.reason = `Please enter a valid link.`
            //     }
            // }

            // Always overwrite with validation overrides
            return _.assign(state, this.validation_override)
        },
    },

    created() {
        this.resetState()
    },

    methods: {
        debouncedValidate: _.debounce((action, text) => {
            action(text)
        }, 500),

        validateAbbrevation(text) {

            // Don't bother if it's not all 4 letters
            if (this.values.abbreviation.length !== 4) return

            this.axios.get(`/campaigns/abbreviation/${text}`).then(({ data }) => {
                if (data.payload.campaign) {
                    this.$set(this.validation_override, 'abbreviation', {
                        status: false,
                        reason: `That abbreviation is already being used.`
                    })
                } else {
                    if (this.validation_override['abbreviation']) {
                        this.$set(this.validation_override, 'abbreviation', null)
                        delete this.validation_override['abbreviation']
                    }
                }
            }).catch((error) => {
                console.error(error)
            })
        },

        resetState() {
            this.values = _.cloneDeep(this.defaults)
        },

        onSubmit() {
            let data

            if (this.type === 'delete') {
                data = {
                    campaign_id: this.campaign.id,
                }
            }

            if (this.values.type === 'key-distribution') {
                if (this.type === 'create') {
                    data = {
                        campaign: {
                            type: this.values.type,
                            title: this.values.title,
                            abbreviation: this.values.abbreviation,
                            description: this.values.description,
                            started_at: moment(`${this.values.dates.start_date} ${this.values.dates.start_time}`).utc().format(),
                            ended_at: moment(`${this.values.dates.end_date} ${this.values.dates.end_time}`).utc().format(),
                            organization_game_id: this.values.organization_game_id,
                            parent_id: this.values.parent_id,
                            payload: {
                                attribution: {
                                    is_enabled: this.values.attribution.is_enabled,
                                    panel_image_url: this.values.attribution.panel_image_url,
                                    clickthrough_destination_url: this.values.attribution.clickthrough_destination_url,
                                },
                                showcase: {
                                    screenshots: [],
                                    videos: [],
                                },
                                stream_delivery: {
                                    is_tracked: this.values.stream_delivery.is_tracked,
                                    is_window_expected: this.values.stream_delivery.is_window_expected,
                                    expected_hours: Number(this.values.stream_delivery.expected_hours),
                                },
                                keys: {
                                    default_distribution: this.values.keys.default_distribution,
                                },
                                min_rank_index: Number(this.values.min_rank_index),
                            },
                        },
                    }
                }

                if (this.type === 'update') {
                    data = {
                        campaign_id: this.campaign.id,
                        campaign: {
                            type: this.values.type,
                            title: this.values.title,
                            description: this.values.description,
                            started_at: moment(`${this.values.dates.start_date} ${this.values.dates.start_time}`).utc().format(),
                            ended_at: moment(`${this.values.dates.end_date} ${this.values.dates.end_time}`).utc().format(),
                            payload: {
                                attribution: {
                                    is_enabled: this.values.attribution.is_enabled,
                                    panel_image_url: this.values.attribution.panel_image_url,
                                    clickthrough_destination_url: this.values.attribution.clickthrough_destination_url,
                                },
                                stream_delivery: {
                                    is_tracked: this.values.stream_delivery.is_tracked,
                                    is_window_expected: this.values.stream_delivery.is_window_expected,
                                    expected_hours: Number(this.values.stream_delivery.expected_hours),
                                },
                                keys: {
                                    default_distribution: this.values.keys.default_distribution,
                                },
                                min_rank_index: Number(this.values.min_rank_index),
                            },
                        },
                    }
                }
            }

            if (this.values.type === 'game-sponsorship') {

                if (this.type === 'create') {
                    data = {
                        campaign: {
                            type: this.values.type,
                            title: this.values.title,
                            abbreviation: this.values.abbreviation,
                            description: this.values.description,
                            started_at: moment(`${this.values.dates.start_date} ${this.values.dates.start_time}`).utc().format(),
                            ended_at: moment(`${this.values.dates.end_date} ${this.values.dates.end_time}`).utc().format(),
                            organization_game_id: this.values.organization_game_id,
                            parent_id: this.values.parent_id,
                            budget_amount: this.values.budget_amount,
                            payload: {
                                attribution: {
                                    is_enabled: this.values.attribution.is_enabled,
                                    panel_image_url: this.values.attribution.panel_image_url,
                                    clickthrough_destination_url: this.values.attribution.clickthrough_destination_url,
                                },
                                showcase: {
                                    screenshots: [],
                                    videos: [],
                                },
                                stream_delivery: {
                                    is_tracked: this.values.stream_delivery.is_tracked,
                                    is_window_expected: this.values.stream_delivery.is_window_expected,
                                },
                            },
                        },
                    }
                }

                if (this.type === 'update') {
                    data = {
                        campaign_id: this.campaign.id,
                        campaign: {
                            type: this.values.type,
                            title: this.values.title,
                            description: this.values.description,
                            started_at: moment(`${this.values.dates.start_date} ${this.values.dates.start_time}`).utc().format(),
                            ended_at: moment(`${this.values.dates.end_date} ${this.values.dates.end_time}`).utc().format(),
                            organization_game_id: this.values.organization_game_id,
                            budget_amount: this.values.budget_amount,
                            payload: {
                                attribution: {
                                    is_enabled: this.values.attribution.is_enabled,
                                    panel_image_url: this.values.attribution.panel_image_url,
                                    clickthrough_destination_url: this.values.attribution.clickthrough_destination_url,
                                },
                                stream_delivery: {
                                    is_tracked: this.values.stream_delivery.is_tracked,
                                    is_window_expected: this.values.stream_delivery.is_window_expected,
                                },
                            },
                        },
                    }
                }
            }

            if (this.values.type === 'steam-broadcast') {
                if (this.type === 'create') {
                    data = {
                        campaign: {
                            type: this.values.type,
                            title: this.values.title,
                            abbreviation: this.values.abbreviation,
                            description: this.values.description,
                            started_at: moment(`${this.values.dates.start_date} ${this.values.dates.start_time}`).utc().format(),
                            ended_at: moment(`${this.values.dates.end_date} ${this.values.dates.end_time}`).utc().format(),
                            organization_game_id: this.values.organization_game_id,
                            parent_id: this.values.parent_id,
                            budget_amount: this.values.budget_amount,
                            state: this.values.state,
                            payload: {
                                slots: {
                                    duration_hours: Number(this.values.slots.duration_hours),
                                    payout_per_slot: Number(this.values.slots.payout_per_slot),
                                    state: this.values.slots.state,
                                },
                                applications: {
                                    state: this.values.applications.state,
                                },
                                min_rank_index: Number(this.values.min_rank_index),
                                showcase: {
                                    screenshots: [],
                                    videos: [],
                                },
                                ingest_server: {
                                    rtmp_url: this.values.ingest_server.rtmp_url,
                                    stream_key: this.values.ingest_server.stream_key,
                                },
                            },
                        },
                    }
                }

                if (this.type === 'update') {
                    data = {
                        campaign_id: this.campaign.id,
                        campaign: {
                            type: this.values.type,
                            title: this.values.title,
                            description: this.values.description,
                            started_at: moment(`${this.values.dates.start_date} ${this.values.dates.start_time}`).utc().format(),
                            ended_at: moment(`${this.values.dates.end_date} ${this.values.dates.end_time}`).utc().format(),
                            budget_amount: this.values.budget_amount,
                            state: this.values.state,
                            payload: {
                                slots: {
                                    duration_hours: Number(this.values.slots.duration_hours),
                                    payout_per_slot: Number(this.values.slots.payout_per_slot),
                                    state: this.values.slots.state,
                                },
                                applications: {
                                    state: this.values.applications.state,
                                },
                                min_rank_index: Number(this.values.min_rank_index),
                                ingest_server: {
                                    rtmp_url: this.values.ingest_server.rtmp_url,
                                    stream_key: this.values.ingest_server.stream_key,
                                },
                            },
                        },
                    }
                }
            }

            this.$emit('submit', this.type, data)
            this.resetState()
        },

        onHide(event) {
            this.$emit('close', event)
            this.resetState()
        },

        onGameClick(game) {
            this.values.game = game
        },
    }
}
</script>

<style lang="scss">
.campaign-modal-wrapper {
}
</style>
