import Vue from 'vue'
import _ from 'lodash'

export default {
    state: {
        operator: null,
        organizations: null,
    },

    mutations: {
        'self:operator'(state, { operator }) {
            Vue.set(state, 'operator', operator)
        },
        'self:organizations'(state, { organizations }) {
            Vue.set(state, 'organizations', organizations)
        },
        'self:clear'(state) {
            Vue.set(state, 'operator', null)
        },
    },

    getters: {
        self(state) {
            return state
        },

        selfOperator(state) {
            return state.operator
        },

        selfOrganizations(state) {
            return state.organizations
        }
    },
}
