<template>
    <div class="game-dropdown-wrapper position-relative w-100">
        <dropdown-text-filterable
            itemTextProperty="name"
            :itemList="games"
            :itemClick="onGameClick"
            :search="onGameSearch"
            searchPlaceholder="Enter game name"
            class="w-100"
            :variant="variant"
        >
            <div class="game-display d-flex">
                <div
                    class="selected w-100"
                    v-if="game"
                >
                    <div
                        v-if="game.cover_url"
                        class="game-backdrop"
                        :style="{
                            'background-image': `url(${game.cover_url})`
                        }"
                    />

                    <div class="foreground d-flex w-100">
                        <div class="title m-auto w-100 px-2">
                            {{ game.name }}
                        </div>
                    </div>
                </div>

                <div
                    v-if="!game"
                    class="game-unselected m-auto"
                >
                    {{ placeholderText }}
                </div>
            </div>
        </dropdown-text-filterable>
    </div>
</template>

<script>
import _ from 'lodash'
import DropdownTextFilterable from '@/components/Dropdowns/DropdownTextFilterable'

export default {
    name: 'GameDropdown',

    components: {
        DropdownTextFilterable,
    },

    props: {
        game: {
            type: Object,
        },
        onGameClick: {
            type: Function
        },
        placeholderText: {
            type: String,
            default: 'Select Game',
        },
        variant: {
            type: String,
            default: 'link',
        },
    },

    data() {
        return {
            games: [],
            searchText: '',
        }
    },

    methods: {
        onGameSearch(text) {
            this.axios.post(`/games/igdb/search`, { text }).then(({ data }) => {
                this.games = data.payload.games
            }).catch((error) => {
                console.error(error.message)
            })
        },
    }
}
</script>

<style lang="scss">
.game-dropdown-wrapper {

    .game-display {
        border-radius: 5px;
        background: rgba(white, 0.05);
        overflow: hidden;
        height: calc(2.75rem + 2px);
    }


    // TODO: Some weird bug where the bottom right of the game select
    // isn't border radius'd
    .selected {
        display: flex;
        position: relative;

        .game-backdrop {
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;

            &:before {
                content: "";
                background: linear-gradient(
                    90deg,
                    #1e2029 0,
                    rgba(30, 32, 41, 0.9) 50%,
                    rgba(30, 32, 41, 0.5)
                );
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .foreground {
            position: relative;

            .title {
                width: calc(100% - 20px);
                color: #fff;
            }

            .remove {
                width: 20px;
                cursor: pointer;
                transition: color 0.25s;

                &:hover {
                    color: #fff;
                }
            }
        }
    }

    .game {
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        height: calc(2.75rem + 2px);
        margin-top: 35px;

        .game-backdrop {
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;

            &:before {
                content: "";
                background: linear-gradient(
                    90deg,
                    #1e2029 0,
                    rgba(30, 32, 41, 0.9) 50%,
                    rgba(30, 32, 41, 0.5)
                );
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .foreground {
            position: relative;

            .title {
                width: calc(100% - 20px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .remove {
                width: 20px;
                cursor: pointer;
                transition: color 0.25s;

                &:hover {
                    color: #fff;
                }
            }
        }
    }
}
</style>
