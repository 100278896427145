<template>
    <div class="slot-applications-modal-wrapper">
        <b-modal id="slot-applications-modal" @ok="onSubmit" @hide="onHide" size="lg">
            <template #modal-title>
                <h1 class="h4 m-0">Slot Applications</h1>
            </template>

            <template #modal-footer="{ ok }">
                <b-button variant="success" @click="ok()">
                    Done
                </b-button>
            </template>

            <div class="applications-wrapper">
                <div class="row">
                    <b-table striped hover :items="applications" :fields="fields">
                        <template #cell(created_at)="data">
                            {{ data.value | moment('calendar') }}
                        </template>

                    </b-table>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'slot-applications-modal',

    props: {
        selectedSlot: {
            type: Object,
        },
        applications: {
            type: Array,
        },
    },

    data() {
        return {
            selected_applicant: null,

            fields: [
                'user_id',
                'created_at',
            ],
        }
    },

    created() {
    },

    methods: {
        onSubmit() {
            this.$emit('submit', null)
        },

        onHide(event) {
            this.$emit('close', event)
        },
    }
}
</script>

<style lang="scss">
.slot-applications-modal-wrapper {
}
</style>
