<template>
    <div class="keys-table-wrapper">
        <b-modal id="keys-remove" @ok="onRemoveKeys">
            <template #modal-footer="{ ok, cancel }">
                <b-button variant="outline-primary" @click="cancel()">
                    Cancel
                </b-button>
                <b-button variant="danger" @click="ok()">
                    Remove
                </b-button>
            </template>
            <p>Are you sure you want to remove the {{ actions.selected.length }} selected keys from the list?</p>
        </b-modal>

        <div class="keys-table row row-panels overflow-auto">
            <!-- table header -->
            <div class="col-lg-12 mb-2 header">
                <div class="px-3 w-100 mb-2">
                    <div class="row no-gutters panel-overlay table-header">
                        <div class="col-lg-1 d-flex">
                            <b-form-checkbox
                                class="custom-control-inline m-auto"
                                v-model="actions.isAllSelected"
                                @change="onSelectAll"
                            />
                        </div>
                        <div class="col d-flex" v-if="actions.selected.length">
                            <div class="remove-selected-keys-wrapper ml-2">
                                <button
                                    class="btn btn-sm btn-outline-danger"
                                    v-b-modal="'keys-remove'"
                                >
                                    Remove selected from list ({{ actions.selected.length }})
                                </button>
                            </div>

                            <div class="remove-all-keys-wrapper ml-2">
                                <button
                                    class="btn btn-sm btn-outline-danger"
                                    v-b-modal="'keys-remove'"
                                >
                                    Remove all keys
                                </button>
                            </div>
                        </div>
                        <div class="col d-flex" v-if="!actions.selected.length">
                            <h3 class="h6 my-auto">ID</h3>
                        </div>
                        <div class="col d-flex" v-if="!actions.selected.length">
                            <h3 class="h6 my-auto">Marketplace</h3>
                        </div>
                        <div class="col d-flex" v-if="!actions.selected.length">
                            <h3 class="h6 my-auto">Console</h3>
                        </div>
                        <div class="col d-flex" v-if="!actions.selected.length">
                            <h3 class="h6 my-auto">Region Lock</h3>
                        </div>
                        <div class="col d-flex" v-if="!actions.selected.length">
                            <h3 class="h6 my-auto">Created At</h3>
                        </div>
                        <div class="col d-flex" v-if="!actions.selected.length">
                            <h3 class="h6 my-auto">Claimed</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 p-0">
                <div class="row no-gutters">
                    <b-form-checkbox-group v-model="actions.selected" class="col-lg-12">
                        <div class="col-lg-12 mb-2" v-for="key in keysOnPage">
                            <div class="panel p-3 w-100">
                                <div class="row no-gutters panel-overlay">
                                    <div class="col-lg-1 d-flex">
                                        <b-form-checkbox
                                            :value="key.id"
                                            class="m-auto"
                                        />
                                    </div>

                                    <div class="col d-flex">
                                        <div class="id-wrapper my-auto">
                                            <span>{{ key.id.split('-')[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col d-flex">
                                        <div class="marketplace-wrapper my-auto">
                                            <!-- <font-awesome-icon
                                                :icon="['fas', 'heart']"
                                                class="mr-1 font-muted"
                                            /> -->
                                            <span class="ml-1">{{ key.marketplace_id | capitalize }}</span>
                                        </div>
                                    </div>
                                    <div class="col d-flex">
                                        <div class="console-wrapper my-auto">
                                            <!-- <font-awesome-icon
                                                :icon="['fas', 'heart']"
                                                class="mr-1 font-muted"
                                            /> -->
                                            <span class="ml-1">{{ key.console_id | capitalize }}</span>
                                        </div>
                                    </div>
                                    <div class="col d-flex">
                                        <div class="region-wrapper my-auto">
                                            <span>{{ key.region_id | capitalize }}</span>
                                        </div>
                                    </div>
                                    <div class="col d-flex">
                                        <div class="created-wrapper my-auto">
                                            <span>{{ key.created_at | moment('calendar') }}</span>
                                        </div>
                                    </div>
                                    <div class="col d-flex">
                                        <div class="claimed-wrapper my-auto">
                                            <span v-if="key.claimed_at">{{ key.claimed_at | moment('calendar') }}</span>
                                            <span v-else>---</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form-checkbox-group>
                </div>
            </div>

            <div
                class="col-lg-12 mb-2"
                v-if="keys && keys.length === 0"
            >
                <div class="panel p-3 text-center">
                    No keys found
                </div>
            </div>

            <!-- pagination controls -->
            <div class="col-lg-12 d-flex mt-2">
                <b-pagination
                    v-model="pagination.currentPage"
                    :total-rows="keys.length"
                    :per-page="pagination.perPage"
                    class="mx-auto"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'KeysTable',

    props: {
        keys: {
            type: Array,
            default: () => [],
        },
    },

    watch: {
        keys(newValue, oldValue) {

            // If any new keys are brought into this component through any means
            // unselected all keys
            this.actions.selected = []
            this.actions.isAllSelected = false
        },
    },

    computed: {
        ...mapGetters([
            'getColors'
        ]),

        keysOnPage() {
            const { currentPage, perPage } = this.pagination
            const startIndex = (currentPage - 1) * perPage

            return this.keys.slice(startIndex, currentPage * perPage)
        },
    },

    data() {
        return {
            actions: {
                selected: [],
                isAllSelected: false,
            },

            pagination: {
                perPage: 10,
                currentPage: 1,
            },
        }
    },

    methods: {
        onSelectAll(isChecked) {
            if (isChecked) {
                this.actions.selected = _.map(this.keysOnPage, 'id')
            } else {
                this.actions.selected = []
            }
        },

        onRemoveKeys() {
            this.$emit('remove-keys', this.actions.selected)
        },
    },
}
</script>

<style lang="scss">
.keys-table-wrapper {
    .table-header {
        min-height: 30px;
    }

    .pagination-controls {
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
            padding: 0;
        }
    }

    &.row-panels .panel {
        transition: 0.25s all;

        &:hover {
            background: rgba(255, 255, 255, 0.075);
        }
    }
}
</style>
