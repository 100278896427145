<template>
    <div class="view view-discover mb-lg">
        <b-modal id="modal-save-search" @ok="onSaveSearch" @hide="save.name = null">
            <form @submit.stop.prevent="onSaveSearch">
                <div class="row">
                    <div class="col-lg-12">
                        <b-form-group label="Name">
                            <b-form-input v-model="save.name" @keydown.enter.prevent="null" type="text" />
                        </b-form-group>
                    </div>
                </div>
            </form>
        </b-modal>

        <div class="row">
            <div class="col-lg-2">
                <div class="sticky-sidebar-wrapper">
                    <h3 class="h5 mt-0 mb-1 d-flex" v-b-toggle.collapse-saved-searches>
                        Saved Searches

                        <span class="ml-auto">
                            <font-awesome-icon v-if="save.isShown" :icon="['fas', 'chevron-up']" size="sm" />
                            <font-awesome-icon v-else :icon="['fas', 'chevron-down']" size="sm" />
                        </span>
                    </h3>

                    <b-collapse id="collapse-saved-searches" class="mt-2 mb-4" v-model="save.isShown">
                        <div class="row row-saved-search mb-1" v-for="savedSearch in savedDiscoverSearches">
                            <div class="col-lg-12 saved-search-wrapper" @click="onSavedSearchClick(savedSearch)">
                                <div class="saved-search px-3 py-1">
                                    <div class="row">
                                        <div class="name col">
                                            {{ savedSearch.name }}
                                        </div>
                                        <div class="controls col-lg-2 d-flex">
                                            <span class="ml-auto">
                                                <font-awesome-icon :icon="['fas', 'trash']" size="sm" @click="onSavedSearchDelete(savedSearch)"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>

                    <hr class="my-3" />

                    <h3 class="h5 mt-0 mb-1 d-flex" v-b-toggle.collapse-lists>
                        Lists

                        <span class="ml-auto">
                            <font-awesome-icon v-if="save.isShown" :icon="['fas', 'chevron-up']" size="sm" />
                            <font-awesome-icon v-else :icon="['fas', 'chevron-down']" size="sm" />
                        </span>
                    </h3>

                    <b-collapse id="collapse-lists" class="mt-2 mb-4" v-model="state.lists.is_shown">
                        <div class="row row-saved-lists">
                            <div class="col-lg-12 saved-lists-wrapper mb-1" @click="onSavedListClick(list)" v-for="list in lists">
                                <div class="saved-list px-3 py-1">
                                    <div class="row">
                                        <div class="name col">
                                            {{ list.name }}
                                        </div>
                                        <div class="controls col-lg-2 d-flex">
                                            <span class="ml-auto">
                                                <!-- <font-awesome-icon :icon="['fas', 'trash']" size="sm" @click="onSavedSearchDelete(savedSearch)"/> -->
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>

                    <hr class="my-3" />

                    <pre-filter-options
                        :isLocked="isLocked"
                        :platform_id="search.platform_id"
                        :defaults="filters.pre.default"
                        :override="filters.pre.override"
                        @filters-change="onPreFiltersChange"
                    />

                    <hr />

                    <div class="actions-wrapper mt-4">
                        <button class="btn btn-outline-primary w-100" v-b-modal.modal-save-search>Save Search</button>

                        <button
                            class="btn btn-primary mt-2 w-100"
                            @click="getCreators"
                            :class="{
                                disabled: isLocked
                            }"
                        >
                            Find Creators
                        </button>

                        <button
                            v-if="creators && creators.length"
                            class="btn btn-outline-primary mt-2 w-100"
                            @click="exportCreators"
                            :class="{
                                disabled: isLocked
                            }"
                        >
                            Export {{ filteredCreators.length }} Creators
                        </button>
                    </div>

                    <hr class="mb-3" />

                    <small v-if="filters.post.defaults" class="m-0 text-center d-block">
                        Showing {{ filteredCreators.length | numeral('0,0') }} results
                    </small>

                    <hr v-if="filters.post.defaults" class="mt-3" />

                    <!-- <post-filter-options
                        v-if="filters.post.defaults"
                        :defaults="filters.post.defaults"
                        @filters-change="onPostFiltersChange"
                    /> -->
                </div>
            </div>

            <div class="col-lg-10">
                <div class="search">
                    <div class="row mb-4">
                        <div class="col-lg-8 d-flex">
                            <b-form-select v-model="search.platform_id" :options="search.platforms" class="w-25 mr-2" />
                            <b-form-input v-model="search.text" placeholder="Creator Name" class="h-100" @keyup.enter="getCreatorsByName" />
                            <b-button
                                variant="primary"
                                class="ml-2"
                                @click="getCreatorsByName"
                                :class="{
                                    disabled: isSearchByNameLocked,
                                }"
                                v-tooltip="isSearchByNameLocked ? 'Enter a search term in the box' : null"
                            >Search by Name</b-button>
                        </div>
                    </div>
                </div>

                <discover-table
                    :type="queryType"
                    :platform_id="search.platform_id"
                    :creators="filteredCreators"
                    :filters="filters.current"
                    :lists="lists"
                    :campaigns="campaigns"
                    @list-select="onListSelect"
                    @campaign-select="onCampaignSelect"
                    @row-clicked="onRowClick"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import moment from 'moment'
import PreFilterOptions from '../components/Options/PreFilterOptions'
import PostFilterOptions from '../components/Options/PostFilterOptions'
import DiscoverTable from '../components/Tables/DiscoverTable'
import { json2csvAsync } from 'json-2-csv'

export default {
    name: 'view-discover',

    metaInfo() {
        return {
            title: 'Discover'
        }
    },

    components: {
        PreFilterOptions,
        PostFilterOptions,
        DiscoverTable,
    },

    computed: {
        ...mapGetters([
            'savedDiscoverSearches',
        ]),

        isSearchByNameLocked() {
            return this.isLocked || (!this.search.text || !this.search.text.length)
        },
    },

    data() {
        return {
            creators: [],
            filteredCreators: [],
            lists: [],
            campaigns: [],

            queryType: 'by-name',

            save: {
                name: '',
                isShown: false,
            },

            state: {
                lists: {
                    is_shown: false,
                }
            },

            search: {
                text: '',
                platform_id: 'twitch',
                platforms: [{
                    text: 'Twitch',
                    value: 'twitch',
                }, {
                    text: 'Youtube',
                    value: 'youtube',
                }],
            },

            isLocked: false,

            filters: {
                pre: {
                    current: null,
                    override: null,
                    default: {
                        maxRankIndex: 4,
                        type: 'streamer',
                        languages: [{ value: 'en', text: 'English' }],
                        games: [],
                    },
                },
                post: {
                    current: null,
                    options: null,
                    defaults: null,
                },
            },
        }
    },

    created() {
        this.getLists()
        this.getCampaigns()
    },

    methods: {
        computeFilteredCreators() {
            if (!this.filters.post.current) return this.creators
            const filters = this.filters.post.current

            return _.filter(this.creators, (creator) => {

                // Filter by country
                if (filters.country && filters.country.length > 0) {
                    const matchLanguage = _.find(filters.country, item => item.code === creator.superview.channel.snippet.country)
                    if (!matchLanguage) return false
                }

                // Filter by avg_viewers min+max
                if (filters.avg_viewers && filters.avg_viewers.length === 2) {
                    const avgViewers = creator.superview.stream.day_90.aggregation.viewers.avg
                    if (avgViewers < filters.avg_viewers[0] || (filters.avg_viewers[1] < 1000 && avgViewers > filters.avg_viewers[1])) return false
                }

                // Filter by avg_viewers min+max
                if (filters.avg_views && filters.avg_views.length === 2) {
                    const avgViewers = creator.superview.video.day_90.aggregation.views.avg
                    if (avgViewers < filters.avg_views[0] || (filters.avg_views[1] < 1000000 && avgViewers > filters.avg_views[1])) return false
                }

                // Filter by streamer affinity
                if (filters.affinity && filters.affinity.streamer && filters.affinity.streamer.length === 2) {
                    const streamerAffinity = creator.affinity.streamer.percent
                    if (streamerAffinity < filters.affinity.streamer[0] || streamerAffinity > filters.affinity.streamer[1]) return false
                }

                // Filter by audience affinity
                if (filters.affinity && filters.affinity.audience && filters.affinity.audience.length === 2) {
                    const audienceAffinity = creator.affinity.audience.percent
                    if (audienceAffinity < filters.affinity.audience[0] || audienceAffinity > filters.affinity.audience[1]) return false
                }

                // Filter by games count
                if (filters.games && filters.games.count && filters.games.count.length === 2) {
                    const gamesCount = _.get(creator, 'superview.stream.day_90.accumulation.games.count')
                    if (gamesCount < filters.games.count[0] || gamesCount > filters.games.count[1]) return false
                }

                // Filter by language
                if (filters.language && filters.language.length > 0) {
                    const matchLanguage = _.find(filters.language, item => item.code === creator.superview.channel.language)
                    if (!matchLanguage) return false
                }

                if (filters.metadata && filters.metadata.email) {
                    if (!_.get(creator, 'superview.metadata.email')) return false
                }

                return true
            })
        },

        onSavedListClick(list) {
            this.getListCreatorsWithTargeting(list)

            this.$notify({
                group: "global",
                type: "success",
                text: `Running analysis on creators from list ${list.name}.`,
                duration: 2500,
                speed: 1000,
            })
        },

        onSavedSearchClick(savedSearch) {
            this.filters.pre.override = savedSearch.filters
            this.save.name = savedSearch.name

            this.$notify({
                group: "global",
                type: "success",
                text: `Loaded in saved search ${savedSearch.name}.`,
                duration: 2500,
                speed: 1000,
            })
        },

        onSavedSearchDelete(savedSearch) {
            this.$store.commit('discover:delete', {
                name: savedSearch.name,
            })

            this.$notify({
                group: "global",
                type: "success",
                text: `Successfully deleted saved filter ${savedSearch.name}.`,
                duration: 5000,
                speed: 1000,
            })
        },

        onSaveSearch() {
            this.$store.commit('discover:save', {
                name: this.save.name,
                filters: this.filters.pre.current,
                created_at: moment.utc().format(),
                version: 1,
            })

            this.$notify({
                group: "global",
                type: "success",
                text: `Successfully saved filters and ${this.creators.length} results to your browser's local storage.`,
                duration: 5000,
                speed: 1000,
            })

            this.save.isShown = true
        },

        onPreFiltersChange(filters) {
            this.filters.pre.current = filters

            this.$nextTick(() => {
                this.filters.pre.override = null
            })
        },

        onPostFiltersChange(filters) {
            console.log('onPostFiltersChange', filters)
            this.filters.post.current = filters
            this.filteredCreators = this.computeFilteredCreators()
        },

        getLists() {
            this.axios.get('/lists').then(({ data }) => {
                this.lists = data.payload.lists

            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message
                    ? error.message
                    : `There was an error fetching your lists.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Fetch Lists',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        getCampaigns() {
            return this.axios.get(`/campaigns`).then(({ data }) => {
                this.campaigns = _.orderBy(data.payload.campaigns, ['created_at'], ['desc'])
            }).catch((error) => {
                console.log(error.message)
            })
        },

        getListCreatorsWithTargeting(list) {
            if (this.isLocked) return
            this.isLocked = true

            const requestTimeout = setTimeout(() => {
                this.isLocked = false
            }, 1000 * 1)

            const body = {}

            if (_.size(this.filters.pre.current.games) > 0) {
                body.games = _.map(this.filters.pre.current.games, 'displayName')
            }

            this.axios.post(`/lists/${list.id}/creators/targeting`, body).then(({ data }) => {
                // Remove request timeout timer
                clearTimeout(requestTimeout)

                // shortly after the response, clear the lock
                setTimeout(() => {
                    this.isLocked = false
                }, 1000 * 1)

                this.queryType = 'by-game'
                this.creators = data.payload.creators
                this.filteredCreators = this.creators

                if (data.payload.filters) {
                    this.filters.post.options = data.payload.filters
                    this.filters.post.defaults = data.payload.filters
                }
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message
                    ? error.message
                    : `There was an error fetching creators.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Find Creators From List',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        getCreators(filters = {}) {
            // if (this.isLocked) return
            // this.isLocked = true

            // const requestTimeout = setTimeout(() => {
            //     this.isLocked = false
            // }, 1000 * 1)

            const body = {}

            if (_.size(this.filters.pre.current.games) > 0) {
                body.games = _.map(this.filters.pre.current.games, 'displayName')
            }

            let endpoint = `/discover/twitch`

            if (this.search.platform_id === 'youtube') {
                endpoint = `/discover/youtube`
            }

            this.axios.post(endpoint, body).then(({ data }) => {
                // Remove request timeout timer
                // clearTimeout(requestTimeout)

                // // shortly after the response, clear the lock
                // setTimeout(() => {
                //     this.isLocked = false
                // }, 1000 * 1)

                this.queryType = 'by-game'
                this.creators = data.payload.creators
                this.filteredCreators = this.creators

                if (data.payload.filters) {
                    this.filters.post.options = data.payload.filters
                    this.filters.post.defaults = data.payload.filters
                }
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message
                    ? error.message
                    : `There was an error fetching creators.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Find Creators',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        getCreatorsByName() {
            if (this.isSearchByNameLocked) return

            this.isLocked = true

            const requestTimeout = setTimeout(() => {
                this.isLocked = false
            }, 1000 * 1)

            const body = {
                name: this.search.text,
                platform: this.search.platform_id,
            }

            let endpointURL = `/discover/twitch/name`
            if (this.search.platform_id === 'youtube') {
                endpointURL = `/discover/youtube/name`
            }

            this.axios.post(endpointURL, body).then(({ data }) => {
                // Remove request timeout timer
                clearTimeout(requestTimeout)

                // 5 seconds after the response, clear the lock
                setTimeout(() => {
                    this.isLocked = false
                }, 1000 * 1)

                this.queryType = 'by-name'
                this.creators = data.payload.creators
                this.filteredCreators = this.creators

                if (data.payload.filters) {
                    this.filters.post.options = data.payload.filters
                    this.filters.post.defaults = data.payload.filters
                }
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message
                    ? error.message
                    : `There was an error fetching creators.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Find Creators',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onListSelect(list, creators) {
            const body = {
                creators: creators,
            }

            this.axios.post(`/lists/${list.id}/creators`, body).then(({ data }) => {
                this.$notify({
                    group: "global",
                    type: "success",
                    text: `Successfully added ${data.payload.count} creators to ${list.name}`,
                    duration: 5000,
                    speed: 1000,
                })
            }).catch((error) => {
                const message = error.message
                    ? error.message
                    : `There was an error adding creators to list ${list.name}.`

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Add creators to list',
                        text: message,
                        duration: 10000,
                        speed: 1000,
                    })
            })
        },

        onCampaignSelect(campaign, creators) {
            const body = {
                creators: creators,
            }

            this.axios.post(`/supercampaigns/${campaign.parent_id}/campaigns/${campaign.id}/participants`, body).then(({ data }) => {
                this.$notify({
                    group: "global",
                    type: "success",
                    text: `Successfully added creators to ${campaign.title}`,
                    duration: 5000,
                    speed: 1000
                })
            }).catch((error) => {
                const message = error.message ? error.message : `Error adding creator to campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Add Creator to Campaign',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        async exportCreators() {
            const link = document.createElement('a')

            const csvResults = await json2csvAsync(
                _.map(this.filteredCreators, (creator) => {
                    if (this.search.platform_id === 'twitch') {
                        return {
                            'Creator Name': _.get(creator, 'superview.channel.name'),
                            'Connect Page': `https://streamforge.com/twitch/${_.get(creator, 'superview.channel.name')}`,
                            'Email': _.get(creator, 'superview.metadata.email') ? _.get(creator, 'superview.metadata.email') : '',
                            'Followers': numeral(_.get(creator, 'superview.channel.followers')).format('0,0'),
                            '30 day ACV': numeral(_.get(creator, 'superview.stream.day_30.aggregation.viewers.avg')).format('0,0'),
                            '90 day ACV': numeral(_.get(creator, 'superview.stream.day_90.aggregation.viewers.avg')).format('0,0'),
                            'Combined Affinity': numeral(_.get(creator, 'affinity.combined.percent')).format('0%'),
                            'Streamer Affinity': numeral(_.get(creator, 'affinity.streamer.percent')).format('0%'),
                            'Audience Affinity': numeral(_.get(creator, 'affinity.audience.percent')).format('0%'),
                            'Language': _.get(creator, 'superview.channel.language'),
                        }
                    } else if (this.search.platform_id === 'youtube') {
                        return {
                            'Creator Name': _.get(creator, 'superview.channel.snippet.title'),
                            'Connect Page': `https://streamforge.com/youtube/${_.get(creator, 'superview._id')}`,
                            'Email': _.get(creator, 'superview.metadata.email') ? _.get(creator, 'superview.metadata.email') : '',
                            'Subscribers': numeral(_.get(creator, 'superview.channel.statistics.subscriberCount')).format('0,0'),
                            '30 day Avg Views': numeral(_.get(creator, 'superview.video.day_30.aggregation.views.avg')).format('0,0'),
                            '90 day Avg Views': numeral(_.get(creator, 'superview.video.day_90.aggregation.views.avg')).format('0,0'),
                            'Combined Affinity': numeral(_.get(creator, 'affinity.combined.percent')).format('0%'),
                            'Streamer Affinity': numeral(_.get(creator, 'affinity.streamer.percent')).format('0%'),
                            'Audience Affinity': numeral(_.get(creator, 'affinity.audience.percent')).format('0%'),
                            'Country': _.get(creator, 'superview.channel.snippet.country'),
                        }
                    }
                })
            )

            link.setAttribute("href", 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvResults))
            link.setAttribute("download", 'creators.csv')
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },

        async onRowClick({ item, index, event }) {
            let superview = item.superview

            // const isMetricsOudated = moment.utc(superview._updated_at).isBefore(moment.utc().subtract(1, 'day'))

            // if (superview.has_metrics === false || isMetricsOudated) {
            //     superview = await this.axios.get(`/discover/youtube/${item.superview._id}/fetch`).then(({ data }) => {
            //         const creator = _.find(this.creators, item => item._id === data._id)
            //         this.$set(creator, 'superview', data.superview)

            //         return data.superview
            //     }).catch((error) => {
            //         console.error(`Error fetching youtube channels`, error.message)
            //     })
            // }

            this.$store.commit('drawer:set', {
                enabled: true,
                creator: superview,
            })
        },
    }
}
</script>

<style lang="scss">
.view-discover {
    hr {
        border-top-color: rgba(white, 0.1);
    }

    .sticky-sidebar-wrapper {
        position: -webkit-sticky;
        position: sticky;
        top: 25px;
        z-index: 100;
    }

    .row-saved-search {
        max-height: 300px;
        overflow-y: scroll;

        .saved-search {
            transition: 0.25s all;
            border-radius: 5px;
            cursor: pointer;
            background: rgba(white, 0.05);

            .controls {
                opacity: 0;
                transition: 0.25s all;

                &:hover {
                    color: white;
                }
            }

            &:hover {
                background: rgba(white, 0.075);

                .controls {
                    opacity: 1;
                }
            }
        }
    }

    .row-saved-lists {
        max-height: 300px;
        overflow-y: scroll;

        .saved-list {
            transition: 0.25s all;
            border-radius: 5px;
            cursor: pointer;
            background: rgba(white, 0.05);

            .controls {
                opacity: 0;
                transition: 0.25s all;

                &:hover {
                    color: white;
                }
            }

            &:hover {
                background: rgba(white, 0.075);

                .controls {
                    opacity: 1;
                }
            }
        }
    }
}
</style>
