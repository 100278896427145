/* ============
 * Vuex Store
 * ============
 *
 * The store of the application
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

// Modules
import authentication from './authentication'
import colors from './colors'
import notifications from './notifications'
import modal from './modal'
import campaigns from './campaigns'
import drawer from './drawer'
import languages from './languages'
import requests from './requests'
import discover from './discover'
import dictionary from './dictionary'
import self from './self'

Vue.use(Vuex)

// Only persist certain paths to localstorage
const plugins = [
    createPersistedState({
        paths: [
            'authentication',
            'campaigns',
            'discover',
        ]
    })
]

// Only use the logger in development
if (process.env.NODE_ENV !== 'production') {
    plugins.push(createLogger())
}

export default new Vuex.Store({
    /**
     * Assign the modules to the store
     */
    modules: {
        authentication,
        colors,
        notifications,
        modal,
        campaigns,
        drawer,
        languages,
        requests,
        discover,
        dictionary,
        self,
    },

    /**
     * If strict mode should be enabled
     */
    strict: false,

    /**
     * Plugins used in the store
     */
    plugins,
})
