<template>
    <span
        class="verified ml-1"
        :class="platform"
        v-tooltip="platformTitle[platform]"
    >
        <font-awesome-icon
            :icon="['fas', 'certificate']"
            class="bg-check"
        />

        <font-awesome-icon
            :icon="['fas', 'check']"
            class="fg-check"
            size="sm"
        />
    </span>
</template>

<script>
export default {
    name: 'VerifiedBadge',

    props: {
        platform: {
            type: String,
            default: 'default',
        }
    },

    data() {
        return {
            platformTitle: {
                twitch: `Twitch Partner`,
                youtube: `Verified Channel`,
                default: `Verified`,
            },
        }
    }
}
</script>

<style lang="scss">
.verified {
    position: relative;
    display: inline;
    margin-right: 5px;

    &.twitch {
        .bg-check {
            color: #9147ff;
        }

        .fg-check {
            color: #fff;
        }
    }

    &.youtube {
        .bg-check {
            color: #c4302b;
        }

        .fg-check {
            color: #fff;
        }
    }

    &.default {
        .bg-check {
            color: #fcd878;
        }

        .fg-check {
            color: #000;
        }
    }

    .fg-check {
        position: absolute;
        top: 25%;
        left: 25%;
        width: 50%;
        height: 50%;
    }
}
</style>
