var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-supercampaign h-100"},[_c('div',{staticClass:"panel p-3 h-100 d-flex flex-column"},[(_vm.backdropUrl)?_c('div',{staticClass:"backdrop",style:({
                'background-image': ("url(" + _vm.backdropUrl + ")")
            })}):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"meta-wrapper col-lg-12 d-flex justify-content-between"},[_c('h3',{staticClass:"h5 my-auto d-flex flex-column"},[(_vm.supercampaign.game)?_c('span',{attrs:{"title":_vm.supercampaign.game.name}},[_vm._v(_vm._s(_vm.supercampaign.game.name))]):(_vm.supercampaign.title)?_c('span',{attrs:{"title":_vm.supercampaign.title}},[_vm._v(_vm._s(_vm.supercampaign.title))]):(_vm.supercampaign.intake_form)?_c('span',{attrs:{"title":_vm.supercampaign.intake_form.game_name}},[_vm._v(_vm._s(_vm.supercampaign.intake_form.game_name))]):_vm._e(),_c('small',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.supercampaign.stage))])]),_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":['fas', 'ellipsis-v']}})]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.onCampaignActionClick(_vm.supercampaign, 'update')}}},[_vm._v("Edit")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.onCampaignActionClick(_vm.supercampaign, 'delete')}}},[_vm._v("Delete")])],1)],1)]),_c('div',{staticClass:"description-wrapper mt-1 mb-5"},_vm._l((_vm.supercampaign.campaigns),function(campaign){return _c('b-badge',{attrs:{"variant":"primary"}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":_vm.typeToIcon(campaign.type)}}),_vm._v(" "+_vm._s(_vm.slugToTitleCase(campaign.type))+" ")],1)}),1),_c('div',{staticClass:"d-flex mt-auto"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"mt-auto mb-0"},[_vm._v(_vm._s(_vm._f("moment")(_vm.getFirstStartDate(_vm.supercampaign),'MMM Do, YYYY')))])]),_c('router-link',{staticClass:"btn btn-primary ml-auto",attrs:{"to":{
                    name: 'supercampaign',
                    params: {
                        supercampaign_id: _vm.supercampaign.id,
                    },
                },"tag":"a"}},[_vm._v(" Open ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }