<template>
    <div class="game-modal-wrapper">
        <b-modal id="game-modal" @ok="onSubmit" @hide="onHide">
            <template #modal-title>
                <h1 class="h4 m-0">Add Game to Account</h1>
            </template>

            <template #modal-footer="{ ok }">
                <b-button :variant="type === 'delete' ? 'danger' : 'success'" @click="ok()">
                    {{ type === 'delete' ? 'Remove Game' : 'Add Game' }}
                </b-button>
            </template>

            <form @submit.stop.prevent="onSubmit">
                <div class="row" v-if="type !== 'delete'">
                    <div class="col-lg-12">
                        <p class="m-0">Here you can request to add a game to your account. We will verify that the game is associated with your organization.</p>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <b-form-group label="Game">
                            <game-dropdown
                                :onGameClick="onGameClick"
                                :game="values.game"
                            />
                        </b-form-group>

                        <b-form-group label="Notes">
                            <b-form-textarea
                                v-model="values.notes"
                                placeholder="Any additional notes you may have"
                                rows="3"
                                max-rows="6"
                            />
                        </b-form-group>
                    </div>
                </div>

                <div class="row" v-if="type === 'delete'">
                    <div class="col-lg-12">
                        <p>Are you sure you want to remove this game from your account?</p>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'
import GameDropdown from '@/components/Dropdowns/GameDropdown'

export default {
    name: 'game-modal',

    components: {
        GameDropdown,
    },

    props: {
        type: {
            type: String,
            default: 'create',
        },
    },

    data() {
        return {
            values: {
                game: null,
                notes: '',
            },
        }
    },

    created() {
    },

    methods: {
        onSubmit() {
            this.$emit('submit', this.type, this.values)
        },

        onGameClick(game) {
            this.values.game = game
        },

        onHide(event) {
            this.$emit('close', event)
        },
    }
}
</script>

<style lang="scss">
.game-modal-wrapper {
}
</style>
