import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Sidebar from './layout/Sidebar'
import Footer from './layout/Footer'

import DiscoverView from './views/Discover'
import ListsView from './views/Lists'
import GamesView from './views/Games'
import SegmentsView from './views/Segments'

import Login from './views/authentication/Login'
import Logout from './views/authentication/Logout'
import Register from './views/authentication/Register'
import ForgotPassword from './views/authentication/ForgotPassword'
import ResetPassword from './views/authentication/ResetPassword'

import UsersView from './views/Users'

import SupercampaignView from './views/supercampaign/Supercampaign'

import SupercampaignsView from './views/Supercampaigns'

import CampaignView from './views/campaign/Campaign'
import CampaignParticipantsView from './views/campaign/CampaignParticipants'
import CampaignTasksView from './views/campaign/CampaignTasks'
import CampaignAttributionView from './views/campaign/CampaignAttribution'
import CampaignStreamsView from './views/campaign/CampaignStreams'
import CampaignSummaryView from './views/campaign/CampaignSummary'
import CampaignSlotsView from './views/campaign/CampaignSlots'
import CampaignApplicationsView from './views/campaign/CampaignApplications'
import CampaignParticipantView from './views/campaign/CampaignParticipant'
import CampaignOverviewView from './views/campaign/CampaignOverview'
import CampaignDeliverables from './views/campaign/CampaignDeliverables'

import GameView from './views/game/Game'
import GameKeysView from './views/game/GameKeys'
import GameTwitchChaptersView from './views/game/GameTwitchChapters'
import GameTwitchBookmarksView from './views/game/GameTwitchBookmarks'
import GameSummaryView from './views/game/GameSummary'

import TwitchProfileView from './views/profile/TwitchProfile'

// Fallback Pages
import NotFound from './views/http-status/NotFound'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
    linkExactActiveClass: 'active',
    mode: 'history',

    routes: [{
        path: '/',
        components: {
            header: Sidebar,
            default: SupercampaignsView,
            footer: Footer
        },
        meta: {
            is_auth_required: true,
            title: 'Dashboard'
        }
    },
    {
        path: '/login',
        name: 'login',
        components: {
            default: Login
        },
        meta: {
            layout: 'fullscreen'
        }
    }, {
        path: '/register',
        name: 'register',
        components: {
            default: Register
        },
        meta: {
            layout: 'fullscreen'
        }

    }, {
        path: '/forgot-password',
        name: 'ForgotPassword',
        components: {
            default: ForgotPassword
        },
        meta: {
            layout: 'fullscreen'
        }
    }, {
        path: '/reset-password',
        name: 'ResetPassword',
        components: {
            default: ResetPassword
        },
        meta: {
            layout: 'fullscreen'
        }
    }, {
        path: '/logout',
        name: 'logout',
        components: {
            default: Logout,
        },
        meta: {
            layout: 'fullscreen',
        }
    }, {
        path: '/campaigns',
        name: 'supercampaigns',
        components: {
            header: Sidebar,
            default: SupercampaignsView,
            footer: Footer
        },
        meta: {
            title: 'Campaigns',
            is_auth_required: false
        }
    },
    {
        path: '/profiles/twitch/:channel_name',
        name: 'profiles-twitch',
        component: TwitchProfileView,
        meta: {
            is_auth_required: true,
        },
    },
    {
        path: '/supercampaigns/:supercampaign_id',
        name: 'supercampaign',
        component: SupercampaignView,
        meta: {
            is_auth_required: true,
        },
        children: [{
            path: 'campaigns/:campaign_id',
            name: 'campaign',
            component: CampaignView,
            redirect: {
                name: 'campaign-participants',
            },
            children: [{
                path: 'participants',
                name: 'campaign-participants',
                component: CampaignParticipantsView,
            }, {
                path: 'participants/:id',
                name: 'campaign-participant',
                component: CampaignParticipantView,
            }, {
                path: 'tasks',
                name: 'campaign-tasks',
                component: CampaignTasksView,
            }, {
                path: 'attribution',
                name: 'campaign-attribution',
                component: CampaignAttributionView,
            }, {
                path: 'streams',
                name: 'campaign-streams',
                component: CampaignStreamsView,
            }, {
                path: 'summary',
                name: 'campaign-summary',
                component: CampaignSummaryView,
            }, {
                path: 'slots',
                name: 'campaign-slots',
                component: CampaignSlotsView,
            }, {
                path: 'applications',
                name: 'campaign-applications',
                component: CampaignApplicationsView,
            }, {
                path: 'overview',
                name: 'campaign-overview',
                component: CampaignOverviewView,
            }, {
                path: 'deliverables',
                name: 'campaign-deliverables',
                component: CampaignDeliverables,
            }],
        }, ]
    },

    {
        path: '/discover',
        name: 'discover',
        components: {
            header: Sidebar,
            default: DiscoverView,
            footer: Footer
        },
        meta: {
            title: 'Discover',
            is_auth_required: true,
        }
    },
    {
        path: '/lists',
        name: 'lists',
        components: {
            default: ListsView,
            footer: Footer
        },
        meta: {
            title: 'Lists',
            is_auth_required: true,
        }
    },
    {
        path: '/segments',
        name: 'segments',
        components: {
            default: SegmentsView,
            footer: Footer
        },
        meta: {
            title: 'Segments',
            is_auth_required: true,
        }
    },
    {
        path: '/games',
        name: 'games',
        components: {
            default: GamesView,
            footer: Footer
        },
        meta: {
            title: 'Games',
            is_auth_required: true,
        }
    },
    {
        path: '/games/:game_id',
        name: 'game',
        component: GameView,
        meta: {
        is_auth_required: true,
        },
        redirect: {
            name: 'game-keys',
        },
        children: [{
            path: 'keys',
            name: 'game-keys',
            component: GameKeysView,
        }, {
            path: 'chapters',
            name: 'game-twitch-chapters',
            component: GameTwitchChaptersView,
        }, {
            path: 'bookmarks',
            name: 'game-twitch-bookmarks',
            component: GameTwitchBookmarksView,
        }, {
            path: 'summary',
            name: 'game-summary',
            component: GameSummaryView,
        }],
    }, {
        path: '/users',
        name: 'users',
        components: {
            header: Sidebar,
            default: UsersView,
            footer: Footer
        },
        meta: {
            title: 'Users',
            is_auth_required: true,
        }
    }, {
        path: '/*',
        name: 'not-found',
        components: {
            default: NotFound
        },
        meta: {
            layout: 'fullscreen'
        }
    }],

    scrollBehavior: (to) => {
        if (to.hash) {
            return {
                selector: to.hash
            }
        } else {
            return {
                x: 0,
                y: 0,
            }
        }
    }
})

const createRouter = (store) => {
    router.beforeEach((to, from, next) => {
        store.dispatch('CANCEL_PENDING_REQUESTS')

        if (to.matched.some(m => m.meta.authentication) && !store.state.authentication.is_auth_required) {
            /*
             * If the user is not authenticated and visits
             * a page that requires authentication, redirect to the login page
             * but save the attempted path to localstorage so we can redirect
             * the user there after login
             */
            store.commit('authentication:redirect', to.fullPath)

            next({
                name: 'logout'
            })
        } else if (to.matched.some(m => m.meta.guest) && store.state.authentication.is_auth_required) {
            /*
             * If the user is authenticated and visits
             * an guest page, redirect to the dashboard page
             */
            next({
                name: 'logout'
            })
        } else {
            next()
        }

        let isDelegated = false

        for (let matched = (to.matched || []), i = matched.length; i--;) {
            let route = matched[i]

            if (route.beforeEnter) {
                isDelegated = true
                route.beforeEnter(to, from, next)
            }
        }

        !isDelegated && next()
    })

    return router
}

export default createRouter
