<template>
    <div
        class="drawer-frame"
        :style="{
            'height': `${windowHeight}px`,
        }"
        v-if="drawer.enabled && drawer.creator"
    >
        <div class="close d-flex mr-2 mt-2" @click="onCloseDrawer">
            <font-awesome-icon
                class="m-auto"
                :icon="['fas', 'times']"
                size="lg"
            />
        </div>

        <div class="drawer-wrapper">
            <div class="backdrop" v-if="drawer.creator.channel.profile_banner"
                :style="{
                    'background-image': `url(${drawer.creator.channel.profile_banner})`
                }"
            />

            <div class="drawer">
                <div class="drawer-content d-flex flex-column">
                    <div class="d-flex px-3 header">
                        <div class="name-rank-avatar-wrapper my-auto d-flex">
                            <img
                                v-if="drawer.creator.platform_id === 'twitch'"
                                :src="drawer.creator.channel.logo"
                                class="avatar avatar-xl my-auto"
                            />

                            <img
                                v-if="drawer.creator.platform_id === 'youtube'"
                                :src="drawer.creator.channel.snippet.thumbnails.medium.url"
                                class="avatar avatar-xl my-auto"
                            />

                            <div class="name-rank-wrapper d-flex flex-column my-auto ml-3">
                                <h2 class="h4 m-0" v-if="drawer.creator.platform_id === 'twitch'">
                                    {{ drawer.creator.channel.name }}

                                    <verified-badge
                                        v-if="drawer.creator.channel.is_partner"
                                        :platform="'twitch'"
                                    />
                                </h2>

                                <h2 class="h4 m-0" v-if="drawer.creator.platform_id === 'youtube'">
                                    {{ drawer.creator.channel.snippet.title }}
                                </h2>

                                <p
                                    v-if="drawer.creator.rank"
                                    class="m-0"
                                    :style="{ color: getColors.ranks[drawer.creator.rank.name] }"
                                >{{ drawer.creator.rank.name | capitalize }}</p>

                                <div class="external-links">
                                    <a :href="`https://twitch.tv/${drawer.creator.channel.name}`" target="blank" v-if="drawer.creator.platform_id === 'twitch'">
                                        <font-awesome-icon
                                            :icon="['fab', 'twitch']" style="color: #8c6dc5;"
                                        />
                                    </a>

                                    <a :href="`https://youtube.com/channel/${drawer.creator._id}`" target="blank" v-if="drawer.creator.platform_id === 'youtube'">
                                        <font-awesome-icon
                                            :icon="['fab', 'youtube']" class="text-danger"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="trueprice-wrapper px-3 mb-3" v-if="drawer.creator.platform_id === 'twitch'">
                        <div class="row">

                            <div
                                class="col-lg-6 py-3 text-center"
                                v-tooltip="drawer.creator.trueprice ? `${numeral(drawer.creator.trueprice.acv_adjustment).format('+0.0%')} CCV dropoff` : ''"
                            >
                                <small>TruePrice</small>
                                <p class="m-0 font-weight-bold text-muted" v-if="drawer.creator.trueprice">
                                    ${{ drawer.creator.trueprice.hourly_rate  | numeral('0,0') }}/hour
                                </p>
                                <p class="m-0 font-weight-bold text-muted" v-else>
                                    No Data
                                </p>
                            </div>

                            <div class="col-lg-6 py-3 text-center">
                                <small>Email</small>
                                <p class="m-0 font-weight-bold text-muted" v-if="drawer.creator.metadata && drawer.creator.metadata.email">
                                    <b-input size="sm" :value="drawer.creator.metadata.email" ref="email" @click="copyEmail" />
                                </p>
                                <p class="m-0 font-weight-bold text-muted" v-else>
                                    No Email Found
                                </p>
                            </div>

                            <!-- <div class="col-lg-6 text-center py-3">
                                <small>Last Updated</small>
                                <p class="m-0 font-weight-bold text-muted">{{ drawer.creator._updated_at | moment('from', 'now') }}</p>
                            </div> -->
                        </div>
                    </div>

                    <!-- <div class="d-flex px-3 mb-3">
                        <div class="pr-1 w-50">
                            <router-link tag="button" class="btn btn-outline-primary w-100"
                                :to="{
                                    name: 'profiles-twitch',
                                    params: {
                                        channel_name: drawer.creator.channel.name
                                    }
                                }"
                            >View Profile</router-link>
                        </div>

                        <div class="pl-1 w-50" v-if="drawer.creator.platform_id === 'twitch'">
                            <a class="h4 m-0" :href="`https://twitch.tv/${drawer.creator.channel.name}`" target="blank">
                                <button class="btn btn-outline-info btn-twitch w-100">Visit Twitch</button>
                            </a>
                        </div>

                        <div class="pl-1 w-50" v-if="drawer.creator.platform_id === 'youtube'">
                            <a class="h4 m-0" :href="`https://youtube.com/channel/${drawer.creator.channel.id}`" target="blank">
                                <button class="btn btn-outline-danger w-100">Visit YouTube</button>
                            </a>
                        </div>
                    </div> -->

                    <div class="px-3 tabs">
                        <b-tabs pills class="tabs-wrapper">
                            <b-tab title="Metrics" class="" v-if="drawer.creator.platform_id === 'twitch' && drawer.creator.has_metrics !== false">

                                <div class="drawer-footer py-2">
                                    <div class="row">
                                        <div class="col-lg-5 text-center">
                                            <small>Platform User ID</small>
                                            <p class="m-0 font-weight-bold text-muted">{{ drawer.creator._id }}</p>
                                        </div>

                                        <div class="col-lg-2 text-center d-flex">
                                            <router-link tag="button" class="btn btn-sm btn-outline-primary my-auto"
                                                :to="{
                                                    name: 'profiles-twitch',
                                                    params: {
                                                        channel_name: drawer.creator.channel.name
                                                    }
                                                }"
                                            >Debug</router-link>
                                        </div>

                                        <div class="col-lg-5 text-center">
                                            <small>Last Updated</small>
                                            <p class="m-0 font-weight-bold text-muted">{{ drawer.creator._updated_at | moment('from', 'now') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-lg-12 py-2 mb-2 sub-section-header d-flex">
                                        <small class="text-muted my-auto">Metrics</small>

                                        <div class="metric-windows ml-auto">
                                            <b-dropdown
                                                right
                                                :text="getFromDictionary(`superview.metricWindow.${state.selectedMetricWindowKey}`)"
                                                variant="outline-primary"
                                                size="sm"
                                            >
                                                <b-dropdown-item
                                                    v-for="metricWindow in metricWindows"
                                                    @click="selectMetricWindow(metricWindow)"
                                                    :active="state.selectedMetricWindowKey === metricWindow"
                                                >
                                                    {{ getFromDictionary(`superview.metricWindow.${metricWindow}`) }}
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>AVG Viewers</small>
                                        <h4 class="h4">{{ drawer.creator.stream[state.selectedMetricWindowKey].aggregation.viewers.avg | numeral('0,0') }}</h4>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>Followers</small>
                                        <h4 class="h4">{{ drawer.creator.channel.followers | numeral('0,0') }}</h4>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>AVG Impressions</small>
                                        <h4 class="h4">{{ drawer.creator.stream[state.selectedMetricWindowKey].aggregation.impressions.avg | numeral('0,0') }}</h4>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>AVG Stream Duration</small>
                                        <h4 class="h4">{{ drawer.creator.stream[state.selectedMetricWindowKey].aggregation.live_seconds.avg / 3600 | numeral('0.0') }} hours</h4>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>Days Streamed</small>
                                        <h4 class="h4">{{ drawer.creator.stream[state.selectedMetricWindowKey].accumulation.days_streamed.count | numeral('0,0') }}/90</h4>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>AVG Games per Stream</small>
                                        <h4 class="h4">{{ drawer.creator.stream[state.selectedMetricWindowKey].accumulation.chapters.count / drawer.creator.stream[state.selectedMetricWindowKey].accumulation.streams.count | numeral('0,0.0') }}</h4>
                                    </div>
                                </div>
                            </b-tab>


                            <b-tab title="Metrics" class="" v-if="drawer.creator.platform_id === 'youtube' && drawer.creator.has_metrics !== false">
                                <div class="drawer-footer p-2">
                                    <div class="row">
                                        <div class="col-lg-6 text-center">
                                            <small>Platform User ID</small>
                                            <b-input size="sm" :value="drawer.creator._id" />
                                        </div>

                                        <div class="col-lg-6 text-center">
                                            <small>Last Updated</small>
                                            <p class="m-0 font-weight-bold text-muted">{{ drawer.creator._updated_at | moment('from', 'now') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-lg-12 py-2 mb-2 sub-section-header">
                                        <small class="text-muted my-auto">Metrics</small>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>Views per video</small>
                                        <h4 class="h4">{{ drawer.creator.video[state.selectedMetricWindowKey].aggregation.views.avg | numeral('0,0') }}</h4>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>Comments per video</small>
                                        <h4 class="h4">{{ drawer.creator.video[state.selectedMetricWindowKey].aggregation.comments.avg | numeral('0,0') }}</h4>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>Likes per video</small>
                                        <h4 class="h4">{{ drawer.creator.video[state.selectedMetricWindowKey].aggregation.likes.avg | numeral('0,0') }}</h4>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>Total Subscribers</small>
                                        <h4 class="h4">{{ drawer.creator.channel.statistics.subscriberCount | numeral('0,0') }}</h4>
                                    </div>

                                    <div class="col-lg-6 mb-2">
                                        <small>Total Impressions</small>
                                        <h4 class="h4">{{ drawer.creator.channel.statistics.viewCount | numeral('0,0') }}</h4>
                                    </div>
                                </div>
                            </b-tab>

                            <b-tab title="Games">
                                <div class="row mt-4">
                                    <div class="col-lg-12 py-2 mb-2 sub-section-header d-flex">
                                        <small class="text-muted my-auto">Games</small>

                                        <div class="metric-windows ml-auto">
                                            <b-dropdown
                                                right
                                                :text="getFromDictionary(`superview.metricWindow.${state.selectedMetricWindowKey}`)"
                                                variant="outline-primary"
                                                size="sm"
                                            >
                                                <b-dropdown-item
                                                    v-for="metricWindow in metricWindows"
                                                    @click="selectMetricWindow(metricWindow)"
                                                    :active="state.selectedMetricWindowKey === metricWindow"
                                                >
                                                    {{ getFromDictionary(`superview.metricWindow.${metricWindow}`) }}
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="row games-wrapper mt-2"
                                    :style="{
                                        'max-height': `${tabContentHeight}px`
                                    }"
                                >
                                    <div class="col-lg-12 mb-3" v-for="game in games">
                                        <div
                                            class="p-3 game h-100 d-flex"
                                            :style="{
                                                'background-image': `url(https://static-cdn.jtvnw.net/ttv-boxart/${encodeURI(game.name)}-285x380.jpg)`
                                            }"
                                        >
                                            <div class="row my-auto w-100">

                                                <div class="col-lg-9">
                                                    <div class="row">
                                                        <div class="col-lg-12 mb-1 d-flex">
                                                            <span class="h5 my-0 game-name" v-tooltip="{
                                                                content: `
                                                                    <div class='font-weight-bold text-center'>${game.name}</div>
                                                                    <div class='text-center'>Streamed ${game.count} times</div>`,
                                                                html: true,
                                                            }">
                                                                <span class="text-primary">({{ game.count }})</span>
                                                                {{ game.name }}
                                                            </span>

                                                        </div>

                                                        <div class="col-lg-12" v-if="drawer.creator.platform_id === 'twitch'">
                                                            {{ game.duration_streamed_human }}
                                                        </div>

                                                        <div class="col-lg-12" v-if="drawer.creator.platform_id === 'youtube'">
                                                            Made {{ game.count }} videos
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-3 d-flex">
                                                    <span class="h3 m-auto">{{ game.affinity | numeral('0%') }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                            <b-tab title="About" class="">
                                <div class="row mt-4">
                                    <div class="col-lg-12 p-0 mb-3">
                                        <div class="sub-section-header py-2 px-3">
                                            <small class="text-muted">Language</small>
                                        </div>
                                        <p class="m-0 px-3 py-2">
                                            <b-badge
                                                variant="primary"
                                                v-if="drawer.creator.platform_id === 'twitch'"
                                            >
                                                <span>{{ getLanguageFromCode(drawer.creator.channel.language || drawer.creator.channel.broadcaster_language) }}</span>
                                            </b-badge>

                                            <b-badge
                                            class="ml-2"
                                                variant="primary"
                                                v-if="drawer.creator.channel.mature"
                                            >
                                                <span>Mature</span>
                                            </b-badge>
                                        </p>
                                    </div>

                                    <div class="col-lg-12 p-0 mb-3" v-if="drawer.creator.platform_id === 'twitch'">
                                        <div class="sub-section-header py-2 px-3">
                                            <small class="text-muted">Description</small>
                                        </div>
                                        <p class="m-0 px-3 py-2" v-if="drawer.creator.channel.description">{{ drawer.creator.channel.description }}</p>
                                        <p class="m-0 px-3 py-2" v-else>No description</p>
                                    </div>

                                    <div class="col-lg-12 p-0 mb-3" v-if="drawer.creator.platform_id === 'youtube'">
                                        <div class="sub-section-header py-2 px-3">
                                            <small class="text-muted">Description</small>
                                        </div>
                                        <p class="m-0 px-3 py-2">{{ drawer.creator.channel.snippet.description }}</p>
                                    </div>

                                    <div class="col-lg-12 p-0 mb-3" v-if="drawer.creator.metadata && drawer.creator.metadata.location">
                                        <div class="sub-section-header py-2 px-3">
                                            <small class="text-muted">Location</small>
                                        </div>
                                        <p v-for="(location, key) in drawer.creator.metadata.location" class="my-1 px-3">
                                            {{ key | capitalize }}: {{ location }}
                                        </p>
                                    </div>

                                    <div class="col-lg-12 p-0 mb-3" v-if="drawer.creator.metadata">
                                        <div class="sub-section-header py-2 px-3">
                                            <small class="text-muted">Social Links</small>
                                        </div>
                                        <p class="m-0 px-3 py-2">
                                            <span v-for="link in links.socials" class="d-block mb-2">
                                                <a :href="link.href">{{ link.key.split('_')[0] | capitalize }}</a>
                                            </span>
                                            <span v-if="!links.socials || !links.socials.length" class="d-block mb-2">No social links</span>
                                        </p>
                                    </div>

                                    <div class="col-lg-12 p-0 mb-3" v-if="drawer.creator.metadata">
                                        <div class="sub-section-header py-2 px-3">
                                            <small class="text-muted">Other Links</small>
                                        </div>
                                        <p class="m-0 px-3 py-2">
                                            <span v-for="link in links.other" class="d-block mb-2">
                                                <a :href="link.href">{{ link.hostname }}</a>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </b-tab>



                            <b-tab title="Campaigns" class="mb-2" v-if="participants && participants.length > 0">
                                <div class="row mt-4">
                                    <div class="col-lg-12 py-2 mb-2 sub-section-header d-flex">
                                        <small class="text-muted my-auto">Campaigns</small>
                                    </div>
                                </div>

                                <div
                                    class="row games-wrapper mt-2 pb-3"
                                    :style="{
                                        'max-height': `${tabContentHeight}px`
                                    }"
                                >
                                    <div class="col-lg-12 mb-3" v-for="participant in participants">
                                        <div
                                            class="p-3 game h-100 d-flex"
                                            :style="{
                                                /* 'background-image': `url(https://static-cdn.jtvnw.net/ttv-boxart/${encodeURI(game.name)}-285x380.jpg)` */
                                            }"
                                        >
                                            <div class="row my-auto w-100">

                                                <div class="col-lg-12">
                                                    <div class="col-lg-12 mb-1 d-flex">
                                                        <span class="h5 my-0 game-name">
                                                            {{ participant.campaign.title }}
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <p class="mb-1">{{ participant.campaign.started_at | moment('MM/DD/YYYY') }}</p>
                                                        <p class="mb-0">Payout: ${{ participant.total_payout | numeral('0,0') }}</p>
                                                        <p class="mb-0">Type: {{ getFromDictionary(`campaign.type.${participant.campaign.type}`, 'label') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>

                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import numeral from 'numeral'
import moment from 'moment'
import { mapGetters } from 'vuex'
import VerifiedBadge from '@/components/Client/VerifiedBadge'

export default {
    name: 'drawer',

    components: {
        VerifiedBadge,
    },

    data() {
        return {
            windowHeight: window.innerHeight,
            tabContentHeight: (window.innerHeight * 0.8) - 149,

            state: {
                selectedMetricWindowKey: 'day_90',
            },

            participants: [],
        }
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.windowHeight = window.innerHeight
            this.tabContentHeight = (window.innerHeight * 0.8) - 149
        })
    },

    watch: {
        drawer(newValue, oldValue) {
            if (newValue === oldValue) {
                console.log(`Reclick, do nothing...`)
                return
            }

            const windows = _.get(this.drawer, 'creator.stream')

            if (!windows || !windows[this.state.selectedMetricWindowKey]) {
                this.state.selectedMetricWindowKey = 'day_90'
            }

            this.participants = null
            this.getPastCampaigns()
        },
    },

    computed: {
        ...mapGetters([
            'getColors',
            'drawer',
            'getLanguageFromCode',
            'getFromDictionary',
        ]),

        metricWindows() {
            const windows = _.get(this.drawer, 'creator.stream')
            if (!windows) return []

            return _.orderBy(_.keys(windows), [item => Number(item.replace('day_', ''))], ['asc'])
        },

        games() {
            let games = []

            if (this.drawer.creator.has_metrics === false) return []

            if (this.drawer.creator && this.drawer.creator.platform_id === 'twitch') {
                games = _.values(this.drawer.creator.stream[this.state.selectedMetricWindowKey].accumulation.games.lookup)
                if (!games || !games.length) return []

                const totalSecondsStreamed = _.sumBy(games, 'duration_seconds')

                games = _.map(games, (game) => {
                    game.affinity = game.duration_seconds / totalSecondsStreamed
                    game.duration_streamed_human = this.toHumanTime(game.duration_seconds)
                    return game
                })
            }

            if (this.drawer.creator.platform_id === 'youtube') {
                games = _.values(this.drawer.creator.video[this.state.selectedMetricWindowKey].accumulation.games.lookup)
                if (!games || !games.length) return []

                // const totalVideosCount = this.drawer.creator.video[state.selectedMetricWindowKey].accumulation.videos.count
                const totalVideosCount = _.sumBy(games, 'count')

                games = _.map(games, (game) => {
                    game.affinity = game.count / totalVideosCount
                    game.video_count = game.count
                    return game
                })
            }

            return _.orderBy(games, ['affinity'], ['desc'])
        },

        links() {
            if (this.drawer.creator && this.drawer.creator.platform_id === 'twitch') {
                const socialMedias = ['instagram_com', 'twitter_com', 'tiktok_com', 'facebook_com', 'youtube_com']

                const socials = []
                const other = []

                _.forEach(this.drawer.creator.metadata.socials, (value, key) => {
                    if (socialMedias.includes(key)) {
                        socials.push(_.assign(value, { key }))
                    } else {
                        other.push(value)
                    }
                })

                return {
                    socials,
                    other,
                }
            }

            return []
        },
    },

    methods: {
        numeral,

        onCloseDrawer() {
            this.$store.commit('drawer:set', {
                enabled: false,
                creator: null,
            })
        },

        /**
         * Translates seconds into human readable format of seconds, minutes, hours, days, and years
         *
         * @param  {number} seconds The number of seconds to be processed
         * @return {string}         The phrase describing the the amount of time
         */
        toHumanTime(seconds) {
             if (seconds < 3600) {
                 return `Less than an hour`
             }

            const levels = [
                [Math.floor(seconds / 31536000), 'years'],
                [Math.floor((seconds % 31536000) / 86400), 'days'],
                [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
                // [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
                // [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
            ]

            let returntext = ''

            for (let i = 0, max = levels.length; i < max; i++) {
                if (levels[i][0] === 0) continue;
                returntext += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length-1): levels[i][1])
            }

            return returntext.trim()
        },

        copyEmail() {
            var Url = this.$refs.email
            Url.innerHTML = window.location.href
            Url.select()
            document.execCommand("copy")
        },

        selectMetricWindow(metricWindowKey) {
            this.state.selectedMetricWindowKey = metricWindowKey
        },

        getPastCampaigns() {
            if (!this.drawer.creator) return

            this.axios.get(`/platforms/${this.drawer.creator.platform_id}/${this.drawer.creator._id}`, {
                params: {
                    relations: ['campaign', 'tasks', 'slots'],
                },
            }).then(({ data }) => {
                this.participants = data.payload.participants

                _.forEach(this.participants, (participant) => {
                    if (participant.campaign.type === 'steam-broadcast') {
                        participant.total_payout = _.sumBy(participant.slots, 'payout_amount')
                    } else {
                        participant.total_payout = _.sumBy(participant.tasks, 'payout_amount')
                    }
                })

            }).catch((error) => {
                console.error(error)
            })
        },
    }
}
</script>

<style lang="scss">
.drawer-frame {
    position: fixed;
    top: 0;
    right: 0;
    width: 20vw;
    min-width: 350px;
    // height: 100%;
    background: #2C2E3B;
    z-index: 1000;

    .drawer-wrapper, .drawer, .drawer-content {
        height: 100%;
    }

    .nav-pills .nav-item:not(:last-child) {
        padding-right: 0.5rem;
    }

    .trueprice-wrapper {
        z-index: 10;
        background: rgba(black, 0.25);
    }

    .sub-section-header {
        background: rgba(black, 0.25);
        // border-radius: 5px;
    }

    .btn-twitch {
        border-color: lighten(#6441a5, 10);
        color: lighten(#6441a5, 20);

        &:hover {
            border-color: lighten(#6441a5, 15);
            color: #000;
            background-color: lighten(#6441a5, 15);
        }
    }

    .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 20vh;
        background-position: center;
        background-size: cover;
        opacity: 0.5;

        &:before {
            content: '';
            width: 100%;
            height: 20vh;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(to top, #2C2E3B 0%, rgba(30, 32, 41, 0.8) 75%, rgba(30, 32, 41, 0.4) 100%);
        }
    }

    .close {
        cursor: pointer;
        position: absolute;
        right: 100%;
        background: rgba(white, 0.05);
        border-radius: 50%;
        height: 50px;
        width: 50px;
    }

    .header {
        height: 15%;
        position: relative;
    }

    .tabs {
        height: 80%;
    }

    .games-wrapper {
        overflow-y: scroll;

        .game {
            background-position: center;
            background-size: cover;
            border-radius: 5px;
            position: relative;
            overflow: hidden;

            &:before {
                content: "";
                background: linear-gradient(
                    90deg,
                    #1e2029 0,
                    rgba(30, 32, 41, 0.9) 50%,
                    rgba(30, 32, 41, 0.6)
                );
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

            .game-name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .drawer-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #21222c;
    }

    // .tabs-wrapper {
    //     ::-webkit-scrollbar {
    //         width: 8px;
    //         height: 8px;
    //     }
    // }
    //
    .nav-pills {
        // overflow-x: scroll;
        // flex-wrap: nowrap;
        // padding: 0;

        .nav-item {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
