<template>
    <div class="view view-campaign-participants">

        <div class="row">
            <div class="col-lg-3">
                <div class="row side-menu">
                    <div
                        class="col-lg-12 side-menu-option mt-1"
                        @click="menuItemSelected = status.name"
                        :class="{
                            'active': menuItemSelected === status.name
                        }"
                        v-for="status in statuses"
                    >
                        <div class="panel py-3 d-flex h-100">
                            <div class="avatar my-auto">
                                <font-awesome-icon :icon="getFromDictionary(`participant.status.${status.name}.icon`)" />
                            </div>

                            <div class="name-rank-wrapper d-flex flex-column my-auto ml-3">
                                <span class="h6 m-0">{{ getFromDictionary(`participant.status.${status.name}.title`) }}</span>
                            </div>

                            <div class="d-flex ml-auto">
                                <span class="h4 my-auto">{{ counts[status.name] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <!-- <div class="row row-panels mb-4">
                    <div class="col-lg-4">
                        <div class="panel p-3">
                            <h4 class="h5 pb-2 border-bottom border-light">Cumulative CCV</h4>
                            <p class="m-0 text-right">
                                <span class="font-weight-bold align-bottom">{{ totalCCV | numeral('0,0') }}</span>
                            </p>
                        </div>
                    </div>
                </div> -->

                <creator-table
                    :creators="menuItemSelected === 'all' ? creators : creatorsByStatus[menuItemSelected]"
                    type="participants"
                    @invite="onInvite"
                    @contacted="onContacted"
                    @remove-participants="onRemoveParticipants"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import CreatorTable from "@/components/Tables/CreatorTable"

export default {
    name: 'view-campaign-participants',

    metaInfo() {
        return {
            title: 'Campaign Participants'
        }
    },

    props: ['supercampaign'],

    components: {
        CreatorTable,
    },

    data() {
        return {
            creators: [],

            statuses: [{
                name: 'all',
                icon: ['fas', 'users'],
            }, {
                name: 'added',
                icon: ['fas', 'plus'],
            }, {
                name: 'contacted',
                icon: ['fas', 'comments'],
            }, {
                name: 'invited',
                icon: ['fas', 'envelope-open'],
            }, {
                name: 'registered',
                icon: ['fas', 'user-plus'],
            }],

            menuItemSelected: 'all',
        }
    },

    created() {
        this.getCampaignParticipants()
    },

    computed: {
        ...mapGetters([
            'getColors',
            'getFromDictionary',
        ]),

        creatorsByStatus() {
            return _.groupBy(this.creators, 'participant.status')
        },

        counts() {
            const counts = _.reduce(this.statuses, (result, status) => {
                const creators = this.creatorsByStatus[status.name]

                if (creators) {
                    result[status.name] = _.size(creators)
                } else {
                    result[status.name] = 0
                }

                return result
            }, {})

            counts.all = _.size(this.creators)
            return counts
        },

        totalCCV() {
            return _.sumBy(this.creators, 'superview.stream.day_30.aggregation.viewers.avg')
        },
    },

    methods: {
        getCampaignParticipants() {
            this.axios.get(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}/participants`).then(({ data }) => {
                this.creators = data.payload.creators
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching participants for campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Participants',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onRemoveParticipants(participantsIds) {
            const body = {
                participants_ids: participantsIds,
            }

            this.axios.delete(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}/participants`, {
                data: body,
            }).then(({ data }) => {
                this.creators = _.filter(this.creators, (creator) => {
                    return !body.participants_ids.includes(creator.participant.id)
                })

                this.$notify({
                    group: "global",
                    type: "success",
                    text: `Successfully removed ${data.payload.participants.count} participants and ${data.payload.tasks.count} tasks from campaign.`,
                    duration: 5000,
                    speed: 1000
                })
            }).catch((error) => {
                const message = error.message ? error.message : `Error removing participant from campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Remove Participant',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onInvite(participant) {
            this.axios.post(`/supercampaigns/${this.supercampaign.id}/participants/${participant.id}/invite`).then(({ data }) => {
                const oldParticipant = _.find(this.creators, item => item.participant.id === participant.id)
                _.assign(oldParticipant, { participant: data.payload.participant })

                if (this.menuItemSelected === 'added') {
                    this.menuItemSelected = 'invited'
                }
            }).catch((error) => {
                const message = error.message ? error.message : `Error creating invite for participant.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Participant Invite',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onContacted(participant) {
            this.axios.put(`/supercampaigns/${this.supercampaign.id}/participants/${participant.id}`, {
                participant: {
                    status: 'contacted',
                },
            }).then(({ data }) => {
                const oldParticipant = _.find(this.creators, item => item.participant.id === participant.id)
                _.assign(oldParticipant, { participant: data.payload.participant })
            }).catch((error) => {
                const message = error.message ? error.message : `Error setting participant status.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Participant Status Change',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },
    }
}
</script>

<style lang="scss">
.view-campaign-participants {
    .side-menu-option {
        border-radius: 5px;
        cursor: pointer;
        transition: 0.25s all;

        &:hover {
            background: rgba(white, 0.05);
        }

        &.active {
            background: rgba(white, 0.075)
        }
    }
}
</style>
