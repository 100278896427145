<template>
    <div class="view view-game-twitch-bookmarks">
        <div class="row">
            <div class="col-lg-3">
                <div class="row side-menu">
                    <div
                        class="col-lg-12 side-menu-option mt-1"
                        @click="menuItemSelected = group"
                        :class="{
                            'active': menuItemSelected === group
                        }"
                        v-for="bookmarks, group in bookmarksByStatus"
                    >
                        <div class="panel py-3 d-flex h-100 px-2">
                            <!-- <div class="avatar my-auto">
                                <font-awesome-icon :icon="" />
                            </div> -->

                            <div class="name-rank-wrapper d-flex flex-column my-auto">
                                <span class="h6 m-0">{{ group | capitalize }}</span>
                            </div>

                            <div class="d-flex ml-auto">
                                <span class="h4 my-auto">{{ bookmarks.length }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <bookmarks-table :items="bookmarksByStatus[menuItemSelected]" />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

import BookmarksTable from '@/components/Tables/BookmarksTable'

export default {
    name: 'view-game-twitch-bookmarks',

    metaInfo() {
        return {
            title: 'Live Streams'
        }
    },

    components: {
        BookmarksTable,
    },

    data() {
        return {
            bookmarks: [],

            lookup: {},

            actions: {
                selected: [],
            },

            menuItemSelected: 'all',
        }
    },

    created() {
        this.getGameTwitchBookmarks()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        bookmarksByStatus() {
            let result = {
                all: this.bookmarks,
            }

            // bookmarksByStatus = _.assign(bookmarksByStatus, _.groupBy(this.keys, 'marketplace_id'))
            return result
        },
    },

    methods: {
        getGameTwitchBookmarks() {
            this.axios.get(`/games/${this.$route.params.game_id}/twitch/bookmarks`).then(({ data }) => {
                this.bookmarks = data.payload.bookmarks
            })
        },
    }
}
</script>

<style lang="scss">
.view-game-twitch-bookmarks {
    .side-menu-option {
        border-radius: 5px;
        cursor: pointer;
        transition: 0.25s all;

        &:hover {
            background: rgba(white, 0.05);
        }

        &.active {
            background: rgba(white, 0.075)
        }
    }
}
</style>
