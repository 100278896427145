<template>
    <div class="creator-table-wrapper">
        <b-modal id="creators-remove" @ok="onRemoveCreators">
            <template #modal-footer="{ ok, cancel }">
                <b-button variant="outline-primary" @click="cancel()">
                    Cancel
                </b-button>
                <b-button variant="danger" @click="ok()">
                    Remove
                </b-button>
            </template>
            <p>Are you sure you want to remove the {{ actions.selected.length }} selected creators from the list?</p>
        </b-modal>

        <b-modal id="participants-remove" @ok="onRemoveParticipants">
            <template #modal-footer="{ ok, cancel }">
                <b-button variant="outline-primary" @click="cancel()">
                    Cancel
                </b-button>
                <b-button variant="danger" @click="ok()">
                    Remove
                </b-button>
            </template>
            <p>Are you sure you want to remove the {{ actions.selected.length }} selected participants from the campaign?</p>
        </b-modal>

        <div class="creator-table row row-panels overflow-auto">

            <!-- table header -->
            <div class="col-lg-12 mb-2 header">
                <div class="px-3 w-100 mb-2">
                    <div class="row no-gutters panel-overlay table-header">
                        <div class="col-lg-1 d-flex">
                            <b-form-checkbox
                                class="custom-control-inline m-auto"
                                v-model="actions.isAllSelected"
                                @change="onSelectAll"
                            />
                        </div>

                        <!-- Row Action Buttons -->
                        <div class="col d-flex" v-if="actions.selected.length">
                            <div class="campaign-select-wrapper position-relative" v-if="type === 'list'">
                                <dropdown-detailed
                                    itemTextProperty="title"
                                    :itemList="campaigns"
                                    :itemClick="onCampaignSelect"
                                    searchPlaceholder='Enter campaign name'
                                    secondaryItemTextProperty="type"
                                    game="payload"
                                >
                                    <button class="btn btn-sm btn-outline-primary">Add selected to Campaign ({{ actions.selected.length }})</button>
                                </dropdown-detailed>
                            </div>

                            <div class="campaign-select-wrapper position-relative ml-2" v-if="type === 'list'">
                                <dropdown-detailed
                                    itemTextProperty="title"
                                    :itemList="campaigns"
                                    :itemClick="onCampaignSelectAll"
                                    searchPlaceholder='Enter campaign name'
                                    secondaryItemTextProperty="type"
                                    game="payload.game.name"
                                >
                                    <button class="btn btn-sm btn-outline-primary">Add all to Campaign ({{ creators.length }})</button>
                                </dropdown-detailed>
                            </div>

                            <div class="campaign-select-wrapper position-relative" v-if="type === 'discover'">
                                <dropdown-text-filterable
                                    itemTextProperty="name"
                                    :itemList="lists"
                                    :itemClick="onListSelect"
                                    searchPlaceholder='Enter list name'
                                >
                                    <button class="btn btn-sm btn-outline-primary">Add to List ({{ actions.selected.length }})</button>
                                </dropdown-text-filterable>
                            </div>

                            <div class="campaign-select-wrapper position-relative ml-2" v-if="type === 'discover'">
                                <dropdown-text-filterable
                                    itemTextProperty="name"
                                    :itemList="lists"
                                    :itemClick="onListSelectAll"
                                    searchPlaceholder='Enter list name'
                                >
                                    <button class="btn btn-sm btn-outline-primary">Add all to List ({{ creators.length }})</button>
                                </dropdown-text-filterable>
                            </div>

                            <div class="remove-from-list-wrapper ml-2" v-if="type === 'list'">
                                <button
                                    class="btn btn-sm btn-outline-danger"
                                    v-b-modal="'creators-remove'"
                                >
                                    Remove selected from list ({{ actions.selected.length }})
                                </button>
                            </div>

                            <div class="remove-participants-wrapper ml-2" v-if="type === 'participants'">
                                <button
                                    class="btn btn-sm btn-outline-danger"
                                    v-b-modal="'participants-remove'"
                                >
                                    Remove participants
                                </button>
                            </div>
                        </div>

                        <!-- Header Fields -->
                        <div class="col-lg-4 d-flex" v-if="!actions.selected.length">
                            <h3 class="h6 my-auto">Creator</h3>
                        </div>
                        <div class="col d-flex" v-if="type === 'discover' && !actions.selected.length">
                            <h3 class="h6 my-auto">Affinity</h3>
                        </div>
                        <div class="col d-flex" v-if="type === 'participants' && !actions.selected.length">
                            <h3 class="h6 my-auto">Status</h3>
                        </div>
                        <!-- <div class="col d-flex" v-if="!actions.selected.length">
                            <h3 class="h6 my-auto" v-if="platform === 'twitch'">Avg Viewers</h3>
                            <h3 class="h6 my-auto" v-if="platform === 'youtube'">Avg Views</h3>
                        </div> -->
                        <!-- <div class="col d-flex" v-if="!actions.selected.length">
                            <h3 class="h6 my-auto">Engagement</h3>
                        </div> -->
                        <!-- <div class="col d-flex" v-if="!actions.selected.length">
                            <h3 class="h6 my-auto" v-if="platform === 'twitch'">Followers</h3>
                            <h3 class="h6 my-auto" v-if="platform === 'youtube'">Subscribers</h3>
                        </div> -->
                        <!-- <div class="col d-flex" v-if="type === 'participants' && !actions.selected.length">
                            <h3 class="h6 my-auto">Invite Link</h3>
                        </div> -->
                        <div class="col d-flex" v-if="type === 'participants' && !actions.selected.length">
                            <h3 class="h6 my-auto">Actions</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 p-0">
                <div class="row no-gutters">
                    <b-form-checkbox-group v-model="actions.selected" class="col-lg-12">
                        <div class="col-lg-12 mb-2" v-for="{ superview, participant, affinity } in creatorsOnPage">
                            <div class="panel p-3 w-100" @click="openCreatorDrawer(superview, participant, $event)">
                                <div class="row no-gutters panel-overlay">
                                    <div class="col-lg-1 d-flex">
                                        <b-form-checkbox
                                            v-if="type === 'list' || type === 'discover'"
                                            :value="superview._id"
                                            class="m-auto"
                                        />
                                        <b-form-checkbox
                                            v-if="type === 'participants'"
                                            :value="participant.id"
                                            class="m-auto"
                                        />
                                    </div>

                                    <div class="col-lg-4 d-flex">
                                        <div
                                            class="name-rank-avatar-wrapper my-auto d-flex"
                                            v-if="superview && superview.channel"
                                        >
                                            <b-img-lazy
                                                :src="participant.platform_user_logo"
                                                class="avatar my-auto"
                                                blank-color="#12141d"
                                                @native.onerror=""
                                            />

                                            <div
                                                class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                                                v-if="participant && participant.superview"
                                            >
                                                <span class="h6 m-0" v-if="platform === 'twitch'">
                                                    {{ participant.platform_user_name }}

                                                    <verified-badge
                                                        v-if="superview.channel.partner"
                                                        :platform="'twitch'"
                                                    />
                                                </span>

                                                <span class="h6 m-0" v-if="platform === 'youtube'">
                                                    {{ superview.channel.snippet.title }}
                                                </span>

                                                <small
                                                    v-if="superview.rank"
                                                    :style="{
                                                        color: getColors.ranks[superview.rank.name]
                                                    }"
                                                >
                                                    {{ superview.rank.name | capitalize }}
                                                </small>
                                                <small v-else>Unranked</small>
                                            </div>
                                        </div>
                                        <div
                                            class="name-rank-avatar-wrapper my-auto d-flex"
                                            v-else
                                        >
                                            <p class="my-auto">Missing data</p>
                                        </div>
                                    </div>
                                    <div class="col d-flex" v-if="type === 'discover'">
                                        <div class="acv-wrapper my-auto">
                                            <div class="affinity-streamer" v-if="affinity && affinity.streamer">
                                                <font-awesome-icon
                                                    :icon="['fas', 'microphone-alt']"
                                                    class="mr-1 font-muted"
                                                    v-tooltip="`Streamer Affinity`"
                                                />
                                                <span class="ml-1">{{ affinity.streamer.percent | numeral("0%") }}</span>
                                            </div>
                                            <div class="affinity-streamer" v-if="affinity && affinity.audience">
                                                <font-awesome-icon
                                                    :icon="['fas', 'users']"
                                                    class="mr-1 font-muted"
                                                    v-tooltip="`Audience Affinity`"
                                                />
                                                <span class="ml-1">{{ affinity.audience.percent | numeral("0%") }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col d-flex" v-if="type === 'participants'">
                                        <div class="status-wrapper my-auto">
                                            <b-badge variant="primary">{{ participant.status ? participant.status : 'Added' }}</b-badge>
                                        </div>
                                    </div>

                                    <!-- <div class="col d-flex">
                                        <div class="acv-wrapper my-auto" v-if="superview && superview.stream">
                                            <font-awesome-icon
                                                :icon="['fas', 'eye']"
                                                class="mr-1 font-muted"
                                            />
                                            <span class="ml-1" v-if="platform === 'twitch'">
                                                {{ superview.stream.day_90.aggregation.viewers.avg | numeral("0,0") }}
                                            </span>
                                            <span class="ml-1" v-if="platform === 'youtube'">
                                                {{ superview.video.day_90.aggregation.views.avg | numeral("0,0") }}
                                            </span>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col d-flex">
                                        <div class="engagement-wrapper my-auto">
                                            <font-awesome-icon
                                                :icon="['fas', 'comments']"
                                                class="mr-1 font-muted"
                                            />
                                            <span class="ml-1">{{ (superview.stream.day_90.aggregation.chatters.avg / superview.stream.day_90.aggregation.viewers.avg) | numeral("0%") }}</span>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col d-flex">
                                        <div class="followers-wrapper my-auto">
                                            <font-awesome-icon
                                                :icon="['fas', 'user-friends']"
                                                class="mr-1 font-muted"
                                            />
                                            <span class="ml-1" v-if="platform === 'twitch'">
                                                {{ superview.channel.followers | numeral("0,0") }}
                                            </span>
                                            <span class="ml-1" v-if="platform === 'youtube'">
                                                {{ superview.channel.statistics.subscriberCount | numeral("0a") }}
                                            </span>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col d-flex" v-if="type === 'participants'">
                                        <div class="invite-link-wrapper my-auto">
                                            <b-form-input
                                                size="sm"
                                                v-if="participant.status === 'invited'"
                                                :value="`https://app.streamforge.com/i/${participant.invite_code}`"
                                                class="invite-code"
                                                @click="onInviteLinkClick({ participant, superview })"
                                            />

                                            <b-button
                                                variant="primary"
                                                size="sm"
                                                @click="onContacted(participant)"
                                                v-if="participant.status === 'added'"
                                            >
                                                Set Contacted
                                            </b-button>

                                            <b-button
                                                variant="primary"
                                                size="sm"
                                                @click="onInvite(participant)"
                                                v-if="participant.status === 'contacted'"
                                            >
                                                Invite
                                            </b-button>
                                        </div>
                                    </div> -->
                                    <div class="col d-flex" v-if="type === 'participants'">
                                        <div class="view-wrapper my-auto">
                                            <router-link tag="button" class="btn btn-outline-primary btn-sm"
                                                :to="{
                                                    name: 'campaign-participant',
                                                    params: {
                                                        id: participant.id
                                                    }
                                                }"
                                            >
                                                View
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form-checkbox-group>
                </div>
            </div>

            <div class="col-lg-12 mb-2" v-if="creators === null">
                <div class="panel p-3 text-center">
                    Try searching creators with filters on the right!
                </div>
            </div>

            <div
                class="col-lg-12 mb-2"
                v-if="creators && creators.length === 0"
            >
                <div class="panel p-3 text-center">
                    No creators found with the provided filters.
                </div>
            </div>

            <!-- pagination controls -->
            <div class="col-lg-12 d-flex mt-2">
                <b-pagination
                    v-model="pagination.currentPage"
                    :total-rows="creators.length"
                    :per-page="pagination.perPage"
                    class="mx-auto"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VerifiedBadge from '@/components/Client/VerifiedBadge'
import DropdownTextFilterable from '@/components/Dropdowns/DropdownTextFilterable'
import DropdownDetailed from '@/components/Dropdowns/DetailedDropdown'


export default {
    name: 'CreatorTable',

    components: {
        VerifiedBadge,
        DropdownTextFilterable,
        DropdownDetailed
    },

    props: {
        creators: {
            type: Array,
            default: () => [],
        },
        filters: {
            type: Object,
        },

        /**
         * list
         * participants
         * discover
         */
        type: {
            type: String,
        },
        lists: {
            type: Array,
            default: () => [],
        },
        campaigns: {
            type: Array,
            default: () => [],
        },
        platform: {
            type: String,
            default: 'twitch',
        },
    },

    watch: {
        creators(newValue, oldValue) {

            // If any new creators are brought into this component through any means
            // unselected all creators
            this.actions.selected = []
            this.actions.isAllSelected = false
        },
    },

    computed: {
        ...mapGetters([
            'getColors'
        ]),

        filteredLists() {
            if (!this.lists) return []
            if (!this.lists.textFilter) return this.lists.values

            return _.filter(this.lists.values, item => {
                return item.name.includes(this.lists.textFilter)
            })
        },

        creatorsOnPage() {
            const { currentPage, perPage } = this.pagination
            const startIndex = (currentPage - 1) * perPage

            return this.creators.slice(startIndex, currentPage * perPage)
        },

        fields() {
            let fields = []

            if (this.platform === 'twitch') {
                fields.concat([{
                    key: 'followers',
                    label: 'Followers',
                }, {
                    key: 'avg_viewers',
                    label: 'Avg Viewers',
                }])
            }

            if (this.platform === 'youtube') {
                fields.concat([{
                    key: 'subscribers',
                    label: 'Subscribers',
                }, {
                    key: 'avg_views',
                    label: 'Avg Views',
                }])
            }
        },
    },

    data() {
        return {
            actions: {
                selected: [],
                isAllSelected: false,
            },

            pagination: {
                perPage: 20,
                currentPage: 1,
            },
        }
    },

    methods: {
        openCreatorDrawer(superview, participant, event) {
            const ignoreList = ['BUTTON', 'LABEL', 'INPUT']

            if (ignoreList.includes(event.target.tagName)) return

            if (this.type === 'participants') {
                // this.$store.commit('superdrawer:set', {
                //     enabled: true,
                //     creator: _.assign(superview, { platform_id: 'twitch' }),
                //     participant: participant,
                // })

                this.$store.commit('drawer:set', {
                    enabled: true,
                    creator: _.assign(superview, { platform_id: 'twitch' }),
                })
            } else {
                this.$store.commit('drawer:set', {
                    enabled: true,
                    creator: _.assign(superview, { platform_id: 'twitch' }),
                })
            }
        },

        onSelectAll(isChecked) {
            if (isChecked) {
                if (this.type === 'list' || this.type === 'discover') {
                    this.actions.selected = _.map(this.creatorsOnPage, 'superview._id')

                } else if (this.type === 'participants') {
                    this.actions.selected = _.map(this.creatorsOnPage, 'participant.id')
                }
            } else {
                this.actions.selected = []
            }
        },

        onRemoveCreatorsAll() {
            this.onRemoveCreators(true)
        },

        onRemoveCreators(isAll) {
            let creators = []

            if (isAll) {
                creators = _.map(this.creators, (creator) => {
                    return {
                        platform_user_id: creator.superview._id,
                        platform_id: 'twitch',
                    }
                })

            } else {
                creators = _.reduce(this.actions.selected, (results, platformUserId) => {
                    const creator = _.find(this.creators, creator => creator.list_creator.platform_user_id === platformUserId)

                    if (creator) {
                        results.push({
                            platform_user_id: creator.list_creator.platform_user_id,
                            platform_id: 'twitch',
                        })
                    }

                    return results
                }, [])
            }

            this.$emit('remove-creators', creators)
        },

        onRemoveParticipants() {
            this.$emit('remove-participants', this.actions.selected)
        },

        onCampaignSelectAll(campaign) {
            this.onCampaignSelect(campaign, true)
        },

        onCampaignSelect(campaign, isAll) {
            let creators = []

            if (isAll) {
                creators = _.map(this.creators, (creator) => {
                    return {
                        platform_user_id: creator.superview._id,
                        platform_id: 'twitch',
                    }
                })

            } else {
                creators = _.reduce(this.actions.selected, (results, platformUserId) => {
                    const creator = _.find(this.creators, (creator) => {
                        return creator.superview._id === platformUserId
                    })

                    if (creator) {
                        results.push({
                            platform_user_id: creator.superview._id,
                            platform_id: 'twitch',
                        })
                    }

                    return results
                }, [])
            }

            this.actions.selected = []
            this.actions.isAllSelected = false
            this.$emit('campaign-select', campaign, creators)
        },

        onListSelectAll(list) {
            this.onListSelect(list, true)
        },

        onListSelect(list, isAll) {
            let creators = []

            if (isAll) {
                creators = _.map(this.creators, (creator) => {
                    return {
                        platform_user_id: creator.superview._id,
                        platform_id: 'twitch',
                    }
                })

            } else {
                creators = _.reduce(this.actions.selected, (results, platformUserId) => {
                    const creator = _.find(this.creators, (creator) => {
                        return creator.superview._id === platformUserId
                    })

                    if (creator) {
                        results.push({
                            platform_user_id: creator.superview._id,
                            platform_id: 'twitch',
                        })
                    }

                    return results
                }, [])
            }

            this.actions.selected = []
            this.actions.isAllSelected = false
            this.$emit('list-select', list, creators)
        },

        onInvite(participant) {
            this.$emit('invite', participant)
        },

        onContacted(participant) {
            this.$emit('contacted', participant)
        },

        onInviteLinkClick({ participant, superview }) {
            const el = document.createElement('textarea')
            el.value = `https://app.streamforge.com/i/${participant.invite_code}`
            el.setAttribute('readonly', '')
            el.style.position = 'absolute'
            el.style.left = '-9999px'
            document.body.appendChild(el)
            const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)

            if (selected) {
                document.getSelection().removeAllRanges()
                document.getSelection().addRange(selected)
            }

            this.$notify({
                group: "global",
                type: "info",
                text: `Copied invite code for ${superview.channel.display_name} to clipboard!`,
                duration: 2500,
                speed: 1000
            })
        }
    },
}
</script>

<style lang="scss">
.creator-table-wrapper {
    .table-header {
        min-height: 30px;
    }

    .pagination-controls {
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
            padding: 0;
        }
    }

    &.row-panels .panel {
        transition: 0.25s all;

        &:hover {
            background: rgba(255, 255, 255, 0.075);
        }
    }

    .affinity-streamer, .affinity-audience {
        svg {
            min-width: 20px;
        }
    }

    .invite-link-wrapper {
        .invite-code {
            direction: rtl;
        }
    }
}
</style>
