import clickOutside from '../directives/click-outside'
import { VBTooltip } from 'bootstrap-vue'

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
    install(Vue) {
        Vue.directive('click-outside', clickOutside)
        Vue.directive('b-tooltip', VBTooltip)
    }
}

export default GlobalDirectives
