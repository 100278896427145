<template>
    <div v-if="!loading" class="page page-client-login m-0">
        <div class="row my-auto d-flex justify-content-center">
            <div class="col-lg-4 px-5 py-md bg-secondary">

                <template v-if="success">
                    <h1 class="display-1 text-center text-uppercase mb-4">Register</h1>

                    <base-alert class="font-weight-bold my-3" type="danger" v-if="errorMessage">
                        <div class="text-center">{{ errorMessage }}</div>
                        <ul v-if="errorList" class="mt-3">
                            <li v-for="error in errorList" :key="error">{{ error }}</li>
                        </ul>
                    </base-alert>

                    <div class="form-group">
                        <div class="d-flex">
                            <div class="mr-1">
                                <label class="form-label" for="firstName">First Name</label>
                                <input
                                    class="form-control mb-2"
                                    type="text"
                                    name="firstName"
                                    v-model="firstName"
                                    @keyup.enter="register"
                                    :disabled="isAdminRegistration"
                                >
                            </div>

                            <div class="ml-1">
                                <label class="form-label" for="lastName">Last Name</label>
                                <input
                                    class="form-control mb-2"
                                    type="text"
                                    name="lastName"
                                    v-model="lastName"
                                    @keyup.enter="register"
                                    :disabled="isAdminRegistration"
                                >
                            </div>
                        </div>

                        <label class="form-label" for="email">Email</label>
                        <input
                            class="form-control mb-2"
                            type="text"
                            name="email"
                            v-model="email"
                            @keyup.enter="register"
                            :disabled="isAdminRegistration"
                        >

                        <label class="form-label" for="password">Password</label>
                        <input
                            class="form-control mb-2"
                            type="password"
                            name="password"
                            v-model="password"
                            @keyup.enter="register"
                        >

                        <label class="form-label" for="confirmPassword">Confirm Password</label>
                        <input
                            class="form-control"
                            type="password"
                            name="confirmPassword"
                            v-model="confirmPassword"
                            @keyup.enter="register"
                        >
                    </div>

                    <div class="form-group mt-3">
                        <button
                            type="button"
                            class="btn btn-primary w-100"
                            @click="register"
                            :class="{ 'disabled': !this.password }"
                        >Register</button>
                    </div>
                </template>

                <template v-else>
                    <div class="text-center">
                        <font-awesome-icon
                            :icon="['fas', 'exclamation-triangle']"
                            class="display-2 text-danger"
                        />
                        <div class="mt-3 font-weight-bold text-muted">This registration code does not exist</div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'register',

    metaInfo() {
        return {
            title: "Register"
        }
    },

    data() {
        return {
            loading: true,
            success: true,
            isAdminRegistration: false,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            errorMessage: null,
            errorList: null
        }
    },

    created() {
        this.isAdminRegistration = true
        this.getOperator()

        /*
            ********* Use this code only if users are allowed to register on their own ***********

            IF there is a 'code' url query THEN the user was registered by an admin
            ELSE this is a normal registration

        if (this.$route.query.code) {
            this.isAdminRegistration = true
            this.getOperator()
        } else {
            this.loading = false
            this.isAdminRegistration = false
        }

        */
    },

    methods: {
        getOperator() {
            this.axios.post(`/operators/register/check`, {
                code: this.$route.query.code
            }).then(({ data }) => {
                this.firstName = data.first_name,
                this.lastName = data.last_name,
                this.email = data.email
                this.loading = false
            }).catch(({ response }) => {
                // Code doesn't work
                this.loading = false
                this.success = false
            })
        },

        register() {
            if (this.isAdminRegistration) {
                this.axios.post(`/operators/password`, {
                    code: this.$route.query.code,
                    newPassword: this.password,
                    confirmPassword: this.confirmPassword,
                    type: 'register'
                }).then(({ data }) => {
                    // Do something after successful registration
                    console.log(data)

                    this.$notify({
                        group: 'global',
                        type: 'success',
                        title: 'Success - Registered',
                        text: `Registration successful!`,
                        duration: 10000,
                        speed: 1000,
                    })

                    this.$router.push({ name: 'login' })
                }).catch(({ response }) => {
                    this.errorMessage = response.data.message
                    this.errorList = response.data.errors;
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.form-control:disabled {
    background-color: #08090c;
    color: grey;
    cursor: not-allowed;
}

</style>
