<template>
    <div class="page page-hero page-not-found">
        <section class="section section-xl my-5">
            <div class="container text-center">
                <h1 class="display-1">Not Found</h1>
                <p class="lead mt-0">Hey, there's nothing here!</p>

                <a href="/" class="btn btn-primary" @click.prevent="leave">Back</a>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    methods: {
        leave() {
            this.$router.push({ name: 'dashboard' })
        }
    }
}
</script>
<style lang="scss">
    .page-not-found {
        .btn-primary {
            color: #000;
        }
    }
</style>
