<template>
    <div class="game-keys-modal-wrapper">
        <b-modal id="game-keys-modal" @ok="onSubmit" @hide="onHide">
            <template #modal-title>
                <h1 class="h4 m-0">Add Game Keys</h1>
            </template>

            <template #modal-footer="{ ok }">
                <b-button :variant="type === 'delete' ? 'danger' : 'success'" @click="ok()">
                    {{ type === 'delete' ? 'Remove Game Key' : 'Add Game Keys' }}
                </b-button>
            </template>

            <form @submit.stop.prevent="onSubmit">
                <div class="row" v-if="type !== 'delete'">
                    <div class="col-lg-12">
                        <p class="m-0">Here you can add game keys for your game to a key pool.</p>
                    </div>

                    <div class="col-lg-12 mt-3">
                        <b-form-group label="Key Pool">
                            <b-form-select
                                v-model="values.group_id"
                                :options="groups"
                                value-field="id"
                                text-field="title"
                                required
                            />
                        </b-form-group>

                        <b-form-group label="Game Keys">
                            <b-form-textarea
                                v-model="values.keys"
                                placeholder="Paste keys 1 per line"
                                rows="5"
                                max-rows="10"
                            />
                        </b-form-group>
                    </div>
                </div>

                <div class="row" v-if="type === 'delete'">
                    <div class="col-lg-12">
                        <p>Are you sure you want to remove these keys from our system?</p>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'game-keys-modal',

    props: {
        type: {
            type: String,
            default: 'create',
        },
        groups: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            values: {
                group_id: null,
                keys: '',
            },
        }
    },

    created() {
    },

    methods: {
        onSubmit() {
            this.$emit('submit', this.type, this.values)
        },

        onHide(event) {
            this.$emit('close', event)
        },
    }
}
</script>

<style lang="scss">
.game-keys-modal-wrapper {
}
</style>
