import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import Router from "./router"

// Plugins
import Argon from "./plugins/argon-kit"
import FontAwesome from "./plugins/fontAwesome"
import Bootstrap from "./plugins/bootstrap"
import VTooltip from "v-tooltip"
import "v-tooltip/dist/v-tooltip.css"

// Dependencies
import VuexRouterSync from "vuex-router-sync"
// import LoadScript from 'vue-plugin-load-script'
import Velocity from "velocity-animate"
import Notifications from "vue-notification"

const router = Router(store)

VuexRouterSync.sync(store, router)
Vue.config.productionTip = false

Vue.use(Argon)
Vue.use(FontAwesome)
Vue.use(Bootstrap)
Vue.use(Notifications, { velocity: Velocity })
Vue.use(VTooltip)

Vue.config.devtools = true

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")
