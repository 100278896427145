<template>
    <footer class="footer">

    </footer>
</template>
<script>
export default {}
</script>
<style lang="scss">
.footer {
    display: flex;
    position: relative;
    flex-direction: column;
}
</style>
