export default {
    state: {
        campaign: {
            type: {
                'key-distribution': {
                    label: 'Key Distribution',
                    labelPlural: 'Key Distribution',
                    icon: ['fas', 'key'],
                },
                'game-sponsorship': {
                    label: 'Paid Twitch Stream',
                    labelPlural: 'Paid Twitch Streams',
                    icon: ['fab', 'twitch'],
                },
                'steam-broadcast': {
                    label: 'Steam Broadcast',
                    labelPlural: 'Steam Broadcasts',
                    icon: ['fab', 'steam']
                },
            },
            status: {
                'kickoff-unscheduled': {
                    label: 'Schedule Meeting',
                },
                'kickoff-scheduled': {
                    label: 'Meeting Scheduled',
                },
                'kickoff-completed': {
                    label: 'Completed Kickoff',
                },
                'discovery-targeting': {
                    label: 'Targeting Discovery',
                },
                'discovery-creators': {
                    label: 'Creator Discovery',
                },
                'execution-collection': {
                    label: 'Execution',
                },
                'reporting-aggregation': {
                    label: 'Generating Reports',
                },
                'reporting-delivery': {
                    label: 'Reports Delivered',
                },
            }
        },

        participant: {
            checklist: {
                groups: {
                    prospect: {
                        title: 'Prospect',
                        icon: ['fas', 'search'],
                        description: 'Creator identified as a good match for campaign. Not yet contacted.',
                    },
                    negotiation: {
                        title: 'Negotiation',
                        icon: ['fas', 'dollar-sign'],
                        description: 'Communication established, contract details are being worked out.'
                    },
                    onboarded: {
                        title: 'Onboarded',
                        icon: ['fas', 'sign-in-alt'],
                        description: 'Creator is registered on our dashboard, deliverables can be communicated.'
                    },
                },
                fields: {
                    contacted: 'Contacted',
                    sent_for_client_approval: 'Sent for client approval',
                    received_client_approval: 'Client approval received',
                    reply_received: 'Reply received',
                    rate_established: 'Rate established',
                    deliverables_confirmed: 'Deliverables confirmed',
                    contract_signed: 'Contract signed',
                    tasks_created: 'Tasks created',
                },
            },
            status: {
                all: {
                    title: 'All',
                    icon: ['fas', 'users'],
                },
                registered: {
                    title: 'Onboarded',
                    icon: ['fas', 'sign-in-alt']
                },
                added: {
                    title: 'Prospect',
                    icon: ['fas', 'search'],
                },
                prospect: {
                    title: 'Prospect',
                    icon: ['fas', 'search'],
                },
                contacted: {
                    title: 'Contacted',
                    icon: ['fas', 'comments']
                },
                invited: {
                    title: 'Invited',
                    icon: ['fas', 'envelope-open'],
                },
            },
        },
        task: {
            title: {
                'custom-text': 'Custom instructions',
                'retrieve-key': 'Retrieve key from vault',
                'twitch-stream': 'Perform Twitch stream',
                'youtube-video': 'Create YouTube video',
                'twitch-panel': 'Setup tracking',
            },
            icon: {
                'custom-text': ['fas', 'question'],
                'retrieve-key': ['fas', 'key'],
                'twitch-stream': ['fas', 'broadcast-tower'],
                'youtube-video': ['fab', 'youtube'],
                'twitch-panel': ['fas', 'chart-line'],
            },
        },
        platforms: {
            toAbbreviations: {
                twitch: 'tw',
                youtube: 'yt',
                twitter: 'tt',
                facebook: 'fb',
                instagram: 'ig',
            },
            fromAbbreviations: {
                tw: 'twitch',
                yt: 'youtube',
                tt: 'twitter',
                fb: 'facebook',
                ig: 'instagram',
            },
        },

        superview: {
            metricWindow: {
                day_30: '30 day',
                day_90: '90 day',
                day_180: '180 day',
                day_365: '365 day',
            },
        }
    },

    getters: {
        dictionary: (state) => {
            return state
        },

        getFromDictionary: (state) => (path, field) => {
            const item = _.get(state, path)

            if (field) {
                return item ? _.get(item, field) : ''
            }

            return item ? item : ''
        },
    }
}
