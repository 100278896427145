<template>
    <div class="view view-game-keys">
        <game-keys-modal
            @submit="onKeysModalSubmit"
            :groups="groups"
        />

        <game-key-group-modal
            @submit="onKeyGroupModalSubmit"
            :marketplaces="marketplaces"
            :consoles="consoles"
            :regions="regions"
        />

        <div class="row">
            <div class="col-lg-3">
                <div class="row side-menu">
                    <div class="col-lg-12 p-0 mb-2" v-if="groups && groups.length > 0">
                        <b-button block variant="primary" @click="onAddGameKeysClick">Add Keys</b-button>
                    </div>

                    <div class="col-lg-12 p-0 mb-3">
                        <b-button block variant="outline-primary" @click="onAddGameKeysGroupClick">Add Key Group</b-button>
                    </div>

                    <div
                        class="col-lg-12 side-menu-option mt-1"
                        @click="menuItemSelected = marketplace"
                        :class="{
                            'active': menuItemSelected === marketplace
                        }"
                        v-for="keys, marketplace in keysByStatus"
                    >
                        <div class="panel py-3 d-flex h-100 px-2">
                            <!-- <div class="avatar my-auto">
                                <font-awesome-icon :icon="" />
                            </div> -->

                            <div class="name-rank-wrapper d-flex flex-column my-auto">
                                <span class="h6 m-0">{{ marketplace | capitalize }}</span>
                            </div>

                            <div class="d-flex ml-auto">
                                <span class="h4 my-auto">{{ getUnclaimedCount(keys) }}/{{ keys ? keys.length : 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <keys-table :keys="keysByStatus[menuItemSelected]" @remove-keys="onRemoveKeys" />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

import GameKeysModal from '@/components/Modals/GameKeysModal'
import GameKeyGroupModal from '@/components/Modals/GameKeyGroupModal'
import KeysTable from '@/components/Tables/KeysTable'

export default {
    name: 'view-game-keys',

    metaInfo() {
        return {
            title: 'Game Keys'
        }
    },

    components: {
        GameKeysModal,
        GameKeyGroupModal,
        KeysTable,
    },

    data() {
        return {
            fields: [{
                key: 'id',
                label: 'ID',
            }, {
                key: 'marketplace_id',
                label: 'Marketplace',
            }, {
                key: 'console_id',
                label: 'Console',
            }, {
                key: 'region_id',
                label: 'Region Lock',
            }],

            groups: [],
            keys: [],
            marketplaces: [],
            consoles: [],
            regions: [],

            lookup: {},

            actions: {
                selected: [],
            },

            menuItemSelected: 'all',
        }
    },

    created() {
        this.getGameKeyGroups()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        keysByStatus() {
            let keysByStatus = {
                all: this.keys,
                // claimed: _.filter(this.keys, item => item.claimed_at),
            }

            _.forEach(this.groups, (group) => {
                keysByStatus[group.title] = group.keys
            })

            // keysByStatus = _.assign(keysByStatus, _.groupBy(this.keys, 'marketplace_id'))
            return keysByStatus
        },

        groupsById() {
            return _.keyBy(this.groups, 'id')
        },
    },

    methods: {
        getUnclaimedCount(keys) {
            return _.size(_.filter(keys, key => !key.claimed_at))
        },

        getGameKeys() {
            return this.axios.get(`/games/${this.$route.params.game_id}/keys`).then(({ data }) => {
                this.keys = data.payload.keys
                this.consoles = data.payload.consoles
                this.marketplaces = data.payload.marketplaces
                this.regions = data.payload.regions

                this.lookup.consoles = _.keyBy(data.payload.consoles, 'id')
                this.lookup.marketplaces = _.keyBy(data.payload.marketplaces, 'id')
                this.lookup.regions = _.keyBy(data.payload.regions, 'id')
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching game keys.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Game Keys',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        getGameKeyGroups() {
            return this.axios.get(`/games/${this.$route.params.game_id}/keys/groups`).then(({ data }) => {
                this.consoles = data.payload.consoles
                this.marketplaces = data.payload.marketplaces
                this.regions = data.payload.regions
                this.groups = data.payload.groups

                this.keys = _.reduce(data.payload.groups, (results, group) => {
                    results.push(...group.keys)

                    return results
                }, [])

                this.lookup.consoles = _.keyBy(data.payload.consoles, 'id')
                this.lookup.marketplaces = _.keyBy(data.payload.marketplaces, 'id')
                this.lookup.regions = _.keyBy(data.payload.regions, 'id')
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching game key groups.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Game Key Groups',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onKeysModalSubmit(type, values) {
            const keyGroup = _.find(this.groups, item => item.id === values.group_id)

            if (!keyGroup) {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Game Keys Upload',
                    text: `No pool found to upload keys to.`,
                    duration: 10000,
                    speed: 1000
                })
            }

            this.axios.post(`/games/${this.$route.params.game_id}/keys`, {
                // Split text input by new line and filter out empty strings
                keys: values.keys.split(/\r?\n/).filter(item => item),

                // Metadata associated with each key in set
                type: keyGroup.type,
                console_id: keyGroup.console_id,
                marketplace_id: keyGroup.marketplace_id,
                region_id: keyGroup.region_id,
                group_id: values.group_id,
            }).then(({ data }) => {
                keyGroup.keys.push(...data.payload.keys)
                this.keys.push(...data.payload.keys)
            }).catch((error) => {
                const message = error.message ? error.message : `Error uploading keys for game.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Game Keys Upload',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onKeyGroupModalSubmit(type, values) {
            this.axios.post(`/games/${this.$route.params.game_id}/keys/groups`, {

                // Metadata associated with each key in set
                title: values.title,
                description: values.description,
                type: values.type,
                console_id: values.console_id,
                marketplace_id: values.marketplace_id,
                region_id: values.region_id,
            }).then(({ data }) => {
                this.groups.push(data.payload.group)
                this.getGameKeyGroups().then(() => {
                    this.menuItemSelected = data.payload.group.title
                })
            }).catch((error) => {
                const message = error.message ? error.message : `Error creating new key pool.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Key Pool Creation',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onAddGameKeysClick() {
            this.$bvModal.show('game-keys-modal')
        },

        onAddGameKeysGroupClick() {
            this.$bvModal.show('game-key-group-modal')
        },

        onRemoveKeys(keys) {

            const body = {
                game_keys: keys,
            }

            this.axios.delete(`/games/${this.$route.params.game_id}/keys`, {
                data: body,
            }).then(({ data }) => {
                this.keys = _.filter(this.keys, (key) => {
                    return !body.game_keys.includes(key.id)
                })

                _.forEach(this.groups, (group) => {
                    group.keys = _.filter(group.keys, (key) => {
                        return !body.game_keys.includes(key.id)
                    })
                })

                this.$notify({
                    group: "global",
                    type: "success",
                    text: `Successfully removed ${data.payload.count} game keys.`,
                    duration: 5000,
                    speed: 1000
                })
            }).catch((error) => {
                const message = error.message ? error.message : `Error removing game key.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Remove Game Key',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        }
    }
}
</script>

<style lang="scss">
.view-game-keys {
    .side-menu-option {
        border-radius: 5px;
        cursor: pointer;
        transition: 0.25s all;

        &:hover {
            background: rgba(white, 0.05);
        }

        &.active {
            background: rgba(white, 0.075)
        }
    }
}
</style>
