<template>
    <div class="view view-campaign-participant mb-md">
        <task-modal
            v-if="campaign"
            :campaign="campaign"
            :creator="modal.creator"
            :task="modal.task"
            @submit="onModalSubmit"
            @close="onModalClose"
        />

        <b-modal
            id="task-delete-modal"
            @ok="onTaskDeleteConfirm"
            @close="this.deletedTaskId = null"
            @cancel="this.deletedTaskId = null"
        >
            <template #modal-footer="{ ok, cancel }">
                <b-button variant="outline-primary" @click="cancel()">
                    Cancel
                </b-button>
                <b-button variant="danger" @click="ok()">
                    Delete
                </b-button>
            </template>
            <p>Are you sure you want to delete this task?</p>
        </b-modal>

        <div class="row row-meta pb-4">
            <div class="col-lg-3 d-flex">
                <div class="d-flex my-auto p-3 header" v-if="superview">
                    <div class="name-rank-avatar-wrapper my-auto d-flex">
                        <img
                            v-if="participant.platform_id === 'twitch'"
                            :src="superview.channel.logo"
                            class="avatar avatar-lg my-auto"
                        />

                        <img
                            v-if="participant.platform_id === 'youtube'"
                            :src="superview.channel.snippet.thumbnails.medium.url"
                            class="avatar avatar-xl my-auto"
                        />

                        <div class="name-rank-wrapper d-flex flex-column my-auto ml-3">
                            <h2 class="h6 m-0" v-if="participant.platform_id === 'twitch'">
                                {{ superview.channel.display_name }}

                                <verified-badge
                                    v-if="superview.channel.partner"
                                    :platform="'twitch'"
                                />
                            </h2>

                            <h2 class="h4 m-0" v-if="participant.platform_id === 'youtube'">
                                {{ superview.channel.snippet.title }}
                            </h2>

                            <p
                                v-if="superview.rank"
                                class="m-0"
                                :style="{ color: getColors.ranks[superview.rank.name] }"
                            >{{ superview.rank.name | capitalize }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3" v-if="superview && participant.platform_id === 'twitch'">
                <div class="row h-100">
                    <div class="col-lg-5 d-flex">
                        <div class="my-auto">
                            <small>Total Cost</small>
                            <h4 class="h4 m-0 text-muted">${{ totalCost | numeral('0,0.00') }}</h4>
                        </div>
                    </div>

                    <div class="col-lg-7 d-flex">
                        <div class="my-auto">
                            <small>TruePrice</small>
                            <h4 class="h4 m-0 text-muted">${{ superview.stream.day_90.aggregation.viewers.avg * 0.75 | numeral('0,0') }}/hr</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6" v-if="superview && participant.platform_id === 'twitch'">
                <div class="row h-100">
                    <div class="col-lg-4 d-flex">
                        <div class="my-auto">
                            <small>AVG Viewers</small>
                            <h4 class="h4 m-0 text-muted">{{ superview.stream.day_90.aggregation.viewers.avg | numeral('0,0') }}</h4>
                        </div>
                    </div>

                    <div class="col-lg-4 d-flex">
                        <div class="my-auto">
                            <small>Followers</small>
                            <h4 class="h4 m-0 text-muted">{{ superview.channel.followers | numeral('0.0a') }}</h4>
                        </div>
                    </div>

                    <div class="col-lg-4 d-flex">
                        <div class="my-auto">
                            <small>AVG Impressions</small>
                            <h4 class="h4 m-0 text-muted">{{ superview.stream.day_90.aggregation.impressions.avg | numeral('0,0') }}</h4>
                        </div>
                    </div>

                    <div class="col-lg-4 d-flex mt-2">
                        <div class="my-auto">
                            <small>AVG Stream Duration</small>
                            <h4 class="h4 m-0 text-muted">{{ superview.stream.day_90.aggregation.live_seconds.avg / 3600 | numeral('0.0') }} hours</h4>
                        </div>
                    </div>

                    <div class="col-lg-4 d-flex mt-2">
                        <div class="my-auto">
                            <small>Days Streamed</small>
                            <h4 class="h4 m-0 text-muted">{{ superview.stream.day_90.accumulation.days_streamed.count | numeral('0,0') }}/90</h4>
                        </div>
                    </div>

                    <div class="col-lg-4 d-flex mt-2">
                        <div class="my-auto">
                            <small>Games per Stream</small>
                            <h4 class="h4 m-0 text-muted">{{ superview.stream.day_90.accumulation.chapters.count / superview.stream.day_90.accumulation.streams.count | numeral('0,0.0') }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-panels mt-sm" v-if="participant">
            <div class="col-lg-3" v-if="campaign.type === 'game-sponsorship'">
                <h4 class="h4 mb-0">Checklist</h4>
                <p class="description">To help keep track where we are in the process.</p>

                <div class="row row-statuses">
                    <div class="col-lg-12 mb-2" v-for="(fields, groupKey) in checklist.groups">
                        <div class="participant-status d-flex p-3">
                            <div class="avatar avatar-sm mt-2 mb-auto">
                                <font-awesome-icon :icon="getFromDictionary(`participant.checklist.groups.${groupKey}.icon`)" />
                            </div>

                            <div class="name-rank-wrapper d-flex flex-column my-auto ml-3">
                                <span class="h6 m-0">{{ getFromDictionary(`participant.checklist.groups.${groupKey}.title`) }}</span>
                                <small class="mb-2">{{ getFromDictionary(`participant.checklist.groups.${groupKey}.description`) }}</small>

                                <b-form-checkbox
                                    v-for="field in fields"
                                    :checked="checklist.values[field].value"
                                    @change="onChecklistChange(field, $event)"
                                    :disabled="!checklist.state[field].is_enabled"
                                >
                                    {{ getFromDictionary(`participant.checklist.fields.${field}`) }}
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <h3 class="h4 mb-0">Tasks</h3>
                <p class="description">These are the deliverables the creator is responsible for.</p>

                <div class="row row-panels row-tasks">
                    <div class="col-lg-6 mb-3" v-for="task in orderBy(tasks, 'title', 1)">
                        <div class="panel h-100 p-3">
                            <div class="row">
                                <div class="col-lg-8">
                                    <h5 class="h5 mb-3">
                                        <font-awesome-icon :icon="getFromDictionary(`task.icon.${task.type}`)" class="mr-2 text-light" />
                                        <span>{{ getFromDictionary(`task.title.${task.type}`) }}</span>
                                    </h5>
                                </div>

                                <div class="col-lg-4 d-flex">
                                    <div class="action-view-wrapper ml-auto px-2">
                                        <a @click="onTaskViewClick(task)" class="text-primary mr-2 cursor-pointer">
                                            <font-awesome-icon :icon="['fas', 'edit']" />
                                        </a>

                                        <a @click="onTaskDeleteClick(task)" class="text-danger cursor-pointer">
                                            <font-awesome-icon :icon="['fas', 'trash']" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div v-if="task.type === 'retrieve-key'">
                                <small>Key Status</small>
                                <p class="m-0" v-if="task.payload.key_id">
                                    <font-awesome-icon class="mr-2 text-success" :icon="['fas', 'check']" /><span class="text-muted">Claimed</span>
                                </p>

                                <div class="m-0" v-else>
                                    <font-awesome-icon class="mr-2 text-danger" :icon="['fas', 'times']" /><span class="text-muted">Unclaimed</span>
                                </div>
                            </div>

                            <div class="mt-2" v-if="task.type === 'retrieve-key'">
                                <small>Key Group</small>
                                <p class="m-0 text-muted">{{ task.payload.group_id && getKeyGroup(task.payload.group_id) ? getKeyGroup(task.payload.group_id).title : 'None' }}</p>
                            </div>

                            <div v-if="task.type === 'twitch-stream'">
                                <small>Payout</small>
                                <p class="m-0 text-muted">${{ task.payout_amount | numeral('0,0.00') }}</p>
                            </div>

                            <div class="mt-2" v-if="task.type === 'twitch-stream'">
                                <small>Hours Hired</small>
                                <p class="m-0 text-muted">{{ task.payload.duration_seconds / (60 * 60) }}</p>
                            </div>

                            <div v-if="task.type === 'twitch-panel' && link">
                                <small>Tracking Link</small>
                                <p class="text-muted">https://streaml.ink/{{ link.path }}</p>

                                <small>Panel Image URL</small>
                                <div v-if="!!campaign.payload.attribution.panel_image_url">
                                    <p class="text-muted" >{{ campaign.payload.attribution.panel_image_url }}</p>
                                </div>
                                <div v-else>
                                    <p class="text-danger">Panel image missing from campaign</p>
                                </div>

                                <div class="d-flex">
                                    <div class="w-50">
                                        <small>Unique Clicks</small>
                                        <h4 class="h4 m-0 text-muted mb-2">{{ link.unique_clicks | numeral('0,0') }}</h4>
                                    </div>

                                    <div class="w-50">
                                        <small>Total Clicks</small>
                                        <h4 class="h4 m-0 text-muted">{{ link.hits | numeral('0,0') }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mb-3 new-task-wrapper d-flex">
                        <div class="new-task-inner inner d-flex w-100 cursor-pointer" @click.prevent="onTaskNewClick">
                            <h5 class="m-auto">
                                <font-awesome-icon :icon="['fas', 'plus']" class="mr-1" />
                                New Task
                            </h5>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="row">
                    <div class="col-lg-12 mb-sm">
                        <h4 class="h4 mb-1">Internal Notes</h4>
                        <p class="description">These notes are not visible to the content creator.</p>

                        <b-form-textarea
                            v-model="participant.notes"
                            placeholder="Enter something..."
                            rows="6"
                            max-rows="12"
                            @input="isNotesChanged = true"
                        ></b-form-textarea>

                        <div class="d-flex mt-2">
                            <b-button variant="primary" size="sm" class="ml-auto" @click="updateParticipantNotes" :disabled="!isNotesChanged">Save</b-button>
                        </div>
                    </div>

                    <div class="col-lg-12" v-if="campaign.type === 'game-sponsorship'">
                        <h4 class="h4 mb-1">Campaign Invite</h4>
                        <p class="description">This invite guides the creator through registration and links their Streamforge account to our backend systems.</p>

                        <b-form-group
                            v-if="participant.invite_code"
                            label="Invite Link"
                            description="This is the invite that was sent to the creator. In the event it was lost in transit, send them it directly."
                        >
                            <b-form-input
                                type="text"
                                :value="`https://app.streamforge.com/i/${participant.invite_code}`"
                            />
                        </b-form-group>

                        <div class="send-invite-wrapper" v-if="!participant.invite_code">
                            <b-form @submit="onCampaignInviteSubmit">
                                <div class="d-flex">
                                    <b-form-group
                                        label="Creator Email"
                                        description="This will send an email with an invite to the creator from no-reply@streamforge.com, you can check SendGrid to see the template."
                                    >
                                        <div class="d-flex">
                                            <b-form-input type="text" v-model="invite.email" />
                                            <b-button variant="primary" class="ml-2" type="submit" :disabled="invite.email === ''">Send</b-button>
                                        </div>
                                    </b-form-group>
                                </div>
                            </b-form>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-3" v-if="campaign.type === 'steam-broadcast'">
                <h4 class="h4 mb-1">Steam Slots</h4>
                <p class="description">Broadcast slots the creator was chosen for.</p>

                <div class="row row-panels">
                    <div class="col-lg-12" v-for="slot in orderBy(slots, 'started_at', 1)">
                        <div class="panel p-3">
                            <div class="row">
                                <div class="col-lg-12">
                                    <small class="font-weight-bold">Date</small>
                                    <p class="m-0">{{ slot.start_at | moment('dddd, MMMM Do, YYYY') }}</p>
                                </div>
                                <div class="col-lg-6 mt-2">
                                    <small class="font-weight-bold">Start</small>
                                    <p class="m-0">{{ slot.start_at | moment('hh:mm A') }}</p>
                                </div>
                                <div class="col-lg-6 mt-2">
                                    <small class="font-weight-bold">End</small>
                                    <p class="m-0">{{ slot.end_at | moment('hh:mm A') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import VerifiedBadge from '@/components/Client/VerifiedBadge'
import TaskModal from '@/components/Modals/TaskModal'
import Vue2Filters from 'vue2-filters'

export default {
    name: 'view-campaign-participant',

    metaInfo() {
        return {
            title: 'Campaign Participants'
        }
    },

    props: {
        supercampaign: {
            type: Object,
            required: true,
        },
    },

    components: {
        VerifiedBadge,
        TaskModal,
    },

    mixins: [Vue2Filters.mixin],

    data() {
        return {
            campaign: null,
            participant: null,
            superview: null,
            game: null,
            keyGroups: null,
            link: null,
            tasks: null,
            slots: null,

            checklist: {
                groups: {
                    prospect: ['contacted', 'sent_for_client_approval', 'received_client_approval'],
                    negotiation: ['reply_received', 'rate_established', 'deliverables_confirmed', 'contract_signed'],
                    onboarded: ['tasks_created'],
                },
                values: {},
                state: {},
            },

            invite: {
                email: '',
            },

            modal: {
                task: null,
                creator: null,
            },

            isNotesChanged: false,
            deletedTaskId: null,
        }
    },

    created() {
        this.initializeChecklist()
        this.getParticipant()
    },

    computed: {
        ...mapGetters([
            'getColors',
            'getFromDictionary',
        ]),

        totalCost() {
            if (this.campaign) {
                if (this.campaign.type === 'game-sponsorship' && this.tasks) {
                    return _.sumBy(this.tasks, 'payout_amount')
                }

                if (this.campaign.type === 'steam-broadcast' && this.slots) {
                    return _.sumBy(this.slots, 'payout_amount')
                }
            }

            return 0
        },
    },

    methods: {
        initializeChecklist() {
            _.forEach(this.checklist.groups, (fields, groupKey) => {
                _.forEach(fields, (field) => {
                    this.checklist.values[field] = {
                        is_irrelevant: false,
                        value: false,
                    }

                    this.checklist.state[field] = {
                        is_enabled: true,
                    }
                })
            })
        },

        getParticipant(id) {
            this.axios.get(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}/participants/${this.$route.params.id}`, {
                params: {
                    relations: ['tasks', 'link', 'campaign', 'game', 'key_groups', 'slots'],
                },
            }).then(({ data }) => {
                this.keyGroups = _.get(data, 'payload.participant.campaign.game.key_groups')
                this.game = data.payload.participant.campaign.game
                this.campaign = data.payload.participant.campaign
                this.participant = data.payload.participant
                this.superview = data.payload.participant.superview
                this.link = data.payload.participant.link
                this.tasks = data.payload.participant.tasks
                this.slots = data.payload.participant.slots

                const checklist = _.get(this.participant, 'payload.checklist')
                if (checklist) this.checklist.values = _.assign(this.checklist.values, checklist)

                const email = _.get(this.superview, 'metadata.emails[0]')
                if (email) this.invite.email = email
            }).catch((error) => {
                if (error.is_cancelled) return

                console.error(`Error fetching participant`, error)

                const message = error.message
                    ? error.message
                    : `There was an error fetching participant.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Fetch Participant',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        getKeyGroup(groupId) {
            if (!this.keyGroups) return null
            return _.find(this.keyGroups, item => item.id === groupId)
        },

        updateParticipantNotes() {
            this.axios.put(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}/participants/${this.participant.id}`, {
                participant: {
                    notes: this.participant.notes,
                },
            }).then(({ data }) => {
                this.isNotesChanged = false

                this.$notify({
                    group: 'global',
                    type: 'success',
                    title: 'Success - Notes Updated',
                    text: `Notes was successfully updated!`,
                    duration: 10000,
                    speed: 1000
                })
            }).catch((error) => {
                if (error.is_cancelled) return

                console.error(`Error updating participant notes`, error)

                const message = error.message
                    ? error.message
                    : `There was an error updating notes.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Update Notes',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onChecklistChange(key, event) {
            this.checklist.values[key].value = event

            if (!this.checklist.values.hasOwnProperty(key)) {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Invalid Checklist State',
                    text: `Please contact an administrator regarding this issue.`,
                    duration: 10000,
                    speed: 1000
                })
            }

            this.axios.put(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}/participants/${this.participant.id}`, {
                participant: {
                    payload: {
                        checklist: this.checklist.values,
                    },
                },
            }).then(({ data }) => {
                this.$notify({
                    group: 'global',
                    type: 'success',
                    title: 'Success - Checklist Updated',
                    text: `Checklist was successfully updated!`,
                    duration: 10000,
                    speed: 1000
                })
            }).catch((error) => {
                if (error.is_cancelled) return
                this.checklist.values[key].value = !event

                console.error(`Error updating checklist`, error)

                const message = error.message
                    ? error.message
                    : `There was an error updating the checklist.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Update Checklist',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onTaskDeleteClick(task) {
            this.deletedTaskId = task.id
            this.$bvModal.show('task-delete-modal')
        },

        onTaskDeleteConfirm() {
            this.axios.delete(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}/tasks/${this.deletedTaskId}`).then(({ data }) => {
                // Find the task object to be deleted
                const deletedTask = _.find(this.tasks, item => item.id === this.deletedTaskId)

                // Remove it from tasks
                this.$nextTick(() => {
                    this.tasks = _.filter(this.tasks, (task) => {
                        return task.id !== this.deletedTaskId
                    })
                })

                // Reset the task that is marked for deletion, as it has been deleted at this point
                this.deletedTaskId = null

                this.$notify({
                    group: 'global',
                    type: 'success',
                    title: 'Task Deleted',
                    text: 'The task was successfully deleted.',
                    duration: 10000,
                    speed: 1000
                })

            }).catch((error) => {
                const message = error.message
                    ? error.message
                    : `There was an error deleting the task.`

                console.error(`Error deleting task`, error)

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Delete Task',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onModalSubmit(state, data) {
            console.log(state, data)

            if (state === 'update') {
                this.updateTask(data)
            }

            if (state === 'create') {
                this.createTask(data)
            }
        },

        onModalClose() {
            this.modal.task = null
            this.modal.creator = null
        },

        updateTask(data) {
            this.axios.put(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}/tasks/${data.task_id}`, {
                task: data.task,
            }).then(({ data }) => {
                const task = data.payload.task

                const existingTask = _.find(this.tasks, item => item.id === task.id)
                _.assign(existingTask, task)

                const creator = _.find(this.creatorsWithTasks, item => item.participant.id === task.participant_id)

                if (creator) {
                    const existingTaskOnCreator = _.find(creator.tasks, item => item.id === task.id)
                    _.assign(existingTaskOnCreator, task)
                }

            }).catch((error) => {
                const message = error.message
                    ? error.message
                    : `There was an error updating the task.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Update Task',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        createTask(data) {
            this.axios.post(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}/tasks`, data).then(({ data }) => {
                this.tasks.push(...data.payload.tasks)

            }).catch((error) => {
                const message = error.message
                    ? error.message
                    : `There was an error creating a new task.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Create Task',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onTaskNewClick() {
            this.modal.task = null
            this.modal.creator = {
                superview: this.superview,
                participant: this.participant,
            }
            this.$bvModal.show('task-modal')
        },

        onTaskViewClick(task) {
            this.modal.task = _.assign(_.assign({}, task), {
                superview: this.superview,
                participant: this.participant,
            })

            this.modal.creator = {
                superview: this.superview,
                participant: this.participant,
            }

            this.$bvModal.show('task-modal')
        },

        onCampaignInviteSubmit(event) {
            event.preventDefault()

            if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.invite.email))) {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Invite',
                    text: `Please enter a valid email address.`,
                    duration: 10000,
                    speed: 1000,
                })

                return
            }

            this.axios.post(`/supercampaigns/${this.supercampaign.id}/campaigns/${this.$route.params.campaign_id}/participants/${this.participant.id}/invite`, {
                email: this.invite.email,
            }).then(({ data }) => {
                this.participant = _.assign(this.participant, data.payload.participant)

                this.$notify({
                    group: 'global',
                    type: 'success',
                    title: 'Success - Campaign Invite Sent',
                    text: `Campaign invite successfully sent!`,
                    duration: 10000,
                    speed: 1000
                })
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message
                    ? error.message
                    : `There was an error sending the campaign invite email.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Invite',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },
    }
}
</script>

<style lang="scss">
.view-campaign-participant {
    .row-meta {
        border-bottom: 1px solid rgba(white, 0.05);
    }

    .row-statuses {
        .participant-status {
            border: 1px solid rgba(white, 0.05);
            border-radius: 12px;
            transition: 0.25s all;

            .avatar {
                min-width: 38px;
                transition: 0.25s all;
            }

            &:hover {
                border: 1px solid rgba(white, 0.1);

                small {
                    color: #fff;
                }

                .avatar {
                    background-color: #fff;
                }
            }
        }
    }

    .row-tasks {
        .new-task-wrapper {
            .new-task-inner {
                border: 1px dashed rgba(white, 0.05);
                border-radius: 12px;
                transition: 0.25s all;
                min-height: 200px;

                h5 {
                    color: rgba(white, 0.75);
                    transition: 0.25s all;
                }

                &:hover {
                    border: 1px dashed rgba(white, 0.1);

                    h5 {
                        color: rgba(white, 0.9);
                    }
                }
            }
        }
    }
}
</style>
