<template>
    <div class="dropdown-text-filterable-wrapper">
        <b-dropdown
            :variant="variant"
            class="dropdown-light ml-auto mb-0 p-0 dropdown-toggle-icon-only"
            no-caret
            :right="alignment === 'right' ? true : false"
            @shown="onShown"
        >
            <template #button-content>
                <slot></slot>
            </template>

            <b-form-input
                v-model="text"
                :placeholder="searchPlaceholder"
                autocomplete="off"
                @keyup="search ? debouncedSearch(search, text) : null"
                ref="textSearchInput"
            />

            <div class="results-wrapper">
                <b-dropdown-item
                    v-for="item in itemListInView"
                    @click="handleClick(item)"
                    :title="item[`${itemTextProperty}`]"
                >
                    {{ item[`${itemTextProperty}`] }}
                </b-dropdown-item>
            </div>
        </b-dropdown>
    </div>
</template>

<script>
import _ from 'lodash'
export default {
    name: 'DropdownTextFilterable',
    props: {
        itemTextProperty: {
            type: String,
        },
        itemClick: {
            type: Function,
            required: true,
        },
        itemList: {
            type: Array,
            required: true,
        },
        search: {
            type: Function,
        },
        searchPlaceholder: {
            type: String,
            default: 'Enter text to search',
        },
        variant: {
            type: String,
            default: 'link',
        },
        alignment: {
            type: String,
            default: 'right',
        },
    },
    data() {
        return {
            text: '',
        }
    },
    computed: {
        itemListInView() {
            if (!this.text) return this.itemList
            if (!this.search) {
                return _.filter(this.itemList, (item) => {
                    return item[this.itemTextProperty].toLowerCase().includes(this.text.toLowerCase())
                })
            }
            return this.itemList
        },
    },

    // mounted() {
    //     this.search()
    // },

    methods: {
        debouncedSearch: _.debounce((search, text) => {
            search(text)
        }, 500),
        handleClick(item) {
            this.itemClick(item)
            this.text = ''
            if (this.search) {
                this.search()
            }
        },
        onShown() {
            if (this.$refs.textSearchInput) {
                this.$refs.textSearchInput.focus()
            }
        },
    }
}
</script>

<style lang="scss">
.dropdown-text-filterable-wrapper {
}
</style>
