<template>
    <div class="view view-game-twitch-chapters">
        <div class="row">
            <div class="col-lg-3">
                <div class="row">
                    <div class="col-lg-12">
                        <h3 class="h5 m-0">Filters</h3>
                    </div>

                    <div class="col-lg-12 mt-4">
                        <label>Minimum ACV ({{ filters.min_acv | numeral('0,0') }})+</label>
                        <b-form-input type="range" v-model="filters.min_acv" min="0" max="1000" />
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <div class="row row-date-ranges m-0 row-panels mb2">
                    <div class="col" v-for="(range, index) in weekRanges" @click="onRangeClick('week', index, range.is_valid)">
                        <div class="panel p-2 d-flex flex-column" :class="{ 'active': index === weekRangeSelected }">
                            <div class="h5 day text-center font-weight-bold m-0">
                                {{ range.dayOfMonthEnd | numeral('00') }} - {{ range.dayOfMonthStart | numeral('00') }}
                            </div>
                            <div class="day-of-week text-center">
                                {{ range.monthEnd }} - {{ range.monthStart }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row row-date-ranges m-0 row-panels mb-md">
                    <div class="col" v-for="(range, index) in dayRanges" @click="onRangeClick('day', index, range.is_valid)">
                        <div
                            class="panel p-2 d-flex flex-column"
                            :class="{
                                'active': range === activeDayRange,
                                'disabled': !range.is_valid,
                            }"
                        >
                            <div class="h5 day text-center font-weight-bold m-0">
                                {{ range.day }}
                            </div>
                            <div class="day-of-week text-center">
                                {{ range.dayOfWeekShort }}
                            </div>
                        </div>
                    </div>
                </div>

                <chapters-table
                    :filters="filters"
                    :game-id="$route.params.game_id"
                    :date-range-start="activeDayRange.start"
                    :date-range-end="activeDayRange.end"
                />
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

import ChaptersTable from '@/components/Tables/ChaptersTable'

export default {
    name: 'view-game-twitch-chapters',

    metaInfo() {
        return {
            title: 'Streams'
        }
    },

    components: {
        ChaptersTable,
    },

    data() {
        return {
            chapters: [],

            menuItemSelected: 'all',

            filters: {
                min_acv: 0,
            },

            dayRangeSelected: null,
            weekRangeSelected: 0,
        }
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        activeDayRange() {
            if (this.dayRangeSelected) {
                return this.dayRanges[this.dayRangeSelected]
            }

            return _.find(this.dayRanges, item => item.is_valid)
        },

        dayRanges() {
            if (!this.weekRanges || !this.weekRanges.length) return []

            const now = moment()
            let dateIterator = moment(this.weekRanges[this.weekRangeSelected].end)

            const ranges = []

            for (let i = 0; i < 7; i++) {
                ranges.push({
                    index: i,
                    start: dateIterator.clone().startOf('day').format(),
                    end: dateIterator.clone().endOf('day').format(),
                    dayOfWeekShort: dateIterator.format('ddd'),
                    day: dateIterator.date(),
                    is_valid: now.isAfter(dateIterator.clone().startOf('day')),
                })

                dateIterator = dateIterator.clone().subtract(1, 'days')
            }

            return ranges
        },

        weekRanges() {
            let dateIterator = moment()
            const ranges = []

            for (let i = 0; i < 4; i++) {
                ranges.push({
                    start: dateIterator.clone().startOf('week').format(),
                    end: dateIterator.clone().endOf('week').format(),
                    dayOfMonthStart: dateIterator.clone().startOf('week').date(),
                    dayOfMonthEnd: dateIterator.clone().endOf('week').date(),
                    monthStart: dateIterator.clone().startOf('week').format('MMM'),
                    monthEnd: dateIterator.clone().endOf('week').format('MMM'),
                })

                dateIterator = dateIterator.clone().subtract(7, 'days')
            }

            return ranges
        },

        chaptersByStatus() {
            let result = {
                all: this.chapters,
            }

            // chaptersByStatus = _.assign(chaptersByStatus, _.groupBy(this.keys, 'marketplace_id'))
            return result
        },
    },

    methods: {
        onRangeClick(rangeType, index, isValid) {
            if (isValid === false) return

            if (rangeType === 'week') {
                this.weekRangeSelected = index
                this.dayRangeSelected = null
            }

            if (rangeType === 'day') {
                this.dayRangeSelected = index
            }
        }
    }
}
</script>

<style lang="scss">
.view-game-twitch-chapters {
    .side-menu-option {
        border-radius: 5px;
        cursor: pointer;
        transition: 0.25s all;

        &:hover {
            background: rgba(white, 0.05);
        }

        &.active {
            background: rgba(white, 0.075)
        }
    }

    .row-date-ranges {
        .col {
            padding: 0.25rem;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }

            .panel {
                transition: 0.25s all;
                opacity: 0.6;

                &:hover {
                    &:not(.disabled) {
                        background: rgba(white, 0.075);
                        opacity: 1;

                        &:not(.active) {
                            cursor: pointer;
                        }
                    }
                }

                &.active {
                    background: rgba(white, 0.075);
                    opacity: 1;
                }

                &.disabled {
                    opacity: 0.2;
                }
            }
        }
    }
}
</style>
