<template>
    <div class="view view-campaign-deliverables">
        <player-modal :show="modals.twitchPlayer.is_shown" @close="modals.twitchPlayer.is_shown = false" v-if="modals.twitchPlayer.is_shown">
            <twitch-player
                v-if="modals.twitchPlayer.video_id"
                :video="modals.twitchPlayer.video_id"
                :default-seek="modals.twitchPlayer.offset_seconds"
                :autoplay="true"
                quality="chunked"
            />
        </player-modal>

        <div class="row row-queue-items no-gutters" v-if="values.participants">
            <div
                class="col-lg-2"
                v-for="(participant, index) in values.participants"
                @click="onParticipantClick(participant)"
            >
                <div
                    class="queue-item p-3 d-flex"
                    :class="{
                        'active': state.activeParticipantId === participant.id
                    }"
                >
                    <font-awesome-icon :icon="['fab', participant.platform_id]" class="mr-2 my-auto" :style="{ color: colors.socials[participant.platform_id] }" />
                    <span>{{ participant.platform_user_name }}</span>
                    <font-awesome-icon :icon="['fas', 'check']" class="text-success ml-auto my-auto" v-if="getIncompleteTaskCount(participant.tasks) === 0" />
                    <span class="text-primary font-weight-bold ml-auto" v-else>{{ getIncompleteTaskCount(participant.tasks) }}</span>
                </div>
            </div>
        </div>

        <div class="row row-panels mt-sm" v-if="activeParticipant">
            <div class="col-lg-12 mb-sm">
                <h2>{{ activeParticipant.platform_user_name }}</h2>
                <p>There is currently <span class="text-primary font-weight-bold">{{ activeParticipant.tasks.length }}</span> task with unconfirmed deliverables for this creator.</p>

                <b-tabs pills v-model="state.activeTaskIndex">
                    <b-tab :title="getFromDictionary(`task.title.${task.type}`)" active v-for="(task, index) in activeParticipant.tasks">

                        <!-- Selected deliverables -->
                        <div class="row mt-md mb-sm">
                            <div class="col-lg-3">
                                <h4 class="m-0">Task Details</h4>
                                <p>Information about the task the creator was given.</p>

                                <div class="panel p-3">
                                    <div class="d-flex">
                                        <div class="w-50" v-if="task.type === 'twitch-stream'">
                                            <small>Hours hired for</small>
                                            <p>{{ task.payload.duration_seconds / 3600 }} Hours</p>
                                        </div>
                                        <div class="w-50">
                                            <small>Payout</small>
                                            <p>${{ task.payout_amount | numeral('0,0.00') }}</p>
                                        </div>
                                    </div>

                                    <div class="d-flex">
                                        <div class="w-50">
                                            <small>Campaign Started At</small>
                                            <p>{{ campaign.started_at | moment('MMM DD, YYYY h:mm A') }}</p>
                                        </div>
                                        <div class="w-50">
                                            <small>Campaign Ended At</small>
                                            <p>{{ campaign.ended_at | moment('MMM DD, YYYY h:mm A') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <h4 class="m-0">Selected Deliverables</h4>
                                <p class="w-50">These were determined to be part of the creator's deliverables.</p>
                            </div>
                        </div>

                        <div class="row" v-if="task.type === 'twitch-stream' && task.payload && task.payload.deliverables && task.payload.deliverables.length > 0">
                            <div
                                class="col-lg-3 mb-sm"
                                v-for="deliverable in task.payload.deliverables"
                            >
                                <div class="panel-outline p-3">
                                    <div class="d-flex flex-column">
                                        <div class="d-flex">
                                            <div class="w-50">
                                                <small>Started At</small>
                                                <p>{{ deliverable.started_at | moment('MMM DD, YYYY h:mm A') }}</p>
                                            </div>
                                            <div class="w-50">
                                                <small>Ended At</small>
                                                <p>{{ deliverable.ended_at | moment('MMM DD, YYYY h:mm A') }}</p>
                                            </div>
                                        </div>

                                        <div class="d-flex">
                                            <div class="w-50">
                                                <small>Duration</small>
                                                <p>{{ toHumanTime(deliverable.duration_seconds) }}</p>
                                            </div>
                                            <div class="w-50">
                                                <small>Order in Stream</small>
                                                <p>{{ deliverable.index + 1 | numeral('0o') }}</p>
                                            </div>
                                        </div>

                                        <small>Game Name</small>
                                        <p>{{ deliverable.game_name }}</p>

                                        <div class="chapter-header-wrapper">
                                            <small>Stream Title</small>
                                            <p>{{ deliverable.title }}</p>
                                        </div>

                                        <div class="d-flex mt-auto">
                                            <b-button
                                                variant="outline-primary"
                                                class="mr-1 flex-fill"
                                                v-if="deliverable.video"
                                                @click="onViewVodClick(deliverable.video, deliverable)"
                                            >
                                                View VOD
                                            </b-button>
                                            <b-button
                                                variant="danger"
                                                class="ml-1 flex-fill"
                                                @click="onSelectDeliverable(task, deliverable, 'remove')"
                                            >
                                                Remove
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="task.type === 'youtube-video' && task.payload && task.payload.deliverables && task.payload.deliverables.length > 0">
                            <div
                                class="col-lg-3 mb-sm"
                                v-for="deliverable in task.payload.deliverables"
                            >
                                <div class="panel-outline p-3 h-100">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-flex w-100 mb-2">
                                            <a :href="`https://youtube.com/watch?v=${deliverable.id}`" target="_BLANK" class="w-100">
                                                <img
                                                    :src="deliverable.snippet.thumbnails.medium.url" class="w-100 rounded"
                                                >
                                            </a>
                                        </div>

                                        <div class="d-flex">
                                            <div class="w-50">
                                                <small>Published At</small>
                                                <p>{{ deliverable.snippet.publishedAt | moment('MMM DD, YYYY h:mm A') }}</p>
                                            </div>
                                            <div class="w-50">
                                                <small>Duration</small>
                                                <p>{{ deliverable.contentDetails.duration.replace('PT', '').replace('H', 'H ').replace('M', 'M ') }}</p>
                                            </div>
                                        </div>

                                        <div class="chapter-header-wrapper">
                                            <small>Video Title</small>
                                            <p>{{ deliverable.snippet.title }}</p>
                                        </div>

                                        <div class="d-flex mt-auto">
                                            <!-- <b-button
                                                variant="outline-primary"
                                                class="mr-1 flex-fill"
                                                v-if="chapter.deliverable"
                                                @click="onViewVodClick(chapter.deliverable, deliverable)"
                                            >
                                                View
                                            </b-button> -->
                                            <b-button
                                                variant="primary"
                                                class="ml-1 flex-fill"
                                                @click="onSelectDeliverable(task, deliverable, 'add')"
                                                v-if="!isDeliverableSelected(task, deliverable._id)"
                                            >
                                                Select
                                            </b-button>
                                            <b-button
                                                variant="danger"
                                                class="ml-1 flex-fill"
                                                @click="onSelectDeliverable(task, deliverable, 'remove')"
                                                v-if="isDeliverableSelected(task, deliverable._id)"
                                            >
                                                Remove
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="row" v-if="!task.payload || !task.payload.deliverables || task.payload.deliverables.length === 0">
                            <div class="col-lg-3" >
                                <div class="panel-outline p-3 flex-fill panel-dashed d-flex flex-column h-100 py-5 text-center">
                                    <font-awesome-icon :icon="['fas', 'question']" size="2x" class="mx-auto mb-2" />
                                    <span>No selected deliverable</span>
                                </div>
                            </div>
                        </div>


                        <!-- Display YouTube Deliverable Options -->
                        <div class="row mt-md" v-if="task.type === 'youtube-video'">
                            <div class="col-lg-6 mb-sm">
                                <div class="panel-outline p-3 mb-sm h-100">
                                    <h4 class="m-0">YouTube Videos</h4>
                                    <p class="">
                                        Below is a list of all videos from the content creator 1 week before and after the campaign.
                                        Outliers are included to cover edge cases. Use the filters to narrow down the videos shown.
                                    </p>

                                    <b-form-checkbox
                                        v-model="filters.chapters.is_campaign_window_only"
                                    >Only videos during campaign window</b-form-checkbox>

                                    <b-form-checkbox
                                        v-model="filters.chapters.is_campaign_game_only"
                                    >Only videos of campaign game</b-form-checkbox>
                                </div>
                            </div>

                            <div
                                class="col-lg-3 mb-sm"
                                v-if="filteredDeliverableOptions && filteredDeliverableOptions.length"
                                v-for="video in filteredDeliverableOptions"
                            >
                                <div class="panel-outline p-3 h-100">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-flex w-100 mb-2">
                                            <a :href="`https://youtube.com/watch?v=${video.id}`" target="_BLANK" class="w-100">
                                                <img
                                                    :src="video.snippet.thumbnails.medium.url" class="w-100 rounded"
                                                >
                                            </a>
                                        </div>

                                        <div class="d-flex">
                                            <div class="w-50">
                                                <small>Published At</small>
                                                <p>{{ video.snippet.publishedAt | moment('MMM DD, YYYY h:mm A') }}</p>
                                            </div>
                                            <div class="w-50">
                                                <small>Duration</small>
                                                <p>{{ video.contentDetails.duration.replace('PT', '').replace('M', 'M ') }}</p>
                                            </div>
                                        </div>

                                        <div class="chapter-header-wrapper">
                                            <small>Video Title</small>
                                            <p>{{ video.snippet.title }}</p>
                                        </div>

                                        <div class="d-flex mt-auto">
                                            <!-- <b-button
                                                variant="outline-primary"
                                                class="mr-1 flex-fill"
                                                v-if="chapter.video"
                                                @click="onViewVodClick(chapter.video, video)"
                                            >
                                                View
                                            </b-button> -->
                                            <b-button
                                                variant="primary"
                                                class="ml-1 flex-fill"
                                                @click="onSelectDeliverable(task, video, 'add')"
                                                v-if="!isDeliverableSelected(task, video._id)"
                                            >
                                                Select
                                            </b-button>
                                            <b-button
                                                variant="danger"
                                                class="ml-1 flex-fill"
                                                @click="onSelectDeliverable(task, video, 'remove')"
                                                v-if="isDeliverableSelected(task, video._id)"
                                            >
                                                Remove
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="row mt-md" v-if="task.type === 'twitch-stream'">
                            <div class="col-lg-6 mb-sm">
                                <div class="panel-outline p-3 mb-sm h-100">
                                    <h4 class="m-0">Twitch Streams</h4>
                                    <p class="">
                                        Below is a list of all streams from the content creator 1 week before and after the campaign.
                                        Outliers are included to cover edge cases. Use the filters to narrow down the streams shown.
                                    </p>

                                    <b-form-checkbox
                                        v-model="filters.chapters.is_campaign_window_only"
                                    >Only streams during campaign window</b-form-checkbox>

                                    <b-form-checkbox
                                        v-model="filters.chapters.is_campaign_game_only"
                                    >Only streams of campaign game</b-form-checkbox>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-sm">
                               <div class="panel-outline p-3 mb-sm h-100">
                                   <h4 class="m-0">Stream Backfill</h4>
                                   <p class="">In the rare event that we missed a stream you can paste a Sullygnome link below to import it into the ETL system.</p>

                                   <div class="d-flex mt-3">
                                       <b-form-input v-model="state.backfill.link" @keyup.enter="backfillStream" placeholder="Example: https://sullygnome.com/channel/jorbs/365/stream/40005508091" />
                                       <b-button variant="primary" size="sm" class="ml-1" @click="backfillStream">Import</b-button>
                                   </div>
                               </div>
                           </div>

                            <div class="col-lg-3 mb-sm" v-if="filteredDeliverableOptions && filteredDeliverableOptions.length" v-for="chapter in filteredDeliverableOptions">
                                <div class="panel-outline p-3 h-100">
                                    <div class="d-flex flex-column h-100">
                                        <div class="d-flex">
                                            <div class="w-50">
                                                <small>Started At</small>
                                                <p>{{ chapter.started_at | moment('MMM DD, YYYY h:mm A') }}</p>
                                            </div>
                                            <div class="w-50">
                                                <small>Ended At</small>
                                                <p>{{ chapter.ended_at | moment('MMM DD, YYYY h:mm A') }}</p>
                                            </div>
                                        </div>

                                        <div class="d-flex">
                                            <div class="w-50">
                                                <small>Duration</small>
                                                <p>{{ toHumanTime(chapter.duration_seconds) }}</p>
                                            </div>
                                            <div class="w-50">
                                                <small>Order in Stream</small>
                                                <p>{{ chapter.index + 1 | numeral('0o') }}</p>
                                            </div>
                                        </div>

                                        <small>Game Name</small>
                                        <p>{{ chapter.game_name }}</p>

                                        <div class="chapter-header-wrapper">
                                            <small>Stream Title</small>
                                            <p>{{ chapter.title }}</p>
                                        </div>

                                        <div class="d-flex mt-auto">
                                            <b-button
                                                variant="outline-primary"
                                                class="mr-1 flex-fill"
                                                v-if="chapter.video"
                                                @click="onViewVodClick(chapter.video, chapter)"
                                            >
                                                View VOD
                                            </b-button>
                                            <b-button
                                                variant="primary"
                                                class="ml-1 flex-fill"
                                                @click="onSelectDeliverable(task, chapter, 'add')"
                                                v-if="!isDeliverableSelected(task, chapter._id)"
                                            >
                                                Select
                                            </b-button>
                                            <b-button
                                                variant="danger"
                                                class="ml-1 flex-fill"
                                                @click="onSelectDeliverable(task, chapter, 'remove')"
                                                v-if="isDeliverableSelected(task, chapter._id)"
                                            >
                                                Remove
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import PlayerModal from '@/components/Modals/PlayerModal'
import TwitchPlayer from '@/components/Media/TwitchPlayer'

export default {
    name: 'view-campaign-deliverables',

    metaInfo() {
        return {
            title: 'Campaign Deliverables'
        }
    },

    components: {
        PlayerModal,
        TwitchPlayer,
    },

    props: {
        campaign: {
            type: Object,
        },
    },

    data() {
        return {
            activeTask: null,

            filters: {
                chapters: {
                    is_campaign_window_only: true,
                    is_campaign_game_only: true,
                },
            },

            values: {
                participants: null,
            },

            state: {
                backfill: {
                    link: '',
                },

                activeTaskId: null,
                activeTaskIndex: null,
                activeParticipantId: null,
            },

            modals: {
                twitchPlayer: {
                    video_id: null,
                    is_shown: false,
                    offset_seconds: 0,
                },
            },
        }
    },

    created() {
        this.getParticipantsWithTasks()
    },

    computed: {
        ...mapGetters([
            'colors',
            'getFromDictionary',
        ]),

        // activeTask() {
        //     if (!this.state.activeParticipantId) return null
        //     // if (!this.values.participants) return null
        //     // if (!this.state.activeTaskId) return null
        //     // if (!this.activeParticipant.tasks) return null
        //
        //     const activeParticipant = _.find(this.values.participants, item => item.id === this.state.activeParticipantId)
        //
        //     if (!activeParticipant || !activeParticipant.tasks || !activeParticipant.tasks.length) return null
        //
        //     return _.find(activeParticipant.tasks, item => item.id === this.state.activeTaskId)
        // },

        filteredDeliverableOptions() {
            if (!this.activeTask) return []

            if (this.activeTask.type === 'twitch-stream') {
                return _.filter(_.get(this.activeTask, 'deliverable.values.chapters'), (chapter) => {
                    let isChapterInvalid = false

                    if (this.filters.chapters.is_campaign_window_only === true) {
                        const isStartWithinWindow = moment.utc(chapter.started_at).isBetween(moment.utc(this.campaign.started_at), moment.utc(this.campaign.ended_at))
                        const isEndWithinWindow = moment.utc(chapter.ended_at).isBetween(moment.utc(this.campaign.started_at), moment.utc(this.campaign.ended_at))
                        if (!isStartWithinWindow && !isEndWithinWindow) return false
                    }

                    if (this.filters.chapters.is_campaign_game_only === true) {
                        if (this.campaign.game.name !== chapter.game_name) return false
                    }

                    return true
                })
            }

            if (this.activeTask.type === 'youtube-video') {
                return _.get(this.activeTask, 'deliverable.values.videos')
            }
        },

        activeParticipant() {
            if (!this.state.activeParticipantId) return null
            if (!this.values.participants) return null

            return _.find(this.values.participants, item => item.id === this.state.activeParticipantId)
        },
    },

    watch: {
        state(newState, oldState) {
            if (newState.state.activeTaskIndex !== oldState.activeTaskIndex) {
                if (!newState.state.activeParticipantId) return null

                const activeParticipant = _.find(this.values.participants, item => item.id === newState.state.activeParticipantId)

                if (!activeParticipant || !activeParticipant.tasks || !activeParticipant.tasks.length) return null

                return activeParticipant.tasks[newState.activeTaskIndex]
            }
        },
    },

    methods: {
        backfillStream() {
            if (!this.state.backfill.link) {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Stream Backfill',
                    text: `Please provide a valid sullygnome link.`,
                    duration: 10000,
                    speed: 1000
                })

                return
            }

            this.axios.post(`/backfill/stream/sullygnome`, {
                sullygnome_url: this.state.backfill.link,
            }).then(({ data }) => {
                this.getParticipantsWithTasks()
            }).catch((error) => {
                if (error.is_cancelled) return
                const responseErrorMessage = _.get(error.response.data.message)
                let message = `Error backfilling that stream from Sullygnome.`
                if (responseErrorMessage) message = responseErrorMessage

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Stream Backfill',
                    text: message,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        getIncompleteTaskCount(tasks) {
            return _.reduce(tasks, (count, task) => {
                const taskDeliverables = _.get(task, 'payload.deliverables')

                if (!taskDeliverables || !taskDeliverables.length) {
                    count++
                }

                return count
            }, 0)
        },

        isDeliverableSelected(task, chapterId) {
            if (!task.payload.deliverables) return false
            return !!_.find(task.payload.deliverables, item => item._id === chapterId)
        },

        onSelectDeliverable(task, chapter, action = 'add') {
            let deliverables = task.payload.deliverables
            if (!deliverables) deliverables = []

            if (action === 'add') {
                deliverables.push(chapter)
            } else if (action === 'remove') {
                deliverables = _.filter(deliverables, item => item.id !== chapter.id)
            }

            this.axios.put(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.$route.params.campaign_id}/tasks/${task.id}`, {
                task: {
                    approved_at: moment.utc().format(),
                    payload: {
                        deliverables,
                    },
                },
            }).then(({ data }) => {
                _.assign(task, data.payload.task)

                this.$notify({
                    group: 'global',
                    type: 'success',
                    title: 'Success - Update deliverables',
                    text: `Deliverables successfully updated for task.`,
                    duration: 10000,
                    speed: 1000
                })
            }).catch((error) => {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Update deliverables',
                    text: `There was an error updating the deliverables for that task.`,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        onViewVodClick(video, chapter) {
            this.modals.twitchPlayer.video_id = video.id

            let secondsOffset = 0

            // If the chapter is not the first in the stream, we need to determine it's offset by summing previous chapters
            if (chapter.index > 0) {
                const otherChaptersFromStream = _.filter(_.get(this.activeTask, 'deliverable.values.chapters'), item => item.stream_id === chapter.stream_id)
                const otherChaptersBeforeCurrent = _.slice(otherChaptersFromStream, 0, chapter.index)
                secondsOffset = _.sumBy(otherChaptersBeforeCurrent, 'duration_seconds')
                console.log({ video, chapter, otherChaptersFromStream, otherChaptersBeforeCurrent, secondsOffset })
            }

            this.modals.twitchPlayer.offset_seconds = secondsOffset

            // this.$bvModal.show('twitch-player')
            this.modals.twitchPlayer.is_shown = true
        },

        onParticipantClick(participant) {
            this.state.activeParticipantId = participant.id
            this.getParticipantDeliverables(participant)
        },

        getParticipantDeliverables(participant) {
            this.state.activeTaskIndex = null

            this.axios.get(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.$route.params.campaign_id}/participants/${participant.id}/deliverables`).then(({ data}) => {

                // We are overwriting the tasks field with a version that includes
                // possible deliverable options to choose from
                participant.tasks = _.map(data.payload.tasks, (task) => {
                    if (!task.payload) task.payload = {}
                    return task
                })

                this.state.activeTaskIndex = 0
                this.activeTask = participant.tasks[0]

            }).catch((error) => {
                if (error.is_cancelled) return

                let errorMessage = `Error fetching campaign deliverables.`

                if (_.get(error, 'response.data.message')) {
                    errorMessage = _.get(error, 'response.data.message')
                }

                console.error(errorMessage, error)

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign',
                    text: errorMessage,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        getParticipantsWithTasks() {
            this.axios.get(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.$route.params.campaign_id}/participants/tasks`).then(({ data }) => {
                this.values.participants = data.payload.participants
                // this.videosByStreamId = _.keyBy(_.flatten(data.payload.videos), 'stream_id')
                // this.game = data.payload.game
                // this.chaptersByChannelId = data.payload.chaptersByChannelId
                // this.superviewsById = data.payload.superviewsById

                // this.queue = _.orderBy(data.payload.deliverableQueue, ['superview.channel.name'], ['asc'])

            }).catch((error) => {
                if (error.is_cancelled) return

                let errorMessage = `Error fetching campaign deliverables.`

                if (_.get(error, 'response.data.message')) {
                    errorMessage = _.get(error, 'response.data.message')
                }

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign',
                    text: errorMessage,
                    duration: 10000,
                    speed: 1000
                })
            })
        },

        /**
         * Translates seconds into human readable format of seconds, minutes, hours, days, and years
         *
         * @param  {number} seconds The number of seconds to be processed
         * @return {string}         The phrase describing the the amount of time
         */
        toHumanTime(seconds) {
             if (seconds < 3600) {
                 return `Less than an hour`
             }

            const levels = [
                [Math.floor(seconds / 31536000), 'years'],
                [Math.floor((seconds % 31536000) / 86400), 'days'],
                [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
                // [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
                // [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
            ]

            let returntext = ''

            for (let i = 0, max = levels.length; i < max; i++) {
                if (levels[i][0] === 0) continue;
                returntext += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length-1): levels[i][1])
            }

            return returntext.trim()
        },
    },
}
</script>

<style lang="scss">
.view-campaign-deliverables {
    .row-queue-items {
        .queue-item {
            border: 1px solid rgba(white, 0.05);
            cursor: pointer;
            transition: 0.25s all;

            &.active {
                background: rgba(white, 0.025);
            }

            &:hover {
                background: rgba(white, 0.05);
                color: #fff;
            }
        }
    }

    .panel-outline {
        border: 1px solid rgba(white, 0.05);
        border-radius: 12px;

        &.panel-primary {
            border: 1px solid rgba(#fcd878, 0.5);
        }

        &.panel-dashed {
            border: 1px dashed rgba(white, 0.05);
            display: flex;
        }
    }
}
</style>
