<template>
    <div class="view view-campaign-applications">
        <div class="row row-panels row-slots">
            <div
                class="col-lg-2 slot"
                v-for="(slots, slotDay) in slotsByDay"
            >
                <div
                    class="panel hoverable clickable p-3 h-100"
                    :class="{
                        'active': slotDay === selectedSlotDay
                    }"
                    @click="onSlotDayClick(slotDay)"
                >
                    <div class="row">
                        <div class="col-lg-12">
                            {{ slotDay | moment('ddd MMM Do YYYY') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-lg-3">
                <div class="row row-panels row-slots">
                    <div
                        class="col-lg-12 slot mb-2"
                        v-for="slot in slotsByDay[selectedSlotDay]"
                    >
                        <div
                            class="panel hoverable clickable p-3 h-100"
                            :class="{
                                'active': slot.id === selectedSlotId
                            }"
                            @click="onSlotClick(slot.id)"
                        >
                            <div class="row">
                                <div class="col-lg-5">
                                    <small class="font-weight-bold">Start</small>
                                    <p class="m-0">{{ slot.start_at | moment('hh:mm A') }}</p>
                                </div>
                                <div class="col-lg-5">
                                    <small class="font-weight-bold">End</small>
                                    <p class="m-0">{{ slot.end_at | moment('hh:mm A') }}</p>
                                </div>
                                <div class="col-lg-2 d-flex">
                                    <span class="h4 m-auto">{{ slot.applications.length | numeral('0,0') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12" v-if="!slotsByDay[selectedSlotDay] || !slotsByDay[selectedSlotDay].length">
                        <div class="panel p-3">
                            <p class="m-0 text-center">No slots found</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <div class="row row-panels">
                    <div class="col-lg-12">
                        <div class="panel p-3">
                            <div class="row" v-if="chosenSlotCreator">
                                <div class="col d-flex">
                                    <b-img-lazy
                                        :src="chosenSlotCreator.superview.channel.logo"
                                        class="avatar my-auto"
                                    />

                                    <div
                                        class="name-rank-wrapper d-flex flex-column my-auto ml-3"
                                    >
                                        <span class="h6 m-0">
                                            {{ chosenSlotCreator.superview.channel.display_name }}

                                            <verified-badge
                                                v-if="chosenSlotCreator.superview.channel.is_partner"
                                                :platform="'twitch'"
                                            />
                                        </span>

                                        <small
                                            :style="{
                                                color: getColors.ranks[chosenSlotCreator.superview.rank.name]
                                            }"
                                        >
                                            {{ chosenSlotCreator.superview.rank.name | capitalize }}
                                        </small>
                                    </div>
                                </div>

                                <div class="col">
                                    <div class="d-flex flex-column my-auto ml-3">
                                        <small>Selected At</small>
                                        <p class="m-0">{{ chosenSlotCreator.participant.created_at | moment('calendar') }}</p>
                                    </div>
                                </div>

                                <div class="col" v-if="campaign.state === 'open'">
                                    <b-button variant="outline-danger" @click="unselectCreator(chosenSlotCreator)">
                                        Remove
                                    </b-button>
                                </div>
                            </div>

                            <p class="m-0 text-center" v-else>No creator has been selected for this slot yet.</p>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-3" v-if="selectedSlotId">
                        <applications-table
                            :is-actions-enabled="campaign.payload.slots.state === 'draft'"
                            :selected-slot="slotsById[selectedSlotId]"
                            :superviews-by-id="superviewsById"
                            @select-application="onSelectApplication"
                            :chosen-slot-creator="chosenSlotCreator"
                            :applications-by-user-id="applicationsByUserId"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import ApplicationsTable from '@/components/Tables/ApplicationsTable'

export default {
    name: 'view-campaign-applications',

    metaInfo() {
        return {
            title: 'Campaign Applications'
        }
    },

    components: {
        ApplicationsTable,
    },

    props: {
        campaign: {
            type: Object,
        },
    },

    data() {
        return {
            slotsById: {},
            slotsByDay: [],
            superviewsById: {},
            applicationsByUserId: {},

            selectedSlotId: null,
            selectedSlotDay: null,
        }
    },

    created() {
        this.getCampaignSlots()
    },

    computed: {
        ...mapGetters([
            'getColors'
        ]),

        statistics() {
            const applications = _.reduce(this.slots, (results, slot) => {
                _.forEach(slot.applications, (application) => {
                    results.push(application.user_id)
                })

                return results
            }, [])

            return {
                slot_count: _.size(this.slots),
                application_count: _.size(applications),
                application_unique_count: _.uniq(applications),
            }
        },

        chosenSlotCreator() {
            if (!this.selectedSlotDay || !this.selectedSlotId) return null

            const selectedSlot = this.slotsById[this.selectedSlotId]

            if (!selectedSlot.participant) return null

            return {
                superview: this.superviewsById[selectedSlot.participant.platform_user_id],
                participant: selectedSlot.participant,
            }
        },
    },

    methods: {
        getCampaignSlots() {
            this.axios.get(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.$route.params.campaign_id}/slots`, {
                params: {
                    relations: ['application', 'participant']
                },
            }).then(({ data }) => {
                this.slotsById = _.keyBy(data.payload.slots, 'id')
                this.slotsByDay = _(data.payload.slots).groupBy((slot) => {
                    return moment(slot.start_at).format('YYYY-MM-DD')
                }).mapValues((slots) => {
                    return _.orderBy(slots, ['start_at'], ['asc'])
                }).value()

                this.superviewsById = _.keyBy(data.payload.superviews, '_id')

                const firstDayKey = _.first(_.keys(this.slotsByDay))
                this.selectedSlotDay = _.first(_.keys(this.slotsByDay))

                const firstSlotOfDay = _.first(this.slotsByDay[this.selectedSlotDay])
                this.selectedSlotId = firstSlotOfDay ? firstSlotOfDay.id : null

                this.getApplicationsFromSlots(this.slotsById)

            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching slots for campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Slots',
                    text: message,
                    duration: 10000,
                    speed: 1000,
                })
            })
        },

        // This is it's own method so we can call it after slot data has been updated
        getApplicationsFromSlots(slotsById) {
            this.applicationsByUserId = _.reduce(slotsById, (result, slot) => {
                _.forEach(slot.applications, (application) => {
                    const cleanedApplication = _.omit(application, ['superview'])
                    cleanedApplication.slot = _.omit(slotsById[application.slot_id], ['applications', 'participant'])
                    cleanedApplication.slot.application_count = _.size(slotsById[application.slot_id].applications)

                    if (result[application.user_id]) {
                        result[application.user_id].push(cleanedApplication)
                    } else {
                        result[application.user_id] = [cleanedApplication]
                    }
                })

                return result
            }, {})
        },

        onSlotClick(slotId) {
            this.selectedSlotId = slotId
        },

        onSlotDayClick(slotDay) {
            this.selectedSlotDay = slotDay

            const firstSlotOfDay = _.first(this.slotsByDay[this.selectedSlotDay])
            this.selectedSlotId = firstSlotOfDay ? firstSlotOfDay.id : null
        },

        onSelectApplication(slot, application) {
            this.axios.post(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.$route.params.campaign_id}/slots/${slot.id}/selection`, {
                application_id: application.id,
            }).then(({ data }) => {

                // Overwrite local slots data & attach participant data to slot
                _.assign(this.slotsById[data.payload.slot.id], data.payload.slot)

                if (this.slotsById[data.payload.slot.id].participant) {
                    _.assign(this.slotsById[data.payload.slot.id].participant, data.payload.participant)
                } else {
                    this.slotsById[data.payload.slot.id].participant = data.payload.participant
                }

                this.getApplicationsFromSlots(this.slotsById)

                this.$notify({
                    group: "global",
                    type: "success",
                    text: `Successfully assigned creator to slot.`,
                    duration: 5000,
                    speed: 1000
                })

            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error selecting creator for slot.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Slot Creator Selection',
                    text: message,
                    duration: 10000,
                    speed: 1000,
                })
            })
        },
    }
}
</script>

<style lang="scss">
.view-campaign-applications {
    .row-slots {
        overflow-y: auto;
        max-height: 500px;
    }
}
</style>
