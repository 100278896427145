<template>
    <div class="view view-campaign-slots">
        <slot-applications-modal :selected-slot="modal.slot" :applications="modal.applications" />

        <div class="row row-panels mb-sm" v-if="campaign">
            <div class="col-lg-2">
                <div class="panel p-3">
                    <small>Campaign Start Time</small>
                    <p class="m-0">{{ campaign.started_at | moment('MM/DD/YYYY HH:mm A') }}</p>
                </div>
            </div>

            <div class="col-lg-2">
                <div class="panel p-3">
                    <small>Campaign End Time</small>
                    <p class="m-0">{{ campaign.ended_at | moment('MM/DD/YYYY HH:mm A') }}</p>
                </div>
            </div>

            <div class="col-lg-2">
                <div class="panel p-3">
                    <small>Allocated Slots</small>
                    <p class="m-0">{{ aggregates.count }}</p>
                </div>
            </div>

            <div class="col-lg-2">
                <div class="p-3">
                    <div>
                        <b-form-checkbox
                            v-model="isScreenshotMode"
                            :value="true"
                            :unchecked-value="false"
                        >
                            Screenshot mode?
                        </b-form-checkbox>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-2">
                <div class="panel p-3">
                    <small>Allocated Budget</small>
                    <p class="m-0">${{ aggregates.payout_amount | numeral('0,00.00') }} / ${{ campaign.budget_amount | numeral('0,00.00') }}</p>
                </div>
            </div> -->
        </div>

        <div class="row mb-sm">
            <div class="col-lg-2" v-if="!slots || slots.length === 0">
                <button class="btn btn-primary" @click="generateSlotSchedule">Generate Schedule</button>
            </div>

            <div class="col-lg-4">
                <button class="btn btn-primary" @click="saveSlots">Save Slots</button>
                <button class="btn btn-outline-primary" @click="addSlot">Add Slot</button>
            </div>

            <div class="col-lg-2" v-if="!slots || slots.length === 0">
                <b-form-group label="Slot Count from Start">
                    <b-form-input v-model="slotCountFromStart" type="number" />
                </b-form-group>
            </div>
        </div>

        <div class="row row-panels" v-for="(slots, slotDay) in slotsByDay">
            <div class="col-lg-12 mb-2">
                <p class="lead m-0">{{ slotDay | moment('dddd, MMMM Do YYYY') }}</p>
            </div>

            <div class="col-lg-2 mb-3" v-for="(slot, index) in slots">
                <div class="panel hoverable p-3">
                    <div class="row">
                        <div class="col-lg-8 mb-2">
                            <small class="font-weight-bold">Selected Creator</small>

                            <creator-tag-nameplate
                                class="mt-1"
                                v-if="slot.participant && superviewsById[slot.participant.platform_user_id]"
                                :superview="superviewsById[slot.participant.platform_user_id]"
                            />

                            <div v-else>
                                <p class="m-0">None</p>
                            </div>
                        </div>

                        <div class="col-lg-4 d-flex">
                            <b-dropdown class="ml-auto mb-auto" variant="link" toggle-class="text-decoration-none" no-caret v-if="!isScreenshotMode">
                                <template #button-content>
                                    <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
                                </template>
                                <b-dropdown-item href="#">Edit</b-dropdown-item>
                                <b-dropdown-item @click.prevent="deleteSlot(slot)">Delete</b-dropdown-item>
                            </b-dropdown>
                        </div>

                        <div class="col-lg-12" v-if="!isScreenshotMode">
                            <small class="font-weight-bold">PayPal Email</small>
                            <p class="m-0" v-if="slot.participant && usersById[slot.participant.user_id] && usersById[slot.participant.user_id].profile">
                                <input type="text" class="form-control" :value="usersById[slot.participant.user_id].profile.payload.email">
                            </p>
                            <p v-else>None</p>
                        </div>

                        <!-- <div class="col-lg-12">
                            <small class="font-weight-bold">Applications</small>
                            <p class="m-0" v-if="slot.applications.length">
                                <a class="m-0" href="#" @click.prevent="openApplicationsModal(slot)">{{ slot.applications.length }}</a>
                            </p>
                            <p class="m-0"v-else>None</p>
                        </div> -->

                        <div class="col-lg-6 mt-1">
                            <small class="font-weight-bold">Start</small>
                            <p class="m-0">{{ slot.start_at | moment('hh:mm A') }}</p>
                        </div>
                        <div class="col-lg-6 mt-1">
                            <small class="font-weight-bold">End</small>
                            <p class="m-0">{{ slot.end_at | moment('hh:mm A') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import SlotApplicationsModal from '@/components/Modals/SlotApplicationsModal'
import CreatorTagNameplate from '@/components/Nameplates/CreatorTagNameplate'

export default {
    name: 'view-campaign-slots',

    metaInfo() {
        return {
            title: 'Campaign Slots'
        }
    },

    components: {
        SlotApplicationsModal,
        CreatorTagNameplate,
    },

    props: {
        campaign: {
            type: Object,
        },
    },

    data() {
        return {
            slots: null,
            slotCountFromStart: 0,
            superviewsById: {},
            usersById: {},

            isScreenshotMode: false,

            modal: {
                slot: null,
                applications: null,
            },
        }
    },

    created() {
        this.getCampaignSlots()
    },

    computed: {
        ...mapGetters([
            'getColors',
        ]),

        aggregates() {
            return {
                count: _.size(this.slots),
                payout_amount: _.sumBy(this.slots, 'payout_amount'),
            }
        },

        slotsByDay() {
            return _(this.slots).groupBy((slot) => {
                return moment(slot.start_at).format('YYYY-MM-DD')
            }).mapValues((slots) => {
                return _.orderBy(slots, ['start_at'], ['asc'])
            }).value()
        },
    },

    methods: {
        openApplicationsModal(slot) {
            this.modal.slot = slot
            this.modal.applications = slot.applications
            this.$bvModal.show('slot-applications-modal')
        },

        getCampaignSlots() {
            this.axios.get(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.$route.params.campaign_id}/slots`, {
                params: {
                    relations: ['application', 'participant', 'user'],
                },
            }).then(({ data }) => {
                this.slots = data.payload.slots
                this.superviewsById = _.keyBy(data.payload.superviews, '_id')
                this.usersById = data.payload.usersById
            }).catch((error) => {
                if (error.is_cancelled) return

                const message = error.message ? error.message : `Error fetching slots for campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Campaign Slots',
                    text: message,
                    duration: 10000,
                    speed: 1000,
                })
            })
        },

        saveSlots() {
            this.axios.post(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.$route.params.campaign_id}/slots`, {
                slots: this.slots,
            }).then(({ data }) => {
                this.$notify({
                    group: "global",
                    type: "success",
                    text: `Successfully saved slots.`,
                    duration: 5000,
                    speed: 1000,
                })
            }).catch((error) => {
                const message = error.message ? error.message : `Error saving slots for campaign.`

                this.$notify({
                    group: 'global',
                    type: 'error',
                    title: 'Error - Save Slots',
                    text: message,
                    duration: 10000,
                    speed: 1000,
                })
            })
        },

        addSlot() {

        },

        deleteSlot(slot) {
            if (!slot.is_generated) {
                this.axios.delete(`/supercampaigns/${this.$route.params.supercampaign_id}/campaigns/${this.$route.params.campaign_id}/slots`, {
                    data: {
                        slots: [slot.id],
                    },
                }).then(({ data }) => {
                    this.slots = _.filter(this.slots, item => item.id !== slot.id)

                    this.$notify({
                        group: "global",
                        type: "success",
                        text: `Successfully deleted slots.`,
                        duration: 5000,
                        speed: 1000,
                    })
                }).catch((error) => {
                    const message = error.message ? error.message : `Error deleting slots from campaign.`

                    this.$notify({
                        group: 'global',
                        type: 'error',
                        title: 'Error - Delete Slots',
                        text: message,
                        duration: 10000,
                        speed: 1000,
                    })
                })

            // Slot only exists on client, don't delete from DB
            } else {
                this.slots = _.filter(this.slots, item => item.id !== slot.id)
            }
        },

        generateSlotSchedule() {
            const campaignStartAt = moment(this.campaign.started_at)
            const campaignEndAt = moment(this.campaign.ended_at)

            const campaignDurationHours = campaignEndAt.diff(campaignStartAt, 'hours')
            // const slotCount = campaignDurationHours / this.campaign.payload.slots.duration_hours
            const slotCount = this.slotCountFromStart
            const hoursPerSlot = this.campaign.payload.slots.duration_hours

            const schedule = []

            let slotStartAt = campaignStartAt.clone()
            let slotEndAt

            for (let i = 0; i < slotCount; i++) {
                slotEndAt = slotStartAt.clone().add(hoursPerSlot, 'hours')

                schedule.push({
                    id: `${slotStartAt.unix()}-${slotEndAt.unix()}`,
                    start_at: slotStartAt.format(),
                    end_at: slotEndAt.format(),
                    payout_amount: Number(this.campaign.payload.slots.payout_per_slot),
                    is_generated: true,
                })

                slotStartAt = slotStartAt.add(hoursPerSlot, 'hours')
            }

            this.slots = schedule
        },
    }
}
</script>

<style lang="scss">
.view-campaign-slots {
    .dropdown-toggle-no-caret {
        padding: 0 0.5rem;

        &::after {
            display: none;
        }
    }
}
</style>
