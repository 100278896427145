<template>
    <div class="chart-wrapper" ref="chartdiv"></div>
</template>

<script>
    import _ from 'lodash'
    import moment from 'moment'

    import * as am4core from '@amcharts/amcharts4/core'
    import * as am4charts from '@amcharts/amcharts4/charts'
    import am4themes_animated from '@amcharts/amcharts4/themes/animated'
    import am4themes_dark from '@amcharts/amcharts4/themes/dark'

    am4core.useTheme(am4themes_dark)
    am4core.useTheme(am4themes_animated)

    export default {
        name: 'game-series-chart',

        props: {
            fields: {
                type: Array,
            },
            series: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                chart: false,
            }
        },

        mounted() {
            this.render(true)
        },

        watch: {
            series() {
                if (this.chart) {
                    this.chart.dispose()
                }

                this.render()
            }
        },

        methods: {
            render(animate = false) {
                let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)
                chart.width = am4core.percent(100)
                chart.height = am4core.percent(100)
                chart.padding(20, 0, 0, 0)

                this.colorSet = new am4core.ColorSet()

                chart.data = _.orderBy(this.series, ['today_time'], ['asc'])

                let dateAxis = chart.xAxes.push(new am4charts.DateAxis())
                dateAxis.tooltip.disabled = true

                dateAxis.baseInterval = {
                    timeUnit: 'minute',
                    count: 30,
                }

                dateAxis.dateFormats.setKey('hour', 'h:mm a')
                dateAxis.dateFormats.setKey('minute', 'h:mm a')
                dateAxis.periodChangeDateFormats.setKey('day', '[bold]EEE h:mm a[/]')

                dateAxis.groupIntervals.setAll([
                    { timeUnit: "minute", count: 30 },
                ])

                // dateAxis.groupInterval = { timeUnit: "hour", count: 1 };

                // if (chart.data.length >= 30) {
                //     dateAxis.baseInterval = {
                //         timeUnit: 'day',
                //         count: 1
                //     }
                // } else {
                //     dateAxis.baseInterval = {
                //         timeUnit: 'hour',
                //         count: 1
                //     }
                // }

                // dateAxis.renderer.minGridDistance = 60
                // dateAxis.renderer.grid.template.location = 0
                // dateAxis.title.text = 'Time';


                chart.dateFormatter.inputDateFormat = "YYYY-MM-DDTHH:mm:ssZ"
                // chart.dateFormatter.timezoneOffset = 0


                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
                valueAxis.tooltip.disabled = true
                // valueAxis.strictMinMax = true
                // valueAxis.renderer.minGridDistance = 20

                _.forEach(this.fields, (field) => {
                    const series = chart.series.push(new am4charts.LineSeries())
                    // series.tooltipText = `[bold][color="${chart.colors.getIndex(0)}"]{${field}}[/] ${field.replace('_', ' ')} on {time.formatDate('M/d h:mm a')}`

                    if (field === 'today') {
                        series.tooltipText = `{today_time.formatDate('EEE M/d h:mm a')}
                        ----
                        Viewers: [bold][color="${chart.colors.getIndex(0)}"]{${field}_viewers_count}[/][/]
                        Streamers: [bold][color="${chart.colors.getIndex(1)}"]{${field}_channels_count}[/][/]
                        Directory Position: [bold][color="${chart.colors.getIndex(2)}"]#{${field}_order}[/][/]
                        `
                    }

                    series.dataFields.dateX = `today_time`
                    series.dataFields.valueY = `${field}_viewers_count`
                    // series.tensionX = 1
                    series.strokeWidth = 1.5
                    series.connect = false

                    if (field === 'yesterday') {
                        series.strokeDasharray = '3,4'
                        series.strokeOpacity = 0.75
                    }

                    if (field === 'lastWeek') {
                        series.strokeDasharray = '6,8'
                        series.strokeOpacity = 0.5
                    }

                    // series.stroke = am4core.color(this.color)
                    // series.tooltip.disabled = true
                    // series.tootip.fillOpacity = 0.5
                    // series.tooltip.stroke = am4core.color(this.color)
                    // series.tooltip.strokeWidth = 1
                    // series.tooltip.fill = am4core.color(this.color)
                    // series.groupFields.valueY = 'high'

                    series.tooltip.getFillFromObject = false
                    series.tooltip.background.fill = am4core.color('#000')
                    series.tooltip.background.fillOpacity = 0.85
                    series.tooltip.background.cornerRadiusTopLeft = 5
                    series.tooltip.background.cornerRadiusTopRight = 5
                    series.tooltip.background.strokeOpacity = 0
                    series.tooltip.label.minWidth = 40
                    series.tooltip.label.minHeight = 40
                    // series.tooltip.label.textAlign = "middle"
                    series.tooltip.label.textValign = "middle"

                    // series.fillOpacity = 1
                    // const gradient = new am4core.LinearGradient()
                    // gradient.addColor(chart.colors.getIndex(0), 0.05)
                    // gradient.addColor(chart.colors.getIndex(0), 0)
                    // gradient.rotation = 90
                    // series.fill = gradient

                    chart.cursor = new am4charts.XYCursor()
                    this.chart = chart
                })
            }
        },

        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose()
            }
        },
    }
</script>

<style lang="scss">
.chart-wrapper {
    width: 100%;
    height: 100%;

    [stroke-width="1.7999999999999998"][fill="none"] {
        // opacity: 0.15;
        // transition: opacity 2s;
        transform: translate(95.5%, 15px);
        transform-origin: bottom right;

        // &:hover {
        //     opacity: 0.75;
        // }
    }
}
</style>
