<template>
    <div class="page page-client-login m-0">
        <div class="row my-auto d-flex justify-content-center">
            <div class="col-lg-4 px-5 py-md bg-secondary">

                <h1 class="display-3 text-center text-uppercase mb-4">Password Reset</h1>

                <template v-if="!submitted">
                    <p>To reset your password, please provide your account's email. If that email exists in our system, reset instructions will be sent.</p>

                    <div class="form-group">
                        <label class="form-label" for="email">Email</label>
                        <input
                            class="form-control mb-2"
                            type="text"
                            name="email"
                            v-model="email"
                            @keyup.enter="send"
                        >
                    </div>

                    <div class="form-group mt-3">
                        <button
                            type="button"
                            class="btn btn-primary w-100"
                            @click="send"
                            :class="{ 'disabled': !this.email }"
                        >Send Reset Instructions</button>
                    </div>
                </template>
                <template v-else>
                    <p>
                        We have sent password reset instructions to that email
                        if there is an account associated with it.
                    </p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ForgotPassword',

    metaInfo() {
        return {
            title: 'Forgot Password'
        }
    },

    data() {
        return {
            email: '',
            submitted: false
        }
    },

    methods: {
        send() {
            if (!this.email) return

            this.axios.post(`/operators/password/forgot`, {
                email: this.email
            }).then(({ data }) => {
                console.log(data)
                this.submitted = true
            })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
