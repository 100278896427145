import Vue from 'vue'

export default {
    state: {
        drawer: {
            enabled: false,
            creator: null,
        },
        superdrawer: {
            enabled: false,
            creator: null,
            participant: null,
        },
    },

    mutations: {
        'drawer:set'(state, drawer) {
            Vue.set(state, 'drawer', drawer)
        },
        'superdrawer:set'(state, superdrawer) {
            Vue.set(state, 'superdrawer', superdrawer)
        },
    },

    getters: {
        drawer: (state) => {
            return state.drawer
        },
        superdrawer: (state) => {
            return state.superdrawer
        },
    }
}
